import {
  FETCH_PROGRESS_START,
  FETCH_PROGRESS_END,
  FETCH_PROGRESS_EMPTY,
  FETCH_PROGRESS_ERROR,
  ADD_STEP_TO_PROGRESS_START,
  ADD_STEP_TO_PROGRESS_END,
  ADD_STEP_TO_PROGRESS_ERROR
} from "./types";
import { updateUserProgress, getSteps, groupSteps } from "services/homeservices";

export const fetchProgress = (userUid) => dispatch => {
  dispatch({
    type: FETCH_PROGRESS_START
  });
  getSteps(userUid)
    .then(r => {
      dispatch({
        type: FETCH_PROGRESS_END,
        payload: {
          steps: r,
          groupedSteps: groupSteps(r),
          currentStep: r.filter(d => d.isCurrent)[0]
        }
      });
    })
    .catch(e => {
      dispatch({
        type: FETCH_PROGRESS_ERROR,
        payload: e
      });
    });
};

export const addStepToProgress = (userUid, step, state, callback) => dispatch => {
  dispatch({
    type: ADD_STEP_TO_PROGRESS_START
  });

  state.timestamp = new Date();

  updateUserProgress(userUid, step, state)
    .then(result => {
      dispatch({
        type: ADD_STEP_TO_PROGRESS_END,
        payload: { code: step, state }
      })
      if (callback) callback()
    })
    .catch(error => {
      dispatch({ type: ADD_STEP_TO_PROGRESS_ERROR, payload: error })
    })
}