import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import HeaderLanding from "components/Header/HeaderLanding.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// import Button from "components/CustomButtons/Button.jsx";
// sections for this page
import SectionText from "./Sections/SectionText.jsx";
import SectionText2 from "./Sections/SectionText2.jsx";
// import SectionComponents from "./Sections/SectionComponents.jsx";
import SectionCards from "./Sections/SectionCards.jsx";

import { connect } from "react-redux";
import { Redirect } from "react-router-i18n";

import landingPageStyle from "assets/jss/material-ui-styles/views/landingPageStyle.jsx";

import Card from "components/Card/Card.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Muted from "components/Typography/Muted.jsx";

import marc from "assets/img/faces/marc.jpg";
import christian from "assets/img/faces/christian.jpg";
import kendall from "assets/img/faces/kendall.jpg";
import avatar from "assets/img/faces/avatar.jpg";
import I18n from "../../i18n";

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      loading: true
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }
  render() {
    const { classes } = this.props;
    console.log("cae por aca")
    if (this.props.auth && this.props.auth.uid) return <Redirect to="/home" />;

    return (
      <div>
        <HeaderLanding
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 300,
            color: "white"
          }}
        />
        <Parallax
          image={require("assets/img/main_background.jpg")}
          filter="dark"
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem md={8} className={classes.textCenter}>
                <h1 className={classes.title}>
                  <I18n t="LANDING_HEADER_TITLE"/>
                </h1>
                <h4 className={classes.subtitle}>
                  <I18n t="LANDING_HEADER_TEXT"/>
                </h4>

                {/* <br />
                <Button color="primary" size="sm" round href="#SectionText">
                  Ingresar
                </Button> */}
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classes.main}>
          <div>
            <div className={classes.container}>
              <SectionText />
            </div>
            <div className={classes.container}>
              <SectionCards />
            </div>
            {/* <div
              className={classes.team}
              style={{ marginTop: 50, marginBottom: 80 }}
            >
              <div className={classes.container}>
                <GridContainer justify="center">
                  <GridItem
                    xs={12}
                    sm={8}
                    md={8}
                    className={`${classes.mlAuto} ${classes.mrAuto} ${
                      classes.textCenter
                    }`}
                  >
                    <h2 className={classes.title2}>Nuestros profesionales</h2>
                    <h5 className={classes.description}>
                      Tenemos un equipo que te acompañará a lo largo de todo el
                      proceso, sin importar cuanto te demore.
                    </h5>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={3} md={3}>
                    <Card profile plain>
                      <CardAvatar profile plain>
                        <a href="#" onClick={e => e.preventDefault()}>
                          <img src={marc} alt="..." className={classes.img} />
                        </a>
                      </CardAvatar>
                      <CardBody>
                        <h4 className={classes.cardTitle}>Pat</h4>
                        <Muted>
                          <h6 className={classes.cardCategory}>
                            CEO / Co-Founder
                          </h6>
                        </Muted>
                        <p className={classes.description}>
                          And I love you like Kanye loves Kanye. We need to
                          restart the human foundation.
                        </p>
                      </CardBody>
                      <CardFooter profile className={classes.justifyContent}>
                        <Button
                          href="#pablo"
                          justIcon
                          simple
                          color="twitter"
                          className={classes.btn}
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fab fa-twitter" />
                        </Button>
                        <Button
                          href="#pablo"
                          justIcon
                          simple
                          color="facebook"
                          className={classes.btn}
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fab fa-facebook" />
                        </Button>
                        <Button
                          href="#pablo"
                          justIcon
                          simple
                          color="google"
                          className={classes.btn}
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fab fa-google" />
                        </Button>
                      </CardFooter>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={3} md={3}>
                    <Card profile plain>
                      <CardAvatar profile plain>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <img
                            src={kendall}
                            alt="..."
                            className={classes.img}
                          />
                        </a>
                      </CardAvatar>
                      <CardBody>
                        <h4 className={classes.cardTitle}>Mujer bonita</h4>
                        <Muted>
                          <h6 className={classes.cardCategory}>DESIGNER</h6>
                        </Muted>
                        <p className={classes.description}>
                          Don't be scared of the truth because we need to
                          restart the human foundation. And I love you like
                          Kanye loves Kanye.
                        </p>
                      </CardBody>
                      <CardFooter profile className={classes.justifyContent}>
                        <Button
                          href="#pablo"
                          justIcon
                          simple
                          color="twitter"
                          className={classes.btn}
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fab fa-twitter" />
                        </Button>
                        <Button
                          href="#pablo"
                          justIcon
                          simple
                          color="dribbble"
                          className={classes.btn}
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fab fa-dribbble" />
                        </Button>
                        <Button
                          href="#pablo"
                          justIcon
                          simple
                          color="linkedin"
                          className={classes.btn}
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fab fa-linkedin" />
                        </Button>
                      </CardFooter>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={3} md={3}>
                    <Card profile plain>
                      <CardAvatar profile plain>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <img
                            src={christian}
                            alt="..."
                            className={classes.img}
                          />
                        </a>
                      </CardAvatar>
                      <CardBody>
                        <h4 className={classes.cardTitle}>Agu</h4>
                        <Muted>
                          <h6 className={classes.cardCategory}>The Psico</h6>
                        </Muted>
                        <p className={classes.description}>
                          I love you like Kanye loves Kanye. Don't be scared of
                          the truth because we need to restart the human
                          foundation.
                        </p>
                      </CardBody>
                      <CardFooter profile className={classes.justifyContent}>
                        <Button
                          href="#pablo"
                          justIcon
                          simple
                          color="facebook"
                          className={classes.btn}
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fab fa-facebook" />
                        </Button>
                        <Button
                          href="#pablo"
                          justIcon
                          simple
                          color="dribbble"
                          className={classes.btn}
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fab fa-dribbble" />
                        </Button>
                      </CardFooter>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={3} md={3}>
                    <Card profile plain>
                      <CardAvatar profile plain>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <img src={avatar} alt="..." />
                        </a>
                      </CardAvatar>
                      <CardBody>
                        <h4 className={classes.cardTitle}>Second women</h4>
                        <Muted>
                          <h6 className={classes.cardCategory}>Psico</h6>
                        </Muted>
                        <p className={classes.description}>
                          And I love you like Kanye loves Kanye. We really need
                          to restart the human foundation.
                        </p>
                      </CardBody>
                      <CardFooter
                        profile
                        plain
                        className={classes.justifyContent}
                      >
                        <Button
                          justIcon
                          simple
                          color="google"
                          href="#pablo"
                          className={classes.btn}
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fab fa-google" />
                        </Button>
                        <Button
                          justIcon
                          simple
                          color="twitter"
                          href="#pablo"
                          className={classes.btn}
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fab fa-twitter" />
                        </Button>
                        <Button
                          justIcon
                          simple
                          color="dribbble"
                          href="#pablo"
                          className={classes.btn}
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fab fa-dribbble" />
                        </Button>
                      </CardFooter>
                    </Card>
                  </GridItem>
                </GridContainer>
              </div>
            </div> */}
            <SectionText2 />
            <div className={classes.textCenter} style={{ marginTop: 50 }}>
              <h5 className={classes.description}>
                ¿Tenés alguna consulta?{" "}
                <a href="/contact-us">
                  <b>¡Contactanos!</b>
                </a>
              </h5>
              <br />
            </div>
            {/* <SectionComponents /> */}
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(
  withStyles(landingPageStyle)(LandingPage)
);
