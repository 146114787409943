import React from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ImageGalleryWithVideos from "./ImageGalleryWithVideos";
import Accordion from "components/Accordion/Accordion.jsx";

export function CareerDetail({ countryCode, career, favButton }) {
    const collapse = [];

    if (career &&
        career.PublicUniversities &&
        career.PublicUniversities.length > 0) {
        collapse.push({
            title: "Universidades Públicas",
            content: (
                <ul>
                    {career.PublicUniversities.map(publicUniversity => {
                        if (publicUniversity && publicUniversity.Name.trim() != "") {
                            return (
                                <li style={{ display: "list-item" }} key={publicUniversity.Name}>
                                    <a
                                        href={`/${countryCode}/universities/${publicUniversity.Id}`}
                                        style={{ textDecoration: "none", color: "black" }}
                                    >
                                        Ir a {publicUniversity.Name}
                                    </a>
                                </li>
                            );
                        }
                        return null;
                    })}
                </ul>
            )
        });
    }

    if (career &&
        career.PrivateUniversities &&
        career.PrivateUniversities.length > 0) {
        collapse.push({
            title: "Universidades Privadas",
            content: (
                <ul>
                    {career &&
                        career.PrivateUniversities &&
                        career.PrivateUniversities.map(privateUniversity => {
                            if (privateUniversity && privateUniversity.Name.trim() != "") {
                                return (
                                    <li style={{ display: "list-item" }} key={privateUniversity.Name}>
                                        <a
                                            href={`/${countryCode}/universities/${privateUniversity.Id}`}
                                            style={{ textDecoration: "none", color: "black" }}
                                        >
                                            Ir a {privateUniversity.Name}
                                        </a>
                                    </li>
                                );
                            }
                            return null;
                        })}
                </ul>
            )
        });
    }

    if (career &&
        career.OtherIntitutions &&
        career.OtherIntitutions.trim() != "") {
        collapse.push({
            title: "Otras instituciones",
            content: (
                <ul>
                    {career &&
                        career.OtherIntitutions &&
                        career.OtherIntitutions.split(";").map(
                            otherInstitution => {
                                return (
                                    <li key={otherInstitution} style={{ display: "list-item" }}>
                                        Ir a {otherInstitution}
                                    </li>
                                );
                            }
                        )}
                </ul>
            )
        });
    }

    return <GridContainer>
        <GridItem md={6} sm={6}>
            <ImageGalleryWithVideos
                image={{
                    thumbnail: career.DetailImage,
                    original: career.DetailImage
                }}
                videos={career.videos}
            />
        </GridItem>
        <GridItem md={6} sm={6}>
            {career && career.ShowName && <h2> {career.Name}</h2>}
            {career && <p>{career.ShortDescription}</p>}
            <Accordion
                activeColor="rose"
                collapses={[
                    {
                        title: "Descripción",
                        content: (
                            <p>{career && career.LongDescription}</p>
                        )
                    },
                    ...collapse
                ]}
            />
            <GridContainer>
                {favButton}
            </GridContainer>
        </GridItem>
    </GridContainer>;
}