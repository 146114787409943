//const welcomePage = require('assets/emailTemplates/welcome.html');
const axios = require("axios");

const emailFromIPage =
  "https://us-central1-instituto-pre-universitario.cloudfunctions.net/sendEmail/from-ipage";

const emailPush =
  "https://us-central1-instituto-pre-universitario.cloudfunctions.net/sendEmail/push";

export const sendMeetupNotification = meetupData => {
  return sendEmailFromIPage(
    "info@woiro.com",
    "infowoiro@gmail.com",
    "ENCUENTRO",
    "Datos del usuario para la reunión " + JSON.stringify(meetupData),
    null
  );
};

export const sendPushEmail = pushEmailData => {
  let config = {
    headers: {
      //'Authorization': 'Bearer ' + token,
      //Authentication...
      "Content-Type": "application/json",
      Accept: `application/json, text/plain, */*`
    }
  };

  return axios.post(emailPush, pushEmailData, config);
};

export const sendContactNotification = contactData => {
  //var html = welcomePage;

  //html.replace('%USER_NAME%',)
  return sendEmailFromIPage(
    "info@woiro.com",
    "infowoiro@gmail.com",
    "CONTACTO",
    JSON.stringify(contactData),
    null
  );
  // }
  //});
};

export const sendEmailToMentor = (emailData, mentorEmail) => {
  return sendEmailFromIPage(
    "info@woiro.com",
    "infowoiro@gmail.com, " + mentorEmail,
    "User message",
    JSON.stringify(emailData),
    null
  );
  // }
  //});
};

export const sendPurchaseInternalNotification = user => {
  return sendEmailFromIPage(
    "info@woiro.com",
    "infowoiro@gmail.com",
    "PAGO",
    `El user ${user.email} pago, grabar ingreso`,
    null
  );
};

export const sendErrorNotification = user => {
  return sendEmailFromIPage(
    "info@woiro.com",
    "infowoiro@gmail.com",
    "FALLO EL TEST",
    `TEST DEL USARIO:${user.email}`,
    null
  );
};

export const sendEmailFromIPage = (from, to, subject, text, html) => {
  let config = {
    headers: {
      //'Authorization': 'Bearer ' + token,
      //Authentication...
      "Content-Type": "application/json",
      Accept: `application/json, text/plain, */*`
    }
  };

  return axios.post(
    emailFromIPage,
    {
      from: from,
      to: to,
      subject: subject,
      text: text,
      html: html
    },
    config
  );
};