export const STEP_WELCOME = "step-welcome";
export const STEP_USER_PROFILE = "step-user-profile";
export const STEP_CATEGORIES = "step-categories";
export const STEP_FEELING = "step-feeling";
export const STEP_EXPLORE = "step-explore";

export const STEP_EXPERIENCE_1 = "step-experience-1";
export const STEP_FORUM = "step-forum";
export const STEP_TEST = "step-test";
export const STEP_TEST_PROFESIONAL_GENERATIONAL_TREE =
  "step-test-professional-generational-tree";

export const STEP_MEET_OUR_TEAM = "step-meet-our-team";
export const STEP_COFFEE = "step-coffee";
export const STEP_MILESTONE_ONE = "step-milestone_one";

export const STEP_EXPERIENCE_2 = "step-experience-2";
export const STEP_CONDICIONAMIENTO_TEMPRANO = "step-condic";

export const STEP_SECOND_TEST = "step-second-test";
export const STEP_FOCUS = "step-focus";
export const STEP_MILESTONE_TWO = "step-milestone_two";
export const STEP_REST = "step-rest";
export const STEP_STUDY = "step-study";
export const STEP_TOUR = "step-tour";
export const STEP_EXPERIENCE_3 = "step-experience-3";

export const STEP_STAY_ACTIVE = "step-stay-active";
export const STEP_WORK = "step-work";

export const STEP_MEET_OUR_TEAM_FINAL = "step-meet-our-team-final";
export const STEP_MILESTONE_THREE = "step-milestone_three";

export const STEP_SELECTION = "step-selection";
export const STEP_LOCATION = "step-location";
export const STEP_ENROLL = "step-enroll";
export const STEP_CELEBRATE = "step-celebrate";
export const STEP_COLABORATE = "step-colaborate";
export const STEP_SHARE = "step-share";

export const STEP_MILESTONE_FINAL = "step-milestone_final";

export const HOME_INITIAL_TITLE = "¡Bienvenido a Woiro!";
export const HOME_INITIAL_TEXT =
  "Diseñamos especialmente cada paso para que puedas ir descubriendo y adquiriendo los recursos necesarios para tomar la mejor decisión. ¡Te invitamos a empezar por este video!";

export const STEP_FEELING_REQUIRED_CAREERS = 5; //todo 10
export const STEP_FOCUS_REQUIRED_CAREERS = 3; //todo 10
