import {
  FIRST_STEP_TEN_CAREERS,
  SECOND_STEP_FIVE_CAREERS,
  THIRD_STEP_THREE_CAREERS,
  FINAL_STEP_SINGLE_CAREER,
} from "./favoriteCareersServices";
import { authRef } from "config/firebase";

const notificationServices = require("services/notificationservices");

const firebase = require("../config/firebase");
const firestore = firebase.db;
const colRef = firestore.collection("users");
const colRefAppointments = firestore.collection("appointments");

export function updateFirebaseEmail(email) {
  var user = authRef.currentUser;
  return user
    .updateEmail(email)
    .then(function () {})
    .catch(function (error) {
      console.log(error);
    });
}

export function updateFirebaseDisplayName(name) {
  var user = authRef.currentUser;
  return user
    .updateProfile({
      displayName: name,
    })
    .then(function () {})
    .catch(function (error) {
      console.log(error);
    });
}

function _upsertUser(user) {
  return colRef
    .doc(user.uid)
    .set(user, { merge: true })
    .catch((err) => console.log(err));
}

function _updateUser(user) {
  colRef
    .doc(user.uid)
    .update(user)
    .catch((err) => console.log(err));
}

function _addPurchase(user, purchaseCode) {
  return colRef
    .doc(user.uid)
    .update("purchaseInfo", purchaseCode)
    .catch((err) => console.log(err));
}

function _addAssesmentTest(user, testResult) {
  return colRef
    .doc(user.uid)
    .update("assesmentTest", testResult)
    .catch((err) => console.log(err));
}

function _addCondicionamientoTemprano(user, testResult) {
  return colRef
    .doc(user.uid)
    .update("condicionamientoTemprano", testResult)
    .catch((err) => console.log(err));
}

function _addUniversity(userUid, university) {
  return colRef
    .doc(userUid)
    .collection("universities")
    .doc(university.uid)
    .set(university)
    .catch((err) => console.log(err));
}

function _deleteUniversity(userUid, university) {
  return colRef
    .doc(userUid)
    .collection("universities")
    .doc(university.uid)
    .delete()
    .catch((err) => console.log(err));
}

function _getUsers(process) {
  return colRef
    .get()
    .then(function (snapshot) {
      snapshot.forEach(function (doc) {
        process(doc.data());
      });
    })
    .catch((err) => console.log(err));
}

function _getUser(userUid) {
  return colRef
    .doc(userUid)
    .get()
    .then(function (doc) {
      if (doc && doc.exists) {
        return doc.data();
      }
    })
    .catch((err) => console.log(err));
}

function _getUserByEmail(currentUser, userEmail) {
  if (
    !currentUser.rols ||
    !currentUser.rols.find((r) => {
      return r == "psico";
    })
  )
    throw { message: "error", code: "err-rol" };

  return colRef
    .where("email", "==", userEmail)
    .get()
    .then(function (doc) {
      if (doc && doc.docs && doc.docs.length != 0) {
        return doc.docs[0].data();
      }

      return null;
    })
    .catch((err) => console.log(err));
}

function _getSubcollection(userUid, subCollectionName) {
  return colRef
    .doc(userUid)
    .collection(subCollectionName)
    .get()
    .then((querySnapshot) => {
      var docs = [];
      querySnapshot.forEach(function (doc) {
        docs.push(doc.data());
      });
      return docs;
    })
    .catch((err) => console.log(err));
}

function _addAppointment(user, appointment) {
  appointment.uid = colRefAppointments.doc().id;

  let userMessage = {
    appointment: appointment,
    userUid: user.uid,
    userEmail: user.email,
  };

  notificationServices
    .sendContactNotification(userMessage)
    .catch((err) => console.log(err));

  return colRef
    .doc(user.uid)
    .collection("appointments")
    .doc(appointment.uid)
    .set(appointment)
    .catch((err) => console.log(err));
}

function _deleteAppointment(user, appointment) {
  return colRef
    .doc(user.uid)
    .collection("appointments")
    .doc(appointment.uid)
    .delete()
    .catch((err) => console.log(err));
}

function _setMentor(user, mentorUid) {
  return colRef
    .doc(user.uid)
    .update("mentor", mentorUid)
    .catch((err) => console.log(err));
}

function _getMentor(auth) {
  return new Promise(function (resolve, reject) {
    _getUser(auth.uid)
      .then((user) => {
        resolve(user.mentor);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export const createPayment = (userUid, paymentPlanCode) => {
  const usersColRef = firestore.collection("users");

  usersColRef
    .doc(userUid)
    .set(
      {
        purchase: {
          purchaseData: "manual-payment",
          date: new Date(),
        },
        plan: paymentPlanCode,
      },
      { merge: true }
    )
    .then((result) => alert("Ok"))
    .catch((e) => {
      console.log(e);
      alert(e.message);
    });
};

export const deleteProgress = (userUid) => {
  const progressColRef = firestore.collection("progress");
  progressColRef
    .doc(userUid)
    .delete()
    .then((result) => console.log("progress deleted"))
    .catch((e) => console.log(e));
  deleteFavoriteSubcollection(userUid, FIRST_STEP_TEN_CAREERS);
  deleteFavoriteSubcollection(userUid, SECOND_STEP_FIVE_CAREERS);
  deleteFavoriteSubcollection(userUid, FINAL_STEP_SINGLE_CAREER);
};

function deleteFavoriteSubcollection(userUid, step) {
  const favoriteCareersColRef = firestore.collection("favorite_careers");
  favoriteCareersColRef
    .doc(userUid)
    .collection(step)
    .get()
    .then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        if (doc.exists) {
          favoriteCareersColRef
            .doc(userUid)
            .collection(step)
            .doc(doc.id)
            .delete()
            .then((result) => console.log("favorite deleted", result))
            .catch((error) => {
              console.log("error deleting favorite", error);
            });
        }
      });
    });
}

export const upsertUser = _upsertUser;
export const updateUser = _updateUser;
export const getUser = _getUser;
export const getUsers = _getUsers;
export const addUniversity = _addUniversity;
export const deleteUniversity = _deleteUniversity;
export const addAssesmentTest = _addAssesmentTest;
export const addCondicionamientoTemprano = _addCondicionamientoTemprano;
export const addPurchase = _addPurchase;
export const addAppointment = _addAppointment;
export const deleteAppointment = _deleteAppointment;
export const setMentor = _setMentor;
export const getSubcollection = _getSubcollection;
export const getMentor = _getMentor;
export const getUserByEmail = _getUserByEmail;
