import {
  container,
  section,
  sectionDark,
  sectionDescription,
  title,
  mlAuto,
  mrAuto,
  blackColor,
  whiteColor,
  hexToRgb
} from "assets/jss/material-ui-styles.jsx";

const cardsStyle = {
  section: {
    ...section,
    padding: "50px 0px"
  },
  sectionDark,
  container,

  sectionDescription,
  title2: {
    ...title
  },
  title: {
    ...title,
    color: "#fff",
    marginTop: "30px",
    marginBottom: "25px",
    minHeight: "32px"
  },
  description: {
    color: "rgba(255,255,255,0.76)"
  },
  imageContainer: {
    maxWidth: "1040px",
    marginTop: "-140px",
    position: "relative",
    height: "660px",
    "& img": {
      maxWidth: "1040px",
      width: "auto",
      position: "absolute",
      right: "0px",
      top: "0px"
    }
  },
  mlAuto,
  mrAuto,
  features3: {
    padding: "80px 0",
    "& $phoneContainer": {
      maxWidth: "220px",
      margin: "0 auto"
    }
  },
  phoneContainer: {
    "& img": {
      width: "100%"
    }
  },
  infoArea: {
    maxWidth: "none",
    margin: "0 auto",
    padding: "10px 0 0px"
  },

  team: {
    padding: "80px 0",
    "& h5$description,& h5$descriptionWhite": {
      marginBottom: "80px"
    }
  },
  textCenter: {
    textAlign: "center"
  }
};

export default cardsStyle;
