import React, { Component } from "react";
import NotificationComponent from "../../components/Notification/NotificationComponent";
import { connect } from "react-redux";
import DetailPage from "./CareerAdminDetailPage";
import { getPlaylistVideos } from "services/youtube/playlistitemsservices";
import { getCareer } from "services/careerservices";
import { NEW_CAREER_IDENTIFIER } from "services/careerservices";

const careerServices = require("services/careerservices");
const careerspageservices = require("services/careerspageservices");

class CareerAdminDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detail: {
        career: {},
        otherCareersByCategory: [],
        careerVideos: []
      }
    };
  }

  saveCareer = career => {
    careerServices
      .upsertCareer(career, this.props.auth)
      .then(() => {
        careerspageservices
          .updateLists(career)
          .then(() => {
            alert("Gracias por contribuir al Woiro-Wiki !");

            if (this.isCreatingCareer()) {
              this.props.history.goBack();
              return;
            }

            this.state.detail.career = career;
            this.setState({ detail: this.state.detail });
          })
          .catch(e => {
            console.log(e);
            alert("error");
          });
      })
      .catch(e => {
        console.log(e);
        alert("error");
      });
  };

  mapYoutubeVideos = data => {
    return data.items.map(element => {
      return {
        thumbnail: `${element.snippet.thumbnails.default.url}`,
        original: `${element.snippet.thumbnails.default.url}`,
        embedUrl: `https://www.youtube.com/embed/${
          element.snippet.resourceId.videoId
          }`
      };
    });
  };

  isCreatingCareer() {
    return this.props.match.params.careerUid == "new";
  }
  componentDidMount() {
    //crear nueva
    if (this.isCreatingCareer()) {
      getCareer(NEW_CAREER_IDENTIFIER).then(career => {
        this.setState({
          detail: {
            career: career,
            otherCareersByCategory: [],
            careerVideos: []
          }
        });
        // getPlaylistVideos(career.YoutubePlaylistId)
        //   .then(response =>
        //     this.setState({
        //       detail: {
        //         career: career,
        //         otherCareersByCategory: [],
        //         careerVideos: this.mapYoutubeVideos(response.data)
        //       }
        //     })
        //   )
        //   .catch(e => {
        //     this.setState({
        //       detail: {
        //         career: career,
        //         otherCareersByCategory: [],
        //         careerVideos: []
        //       }
        //     });
        //   });
      });
    } else {
      getCareer(this.props.match.params.careerUid).then(career => {
        getPlaylistVideos(career.YoutubePlaylistId)
          .then(response =>
            this.setState({
              detail: {
                career: career,
                otherCareersByCategory: [],
                careerVideos: this.mapYoutubeVideos(response.data)
              }
            })
          )
          .catch(e => {
            this.setState({
              detail: {
                career: career,
                otherCareersByCategory: [],
                careerVideos: []
              }
            });
          });
      });
    }
  }

  render() {
    console.log(this.props);
    return (
      <div>
        <NotificationComponent />
        <DetailPage
          {...this.props}
          {...this.state}
          saveCareer={this.saveCareer.bind(this)}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CareerAdminDetailContainer);
