import {
  CAREERSPAGE_FETCH_CAREERS_START,
  CAREERSPAGE_FETCH_CAREERS,
  CAREERSPAGE_FETCH_CAREERS_ERROR
} from "../types/careerPageTypes";

const initialState = {
  list: [],
  isLoadingCareersList: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CAREERSPAGE_FETCH_CAREERS_ERROR:
      return {
        ...state,
        list: null,
        isLoadingCareersList: false,
        fetchError: action.error
      };
    case CAREERSPAGE_FETCH_CAREERS:
      return { ...state, list: action.payload, isLoadingCareersList: false };
    case CAREERSPAGE_FETCH_CAREERS_START:
      return { ...state, isLoadingCareersList: true };
    default:
      return state;
  }
};
