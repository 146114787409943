import React from "react";
// react component used to create nice image meadia player
import ImageGallery from "react-image-gallery";

class ImageGalleryWithVideos extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showVideo: {},
            showFullscreenButton: true,
            showGalleryFullscreenButton: false,
            showPlayButton: true,
            showGalleryPlayButton: false
        };
    }

    _resetVideo() {
        this.setState({ showVideo: {} });

        if (this.state.showPlayButton) {
            this.setState({ showGalleryPlayButton: true });
        }

        if (this.state.showFullscreenButton) {
            this.setState({ showGalleryFullscreenButton: true });
        }
    }

    _toggleShowVideo(url) {
        this.state.showVideo[url] = !Boolean(this.state.showVideo[url]);
        this.setState({
            showVideo: this.state.showVideo
        });

        if (this.state.showVideo[url]) {
            if (this.state.showPlayButton) {
                this.setState({ showGalleryPlayButton: false });
            }

            if (this.state.showFullscreenButton) {
                this.setState({ showGalleryFullscreenButton: false });
            }
        }
    }
    //generar componenete con los stylos
    _renderVideo(item) {
        return (
            <div className="image-gallery-image">
                {
                    <div className="video-wrapper">
                        <a
                            className="close-video"
                            onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
                        />
                        <iframe
                            width="100%"
                            height="250"
                            src={item.embedUrl}
                            frameBorder="0"
                            allowFullScreen
                        />
                    </div>
                }
            </div>
        );
    }

    render() {
        return (
            <ImageGallery
                showFullscreenButton={false}
                showPlayButton={false}
                showNav={true}
                startIndex={0}
                items={[
                    {
                        thumbnail: this.props.image.thumbnail,
                        original: this.props.image.original
                    }
                ].concat(
                    this.props.videos.map(video => {
                        return {
                            ...video,
                            renderItem: this._renderVideo.bind(this)
                        };
                    })
                )}
                style={{ minHeight: 200 }}
            />);
    }
}

export default ImageGalleryWithVideos;
