import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Redirect } from 'react-router-i18n';

export default function (ComposedComponent) {
  class Authentication extends Component {
    static contextTypes = {
      router: PropTypes.object
    };

    // componentWillMount() {
    //   console.log(this.props);
    //   if (this.props.authenticated === false) {
    //       this.context.router.history.push("/login-page");
    //   }
    // }

    // componentWillUpdate(nextProps) {
    //   console.log(nextProps);
    //   if (!nextProps.authenticated) {
    //       this.context.router.history.push("/login-page");
    //   }
    // }

    render() {
      if (this.props.authenticated && this.props.authenticated.isLoggedIn) {
        return <ComposedComponent {...this.props} />;
      }

      return <Redirect to={`/login-page`} />
    }
  }

  function mapStateToProps(state) {
    return {
      authenticated: state.auth,
      country: state.country
    };
  }

  return connect(mapStateToProps)(Authentication);
}
