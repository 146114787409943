import React, { Component } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Loading from "components/Loader/Loading.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui icons
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import styles from "../jss/sections/favoritesStyle.jsx";

import PropTypes from "prop-types";

import Subject from "@material-ui/icons/Subject";
import WatchLater from "@material-ui/icons/WatchLater";
import office1 from "assets/img/examples/office1.jpg";
import cardBlog3 from "assets/img/examples/card-blog3.jpg";
import SingleLineGridList from "../Sections/SingleLineGridList";
import Hidden from "@material-ui/core/Hidden";

class SectionFavorites extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    let height = 200;
    let content = <div>A&uacute;n no elegiste tus carreras favoritas</div>;
    console.log("favoritecareers count", this.props.favoriteCareers.length);
    let fill = (
      <Hidden smDown>
        <GridItem xs={12} sm={4} md={4}>
          <Card style={{ minHeight: height, maxHeight: height }}>
            <CardBody background>
              <Button
                simple
                color="primary"
                href="#SectionProducts"
                style={{ paddingTop: 50 }}
              >
                <FavoriteBorder /> Seleccionar
              </Button>
            </CardBody>
          </Card>
        </GridItem>
      </Hidden>
    );

    if (this.props.favoriteCareers && this.props.favoriteCareers.length > 0) {
      content = (
        <GridContainer>
          {this.props.favoriteCareers
            .filter(
              career => career.Discard === undefined || career.Discard === false
            )
            .map((career, index) => {
              return (
                <GridItem key={index} xs={12} sm={4} md={4}>
                  <Card
                    background
                    style={{
                      backgroundImage: `url(${career.ListImage})`,
                      minHeight: height,
                      maxHeight: height
                    }}
                  >
                    <CardBody
                      background
                      style={{
                        minHeight: height,
                        maxHeight: height,
                        paddingTop: 0
                      }}
                    >
                      <h6
                        className={classes.cardCategoryWhite}
                        onClick={e => {
                          e.preventDefault();
                          this.props.careerDetail(career, { Careers: [] });
                        }}
                      >
                        {career.categoryLabel}
                      </h6>
                      <a href="" onClick={e => e.preventDefault()}>
                        <h3
                          className={classes.cardTitleWhite}
                          style={{ minHeight: 80 }}
                          onClick={e => {
                            e.preventDefault();
                            this.props.careerDetail(career, { Careers: [] });
                          }}
                        >
                          {career.Name}
                        </h3>
                      </a>

                      <Button
                        justIcon
                        color="rose"
                        simple
                        style={{ zIndex: 10 }}
                        onClick={e => {
                          e.preventDefault();
                          this.props.deleteCareerFromFavorite(career);
                        }}
                      >
                        <Favorite />
                      </Button>

                      <Button
                        simple
                        color="white"
                        onClick={e => {
                          e.preventDefault();
                          this.props.careerDetail(career, { Careers: [] });
                        }}
                      >
                        <Subject /> Ver
                      </Button>
                    </CardBody>
                  </Card>
                </GridItem>
              );
            })}
          {this.props.favoriteCareers.length < 2 && fill}
          {this.props.favoriteCareers.length < 3 && fill}
        </GridContainer>
      );
    }

    return (
      <div className={classes.section} style={{ paddingTop: 30 }}>
        <div className={classes.container}>
          {this.props.isLoadingFavoriteCareers && <Loading />}
          {!this.props.isLoadingFavoriteCareers && content}
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(SectionFavorites);
