import React, { Component } from "react";
import NotificationComponent from "../../components/Notification/NotificationComponent";
import { connect } from "react-redux";
import DetailPage from "./ProductPage";
import { getPlaylistVideos } from "services/youtube/playlistitemsservices";
import { getCareer } from "services/careerservices";

import {
  fetchFavoriteCareersFromStep,
  addFavoriteCareer,
  deleteFavoriteCareer
} from "../../redux/favorite_careers/actions";
import { addNotification } from "../../redux/notifications/actions";
import { STEP_FEELING_REQUIRED_CAREERS } from "types/progressStepsTypes";
import { FIRST_STEP_TEN_CAREERS } from "../../services/favoriteCareersServices";
const homeServices = require("services/homeservices");

class DetailContainer extends Component {
  constructor(props) {
    super(props);
    this.addCareerToFavorite = this.addFavoriteCareer.bind(this);
    this.deleteCareerFromFavorite = this.deleteFavoriteCareer.bind(this);
    this.state = {
      detail: {
        career: {},
        otherCareersByCategory: [],
        careerVideos: []
      }
    };
  }

  addFavoriteCareer = career => {
    if (
      this.props.progress &&
      this.props.progress.data &&
      this.props.progress.data.STEP_FEELING
    )
      return;

    return this.props
      .addFavoriteCareer(
        this.props.auth.uid,
        this.props.favoriteCareers.lastStep,
        career,
        this.props.favoriteCareers.count + 1,
        STEP_FEELING_REQUIRED_CAREERS
      )
      .then(r => {
        this.innerSetFavoriteCareerCount(
          this.props.auth,
          this.props.favoriteCareers.count
        );
      })
      .catch(e => {
        console.log(e);
      });
  };

  innerSetFavoriteCareerCount(user, count) {
    homeServices.setFavoriteCareerCount(user, count);
    if (
      !this.props.progress.data.STEP_FEELING &&
      count >= STEP_FEELING_REQUIRED_CAREERS
    ) {
      homeServices.setStepFeeling(user);
      let message = (
        <b>
          Excelente, ya podés avanzar. <br /> <a href="/home">Siguiente paso</a>
        </b>
      );
      this.props.addNotification(message, 4000);
    }
  }

  deleteFavoriteCareer = career => {
    if (
      this.props.progress &&
      this.props.progress.data &&
      this.props.progress.data.STEP_FEELING
    )
      return;

    return this.props
      .deleteFavoriteCareer(
        this.props.auth.uid,
        this.props.favoriteCareers.lastStep,
        career
      )
      .then(r => {
        this.innerSetFavoriteCareerCount(
          this.props.auth,
          this.props.favoriteCareers.count
        );
      })
      .catch(e => {
        console.log(e);
      });
  };

  careerDetail(career, list) {
    this.props.history.push(`/${this.props.country.code}/careers/${career.Id}`, {
      career,
      otherCareersByCategory: list
    });
  }

  mapYoutubeVideos = data => {
    if (data)
      return data.items.map(element => {
        return {
          thumbnail: `${element.snippet.thumbnails.default.url}`,
          original: `${element.snippet.thumbnails.default.url}`,
          embedUrl: `https://www.youtube.com/embed/${element.snippet.resourceId.videoId}`
        };
      });
    else return [];
  };

  componentDidMount() {
    if (
      !this.props.favoriteCareers ||
      !this.props.favoriteCareers.list ||
      this.props.favoriteCareers.list.length <= 0
    ) {
      this.props.fetchFavoriteCareersFromStep(
        this.props.auth.uid,
        FIRST_STEP_TEN_CAREERS
      );
    }

    if (this.props.location.state && this.props.location.state.career) {
      getPlaylistVideos(
        this.props.location.state.career.YoutubePlaylistId
      ).then(response =>
        this.setState({
          detail: {
            career: this.props.location.state.career,
            otherCareersByCategory:
              this.props.location.state.otherCareersByCategory || [],
            careerVideos: this.mapYoutubeVideos(response.data)
          }
        })
      );
    } else {
      getCareer(this.props.match.params.careerUid).then(career => {
        getPlaylistVideos(career.YoutubePlaylistId).then(response =>
          this.setState({
            detail: {
              career: career,
              otherCareersByCategory: [],
              careerVideos: this.mapYoutubeVideos(response.data)
            }
          })
        );
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.careerUid == this.props.match.params.careerUid)
      return;

    if (this.props.location.state && this.props.location.state.career) {
      getPlaylistVideos(
        this.props.location.state.career.YoutubePlaylistId
      ).then(response =>
        this.setState({
          detail: {
            career: this.props.location.state.career,
            otherCareersByCategory:
              this.props.location.state.otherCareersByCategory || [],
            careerVideos: this.mapYoutubeVideos(response.data)
          }
        })
      );
    } else {
      getCareer(this.props.match.params.careerUid).then(career => {
        getPlaylistVideos(career.YoutubePlaylistId).then(response =>
          this.setState({
            detail: {
              career: career,
              otherCareersByCategory: [],
              careerVideos: this.mapYoutubeVideos(response.data)
            }
          })
        );
      });
    }
  }

  render() {
    return (
      <div>
        <NotificationComponent />
        <DetailPage
          addCareerToFavorite={this.addCareerToFavorite}
          deleteCareerFromFavorite={this.deleteCareerFromFavorite}
          {...this.props}
          {...this.state}
          careerDetail={this.careerDetail.bind(this)}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  favoriteCareers: state.favoriteCareers,
  progress: state.progress,
  country: state.country
});

const mapDispatchToProps = {
  fetchFavoriteCareersFromStep,
  addFavoriteCareer,
  deleteFavoriteCareer,
  addNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailContainer);
