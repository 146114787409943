import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Header from "components/Header/Header.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import Footer from "components/Footer/Footer.jsx";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "./jss/style.jsx";
import { connect } from "react-redux";

import { SectionComunityForum } from "./Sections/SectionComunityForum.jsx";

import SectionCards from "./Sections/SectionCards.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FORUM_COMMUNITY } from "./types/communityTypes";
import Slide from "@material-ui/core/Slide";

const homeServices = require("services/homeservices");

const topicservices = require("services/topicservices");

const forumServices = require("services/forumServices");

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class CommunityPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      topics: [],
      topicsLoaded: false
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    forumServices
      .getPosts(FORUM_COMMUNITY, null)
      .then(posts => {
        this.setState({
          posts: posts,
          postsLoaded: true
        });
      })
      .catch(e => {
        this.setState({
          posts: null,
          postsLoaded: true
        });
      });
  }

  createNewPost(content) {
    let post = {
      senderUid: this.props.auth.uid,
      senderName: this.props.auth.name,
      senderAvatarUri: this.props.auth.photoURL
        ? this.props.auth.photoURL + "?type=large"
        : "/img/unknown_avatar.png",
      forumName: FORUM_COMMUNITY,
      title: "",
      content: content,
      created: new Date()
    }

    forumServices
      .addPost(post)
      .then(() => {
        this.setState({
          posts: [post, ...this.state.posts]
        })
      })
      .catch(e => {
        console.log(e);
      });
  }


  createReply(postUid, message) {
    let reply = {
      name: this.props.auth.name,
      avatar: this.props.auth.photoURL
        ? this.props.auth.photoURL + "?type=large"
        : "/img/unknown_avatar.png",
      time: new Date(),
      description: message
    }

    forumServices.addReply(postUid, reply.name, reply.avatar, reply.time, reply.description).then(result => {
      for (let i = 0; i < this.state.posts.length; i++) {
        if (this.state.posts[i].uid == postUid) {
          this.state.posts[i].replys = [...this.state.posts[i].replys, reply];
          break;
        }
      }

      this.setState({
        posts: this.state.posts
      });
    })
      .catch(e => console.log(e))
  }

  render() {
    const { classes, ...rest } = this.props;
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRoundedCircle,
      classes.imgFluid
    );

    return (
      <div>
        <Header
          authenticatedUser={this.props.auth}
          links={<HeaderLinks authenticatedUser={this.props.auth} />}
          fixed
          color="transparent"
          forceTransparent={true}
          changeColorOnScroll={{
            height: 250,
            color: "white"
          }}
        />
        <Parallax
          image={require("assets/img/backgrounds/IPU-imagen-04.jpg")}
          filter="dark"
          small
          className={classes.parallax}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem
                md={8}
                sm={8}
                className={classNames(
                  classes.mlAuto,
                  classes.mrAuto,
                  classes.textCenter
                )}
              >
                <h2 className={classes.mainTitle}>COMUNIDAD</h2>
                <h4 className={classes.subtitle}>
                  Nuestra comunidad es nuestro mayor orgullo y queremos que
                  formes parte de ella
                </h4>
                <br />
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classes.main}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem
                xs={12}
                sm={12}
                md={12}
                className={classNames(classes.mlAuto, classes.mrAuto)}
              >
                {this.state.postsLoaded ? (
                  <SectionComunityForum
                    isAdmin={this.props.auth.rols && this.props.auth.rols.includes("admin")}
                    posts={this.state.posts}
                    history={this.props.history}
                    onReply={(post, comment) => this.createReply(post, comment)}
                    onPublish={e => this.createNewPost(e)}
                    avatar={this.props.auth.photoURL}
                  />
                ) : (
                    <GridContainer justify="center">
                      <GridItem xs={1} sm={1} md={1}>
                        <CircularProgress className={classes.progress} />
                      </GridItem>
                    </GridContainer>
                  )}
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default withStyles(styles)(connect(mapStateToProps)(CommunityPage));
