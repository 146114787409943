import React from "react";
import ProgressStepPageContainer from '../ProgressStepPage/ProgressStepPageContainer';
import withStyles from "@material-ui/core/styles/withStyles";
import headersStyle from "assets/jss/material-ui-styles/views/sectionsSections/headersStyle.jsx";
import { addStepToProgress } from '../../redux/progress/actions';
import { connect } from "react-redux";
import classNames from "classnames";
import { Typography } from "@material-ui/core";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CardAprove from "components/Card/CardAprove";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import {
  addFavoriteCareerToStep,
  getFavoriteCareersFromStep,
  SECOND_STEP_FIVE_CAREERS,
  FIRST_STEP_TEN_CAREERS
} from "../../services/favoriteCareersServices";
import {
  fetchFavoriteCareersFromStep,
  addFavoriteCareer
} from "../../redux/favorite_careers/actions";
import { STEP_FOCUS } from "../../services/homeservices";
import { STEP_FOCUS_REQUIRED_CAREERS } from "types/progressStepsTypes";

class ProgressStepFocusContainer extends ProgressStepPageContainer {
  constructor(props) {
    super(props);
  }

  getStateToSave() {
    let infoToDisplay = this.state.selectedCareers.map(career => career.Name);
    return { ...this.state, infoToDisplay };
  }

  executeCustomComponentDidMount() {
    getFavoriteCareersFromStep(this.props.auth.uid, FIRST_STEP_TEN_CAREERS)
      .then(r => this.setState({ ...this.state, careers: r.careers }))
      .catch(e => console.log(e));
    getFavoriteCareersFromStep(this.props.auth.uid, SECOND_STEP_FIVE_CAREERS)
      .then(r => {
        this.setState({
          selectedCareers: (r.careers || []).filter(c => c.Discard === false)
        });
      })
      .catch(e => console.log(e));
  }

  getDefaultState() {
    return {
      disableButton: true,
      careers: [],
      selectedCareers: [],
      cantidadTotal: STEP_FOCUS_REQUIRED_CAREERS
    }
  }

  getChildren(classes) {

    return <GridContainer>
      {this.state.careers &&
        this.state.careers.map(data => {
          let checked =
            this.state.selectedCareers.findIndex(
              c => c.Id == data.Id
            ) != -1;

          let cardClass = checked
            ? classNames("animated", "fadeInCustom", "delay-02s")
            : classNames("animated", "fadeOutCustom", "delay-02s");

          return (
            <GridItem className={cardClass}>
              <CardAprove
                acceptEvent={e => {
                  this.handleCardAproveCheck(
                    data,
                    e.target.checked
                  );
                }}
                rejectEvent={this.rejectEvent}
                image={data.ListImage}
                title={data.Name}
                description={data.DescriptionForList}
                checked={checked}
              />
            </GridItem>
          );
        })}
      <GridItem
        style={{
          marginTop: "1em",
          display: "flex",
          justifyContent: "center"
        }}
      >
        <Typography style={{ color: "#cd6d5e" }}>
          {`Carreras seleccionadas: ${this.state.selectedCareers.length} de ${this.state.cantidadTotal}`}
        </Typography>
      </GridItem>
    </GridContainer>
  }

  submit = () => {
    let rest = this.state.favoriteCareers.filter(
      c => this.state.selectedCareers.findIndex(s => s.Id == c.Id) == -1
    );

    rest
      .map(c => {
        return { ...c, Discard: true };
      })
      .forEach(c => {
        addFavoriteCareerToStep(
          this.props.auth.uid,
          SECOND_STEP_FIVE_CAREERS,
          c
        );
      });
  };

  handleCardAproveCheck(career, checked) {
    if (
      this.props.progress &&
      this.props.progress.data &&
      this.props.progress.data.STEP_FOCUS
    )
      return;

    let newCareer = {
      ...career,
      Discard: !checked
    };

    addFavoriteCareerToStep(
      this.props.auth.uid,
      SECOND_STEP_FIVE_CAREERS,
      newCareer
    )
      .then(r => {
        switch (newCareer.Discard) {
          case true: {
            this.setState({
              selectedCareers: this.state.selectedCareers.filter(
                c => c.Id != newCareer.Id
              ),
              disableButton: this.state.selectedCareers.length - 1 < this.state.cantidadTotal
            });
            break;
          }
          case false: {
            this.setState({
              selectedCareers: [...this.state.selectedCareers, newCareer],
              disableButton: this.state.selectedCareers.length + 1 < this.state.cantidadTotal
            });
            break;
          }
        }
      })
      .catch(e => console.log(e));
  }

}

const mapStateToProps = state => ({
  auth: state.auth,
  progress: state.progress,
  favoriteCareers: state.favoriteCareers,
});

const mapDispatchToProps = {
  addStepToProgress,
  fetchFavoriteCareersFromStep,
  addFavoriteCareer
}

export default withStyles(headersStyle)(connect(mapStateToProps, mapDispatchToProps)(ProgressStepFocusContainer));
