import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import productStyle from "../jss/sections/productStyle.jsx";

import InfoArea from "components/InfoArea/InfoArea.jsx";
import ChatBubbleOutline from "@material-ui/icons/ChatBubbleOutline";
import Group from "@material-ui/icons/Group";
import Mood from "@material-ui/icons/Mood";

import Book from "@material-ui/icons/Book";
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
import I18n from "../../../i18n/index.js";
function SectionText({ ...props }) {
  const { classes } = props;
  const imgClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <GridContainer justify="center" id="about" className="about">
        <GridItem xs={12} sm={8} md={8}>
          <h2>¿Tus alumnos necesitan herramientas para tomar mejores decisiones?</h2>
          <h5>
          Woiro es una plataforma diseñada para acompañar a tus alumnos en la construcción de su camino de vida.
          </h5>
        </GridItem>
      </GridContainer>
      <div class="listaItems" >
        <div>
            <i class="icofont-puzzle iconito red"></i> 
            <h4>Personalizado</h4>
            <p> 
            Acompañamiento individual, con un psicólogo o psicopedagogo exclusivo.
            </p>
        </div>
        <div>
            <i class="icofont-infinite iconito blue-light"></i>
            <h4>Flexible</h4>
            <p> 
            Acompañamiento al alcance de los horarios y necesidades de tus alumnos.
            </p>
        </div>
        <div>
          <i class="icofont-pixels iconito red"></i> 
          <h4>Integral</h4>
          <p>Acompañamiento que toma en cuenta todas las dimensiones de la persona.</p>
        </div>
      </div>
      <div>
        </div>
    </div>

    // <div className={classes.section}>
    //   <GridContainer justify="center">
    //     <GridItem xs={12} sm={8} md={8}>
    //       <h3 className={classes.title}>
    //         Woiro: la primera plataforma de orientación vocacional on-line donde
    //         lo importante sos vos y no los algoritmos.
    //       </h3>

    //       <GridContainer style={{ paddingTop: 30, paddingBottom: 40 }}>
    //         <GridItem md={6} sm={6}>
    //           <InfoArea
    //             style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 20 }}
    //             title="Información"
    //             description={
    //               <span>
    //                 <p>
    //                   Toda la información de carreras y facultades para decidir
    //                   tu futuro
    //                 </p>
    //               </span>
    //             }
    //             icon={Info}
    //             iconColor="rose"
    //           />
    //         </GridItem>
    //         <GridItem md={6} sm={6}>
    //           <InfoArea
    //             style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 20 }}
    //             title="Comunidad"
    //             description={
    //               <span>
    //                 <p>Testimonios de jóvenes profesionales</p>
    //               </span>
    //             }
    //             icon={ChatBubbleOutline}
    //             iconColor="rose"
    //           />
    //         </GridItem>
    //         <GridItem md={6} sm={6}>
    //           <InfoArea
    //             style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 20 }}
    //             title="Herramientas"
    //             description={
    //               <span>
    //                 <p>Conocemos las cosas que aceleran tu auto-conocimiento</p>
    //               </span>
    //             }
    //             icon={Build}
    //             iconColor="rose"
    //           />
    //         </GridItem>
    //         <GridItem md={6} sm={6}>
    //           <InfoArea
    //             style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 20 }}
    //             title="Nuestros profesionales"
    //             description={
    //               <span>
    //                 <p>Acompañamiento psicológico</p>
    //               </span>
    //             }
    //             icon={Mood}
    //             iconColor="rose"
    //           />
    //         </GridItem>
    //       </GridContainer>
    //       <br />

    //     </GridItem>
    //   </GridContainer>
    // </div>
  );
}

export default withStyles(productStyle)(SectionText);
