import { FETCH_CAREERS, FILTER_CAREERS } from '../types/careerTypes';

var initialState = {
    all: [],
    filtered: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CAREERS:
            return {
                ...state,
                all: action.payload
            };
        case FILTER_CAREERS:
            return {
                ...state,
                filtered: action.payload
            }
        default:
            return state;
    }
};
