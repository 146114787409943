import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Header from "components/Header/Header.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import Footer from "components/Footer/Footer.jsx";

// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.jsx";
//import homeStyle from "assets/jss/material-ui-styles/views/homeStyle.jsx";
import homeStyle from "./jss/homeStyle.jsx";
import SectionForum from "./Sections/SectionForum.jsx";

import SectionAssesmentProgress from "./Sections/SectionAssesmentProgress.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

import FlowStepper from "components/Stepper/FlowStepper.jsx";
import SectionComments from "components/Topics/SectionComments.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Loading from "components/Loader/Loading.jsx";
import FormDialog from "components/CustomDialog/FormDialog.jsx";
import ErrorBoundary from "components/Error/ErrorBoundary";
import {
  HOME_INITIAL_TITLE,
  HOME_INITIAL_TEXT
} from "types/progressStepsTypes";

import WhatssAppBar from "components/Snackbar/WhatssAppBar.jsx";
import I18n from "../../i18n/index.js";

const homeServices = require("services/homeservices");

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

let background = "/img/texture-blue.jpg";

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      userData: null,
      loading: true,
      userDisplayName: "",
      userEmail: "",
      userTelephone: "",
      userAboutmMe: ""
    };

    let rnd = Math.floor(Math.random() * 3);

    if (rnd == 0) background = "/img/texture-blue.jpg";
    if (rnd == 1) background = "/img/texture-red.jpg";
    if (rnd == 2) background = "/img/texture-yellow.jpg";
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  render() {
    const { classes, ...rest } = this.props;
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRoundedCircle,
      classes.imgFluid
    );

    var pageContent = (
      <div>
        <FormDialog user={{ ...this.props.auth }} />
        <WhatssAppBar place="br" color="white" open={true} close />

        <Header
          authenticatedUser={this.props.auth}
          links={<HeaderLinks authenticatedUser={this.props.auth} />}
          fixed
          color="transparent"
          forceTransparent={true}
          changeColorOnScroll={{
            height: 50,
            color: "white"
          }}
          {...rest}
        />
        <Parallax
          image={background}
          filter="dark"
          className={classes.parallax}
        />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <Dialog
              classes={{
                root: classes.modalRoot,
                paper: classes.modal
              }}
              open={this.props.isLoading}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => { }}
              aria-labelledby="notice-modal-slide-title"
              aria-describedby="notice-modal-slide-description"
            >
              <DialogContent
                id="notice-modal-slide-description"
                className={classes.modalBody}
              >
                <CircularProgress className={classes.progress} />
              </DialogContent>
            </Dialog>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img
                      src={
                        this.props.user.photoURL != null
                          ? this.props.user.photoURL + "?type=large"
                          : require("assets/img/unknown_avatar.png")
                      }
                      alt="avatar"
                      className={imageClasses}
                    />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>
                      {this.props.currentStep && <I18n t={this.props.currentStep.Title} /> || HOME_INITIAL_TITLE}
                    </h3>
                    {/* <h6>aprendiz</h6> */}
                  </div>
                </div>

                <div className={classes.follow}>
                  <div
                    className="waimgcontainer"
                    style={{}}
                    onClick={e => {
                      window.open("https://wa.me/5491173629603");
                    }}
                  >
                    <img
                      className={"waimg"}
                      style={{}}
                      src={require("assets/img/whatsapp-icon.png")}
                      alt="whatsapp"
                    // className={imageClasses}
                    />
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div
              className={classNames(classes.description, classes.textCenter)}
            >
              <p style={{ fontSize: 18 }}>
                {this.props.currentStep && <I18n t={this.props.currentStep.Text} /> || HOME_INITIAL_TEXT}
              </p>
            </div>
            <div className={classes.profileTabs}>
              {this.state.homePageError ? (
                <div>
                  <GridContainer justify="center">
                    <GridItem
                      xs={12}
                      sm={6}
                      md={6}
                      className={classNames(classes.mlAuto, classes.mrAuto)}
                    >
                      <div className={classes.textCenter}>
                        <h3 className={classes.title}>
                          Problemas para obtener tu información =(
                        </h3>
                        <p className={classes.description}>
                          {this.state.homePageError.message}
                        </p>
                      </div>
                    </GridItem>
                  </GridContainer>
                </div>
              ) : (
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={7} className={classes.gridItem}>
                      {
                        this.props.user && this.props.groupedSteps && this.props.groupedSteps.length > 0 ? (
                          <ErrorBoundary>
                            <FlowStepper
                              {...this.props}
                              auth={this.props.user}
                              groupedSteps={this.props.groupedSteps}
                            />
                          </ErrorBoundary>
                        ) : (
                            <Loading />
                          )}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2} className={classes.gridItem}>
                      <h4 className={classes.title}>Tu progreso</h4>
                      {this.props.currentStep &&
                        this.props.currentStep.Percentage && (
                          <SectionAssesmentProgress
                            progress={this.props.currentStep.Percentage}
                          />
                        )}
                      <hr />
                      <h4 className={classes.title}>
                        {this.props.currentStep &&
                          this.props.currentStep.Quote &&
                          this.props.currentStep.Quote.Title}
                      </h4>
                      <p className={classes.description}>
                        {this.props.currentStep &&
                          this.props.currentStep.Quote &&
                          this.props.currentStep.Quote.Text}
                      </p>
                      <hr />

                      <div style={{ textAlign: "center" }}>
                        <Button
                          justIcon
                          simple
                          color="twitter"
                          className={classes.btn}
                          onClick={e => {
                            window.open("https://twitter.com/somoswoiro");
                          }}
                        >
                          <i className="fab fa-twitter" />
                        </Button>
                        <Button
                          justIcon
                          simple
                          color="facebook"
                          className={classes.btn}
                          onClick={e => {
                            window.open("https://www.facebook.com/woiroedu");
                          }}
                        >
                          <i className="fab fa-facebook" />
                        </Button>
                        <Button
                          justIcon
                          simple
                          color="google"
                          className={classes.btn}
                          onClick={e => {
                            window.open("https://www.instagram.com/somoswoiro/");
                          }}
                        >
                          <i className="fab fa-instagram" />
                        </Button>
                      </div>
                    </GridItem>
                  </GridContainer>
                )}
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
    return pageContent;
  }
}

export default withStyles(homeStyle)(HomePage);
