import React, { Component } from 'react'
import { connect } from 'react-redux';
import ErrorComponent from './ErrorPage'

class ErrorContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  componentDidMount() {
    
  }
  render() {

    let content =
      this.state.loading ?
        <div>...</div>
        :
        <div>
          <ErrorComponent {...this.props} />
        </div>
    return (content)
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, {  })(ErrorContainer);