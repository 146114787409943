export const FETCH_UNIVERSITIES_WITH_DISTANCE =
  "FETCH_UNIVERSITIES_WITH_DISTANCE";
export const FETCH_FAVORITE_UNIVERSITIES = "FETCH_FAVORITE_UNIVERSITIES";
export const FETCH_UNIVERSITIES_ERROR = "FETCH_UNIVERSITIES_ERROR";
export const ADD_UNIVERSITY_TO_FAVORITE = "ADD_UNIVERSITY_TO_FAVORITE";
export const DELETE_UNIVERSITY_FROM_FAVORITE =
  "DELETE_UNIVERSITY_FROM_FAVORITE";
export const FILTER_UNIVERSITIES = "FILTER_UNIVERSITIES";
export const UPDATE_NEW_UNIVERSITY = "UPDATE_NEW_UNIVERSITY";
export const FORCE_SHOW_FILTERS = "FORCE_SHOW_FILTERS";
export const SHOW_FILTERS = "SHOW_FILTERS";
export const FETCH_USER_GEOLOCATION = "FETCH_USER_GEOLOCATION";
export const FETCH_UNIVERSITIES_DISTANCES = "FETCH_UNIVERSITIES_DISTANCES";
export const FETCH_UNIVERSITIES_TRAVEL_DISTANCES = "FETCH_TRAVEL_DISTANCES";
export const FILTER_UNIVERSITY_COUNTRY = "FILTER_UNIVERSITY_COUNTRY";
export const SET_UNIVERSITY = "SET_UNIVERSITY";
export const FILTER_UNIVERSITIES_BY_NAME_AND_GROUP_CODE =
  "FILTER_UNIVERSITIES_BY_NAME_AND_GROUP_CODE";
export const FETCH_UNIVERSITIES = "FETCH_UNIVERSITIES";

export const CLOSE_TO_YOU_LIST_NAME = "Cerca tuyo";
