import { ADD_NOTIFICATION, DELETE_NOTIFICATION } from "./types";
import uuid from "uuid";

export const addNotification = (message, timeout) => dispatch => {
  const uid = uuid.v4();
  dispatch({
    type: ADD_NOTIFICATION,
    payload: {
      message,
      uid,
      timeout
    }
  });
};

export const cleanNotification = (uid, timeout) => dispatch => {
  return setTimeout(() => {
    dispatch({
      type: DELETE_NOTIFICATION,
      payload: { uid }
    });
  }, timeout || 2500);
};
