export const registerEvent = (auth, eventType, data, info) => {
  try {
    const eventservices = require("services/eventservices");

    console.log(`EventType: ${eventType}`);
    console.log(`Data: ${data}`);
    console.log(`DataInfo: ${JSON.stringify(info)}`);

    let uid = null;
    let email = null;
    if (auth) {
      uid = auth.uid;
      email = auth.email;

      eventservices
        .addEvent(
          uid,
          email,
          JSON.stringify(eventType),
          JSON.stringify(data),
          JSON.stringify(info)
        )
        .catch(() => { });
    }
  } catch (e) {
    console.log("lpm", e);
  }
};

export const registerEventAnonymous = (userData, eventType, data, info) => {
  try {
    const eventservices = require("services/eventservices");

    console.log(`EventType: ${eventType}`);
    console.log(`Data: ${data}`);
    console.log(`DataInfo: ${JSON.stringify(info)}`);
    let uid = null;

    if (userData && typeof userData == "string") uid = userData;
    else if (window.capacitorDeviceInfo.uuid)
      uid = window.capacitorDeviceInfo.uuid;
    eventservices
      .addEvent(
        uid,
        JSON.stringify(userData),
        eventType,
        JSON.stringify(data),
        JSON.stringify(info)
      )
      .catch(e => {
        console.log("lpm2", e);
      });
  } catch (e) {
    console.log("lpm", e);
  }
};

export const setAuthTag = auth => {
  if (!auth || !auth.isLoggedIn) return;

  try {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({ user_id: auth.uid });
  } catch (e) {
    console.log("lpm", e);
  }
};
