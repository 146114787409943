var firebase = require("config/firebase");

const firestore = firebase.db;
const colRef_posts = firestore.collection("posts");

export function deletePost(postId) {
  return colRef_posts.doc(postId).delete();
}

export function deleteReply(postId, replyId) {
  return colRef_posts.doc(postId).collection("replies").doc(replyId).delete();
}

export function addReply(postId, name, avatar, time, description) {
  return colRef_posts.doc(postId).collection("replies").add({
    name,
    avatar,
    time,
    description
  });
}

function _addPost(post) {
  return new Promise(function (resolve, reject) {
    let id = colRef_posts.doc().id;
    colRef_posts
      .doc(id)
      .set({
        uid: id,
        author_name: post.senderName,
        senderUid: post.senderUid,
        senderAvatarUri: post.senderAvatarUri,
        created: post.created,
        forumName: post.forumName,
        title: post.title,
        content: post.content,
        labels: post.labels ? post.labels : null, //labels
        topicId: post.topicId ? post.topicId : null
      })
      .then(() => {
        resolve();
      })
      .catch(err => {
        console.log(err);
        reject(err);
      });
  });
}

async function _getPosts(forumName, topicId) {
  try {
    let posts = await colRef_posts
      .orderBy("created", "desc")
      .where("forumName", "==", forumName)
      .where("topicId", "==", topicId)
      .get();

    if (posts.docs.length != 0) {
      return Promise.all(posts.docs.map(async post => {
        let postData = post.data();
        let replys = await colRef_posts.doc(post.id).collection("replies").get();
        return {
          ...postData, id: post.id, replys: replys.docs.map(reply => {
            let dataReply = reply.data();
            return { ...dataReply, id: reply.id, time: dataReply.time.toDate() }
          }),
          created: postData.created.toDate()
        };
      }));
    }
  }
  catch (e) {
    console.log(e)
  }

}

function _getPost(postId) {
  return new Promise(function (resolve, reject) {
    colRef_posts
      .where("uid", "==", postId)
      // .where("receiverUid", "==", receiverUid)
      .get()
      .then(snapshotConversations => {
        if (snapshotConversations.docs.length != 0) {
          let post = snapshotConversations.docs[0].data();
          resolve({ post: post });
        } else {
          resolve(null);
        }
      })
      .catch(err => {
        console.log(err);
        reject(err);
      });
  });
}

export const addPost = _addPost;
export const getPosts = _getPosts;
export const getPost = _getPost;
