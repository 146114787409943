import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react component used to create nice image meadia player
import ImageGallery from "react-image-gallery";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Footer from "components/Footer/Footer.jsx";
import productStyle from "assets/jss/material-ui-styles/views/productStyle.jsx";
import TopicPage from "views/TopicPage/TopicContainer.jsx";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

class UniversityDetailPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showVideo: {},
      showFullscreenButton: true,
      showGalleryFullscreenButton: false,
      showPlayButton: true,
      showGalleryPlayButton: false
    };
  }

  _resetVideo() {
    this.setState({ showVideo: {} });

    if (this.state.showPlayButton) {
      this.setState({ showGalleryPlayButton: true });
    }

    if (this.state.showFullscreenButton) {
      this.setState({ showGalleryFullscreenButton: true });
    }
  }

  _toggleShowVideo(url) {
    this.state.showVideo[url] = !Boolean(this.state.showVideo[url]);
    this.setState({
      showVideo: this.state.showVideo
    });

    if (this.state.showVideo[url]) {
      if (this.state.showPlayButton) {
        this.setState({ showGalleryPlayButton: false });
      }

      if (this.state.showFullscreenButton) {
        this.setState({ showGalleryFullscreenButton: false });
      }
    }
  }
  //generar componenete con los stylos
  _renderVideo(item) {
    return (
      <div className="image-gallery-image">
        {
          <div className="video-wrapper">
            <a
              className="close-video"
              onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
            />
            <iframe
              width="500"
              height="350"
              src={item.embedUrl}
              frameBorder="0"
              allowFullScreen
            />
          </div>
        }
      </div>
    );
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.productPage}>
        <Header
          authenticatedUser={this.props.auth}
          links={
            <HeaderLinks
              dropdownHoverColor="rose"
              authenticatedUser={this.props.auth}
            />
          }
          fixed
          color="transparent"
          forceTransparent={true}
          changeColorOnScroll={{
            height: 100,
            color: "white"
          }}
        />
        <Parallax
          image={require("assets/img/backgrounds/IPU-imagen-05.jpg")}
          filter="dark"
          className={classes.pageHeader}
        />
        <div className={classNames(classes.section, classes.sectionGray)}>
          <div className={classes.container}>
            <div className={classNames(classes.main, classes.mainRaised)}>
              <GridContainer>
                <GridItem md={12} sm={12}>
                  <div
                    onClick={() => {
                      this.goBack();
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <ChevronLeft style={{ float: "left" }} />
                    Volver
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem md={6} sm={6}>
                  {this.props.university && (
                    <ImageGallery
                      showFullscreenButton={false}
                      showPlayButton={false}
                      showNav={true}
                      additionalClass={classes.customImageGalery}
                      startIndex={0}
                      items={[
                        {
                          original: `/img/universities/${this.props.university.ImagesFolder}/university-background.jpg`,
                          thumbnail: `/img/universities/${this.props.university.ImagesFolder}/university-background.jpg`
                        }
                      ].concat(
                        this.props.videos.map(video => {
                          return {
                            ...video,
                            renderItem: this._renderVideo.bind(this)
                          };
                        })
                      )}
                      originalClass={classes.customImageGalery}
                      style={{ minHeight: 200 }}
                    />
                  )}
                </GridItem>
                <GridItem md={6} sm={6}>
                  <h2 className={classes.title}>
                    {this.props.university && this.props.university.Name}
                  </h2>
                  <p>
                    {this.props.university && this.props.university.Description}
                  </p>
                </GridItem>
              </GridContainer>
            </div>

            {this.props.relatedUniversities &&
              this.props.relatedUniversities.length > 0 && (
                <div className={classes.relatedProducts}>
                  <h3 className={classNames(classes.title, classes.textCenter)}>
                    Sedes:
                  </h3>
                  <GridContainer>
                    {this.props.relatedUniversities.map((univ, index) => {
                      return (
                        <GridItem key={index} sm={6} md={3}>
                          <Card
                            product
                            // onClick={() => {
                            //   this.props.universityDetail(univ);
                            //   window.scrollTo(0, 0);
                            // }}
                          >
                            <CardHeader image>
                              {/* <a href=""> */}
                              <img
                                src={`/img/universities/${univ.ImagesFolder}/university-background.jpg`}
                                alt="relatedUniversity"
                              />
                              {/* </a> */}
                            </CardHeader>
                            <CardBody>
                              <h6 className={classes.cardCategory}>
                                {univ.categoryLabel}
                              </h6>
                              <h4 className={classes.cardTitle}>
                                {univ.ShortName}
                              </h4>
                              {/* <div className={classes.cardDescription}>
                                {univ.Description}
                              </div> */}
                            </CardBody>
                            <CardFooter
                              className={classes.justifyContentBetween}
                            >
                              <div className={classes.price} />
                              <div className={classes.stats} />
                            </CardFooter>
                          </Card>
                        </GridItem>
                      );
                    })}
                  </GridContainer>
                </div>
              )}

            {this.props.university && this.props.university.ForumTopicId && (
              <TopicPage topicId={this.props.university.ForumTopicId} />
            )}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(productStyle)(UniversityDetailPage);
