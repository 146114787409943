import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import Footer from "components/Footer/Footer.jsx";
import HeaderBack from "components/Header/HeaderBack.jsx";

import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import headersStyle from "assets/jss/material-ui-styles/views/sectionsSections/headersStyle.jsx";

import classNames from "classnames";
// @material-ui/icons
import Edit from "@material-ui/icons/Edit";

import Table from "components/Table/Table.jsx";
import Loading from "components/Loader/Loading.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import Palette from "@material-ui/icons/Palette";
import People from "@material-ui/icons/People";

const careerServices = require("services/careerservices");
const careerspageservices = require("services/careerspageservices");

class CareersAdminPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      careers: [],
      lists: [],
      isLoadingCareers: true,
      isLoadingLists: true,
      isLoadingPendingApprovals: true
    };
  }

  goToCareerEditionPage(career) {
    this.props.history.push(`/careers-admin/${career.Id}`, {
      career
    });
  }
  goToListEditionPage(list) {
    this.props.history.push(`/careers-list-admin/${list.uid}`, {
      list
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    let careers = [];

    careerServices.getCareers().then(snapshot => {
      snapshot.forEach(doc => {
        var career = doc.data();
        careers.push([
          career.Id,
          career.Name,
          <div>
            <Button
              color="info"
              size="sm"
              onClick={() => {
                this.goToCareerEditionPage(career);
              }}
            >
              <Edit />
            </Button>
          </div>
        ]);
      });

      this.setState({ careers: careers, isLoadingCareers: false });

      let tableLists = [];
      careerspageservices.getCareersPageLists().then(aux => {
        aux.forEach(list => {
          tableLists.push([
            list.uid,
            list.Description,
            <div>
              <Button
                color="info"
                size="sm"
                onClick={() => {
                  this.goToListEditionPage(list);
                }}
              >
                <Edit />
              </Button>
            </div>
          ]);
        });

        this.setState({ tableLists: tableLists, isLoadingLists: false });
      });
    });

    let careersPendingApproval = [];

    careerServices
      .getCareersPendingApproval()
      .then(snapshot => {
        snapshot.docs.forEach(doc => {
          var career = doc.data();
          careersPendingApproval.push([
            career.Id,
            career.Name,
            <div>
              <Button
                color="info"
                size="sm"
                onClick={() => {
                  alert("Pendiente, contingencia: Firebase store");
                }}
              >
                Aprobar
              </Button>
            </div>
          ]);
        });
        this.setState({
          careersPendingApproval: careersPendingApproval,
          isLoadingPendingApprovals: false
        });
      })
      .catch(e => {
        console.log(e);
      });
  }

  render() {
    const { classes, ...rest } = this.props;

    return (
      <div>
        <HeaderBack
          authenticatedUser={this.props.auth}
          fixed
          color="transparent"
          forceTransparent={true}
          {...rest}
        />
        <div className={classes.pageHeader} style={{ height: 150 }} />

        <div className={classes.profileTabs}>
          <NavPills
            alignCenter
            color="primary"
            tabs={[
              {
                tabButton: "Carreras",
                tabIcon: Palette,
                tabContent: (
                  <div>
                    <GridContainer>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        className={classNames(classes.mlAuto, classes.mrAuto)}
                      >
                        {this.state.isLoadingCareers ? (
                          <Loading />
                        ) : (
                          <Table
                            tableHead={["Id", "Name", ""]}
                            tableData={this.state.careers}
                            customCellClasses={[
                              classes.textCenter,
                              classes.textRight,
                              classes.textRight
                            ]}
                            customClassesForCells={[0, 4, 5]}
                            customHeadCellClasses={[
                              classes.textCenter,
                              classes.textRight,
                              classes.textRight
                            ]}
                            customHeadClassesForCells={[0, 4, 5]}
                          />
                        )}
                      </GridItem>
                    </GridContainer>
                  </div>
                )
              },
              {
                tabButton: "Listas",
                tabIcon: People,
                tabContent: (
                  <div>
                    <GridContainer>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        className={classNames(classes.mlAuto, classes.mrAuto)}
                      >
                        {this.state.isLoadingLists ? (
                          <Loading />
                        ) : (
                          <Table
                            tableHead={["Id", "Description", ""]}
                            tableData={this.state.tableLists}
                            customCellClasses={[
                              classes.textCenter,
                              classes.textRight,
                              classes.textRight
                            ]}
                            customClassesForCells={[0, 4, 5]}
                            customHeadCellClasses={[
                              classes.textCenter,
                              classes.textRight,
                              classes.textRight
                            ]}
                            customHeadClassesForCells={[0, 4, 5]}
                          />
                        )}
                      </GridItem>
                    </GridContainer>
                  </div>
                )
              },
              {
                tabButton: "Pendientes",
                tabIcon: Palette,
                tabContent: (
                  <div>
                    <GridContainer>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        className={classNames(classes.mlAuto, classes.mrAuto)}
                      >
                        {this.state.isLoadingPendingApprovals ? (
                          <Loading />
                        ) : (
                          <Table
                            tableHead={["Id", "Name", ""]}
                            tableData={this.state.careersPendingApproval}
                            customCellClasses={[
                              classes.textCenter,
                              classes.textRight,
                              classes.textRight
                            ]}
                            customClassesForCells={[0, 4, 5]}
                            customHeadCellClasses={[
                              classes.textCenter,
                              classes.textRight,
                              classes.textRight
                            ]}
                            customHeadClassesForCells={[0, 4, 5]}
                          />
                        )}
                      </GridItem>
                    </GridContainer>
                  </div>
                )
              }
            ]}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(headersStyle)(CareersAdminPage);
