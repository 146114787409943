import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Quote from "components/Typography/Quote.jsx";
// core components

import blog4 from "assets/img/examples/blog4.jpg";
import blog3 from "assets/img/examples/blog3.jpg";
import blog1 from "assets/img/examples/blog1.jpg";

import sectionTextStyle from "../jss/sections/sectionTextStyle.jsx";

import {safeForumTitle} from "helpers/topicHelper.js";

function SectionText({ ...props }) {
  const { classes } = props;
  const imgClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid
  );

  let topic = props.topic;
  let content = topic.content;
  //para que las imgs que incluyen no se excedan de los limites 
  content = content.replace("<img ", "<img style='max-width: 100%; min-width: 100%; object-fit: cover;' ");
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h3 className={classes.title} dangerouslySetInnerHTML={{ __html: safeForumTitle(topic.title)}}>
            
          </h3>
          <div style={{overflowX:'hidden'}} dangerouslySetInnerHTML={{ __html: content}}></div>
        </GridItem>
        
      </GridContainer>
    </div>
  );
}

export default withStyles(sectionTextStyle)(SectionText);
