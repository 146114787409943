import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react component used to create nice image meadia player
import ImageGallery from "react-image-gallery";
import withStyles from "@material-ui/core/styles/withStyles";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
// core components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Footer from "components/Footer/Footer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import productStyle from "assets/jss/material-ui-styles/views/productStyle.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import TopicPage from "views/TopicPage/TopicContainer.jsx";
import Favorite from "@material-ui/icons/Favorite";

import Button from "components/CustomButtons/Button.jsx";
import { CareerDetail } from "./CareerDetail";

class ProductPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showVideo: {},
      showFullscreenButton: true,
      showGalleryFullscreenButton: false,
      showPlayButton: true,
      showGalleryPlayButton: false
    };
  }

  _resetVideo() {
    this.setState({ showVideo: {} });

    if (this.state.showPlayButton) {
      this.setState({ showGalleryPlayButton: true });
    }

    if (this.state.showFullscreenButton) {
      this.setState({ showGalleryFullscreenButton: true });
    }
  }

  _toggleShowVideo(url) {
    this.state.showVideo[url] = !Boolean(this.state.showVideo[url]);
    this.setState({
      showVideo: this.state.showVideo
    });

    if (this.state.showVideo[url]) {
      if (this.state.showPlayButton) {
        this.setState({ showGalleryPlayButton: false });
      }

      if (this.state.showFullscreenButton) {
        this.setState({ showGalleryFullscreenButton: false });
      }
    }
  }
  //generar componenete con los stylos
  _renderVideo(item) {
    return (
      <div className="image-gallery-image">
        {
          <div className="video-wrapper">
            <a
              className="close-video"
              onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
            />
            <iframe
              width="100%"
              height="250"
              src={item.embedUrl}
              frameBorder="0"
              allowFullScreen
            />
          </div>
        }
      </div>
    );
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    const { classes } = this.props;
    const { pullRight, title } = classes;
    let favButton = null;

    if (this.props.auth && this.props.auth.uid) {
      if (this.props.favoriteCareers.list != null &&
        this.props.favoriteCareers.list.find(career => {
          return this.props.detail.career.Name == career.Name;
        })
      ) {
        favButton = (
          <Button
            onClick={e => {
              this.props.deleteCareerFromFavorite(this.props.detail.career);
            }}
            round
            color="primary"
          >
            <Favorite />
          Quitar de favoritos
          </Button>
        );
      } else {
        favButton = (
          <Button
            onClick={e => {
              this.props.addCareerToFavorite(this.props.detail.career);
            }}
            round
            color="primary"
          >
            <Favorite />
          Agregar a favoritos
          </Button>
        );
      }
    }
    return (
      <div className={classes.productPage}>
        <Header
          authenticatedUser={this.props.auth}
          links={
            <HeaderLinks
              dropdownHoverColor="rose"
              authenticatedUser={this.props.auth}
            />
          }
          fixed
          color="transparent"
          forceTransparent={true}
          changeColorOnScroll={{
            height: 100,
            color: "white"
          }}
        />
        <Parallax
          image={require("assets/img/backgrounds/IPU-imagen-05.jpg")}
          filter="dark"
          className={classes.pageHeader}
        />
        <div className={classNames(classes.section, classes.sectionGray)}>
          <div className={classes.container}>
            <div className={classNames(classes.main, classes.mainRaised)}>
              <GridContainer>
                <GridItem md={12} sm={12}>
                  <div
                    onClick={() => {
                      this.goBack();
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <ChevronLeft style={{ float: "left" }} />
                    Volver
                  </div>
                </GridItem>
              </GridContainer>
              <CareerDetail
                career={{ ...this.props.detail.career, videos: this.props.detail.careerVideos, ShowName: true }}
                classes={{ pullRight, title }}
                favButton={favButton}
                countryCode={this.props.country.code}
              />
            </div>
            {this.props.detail.otherCareersByCategory.length > 0 && (
              <div className={classes.relatedProducts}>
                <h3 className={classNames(classes.title, classes.textCenter)}>
                  También te podría interesar:
                </h3>
                <GridContainer>
                  {this.props.detail.otherCareersByCategory.map(
                    (career, index) => {
                      return (
                        <GridItem key={index} sm={6} md={3}>
                          <Card
                            product
                            onClick={() => {
                              this.props.careerDetail(
                                career,
                                this.props.detail.otherCareersByCategory
                              );
                              window.scrollTo(0, 0);
                            }}
                          >
                            <CardHeader image>
                              {/* <a href=""> */}
                              <img
                                src={career.DetailImage}
                                alt="relatedCareer"
                              />
                              {/* </a> */}
                            </CardHeader>
                            <CardBody>
                              <h6 className={classes.cardCategory}>
                                {career.categoryLabel}
                              </h6>
                              <h4 className={classes.cardTitle}>
                                {career.Name}
                              </h4>
                              <div className={classes.cardDescription}>
                                {career.ShortDescription}
                              </div>
                            </CardBody>
                            <CardFooter
                              className={classes.justifyContentBetween}
                            >
                              <div className={classes.price} />
                              <div className={classes.stats} />
                            </CardFooter>
                          </Card>
                        </GridItem>
                      );
                    }
                  )}
                </GridContainer>
              </div>
            )}
            {this.props.detail.career &&
              this.props.detail.career.People &&
              this.props.detail.career.People.length > 0 && (
                <div className={classes.relatedProducts}>
                  <h3 className={classNames(classes.title, classes.textCenter)}>
                    Quienes también estudiaron esto
                  </h3>
                  <GridContainer>
                    {this.props.detail.career.People.split(";")
                      .filter(c => c != "")
                      .map((person, index) => {
                        var p = person.split("|");
                        var personData = {
                          name: p[0],
                          alias: p[1] != "" && `(${p[1]})`,
                          description: p[2],
                          image: p[3]
                        };

                        return (
                          <GridItem xs={12} sm={3} md={3} key={index}>
                            <Card profile plain>
                              <CardAvatar profile plain>
                                <a
                                  href="javascript:void(0)"
                                  onClick={e => e.preventDefault()}
                                >
                                  <img
                                    src={`/img/other_people/${personData.image}`}
                                    alt="..."
                                    className={classes.img}
                                  />
                                </a>
                              </CardAvatar>
                              <CardBody>
                                <h4 className={classes.cardTitle}>
                                  {personData.name} {personData.alias}
                                </h4>
                                <p className={classes.description}>
                                  {personData.description}
                                </p>
                              </CardBody>
                            </Card>
                          </GridItem>
                        );
                      })}
                  </GridContainer>
                </div>
              )}

            {this.props.detail.career.ForumTopicId && (
              <TopicPage topicId={this.props.detail.career.ForumTopicId} />
            )}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(productStyle)(ProductPage);
