import React from "react";
import ProgressStepPageContainer from '../ProgressStepPage/ProgressStepPageContainer';
import withStyles from "@material-ui/core/styles/withStyles";
import headersStyle from "assets/jss/material-ui-styles/views/sectionsSections/headersStyle.jsx";
import { addStepToProgress } from '../../redux/progress/actions';
import { connect } from "react-redux";
import Button from "components/CustomButtons/Button.jsx";

const careerPageServices = require("services/careerspageservices");

class ProgressStepCategoriesContainer extends ProgressStepPageContainer {
  constructor(props) {
    super(props);
  }

  getStateToSave() {
    let infoToDisplay = this.state.categories.filter(category => this.state.categoriesSelected.includes(category.uid)).map(category => category.Description);
    return { ...this.state, infoToDisplay}
  }

  executeCustomComponentDidMount() {
    careerPageServices.getCareersPageCategories().then(categories => {
      this.setState({ categories });
    })
  }

  getDefaultState() {
    return {
      categories: [],
      categoriesSelected: [],
      disableButton: true
    };
  }

  handleListItemClick(uid) {
    let disableButton = true;
    if (this.state.categoriesSelected.includes(uid)) {
      if (this.state.categoriesSelected.lenght - 1 > 0) disableButton = false;
      this.setState({
        categoriesSelected: this.state.categoriesSelected.filter(categoryUid => categoryUid != uid),
        disableButton
      })
    } else {
      this.setState({
        categoriesSelected: [...this.state.categoriesSelected, uid],
        disableButton: false
      })
    }
  }

  getChildren(classes) {
    return <div style={{ textAlign: "center" }}>
      {
        this.state.categories
          .map(category => {
            let recommendationBtnClasses =
              this.state.categoriesSelected.includes(category.uid)
                ? classes.recommendationSelected
                : classes.recommendation;
            return <Button
              className={recommendationBtnClasses}
              key={category.uid}
              round
              simple
              color="white"
              onClick={() => {
                this.handleListItemClick(category.uid);
              }}
              style={{
                border: "1px solid rgba(256, 256, 256, 1)",
                marginRight: 4,
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 10,
                paddingBottom: 10
              }}
            >
              {category.Description}
            </Button>;
          })
      }
    </div>
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  progress: state.progress
});

const mapDispatchToProps = {
  addStepToProgress
}

export default withStyles(headersStyle)(connect(mapStateToProps, mapDispatchToProps)(ProgressStepCategoriesContainer));