import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import cardsStyle from "../jss/sections/cardsStyle.jsx";

import Explore from "@material-ui/icons/Explore";
import Fingerprint from "@material-ui/icons/Fingerprint";
import ChildFriendly from "@material-ui/icons/ChildFriendly";
import WatchLater from "@material-ui/icons/WatchLater";
// core components
import InfoArea from "components/InfoArea/InfoArea.jsx";

import iphone from "assets/img/sections/iphone.png";
import I18n from "../../../i18n";

class SectionCards extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.features3}>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6}>
            <div className={classes.phoneContainer}>
              <img src={iphone} alt="..." />
            </div>
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <h2 className={classes.title2}><I18n t="LANDING_CONTROL_TITLE" /></h2>
            <InfoArea
              className={classes.infoArea}
              icon={WatchLater}
              title={<I18n t="LANDING_CONTROL_TIEMPOS_TITLE" />}
              description={<I18n t="LANDING_CONTROL_TIEMPOS_TEXT" />}
              iconColor="info"
            />
            <InfoArea
              className={classes.infoArea}
              icon={Fingerprint}
              title={<I18n t="LANDING_CONTROL_CONOCETE_TITLE" />}
              description={<I18n t="LANDING_CONTROL_CONOCETE_TEXT" />}
              iconColor="info"
            />
            <InfoArea
              className={classes.infoArea}
              icon={Explore}
              title={<I18n t="LANDING_CONTROL_TECNOLOGIA_TITLE" />}
              description={<I18n t="LANDING_CONTROL_TECNOLOGIA_TEXT" />}
              iconColor="info"
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(cardsStyle)(SectionCards);
