const plans = {
  ar: [
    {
      recommendedFor:
        "¿Tenés alguna idea de lo que te gustaría estudiar, pero querés confirmarlo? Este plan es para vos.",
      code: "starter",
      name: "Consulta Vocacional",
      price: getPrice(process.env.REACT_APP_ENV, "ar", "STARTER"),
      currency_id: "ARS",
      currency: "AR$",
      mercadopago: true,
      paypal: false,
      features: [
        {
          text: "1 encuentro con uno de nuestros profesionales psi",
          icon: "Check",
        },
        {
          text: "4 técnicas de autoconocimiento",
          icon: "Check",
        },
        {
          text: "Acceso a todo el camino Woiro",
          icon: "Check",
        },
      ],
    },
    {
      recommendedFor:
        "¿Tenés claridad sobre tu proyecto de vida pero necesitás acompañamiento para tomar una decisión concreta? Te recomendamos este plan.",
      code: "basic",
      name: "Proceso Base",
      price: getPrice(process.env.REACT_APP_ENV, "ar", "BASIC"),
      currency_id: "ARS",
      currency: "AR$",
      mercadopago: true,
      paypal: false,
      features: [
        {
          text: "3 encuentros con uno de nuestros profesionales psi",
          icon: "Check",
        },
        {
          text: "6 técnicas de autoconocimiento",
          icon: "Check",
        },
        {
          text: "Acceso a todo el camino Woiro",
          icon: "Check",
        },
        {
          text: "Acceso a jóvenes profesionales",
          icon: "Check",
        },
        {
          text: "Informe final personalizado",
          icon: "Check",
        },
      ],
    },
    {
      recommendedFor:
        "¿Estás más perdido en cuanto a tus intereses y proyecto de vida? Te recomendamos este plan.",
      code: "full",
      name: "Proceso Full",
      price: getPrice(process.env.REACT_APP_ENV, "ar", "FULL"),
      currency_id: "ARS",
      currency: "AR$",
      mercadopago: true,
      paypal: false,
      features: [
        {
          text: "5 encuentros con uno de nuestros profesionales psi",
          icon: "Check",
        },
        {
          text: "7 técnicas de autoconocimiento",
          icon: "Check",
        },
        {
          text: "Acceso a todo el camino Woiro",
          icon: "Check",
        },
        {
          text: "Acceso a jóvenes profesionales",
          icon: "Check",
        },
        {
          text: "Informe final personalizado",
          icon: "Check",
        },
      ],
    },
  ],
  cl: [
    {
      recommendedFor:
        "¿Tienes alguna idea de lo que quieres estudiar, pero necesitas confirmarlo? Este plan es para ti.",
      code: "starter",
      name: "Consulta Vocacional",
      price: getPrice(process.env.REACT_APP_ENV, "cl", "STARTER"),
      currency_id: "CLP",
      currency: "$",
      mercadopago: true,
      paypal: true,
      features: [
        {
          text: "1 encuentro con uno de nuestros profesionales psi",
          icon: "Check",
        },
        {
          text: "4 técnicas de autoconocimiento",
          icon: "Check",
        },
        {
          text: "Acceso a todo el camino Woiro",
          icon: "Check",
        },
      ],
    },
    {
      recommendedFor:
        "¿Tienes claridad sobre tu proyecto de vida pero necesitas acompañamiento para tomar una decisión concreta? Te recomendamos este plan.",
      code: "basic",
      name: "Proceso Base",
      price: getPrice(process.env.REACT_APP_ENV, "cl", "BASIC"),
      currency_id: "CLP",
      currency: "$",
      mercadopago: true,
      paypal: true,
      features: [
        {
          text: "3 encuentros con uno de nuestros profesionales psi",
          icon: "Check",
        },
        {
          text: "6 técnicas de autoconocimiento",
          icon: "Check",
        },
        {
          text: "Acceso a todo el camino Woiro",
          icon: "Check",
        },
        {
          text: "Acceso a jóvenes profesionales",
          icon: "Check",
        },
        {
          text: "Informe final personalizado",
          icon: "Check",
        },
      ],
    },
    {
      recommendedFor:
        "¿Estás más perdido en cuanto a tus intereses y proyecto de vida? Te recomendamos este plan.",
      code: "full",
      name: "Proceso Full",
      price: getPrice(process.env.REACT_APP_ENV, "cl", "FULL"),
      currency_id: "CLP",
      currency: "$",
      mercadopago: true,
      paypal: true,
      features: [
        {
          text: "5 encuentros con uno de nuestros profesionales psi",
          icon: "Check",
        },
        {
          text: "7 técnicas de autoconocimiento",
          icon: "Check",
        },
        {
          text: "Acceso a todo el camino Woiro",
          icon: "Check",
        },
        {
          text: "Acceso a jóvenes profesionales",
          icon: "Check",
        },
        {
          text: "Informe final personalizado",
          icon: "Check",
        },
      ],
    },
  ],
  es: [
    {
      recommendedFor:
        "¿Tienes alguna idea de lo que quieres estudiar, pero necesitas confirmarlo? Este plan es para ti.",
      code: "starter",
      name: "Consulta Vocacional",
      price: getPrice(process.env.REACT_APP_ENV, "es", "STARTER"),
      currency_id: "USD",
      currency: "$",
      mercadopago: false,
      paypal: true,
      features: [
        {
          text: "1 encuentro con uno de nuestros profesionales psi",
          icon: "Check",
        },
        {
          text: "4 técnicas de autoconocimiento",
          icon: "Check",
        },
        {
          text: "Acceso a todo el camino Woiro",
          icon: "Check",
        },
      ],
    },
    {
      recommendedFor:
        "¿Tienes claridad sobre tu proyecto de vida pero necesitas acompañamiento para tomar una decisión concreta? Te recomendamos este plan.",
      code: "basic",
      name: "Proceso Base",
      price: getPrice(process.env.REACT_APP_ENV, "es", "BASIC"),
      currency_id: "USD",
      currency: "$",
      mercadopago: false,
      paypal: true,
      features: [
        {
          text: "3 encuentros con uno de nuestros profesionales psi",
          icon: "Check",
        },
        {
          text: "6 técnicas de autoconocimiento",
          icon: "Check",
        },
        {
          text: "Acceso a todo el camino Woiro",
          icon: "Check",
        },
        {
          text: "Acceso a jóvenes profesionales",
          icon: "Check",
        },
        {
          text: "Informe final personalizado",
          icon: "Check",
        },
      ],
    },
    {
      recommendedFor:
        "¿Estás más perdido en cuanto a tus intereses y proyecto de vida? Te recomendamos este plan.",
      code: "full",
      name: "Proceso Full",
      price: getPrice(process.env.REACT_APP_ENV, "es", "FULL"),
      currency_id: "USD",
      currency: "$",
      mercadopago: false,
      paypal: true,
      features: [
        {
          text: "5 encuentros con uno de nuestros profesionales psi",
          icon: "Check",
        },
        {
          text: "7 técnicas de autoconocimiento",
          icon: "Check",
        },
        {
          text: "Acceso a todo el camino Woiro",
          icon: "Check",
        },
        {
          text: "Acceso a jóvenes profesionales",
          icon: "Check",
        },
        {
          text: "Informe final personalizado",
          icon: "Check",
        },
      ],
    },
  ],
};

function getPrice(env, country, plan) {
  const plans = {
    development: {
      ar: {
        STARTER: 10,
        FULL: 10,
        BASIC: 10,
      },
      cl: {
        STARTER: 10,
        FULL: 10,
        BASIC: 10,
      },
      es: {
        STARTER: 10,
        FULL: 10,
        BASIC: 10,
      },
    },
    production: {
      ar: {
        STARTER: 2400,
        FULL: 9800,
        BASIC: 6500,
      },
      cl: {
        STARTER: 23600,
        FULL: 90400,
        BASIC: 58950,
      },
      es: {
        STARTER: 35,
        FULL: 120,
        BASIC: 80,
      },
    },
  };

  return plans[env][country][plan];
}

export function getPlans(country_id) {
  return plans[country_id];
}

const firebase = require("../config/firebase");
const firestore = firebase.db;
const users = firestore.collection("users");

export function listenOnPlanChange(userId, callback) {
  users.doc(userId).onSnapshot(function (doc) {
    let user = doc.data();
    if (user.plan) {
      callback(user.plan);
    } else {
      callback(undefined);
    }
  });
}
