import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import HeaderLanding from "components/Header/HeaderLanding.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// import Button from "components/CustomButtons/Button.jsx";
// sections for this page
import IntitutionsText from "./Sections/IntitutionsText.jsx";

// import SectionComponents from "./Sections/SectionComponents.jsx";
import SectionCards from "./Sections/SectionCards.jsx";

import { connect } from "react-redux";
import { Redirect } from "react-router-i18n";

import landingPageStyle from "assets/jss/material-ui-styles/views/landingPageStyle.jsx";

import Card from "components/Card/Card.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Muted from "components/Typography/Muted.jsx";

import marc from "assets/img/faces/marc.jpg";
import christian from "assets/img/faces/christian.jpg";
import kendall from "assets/img/faces/kendall.jpg";
import avatar from "assets/img/faces/avatar.jpg";
import I18n from "../../i18n";

import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import "assets/scss/landing.css";
import "assets/scss/landing/icofont/icofont.css";

const notificationServices = require("services/notificationservices");

class LandingInstitutionsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      loading: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  sendContactUsEmail() {
    let msg = {
      subject: "CONTACT-US: " + this.state.contactUsInstitutionName,
      to: "infowoiro@gmail.com",
      button_link: "https://woiro.com",
      button_text: "Ir a woiro",
      body: this.state.contactUsMessage,
      title:
        "Mensaje de: " +
        this.state.contactUsName +
        "(" +
        this.state.contactUsEmail +
        ")",
    };

    this.setState({ loading: true });
    notificationServices
      .sendPushEmail(msg)
      .then((res) => {
        this.setState({ contactUsSuccess: true });
      })
      .finally(() => {
        this.setState({ loading: false });
      })
      .catch((err) => alert("Ups, hubo un error. (" + err.message + ")"));
  }
  render() {
    const { classes } = this.props;

    const Accordion = withStyles({
      root: {
        border: "1px solid rgba(0, 0, 0, .125)",
        boxShadow: "none",
        "&:not(:last-child)": {
          borderBottom: 0,
        },
        "&:before": {
          display: "none",
        },
        "&$expanded": {
          margin: "auto",
        },
      },
      expanded: {},
    })(MuiAccordion);

    const AccordionSummary = withStyles({
      root: {
        backgroundColor: "rgba(0, 0, 0, .03)",
        borderBottom: "1px solid rgba(0, 0, 0, .125)",
        marginBottom: -1,
        minHeight: 56,
        "&$expanded": {
          minHeight: 56,
        },
      },
      content: {
        "&$expanded": {
          margin: "12px 0",
        },
      },
      expanded: {},
    })(MuiAccordionSummary);

    const AccordionDetails = withStyles((theme) => ({
      root: {
        padding: theme.spacing(2),
      },
    }))(MuiAccordionDetails);

    return (
      <div>
        <header id="header" className="fixed-top ">
          <div className="container d-flex align-items-center">
            <a href="https://hola.woiro.com/ar" className="logo">
              <img src={"/img/Logo3x.png"} alt="" className="img-fluid" />
            </a>
            <nav className="nav-menu d-none d-lg-block">
              <ul>
                <li className="active">
                  <a href="https://hola.woiro.com/ar">Home</a>
                </li>
                <li>
                  <a href="#contact" className="scrollto">
                    Contacto
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>

        <Parallax image={require("assets/img/forest.jpg")} filter="dark">
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem md={8}>
                {/* <h1 className="hero-title">
                  
                </h1> */}
                <h4 className="hero-subtitle">
                  Bienvenido a Woiro: <br />
                  Una plataforma de orientación vocacional <br /> 100% digital y
                  personalizada
                </h4>
                <br />
                <Button
                  color="primary"
                  className="btn-get-started"
                  size="sm"
                  href="#contact"
                >
                  Contactanos
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classes.main}>
          <div>
            <div className={classes.container}>
              <IntitutionsText />
            </div>

            <section id="services" className="services">
              <div className="container">
                <div className="section-title">
                  <h2>¿Lo sabías?</h2>
                  <p>Diversos estudios muestran que…</p>
                </div>
                <div className="row">
                  <div className="service-box">
                    <div className="icon-box">
                      <i className="icofont-meetme"></i>
                      <h4>
                        <a>
                          La madurez vocacional tiene una correlación
                          significativa con el rendimiento académico
                        </a>
                      </h4>
                    </div>
                  </div>
                  <div className="service-box">
                    <div className="icon-box">
                      <i className="icofont-chart-histogram"></i>
                      <h4>
                        <a>
                          Si el alumno elige correctamente su carrera, aumentará
                          el promedio de sus calificaciones en un 7%
                        </a>
                      </h4>
                    </div>
                  </div>
                  <div className="service-box">
                    <div className="icon-box">
                      <i className="icofont-computer"></i>
                      <h4>
                        <a>
                          El uso de plataformas virtuales se incrementó en la
                          educación secundaria, llegando al 40%
                        </a>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section id="faq" className="faq">
              <div className="container">
                <div className="section-title">
                  <h2>Preguntas frecuentes</h2>
                  <p>Acerca de nosotros</p>
                </div>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    ¿Qué es Woiro?
                  </AccordionSummary>
                  <AccordionDetails>
                    Woiro es una plataforma de orientación vocacional online en
                    la que los protagonistas son los usuarios.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    ¿Qué creemos?
                  </AccordionSummary>
                  <AccordionDetails>
                    En Woiro creemos que la elección de una carrera es un
                    proceso personal en el que influyen muchas variables.
                    Nuestro objetivo es proporcionarle a los usuarios las
                    herramientas para que puedan tomar una buena decisión.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                  >
                    ¿Qué ayudamos a analizar?
                  </AccordionSummary>
                  <AccordionDetails>
                    Inteligencias múltiples, historia personal, influencia del
                    entorno, recursos y aptitudes, intereses, modalidades y
                    estrategias de aprendizaje.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4a-content"
                    id="panel4a-header"
                  >
                    ¿Qué incluye Woiro?
                  </AccordionSummary>
                  <AccordionDetails>
                    Encuentros online individuales con un psicólogo o
                    psicopedagogo de nuestro equipo, acceso a nuestro plantel de
                    jóvenes profesionales, técnicas de autoconocimiento,
                    información de carreras y universidades, actividades de
                    introspección y extrospección, acceso a la comunidad de
                    usuarios de Woiro.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel5a-content"
                    id="panel5a-header"
                  >
                    ¿Qué podemos hacer juntos?
                  </AccordionSummary>
                  <AccordionDetails>
                    Ofrecer a tus alumnos un proceso de acompañamiento
                    vocacional que es 100% online y personalizado, y actividades
                    grupales.
                  </AccordionDetails>
                </Accordion>
              </div>
            </section>

            <section id="contact" className="contact">
              <div className="container">
                <div className="section-title">
                  <h2>¡Hablemos!</h2>
                  <p>Contacto</p>
                </div>

                <div className="row mt-5">
                  <div className="col-lg-4">
                    <div className="info">
                      <div className="email">
                        <a href="mailto:info@woiro.com">
                          <i className="icofont-envelope"></i>
                          <h4>Email:</h4>
                          <p>info@woiro.com</p>
                        </a>
                      </div>

                      <div className="phone">
                        <a href="tel:+54 9 11 7362-9603">
                          <i className="icofont-phone"></i>
                          <h4>Teléfono:</h4>
                          <p>+54 11 3164 2800</p>
                        </a>
                      </div>
                      <div className="phone">
                        <a
                          href="https://api.whatsapp.com/send/?phone=541131642800&text&app_absent=0"
                          target="_blank"
                        >
                          <i className="icofont-whatsapp"></i>
                          <h4>Whatsapp</h4>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-8 mt-5 mt-lg-0">
                    <form method="post" role="form" className="php-email-form">
                      <div className="form-row">
                        <div className="col-md-6 form-group">
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            id="name"
                            placeholder="Tu Nombre"
                            data-rule="minlen:4"
                            data-msg="Escribe al menos 4 caracteres"
                            value={this.state.contactUsName}
                            onChange={(event) => {
                              this.setState({
                                contactUsName: event.target.value,
                              });
                            }}
                          />
                          <div className="validate"></div>
                        </div>
                        <div className="col-md-6 form-group">
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            id="email"
                            placeholder="Tu Mail"
                            data-rule="email"
                            data-msg="Por favor ingresá un correo válido"
                            value={this.state.contactUsEmail}
                            onChange={(event) => {
                              this.setState({
                                contactUsEmail: event.target.value,
                              });
                            }}
                          />
                          <div className="validate"></div>
                        </div>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="subject"
                          id="subject"
                          placeholder="Institución"
                          data-rule="minlen:4"
                          data-msg="Escribe al menos 8 caracteres"
                          value={this.state.contactUsInstitutionName}
                          onChange={(event) => {
                            this.setState({
                              contactUsInstitutionName: event.target.value,
                            });
                          }}
                        />
                        <div className="validate"></div>
                      </div>
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          name="message"
                          rows="5"
                          data-rule="required"
                          data-msg="Por favor, escribe algo para nosotros"
                          placeholder="Mensaje"
                          value={this.state.contactUsMessage}
                          onChange={(event) => {
                            this.setState({
                              contactUsMessage: event.target.value,
                            });
                          }}
                        ></textarea>
                        <div className="validate"></div>
                      </div>
                      <div className="mb-3">
                        {this.state.loading && (
                          <div className="loading">Cargando</div>
                        )}
                        <div className="error-message"></div>
                        {this.state.contactUsSuccess && (
                          <div className="sent-message">
                            Tu mensaje ha sido enviado. Gracias!
                          </div>
                        )}
                      </div>
                      <div className="text-center">
                        <button
                          type="button"
                          onClick={() => {
                            this.sendContactUsEmail();
                          }}
                        >
                          Enviar Mensaje
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(
  withStyles(landingPageStyle)(LandingInstitutionsPage)
);
