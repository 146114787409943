const firebase = require("../config/firebase");

const firestore = firebase.db;
const eventsRef = firestore.collection("events");

function _addEvent(userUid, userEmail, eventType, data, info) {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    user_id: userUid,
    eventType: eventType,
    userEmail: userEmail,
    data: data,
    info: info
  });

  let uid = eventsRef.doc().id;

  let now = new Date();
  return eventsRef
    .doc(uid)
    .set({
      userUid: userUid ? userUid : null,
      userEmail: userEmail ? userEmail : null,
      created: now,
      eventType: eventType ? eventType : null,
      data: data ? data : null,
      info: info ? info : null,
      location: window.location.href
    })
    .then(() => { })
    .catch(err => {
      console.log(err);
    });
}

function _getEventsByEmail(userEmail) {
  if (userEmail) {
    return (
      eventsRef
        .where("userEmail", "==", userEmail.toLowerCase())
        //.orderBy("created")
        //.limit(100)
        .get()
        .then(querySnapshot => {
          return querySnapshot.docs.map(doc => {
            return doc.data();
          });
        })
        .catch(e => console.log(e))
    );
  } else {
    return (
      eventsRef
        //.orderBy("created")
        //limit(100)
        .get()
        .then(querySnapshot => {
          return querySnapshot.docs.map(doc => {
            return doc.data();
          });
        })
        .catch(e => console.log(e))
    );
  }
}

function _getEvents(userUid) {
  if (userUid) {
    return (
      eventsRef
        .where("userUid", "==", userUid)
        // .orderBy("created")
        // .limit(100)
        .get()
        .then(querySnapshot => {
          return querySnapshot.docs.map(doc => {
            return doc.data();
          });
        })
        .catch(e => console.log(e))
    );
  } else {
    return (
      eventsRef
        // .orderBy("created")
        // .limit(100)
        .get()
        .then(querySnapshot => {
          return querySnapshot.docs.map(doc => {
            return doc.data();
          });
        })
        .catch(e => console.log(e))
    );
  }
}


export const addEvent = _addEvent;
export const getEvents = _getEvents;
export const getEventsByEmail = _getEventsByEmail;

