import {
  container,
  defaultFont,
  primaryColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  roseColor,
  transition,
  boxShadow,
  drawerWidth
} from "assets/jss/material-ui-styles.jsx";

const flowStepperStyle = theme => ({

  // contentTrack: {
  //   width: "100%",
  //   maxWidth: "1000px",
  //   padding: "15px",
  //   fontFamily: "Arial, Helvetica, sans-serif",
  //   margin: "auto",
  //   "& ul": {
  //     listStyle: "none",
  //     margin: "auto",
  //     clear: "both",
  //     maxWidth: "300px"
  //   }
  // },

  // contentTrackTwo: {
  //   maxWidth: "200px"
  // }, 

});

export default flowStepperStyle;
