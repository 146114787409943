import {
  FETCH_COUNTRY_START,
  FETCH_COUNTRY_END,
  FETCH_COUNTRY_ERROR
} from "./types";

import { getCurrentLocation } from "../../i18n"

var ipapi = require('ipapi.co');

export const fetchCountry = () => dispatch => {
  dispatch({
    type: FETCH_COUNTRY_START
  });

  const countryFromUrl = getCurrentLocation(window.location);

  if (countryFromUrl) {
    dispatch({
      type: FETCH_COUNTRY_END,
      payload: countryFromUrl
    })
  } else {
    ipapi.location((data) => dispatch({
      type: FETCH_COUNTRY_END,
      payload: data.toString().toLowerCase()
    }), '', '', 'country');
  }
};