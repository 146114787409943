const { cors_api_host } = require("helpers/networkHelper.js");
const axios = require("axios");

const aamApiBase = "https://www.woiro.com/foro/wp-json/aam/v1";
const bbpApiBase = "https://www.woiro.com/foro/wp-json/bbp-api/v1";
const wpApiBase = "https://www.woiro.com/foro/wp-json/wp/v2";

const usersEndpoint = wpApiBase + "/users";
const authenticationEndpoint = aamApiBase + "/authenticate";

var appConfig = require("config/appConfig");

function _authenticate(user) {
  let config = {
    headers: {
      //'Authorization': 'Bearer ' + token,
      //Authentication...
      "Content-Type": "application/json",
      Accept: `application/vnd.iman.v1+json, application/json, text/plain, */*`
    }
  };

  return axios
    .post(
      authenticationEndpoint,
      {
        username: "santiago.demian.abdala@gmail.com",
        email: "santiago.demian.abdala@gmail.com",
        password: "@creator1234PWD",
        name: user.displayName
      },
      config
    )
    .then(userAuth => {
      config.headers.Authentication = "Bearer " + userAuth.data.token;
      axios
        .post(
          usersEndpoint,
          {
            username: user.email,
            email: user.email,
            password: user.uid,
            name: user.displayName
          },
          config
        )
        .then(userCreation => {
          console.log(userCreation);
          alert("OK2 !");
        })
        .catch(e => {
          if (e.code == "existing_user_email") {
            alert("OK2 !");
          }
        });
    })
    .catch(e => {
      console.log(e);
      alert("error de autenticacion de creator");
    });

  // axios.post('url', data, {
  //     headers: {
  //       'X-My-Custom-Header': 'foo'
  //     }
  //   });

  //
}
function _postReply(token, topicId, content) {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: `application/vnd.iman.v1+json, application/json, text/plain, */*`,
      "App-Version": 1
    }
  };

  return axios.post(
    appConfig.boApiUrl + "forum/postReply",
    { topicId: topicId, content: content },
    config
  );
}

function _newTopic(token, forumId, title, content) {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: `application/vnd.iman.v1+json, application/json, text/plain, */*`,
      "App-Version": 1
    }
  };
  return axios.post(
    appConfig.boApiUrl + "forum/newTopic",
    { forumId: forumId, title: title, content: content },
    config
  );
}

function _internalGetTopics() {
  let url = "https://www.woiro.com/foro/wp-json/bbp-api/v1/topics?per_page=200";
  // if (window.capacitorDeviceInfo.platform == "ios") {
  //   return axios.get("https://" + cors_api_host + "/" + url, {
  //     headers: {
  //       Accept: `application/vnd.iman.v1+json, application/json, text/plain, */*`,
  //       "Content-Type": "application/json; charset=UTF-8"
  //     }
  //   });
  // } //android y web
  // else {
  return axios.get(url, {
    headers: {
      Accept: `application/vnd.iman.v1+json, application/json, text/plain, */*`,
      "Content-Type": "application/json; charset=UTF-8"
    }
  });
  // }
}

function _internalGetTags() {
  let url = "https://www.woiro.com/foro/wp-json/bbp-api/v1/topic-tags";
  // if (window.capacitorDeviceInfo.platform == "ios") {
  //   return axios.get("https://" + cors_api_host + "/" + url, {
  //     headers: {
  //       Accept: `application/vnd.iman.v1+json, application/json, text/plain, */*`,
  //       "Content-Type": "application/json; charset=UTF-8"
  //     }
  //   });
  // } //android y web
  // else {
  return axios.get(url, {
    headers: {
      Accept: `application/vnd.iman.v1+json, application/json, text/plain, */*`,
      "Content-Type": "application/json; charset=UTF-8"
    }
  });
  // }
}

function _getTopics() {
  return new Promise(function (resolve, reject) {
    let topics = [];

    _internalGetTags()
      .then(tags => {
        _internalGetTopics()
          .then(resp => {
            if (resp && resp.data && resp.data.topics) {
              //topics = resp.data.topics;
              resp.data.topics.forEach(topic => {
                let topicTags = [];
                if (tags && tags.data) {
                  tags.data.forEach(tag => {
                    if (topic.tags && topic.tags.indexOf(tag.name) !== -1) {
                      topicTags.push(tag.name);
                    }
                  });
                }

                topics.push({
                  ...topic,
                  tags: topicTags
                });
              });
            }

            resolve(topics);
          })
          .catch(err => {
            console.log("TopicService (1):", err, {
              message: err.message,
              codeDos: err.code ? err.code : null,
              data:
                err.response && err.response.data
                  ? JSON.stringify(err.response.data)
                  : null,
              status: err.response ? err.response.status : null,
              code: err.response ? err.response.code : null
            });
            reject(err);
          });
      })
      .catch(err => {
        console.log("TopicService (2):", err, {
          message: err.message,
          codeDos: err.code ? err.code : null,
          data:
            err.response && err.response.data
              ? JSON.stringify(err.response.data)
              : null,
          status: err.response ? err.response.status : null,
          code: err.response ? err.response.code : null
        });
        reject(err);
      });
  });
}

export const postReply = _postReply;
export const newTopic = _newTopic;
export const authenticate = _authenticate;
export const getTopics = _getTopics;
