import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import Footer from "components/Footer/Footer.jsx";
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";

import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import headersStyle from "assets/jss/material-ui-styles/views/sectionsSections/headersStyle.jsx";

import classNames from "classnames";

import ChevronLeft from "@material-ui/icons/ChevronLeft";

const bg12 = "/img/texture-red.jpg";

class ErrorPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    //window.lastError
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    const { classes, ...rest } = this.props;

    const styledIcon = {
      marginRight: 4,
      verticalAlign: "middle"
    };

    return (
      <div>
        <Header
          authenticatedUser={this.props.auth}
          links={<HeaderLinks authenticatedUser={this.props.auth} />}
          fixed
          color="transparent"
          forceTransparent={true}
          changeColorOnScroll={{
            height: 250,
            color: "white"
          }}
          {...rest}
        />
        <div
          className={classes.pageHeader}
          style={{ backgroundImage: `url("${bg12}")` }}
        >
          <div
            className="cd-section"
            {...rest}
            style={{ paddingTop: 80, width: "100%" }}
          >
            <div className={classes.container}>
              <GridContainer>
                <GridItem
                  xs={12}
                  sm={7}
                  md={7}
                  className={classNames(classes.mlAuto, classes.textRight)}
                >
                  <h1 className={classes.title}>Ocurrió un error -(</h1>
                  <h4>Te pedimos disculpas, volvé a intentar.</h4>
                  <br />
                  <div>
                    <Button
                      color="primary"
                      size="lg"
                      onClick={() => {
                        this.goBack();
                      }}
                    >
                      <ChevronLeft style={{ float: "left" }} /> Volver
                    </Button>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default withStyles(headersStyle)(ErrorPage);
