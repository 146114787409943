import React, { Component } from "react";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import classNames from "classnames";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Search from "@material-ui/icons/Search";

import CardSearchResult from "components/Card/CardSearchResult";

import withStyles from "@material-ui/core/styles/withStyles";
import styles from "../jss/style.jsx";
import { connect } from "react-redux";
import { filterCareersByNameAndArea } from "../actions/careersActions";
import SectionSearchElement from "./SectionSearchElement.jsx";
import { fetchCareers } from "../actions/careersActions";

class SectionSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredList: [],
      keyword: ""
    };
  }

  onChange = event => {
    let value = event.target.value;

    this.setState({ keyword: value }, () => {
      if (value.trim() == "") {
        this.props.filterCareersByNameAndArea([], "");
      } else {
        this.props.filterCareersByNameAndArea(this.props.careers.all, value);
      }
    });
  };

  componentDidMount() {
    this.props.fetchCareers();
  }

  render() {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={8}>
          <CustomInput
            formControlProps={{
              fullWidth: true,
              className: classes.cardFormSearch
            }}
            inputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              placeholder: "Buscar una carrera ...",
              onChange: e => this.onChange(e)
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={4}>
          <Button
            color="primary"
            block
            className={classes.subscribeButton}
            style={{ marginTop: 10 }}
          >
            buscar{" "}
          </Button>
        </GridItem>
        <GridItem>
          {this.props.careers.filtered &&
            this.props.careers.filtered.slice(0, 5).map((career, key) => {
              return (
                <CardSearchResult
                  image={career.ListImage}
                  title={career.Name}
                  description={career.DescriptionForList}
                  onClick={() => {
                    this.props.careerDetail(
                      career,
                      this.props.careers.filtered
                    );
                    this.props.filterCareersByNameAndArea([], ""); //Para limpiar las filtradas
                  }}
                />
              );
            })}
        </GridItem>
        {this.props.careers &&
          this.props.careers.filtered &&
          this.props.careers.filtered.length == 0 &&
          this.state.keyword != "" &&
          this.props.defaultItem && (
            <GridItem>{this.props.defaultItem}</GridItem>
          )}
      </GridContainer>
    );
  }
}

const mapStateToProps = state => ({
  careers: state.careers
});

const mapDispatchToProps = {
  filterCareersByNameAndArea,
  fetchCareers
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SectionSearch));
