import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react component used to create nice image meadia player
import ImageGallery from "react-image-gallery";
import withStyles from "@material-ui/core/styles/withStyles";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
// core components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Footer from "components/Footer/Footer.jsx";
import Accordion from "components/Accordion/Accordion.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import productStyle from "assets/jss/material-ui-styles/views/productStyle.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Loading from "components/Loader/Loading.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Table from "components/Table/Table.jsx";
import Edit from "@material-ui/icons/Edit";

class CareerListsAdminDetailPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      list: null
    };
  }

  innerSaveList() {
    this.props.saveList(this.state.list);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ list: nextProps.detail.list });
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    console.log(this.props);
    const { classes } = this.props;

    if (!this.state.list) return <Loading />;

    let tableData = [];
    if (this.state.list.Careers)
      this.state.list.Careers.sort((a, b) => {
        if (typeof a.order === "undefined") a.order = 999;
        if (typeof b.order === "undefined") b.order = 999;

        return a.order - b.order;
      }).forEach(c => {
        tableData.push([
          c.Name,
          <CustomInput
            labelText="Order"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              id: "input_" + c.Name.replace(" ", "_"),
              value: c.order,
              onChange: e => {
                c.order = parseInt(e.target.value);
                this.setState({ list: this.state.list }, () => {
                  document
                    .getElementById("input_" + c.Name.replace(" ", "_"))
                    .focus();
                });
              }
            }}
          />
        ]);
      });

    tableData.push([
      "Agregar nueva carrera",
      <Button
        color="info"
        size="sm"
        onClick={() => {
          alert("Aún en desarrollo...");
        }}
      >
        <Edit />
      </Button>
    ]);

    return (
      <div className={classes.productPage}>
        <Header
          authenticatedUser={this.props.auth}
          links={
            <HeaderLinks
              dropdownHoverColor="rose"
              authenticatedUser={this.props.auth}
            />
          }
          fixed
          color="transparent"
          forceTransparent={true}
          changeColorOnScroll={{
            height: 100,
            color: "white"
          }}
        />
        <Parallax
          image={require("assets/img/backgrounds/IPU-imagen-05.jpg")}
          filter="dark"
          className={classes.pageHeader}
        />
        <div className={classNames(classes.section, classes.sectionGray)}>
          <div className={classes.container}>
            <div className={classNames(classes.main, classes.mainRaised)}>
              <GridContainer>
                <GridItem md={12} sm={12}>
                  <div
                    onClick={() => {
                      this.goBack();
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <ChevronLeft style={{ float: "left" }} />
                    Volver
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem md={12} sm={12}>
                  <CustomInput
                    labelText="Description"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: this.state.list.Description,
                      onChange: e => {
                        this.state.list.Description = e.target.value;
                        this.setState({ list: this.state.list });
                      }
                    }}
                  />
                </GridItem>
                <GridItem md={12} sm={12}>
                  <CustomInput
                    labelText="List Order"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: this.state.list.order,
                      onChange: e => {
                        this.state.list.order = parseInt(e.target.value);
                        this.setState({ list: this.state.list });
                      }
                    }}
                  />
                </GridItem>
                <GridItem md={12} sm={12}>
                  <Table
                    tableHead={[]}
                    tableData={tableData}
                    customCellClasses={[
                      classes.textCenter,
                      classes.textRight,
                      classes.textRight
                    ]}
                    customClassesForCells={[0, 4, 5]}
                    customHeadCellClasses={[
                      classes.textCenter,
                      classes.textRight,
                      classes.textRight
                    ]}
                    customHeadClassesForCells={[0, 4, 5]}
                  />
                </GridItem>

                <GridItem md={12} sm={12} style={{ float: "right" }}>
                  <Button
                    color="info"
                    size="sm"
                    onClick={() => {
                      this.innerSaveList();
                    }}
                  >
                    <Edit /> Guardar
                  </Button>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(productStyle)(CareerListsAdminDetailPage);
