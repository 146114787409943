import React, { Component } from "react";
import { connect } from "react-redux";
import LogsAdminComponent from "./LogsAdminPage";

class LogsAdminContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}
  render() {
    let cotent = this.state.loading ? (
      <div>...</div>
    ) : (
      <div>
        <LogsAdminComponent {...this.props} />
      </div>
    );
    return cotent;
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {}
)(LogsAdminContainer);
