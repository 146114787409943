import React from "react";
import classNames from "classnames";
import { makeStyles } from '@material-ui/core/styles';

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";

import style from './stlye';

const useStyles = makeStyles(style);

export default function TestDots(props) {
    const classes = useStyles(props);
    const { questions, onClick, loadTestData } = props;
    return questions.map((question, index) => {
        return <GridContainer alignItems="center" justify="center">
            <GridItem key={question.Text} style={{ textAlign: "center" }}>
                <h4 onClick={index == 0 ? loadTestData : undefined}><b>{question.Order}. {question.Text}</b></h4>
            </GridItem>
            <GridItem>
                <GridContainer direction="row" alignItems="center" justify="center">
                    {
                        question.Rates.map(rate => {
                            const checkedClass = rate.selected ? classes.customRadioButtonPositiveChecked : classes.customRadioButtonPositive;
                            const className = classNames(classes.customRadioButton, classes.customRadioButtonSmall, checkedClass);
                            return <GridContainer spacing="5" direction="column" alignItems="center" justify="center">
                                <GridItem xs={3} sm={3} md={3} style={{ alignItems: "center", display: "flex", flexDirection: "column", marginBottom: "15px", marginTop: "-10px" }} className={classNames(classes.mlAuto, classes.mrAuto)}>
                                    <Button
                                        onClick={(e) => { onClick(question.Order, rate.Value) }}
                                        justIcon round className={className} >
                                        <div></div>
                                    </Button>
                                    {rate.Text || rate.Value}
                                </GridItem>
                            </GridContainer>
                        })
                    }
                </GridContainer>
            </GridItem>
        </GridContainer>
    })
}