import React from "react";
import ProgressStepPageContainer from '../ProgressStepPage/ProgressStepPageContainer';
import withStyles from "@material-ui/core/styles/withStyles";
import headersStyle from "assets/jss/material-ui-styles/views/sectionsSections/headersStyle.jsx";
import { addStepToProgress } from '../../redux/progress/actions';
import { connect } from "react-redux";
import classNames from "classnames";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CardExperience from "components/Card/CardExperience";
import CustomInput from "components/CustomInput/CustomInput.jsx";

class ProgressStepExperienceContainer extends ProgressStepPageContainer {
  constructor(props) {
    super(props);
  }

  getStateToSave() {
    let infoToDisplay = `${this.state.selectedExperience.Name}: ${this.state.input}`;
    return { ...this.state, infoToDisplay };
  }

  fetchElements() {
    // let mate = {
    //   Id: 1,
    //   ListImage: "/img/experience/mate.jpg",
    //   Name: "Tomate un mate solo",
    //   Description:
    //     "Andá a una plaza o algún lugar donde puedas estar tranquilo y tomate un mate, solo, sin nadie que te distraiga. Si te animás, dejá el celular en tu casa."
    // };
    // let charla = {
    //   Id: 2,
    //   ListImage: "/img/experience/mentoria.jpg",
    //   Name: "Hablá con alguien",
    //   Description:
    //     "Tomate un ratito para hablar con un referente tuyo (puede ser un familiar, un amigo, un profesor, un entrenador...quien vos prefieras) acerca de la vocación, la universidad y el trabajo."
    // };
    // let cosas = {
    //   Id: 3,
    //   ListImage: "/img/experience/lista.jpg",
    //   Name: "Hacé algo que te guste",
    //   Description:
    //     "Hacé una lista de 20 cosas que te gusta hacer. Después elegí una y hacela!"
    // };
    let vidas = {
      Id: 1,
      ListImage: "/img/experience/imaginacion.jpg",
      Name: "Imaginá otras vidas",
      Description:
        "Si no fueras vos, ¿qué te gustaría ser? Escribí acerca de 2 (o más) vidas imaginarias con todo el detalle que puedas. Por ejemplo: jugador de fútbol del Real Madrid, guitarrista de los Rolling Stones, científica en la Antártida..."
    };
    let futuro = {
      Id: 2,
      ListImage: "/img/experience/futuro.jpg",
      Name: "Imaginá tu futuro",
      Description:
        "¿Cómo te ves dentro de 7 años? ¿Cómo te gustaría ser? ¿Dónde te gustaría vivir? Imaginá tu futuro ideal y escribilo."
    };
    let deseos = {
      Id: 3,
      ListImage: "/img/experience/deseos.jpg",
      Name: "Tus deseos",
      Description:
        "Escribí una lista de 20 deseos que tengas y te gustaría cumplir. Por ejemplo: viajar en el espacio, conocer Rusia, escribir un libro."
    };
    // let collage = {
    //   Id: 7,
    //   ListImage: "/img/experience/collage.jpg",
    //   Name: "Collage",
    //   Description:
    //     "¿Qué cosas te interesan? Juntá revistas y hacé un collage para ayudarte a ubicar y entender tus intereses."
    // };
    // let solidaridad = {
    //   Id: 8,
    //   ListImage: "/img/experience/solidaridad.jpg",
    //   Name: "Solidaridad",
    //   Description: "Hacé algo bueno por una persona que no conozcas."
    // };
    // let cocina = {
    //   Id: 9,
    //   ListImage: "/img/experience/cocina.jpg",
    //   Name: "Tu turno de cocinar",
    //   Description:
    //     "Cociná para alguien que quieras. No hace falta que sea un plato muy elaborado, cualquier cosa va a estar bien, hasta unos panchos."
    // };
    // let peliculas = {
    //   Id: 10,
    //   ListImage: "/img/experience/peliculas.jpg",
    //   Name: "Películas recomendadas",
    //   Description: (
    //     <p>
    //       Mirá alguna película inspiradora. Hay muchas, pero algunas que te
    //       recomendamos son:
    //       <ul>
    //         <li style={{ display: "block" }} style={{ display: "block" }}>
    //           Billy Elliot
    //         </li>
    //         <li style={{ display: "block" }}>La Sonrisa de Mona Lisa</li>
    //         <li style={{ display: "block" }}>Los Coristas</li>
    //         <li style={{ display: "block" }}>El Rey León</li>
    //         <li style={{ display: "block" }}>En Busca del Destino</li>
    //         <li style={{ display: "block" }}>
    //           La Sociedad de los Poetas Muertos
    //         </li>
    //         <li style={{ display: "block" }}>En Busca de la Felicidad</li>
    //         <li style={{ display: "block" }}>Kung Fu Panda</li>
    //         <li style={{ display: "block" }}>El Triunfo del espíritu</li>
    //         <li style={{ display: "block" }}>El Guerrero Pacífico</li>
    //         <li style={{ display: "block" }}>Forrest Gump</li>
    //         <li style={{ display: "block" }}>Jerry Maguire</li>
    //         <li style={{ display: "block" }}>Coach Carter</li>
    //         <li style={{ display: "block" }}>Steve Jobs</li>
    //         <li style={{ display: "block" }}>La Teoría del Todo</li>
    //         <li style={{ display: "block" }}>Cuestión de Tiempo</li>
    //         <li style={{ display: "block" }}>Monster University</li>
    //         <li style={{ display: "block" }}>Mi Encuentro Conmigo</li>
    //         <li style={{ display: "block" }}>El Aviador</li>
    //         <li style={{ display: "block" }}>Escritores de la Libertad</li>
    //         <li style={{ display: "block" }}>Un Sueño Posible</li>
    //       </ul>
    //     </p>
    //   )
    // };

    return [
      vidas,
      futuro,
      deseos
    ];
  }

  getDefaultState() {
    return {
      experiences: this.fetchElements(),
      selectedExperience: null,
      input: "",
      disableButton: true,
      selected: false
    }
  }

  getButtons() {
    if (this.state.selected) {
      return [this.getDefaultButton()]
    }

    return [this.getButton("Seleccionar", !this.state.selectedExperience, () => this.setState({ selected: true }))];
  }

  getChildren(classes) {

    if (this.state.selected) {
      return <GridContainer justify="center">
        <GridItem md={4} sm={4}>
          <CustomInput
            particularInputClasses={classNames(classes.input, classes.whiteInput)}
            formControlProps={{
              fullWidth: true,
              className: classes.customFormControlClasses
            }}
            inputProps={{
              value: this.state.input,
              onChange: e => this.setState({ input: e.target.value, disableButton: e.target.value.length < 10 }),
              multiline: true,
              rows: 3
            }}
            labelText="Experiencia"
          />
        </GridItem>
      </GridContainer>
    }

    return this.state.experiences.map(data => {
      let checked =
        this.state.selectedExperience &&
        this.state.selectedExperience.Id == data.Id;

      let cardClass = checked
        ? classNames("animated", "fadeInCustom", "delay-02s")
        : classNames("animated", "fadeOutCustom", "delay-02s");

      return (
        <GridItem
          className={cardClass}
          onClick={e => {
            this.handleCardAproveCheck(data);
          }}
        >
          <CardExperience
            image={data.ListImage}
            title={data.Name}
            description={data.Description}
          />
        </GridItem>)
    });
  }

  handleInputChange(event) {
    this.setState({ input: event.target.value });
  };

  handleCardAproveCheck(element) {
    this.setState({ selectedExperience: element });
  }

  getStateToSave() {
    return { ...this.state, experiences: [] }
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  progress: state.progress
});

const mapDispatchToProps = {
  addStepToProgress
}

export default withStyles(headersStyle)(connect(mapStateToProps, mapDispatchToProps)(ProgressStepExperienceContainer));
