import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import headerStyle from "assets/jss/material-ui-styles/components/flowStepperStyle.jsx";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class FlowStepper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previousStepRequired: false
    };
  }

  render() {
    const { classes, groupedSteps } = this.props;

    return (
      <div className={classes.root}>
        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: classes.modal
          }}
          open={this.state.previousStepRequired}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => {
            this.setState({ previousStepRequired: false });
          }}
          aria-labelledby="notice-modal-slide-title"
          aria-describedby="notice-modal-slide-description"
        >
          <DialogContent
            id="notice-modal-slide-description"
            className={classes.modalBody}
          >
            <h3>Te pedimos que por favor completes los pasos en orden. ¡Gracias!</h3>
          </DialogContent>
        </Dialog>

        <section className="design-process-section" id="process-tab">
          <div className="container">
            <div className="row">
              <div className="col-xs-12">
                <div className="content-track">
                  {groupedSteps && groupedSteps.map(steps => {
                    return <ul className={steps.length == 2 ? "two" : ""} key={steps[0].Code}>
                      {steps.map(step => {
                        let statusClass = step.isCompleted ? "complete" : step.isCurrent ? "current" : "";
                        return <li
                          key={`${step.Code}-${step.isCompleted}`}
                          style={{ zIndex: 15 }}
                          className={`${step.Class} ${statusClass}`}
                          onClick={e => {
                            if (step.isCompleted || step.isCurrent) {
                              this.props.history.push(step.URL)
                            }
                            else {
                              this.setState({ previousStepRequired: true });
                            }
                          }}
                        >
                          <i className={step.Icon} />
                          <p>{step.Name}</p>
                        </li>
                      })}
                    </ul>
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

FlowStepper.propTypes = {
  classes: PropTypes.object
};

export default withStyles(headerStyle)(FlowStepper);
