import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import FormatAlignLeft from "@material-ui/icons/FormatAlignLeft";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";

import CardFooter from "components/Card/CardFooter.jsx";
import Favorite from "@material-ui/icons/Favorite";
import Share from "@material-ui/icons/Share";

import {
  takeCharacters,
  getTagString,
  getImgFromTopicContent,
  safeForumTextNoImg
} from "helpers/topicHelper.js";

import styles from "../jss/sections/sectionOwnDiscussionsStyle.jsx";
class SectionOwnDiscussions extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { classes } = this.props;

    let forumFilter = "ipu";
    let filterTag_1 = "destacado";

    let topics = [];

    //me quedo con los topics que corresponden a esta seccion
    this.props.topics.forEach(topic => {
      let expectedTopics = topic.tags.find(tag => {
        if (tag.toLowerCase() == filterTag_1) return true;
        return false;
      });

      //en este formato solo muestro los topics del IPU
      //if (topic.author_name == "admin" && expectedTopics != null && expectedTopics.length != 0) {

      if (
        topic.forum_title.toLowerCase() == forumFilter &&
        expectedTopics != null &&
        expectedTopics.length != 0
      ) {
        topics.push(topic);
      }
    });

    return (
      <div className={classes.section} style={{ paddingTop: 10 }}>
        <GridContainer>
          {!topics || topics.length == 0 ? (
            <div />
          ) : (
            topics.map((topic, i) => {
              let tagsString = getTagString(topic.tags, filterTag_1);

              let cols = 6;

              if ((i + 1) % 3 == 0) cols = 12;

              let img = getImgFromTopicContent(
                topic.content,
                "/img/defaultBackgroundCard.jpg"
              );

              return (
                <GridItem xs={12} sm={cols} md={cols} key={i}>
                  <Card
                    raised
                    background
                    style={{ backgroundImage: "url(" + img + ")" }}
                    onClick={() => this.props.topicDetail(topic)}
                  >
                    <CardBody background>
                      <h6
                        className={classes.category}
                        style={{ textTransform: "uppercase", minHeight: 30 }}
                      >
                        {tagsString}
                      </h6>

                      <a href="">
                        <h3
                          className={classes.cardTitle}
                          style={{ minHeight: 110 }}
                        >
                          {topic.title}
                        </h3>
                      </a>

                      <p
                        className={classes.category}
                        dangerouslySetInnerHTML={{
                          __html: takeCharacters(
                            safeForumTextNoImg(topic.content),
                            100
                          )
                        }}
                      />
                      <Button round color="primary">
                        <FormatAlignLeft className={classes.icons} /> Ver
                      </Button>
                    </CardBody>
                    <CardFooter>
                      <div className={classes.authorWhite} />
                      <div
                        className={`${classes.statsWhite} ${classes.mlAuto}`}
                      >
                        <Share />
                        {topic.reply_count}
                      </div>
                    </CardFooter>
                  </Card>
                </GridItem>
              );
            })
          )}
        </GridContainer>
      </div>
    );
  }
}
export default withStyles(styles)(SectionOwnDiscussions);
