import React, { Component } from "react";
import { connect } from "react-redux";

import StepDone from "../Stepper/StepDone.jsx";

import { cleanNotification } from "../../redux/notifications/actions";

class NotificationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.timeout = null;
  }

  // componentDidMount() {
  //   console.log("notification component", this.props);
  //   this.props.notifications.forEach(element => {
  //     console.log(element);
  //   });
  // }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   console.log("Por update anda");
  //   console.log(prevProps, prevState, snapshot);
  // }

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  render() {
    this.timeout && clearTimeout(this.timeout);

    let notification =
      this.props.notifications && this.props.notifications.pop();

    this.timeout =
      notification &&
      this.props.cleanNotification(notification.uid, notification.timeout);

    return (
      <div>
        {notification && (
          <StepDone message={notification.message} key={notification.uid} />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  notifications: state.notifications
});

const mapDispatchToProps = { cleanNotification };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationComponent);
