import React from "react";
import ProgressStepPageContainer from "../ProgressStepPage/ProgressStepPageContainer";
import withStyles from "@material-ui/core/styles/withStyles";
import headersStyle from "assets/jss/material-ui-styles/views/sectionsSections/headersStyle.jsx";
import { addStepToProgress } from "../../redux/progress/actions";
import { connect } from "react-redux";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Success from "components/Typography/Success.jsx";
import Danger from "components/Typography/Danger.jsx";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { authRef } from "config/firebase";
import Input from "react-phone-number-input/input";
import { isValidPhoneNumber } from "react-phone-number-input";

import { getPlans, listenOnPlanChange } from "../../services/plansservices";
import { getPreferencesMercadoPago } from "../../services/mercadopagoservices";
import classNames from "classnames";

import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import { upsertUser } from "../../services/userservices";

const purchaseServices = require("../../services/purchaseservices");

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class ProgressStepPlansContainer extends ProgressStepPageContainer {
  constructor(props) {
    super(props);
  }

  getStateToSave() {
    return {
      disableButton: false,
      plan: this.state.plan,
    };
  }

  executeCustomComponentDidMount() {
    if (!this.getStep().isCompleted) {
      listenOnPlanChange(this.props.auth.uid, (userPlan) => {
        if (userPlan && userPlan != "free") {
          this.setState({ plan: userPlan }, this.handleStepDone);
        }
      });
    }

    let plans = getPlans(this.props.country.code);
    this.setState({ plans });

    let methods = [];

    plans.forEach((plan) => {
      const theUrl =
        "https://wa.me/5491173629603?text=" +
        "Contacto para contratar " +
        plan.name +
        " (" +
        this.props.country.code +
        ")";

      methods.push([{ url: theUrl, name: "Contactar con Woiro" }]);
    });

    this.setState({
      methods,
    });
    // getPreferencesMercadoPago(plans, this.props.country.code, {
    //   email: this.props.auth.email,
    //   uid: this.props.auth.uid,
    // }).then((preferences) => {
    //   let methods = [...this.state.methods];

    //   preferences.forEach((preference, index) => {
    //     const theUrl =

    //     if (methods.length - 1 >= index) {
    //       methods[index] = [
    //         ...methods[index],
    //         { url: preference, name: "Contactar con Woiro" },
    //       ];
    //     } else {
    //       methods.push([{ url: preference, name: "Contactar con Woiro" }]);
    //     }
    //   });

    //   this.setState({
    //     methods,
    //   });
    // });
  }

  getDefaultState() {
    return {
      plans: [],
      methods: [],
      plan: "",
      promotional_code: "",
      disableButton: true,
      disableCallmeButton: true,
      telephone: "",
      callme: false,
    };
  }

  getText() {
    if (
      this.props.match &&
      this.props.country &&
      this.props.country.code == "es"
    ) {
      return (
        <div>
          {super.getText()}
          <h4>
            Si quieres adquirir alguno de nuestros planes, por favor comunicate
            con nosotros por WhatsApp al +54 9 11 7362-9603. Estamos trabajando
            para integrar nuestra cuenta de PayPal y mejorar tu experiencia de
            pago!
          </h4>
        </div>
      );
    } else {
      return super.getText();
    }
  }

  getButtons() {
    // let callmeFn = () => this.setState({ callme: true });
    // let callmeButton = this.getButton("Contactenme", false, callmeFn, "secondary");
    let skipPlans = this.getButton(
      "Continuar Gratis",
      false,
      this.handleStepDone.bind(this),
      "secondary"
    );
    return [skipPlans, this.getDefaultButton()];
  }

  getChildren(classes) {
    if (
      this.props.location.search.includes("success") &&
      this.state.plan == ""
    ) {
      return (
        <h4>
          Estamos procesando tu pago, en cuanto esté acreditado podrás
          continuar. ¡Gracias por elegirnos para acompañarte en éste momento tan
          importante!
        </h4>
      );
    }

    const icons = {
      check: (
        <Success>
          <Check />
        </Success>
      ),
      cross: (
        <Danger>
          <Close />
        </Danger>
      ),
    };

    return (
      <GridContainer justify="center">
        {this.state.callme && (
          <Dialog
            open={true}
            TransitionComponent={Transition}
            keepMounted
            aria-labelledby="notice-modal-slide-title"
            aria-describedby="notice-modal-slide-description"
          >
            <DialogTitle>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => this.setState({ callme: false })}
                aria-label="close"
              >
                <ChevronLeft style={{ float: "left" }} />
              </IconButton>
              Volver
            </DialogTitle>
            <DialogContent id="notice-modal-slide-description">
              <h4>
                Dejanos tu número de celular y te vamos a estar contactando por
                WhatsApp o podés dejarnos un mensaje{" "}
                <a
                  href="https://wa.me/5491173629603?text=Hola! Quiero que me cuenten más de woiro"
                  target="_blank"
                >
                  acá
                </a>
              </h4>
              <Input
                country={this.props.country.code.toString().toUpperCase()}
                placeholder="Celular"
                value={this.state.telephone}
                onChange={(value) => {
                  this.setState({
                    telephone: value,
                    disableCallmeButton: !isValidPhoneNumber(value),
                  });
                }}
                error={
                  this.state.telephone
                    ? isValidPhoneNumber(this.state.telephone)
                      ? undefined
                      : "Número teléfonico invalido"
                    : "Numero teléfonico requerido"
                }
              />
            </DialogContent>
            <DialogActions>
              <Button
                disabled={this.state.disableCallmeButton}
                onClick={() => {
                  upsertUser({
                    uid: this.props.auth.uid,
                    telephone: this.state.telephone,
                    telephoneConfirmedByUser: true,
                  }).then(() => {
                    this.setState({ callme: false });
                  });
                }}
              >
                Listo
              </Button>
            </DialogActions>
          </Dialog>
        )}
        <GridItem
          xs={11}
          sm={12}
          md={12}
          style={{
            position: "relative",
            paddingBottom: "56.25%",
            height: "0",
          }}
        >
          <iframe
            width="560"
            height="450"
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
            }}
            src="https://www.youtube.com/embed/KYWcWbfT3YE?autoplay=1&rel=0&hd=1"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            autoplay
            allowfullscreen
          />
        </GridItem>
        {this.state.plans.map((plan, index) => {
          return (
            <GridItem md={4} sm={4}>
              <Card raised pricing color={index % 2 == 0 ? "rose" : "info"}>
                <CardBody pricing>
                  <h6 className={classes.cardCategory}>{plan.name}</h6>
                  <h4>{plan.recommendedFor}</h4>
                  <h1 className={classes.cardTitleWhite}>
                    {plan.currency}
                    {plan.price.toLocaleString()}
                  </h1>
                  <ul>
                    {plan.features.map((feature) => {
                      return (
                        <li>
                          {" "}
                          {icons[feature.icon]}
                          {` ${feature.text}`}{" "}
                        </li>
                      );
                    })}
                  </ul>
                  {this.state.methods.length - 1 >= index &&
                    this.state.methods[index].map((method) => {
                      return (
                        <Button color="white" round>
                          <a href={method.url} target="_blank">
                            {method.name}
                          </a>
                        </Button>
                      );
                    })}
                </CardBody>
              </Card>
            </GridItem>
          );
        })}
        {/* <GridItem md={4} sm={4}>
        <Card raised plain pricing>
          <CardBody pricing>
            <h6 className={classes.cardCategory}>Código Promocional</h6>
            <CustomInput
              particularInputClasses={classNames(classes.input, classes.whiteInput)}
              formControlProps={{
                fullWidth: true,
                className: classes.customFormControlClasses
              }}
              inputProps={{
                value: this.state.promotional_code,
                onChange: e => this.setState({ promotional_code: e.target.value }),
                placeholder: "Código"
              }}
            />
            <Button
              color="white"
              round
              onClick={this.promotionalCodeClick.bind(this)}
            >
              Canjear
            </Button>
          </CardBody>
        </Card>
      </GridItem> */}
      </GridContainer>
    );
  }

  promotionalCodeClick() {
    authRef.currentUser.getIdToken().then((token) => {
      purchaseServices
        .promotionalCode(this.props.auth, token, this.state.promotional_code)
        .then((paymentResult) => {
          if (paymentResult.data.PaymentStatusType === "Accepted") {
          }
        })
        .catch((e) => console.log(e));
    });
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  progress: state.progress,
  country: state.country,
});

const mapDispatchToProps = {
  addStepToProgress,
};

export default withStyles(headersStyle)(
  connect(mapStateToProps, mapDispatchToProps)(ProgressStepPlansContainer)
);
