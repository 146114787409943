import React from "react";

import GridContainer from "components/Grid/GridContainer.jsx";
import Blinker from "../Blink/Blinker";
const logo = require("../../assets/img/woiro.ico");

class IpuLoading extends React.Component {
  render() {
    const { classes, className } = this.props;

    return (
      <GridContainer
        justify="center"
        style={{
          position: "fixed",
          width: "144px",
          height: "116px",
          top: "50%",
          left: "50%",
          marginTop: "-58px",
          marginLeft: "-77px"
        }} //WIDTH, HEIGH, MARGINTOP Y MARGIN LEFT DEPENDEN DEL TAMAÑO DEL FAVICON.ICO
      >
        <Blinker interval={500}>
          <img src={logo} />
        </Blinker>
      </GridContainer>
    );
  }
}

export default IpuLoading;
