import { db } from "../config/firebase";

const firestore = db;
const careersPageRef = firestore.collection("careerspage");

function _getCareersPageLists() {
  return careersPageRef.get().then(snapshot => {

    let lists = snapshot.docs.map(doc => {
      var data = doc.data();

      return { uid: doc.id, ...data };
    });

    //no entiendo como es que se está ordenando tmb dentro de la misma lista pero dejalo así que anda xD
    lists = lists.sort((a, b) => {
      if (typeof a.order === "undefined") a.order = 999;
      if (typeof b.order === "undefined") b.order = 999;

      return a.order - b.order;
    });

    return lists;
  });
}

function _getCareersPageCategories() {
  return careersPageRef.get().then(snapshot => {
    return snapshot.docs.map(doc => {
      var data = doc.data();
      return { uid: doc.id, Description: data.Description };
    }).filter(data => data.Description != "");
  });
}

function _getCareersPagesListsFilterByCategories(categories) {
  let categoriesPromises = []

  while (categories.length > categoriesPromises.length * 10) {
    let slicedCategories = categories.slice(categoriesPromises.length * 10, categoriesPromises.length * 10 + 10);
    categoriesPromises = [...categoriesPromises, careersPageRef.where("Id", "in", slicedCategories)
      .get()];
  }

  return Promise.all(categoriesPromises).then(results => {
    return results.map(snapshot => {
      return snapshot.docs.map(doc => {
        var data = doc.data();
        return { uid: doc.id, ...data };
      }).filter(data => data.Description != "");
    }).flat()
  })
}

function _updateLists(career) {
  return new Promise((resolve, reject) => {
    _getCareersPageLists()
      .then(lists => {
        lists.forEach(list => {
          let listCareer = list.Careers.find(auxListCareer => {
            return auxListCareer.Id == career.Id;
          });

          if (listCareer) {

            listCareer = Object.assign(listCareer, career);

            careersPageRef
              .doc(list.uid)
              .update("Careers", list.Careers)
              .then(() => {
                console.log("ok: " + list.Description);
              })
              .catch(err => {
                reject(err);
              });
          }
          resolve();
        });
      })
      .catch(err => {
        reject(err);
      });
  });
}

function _upsertList(list) {
  return new Promise((resolve, reject) => {
    careersPageRef
      .doc(list.uid)
      .update({
        Careers: list.Careers,
        Description: list.Description,
        order: (typeof list.order === "undefined") ? 999 : list.order
      })
      // .update("Description", list.Description)
      // .update("order", list.order)
      // .collection("Careers")
      // .doc(career.Id)
      // .set(career, { merge: true })
      // .set(list)

      .then(() => {
        console.log("ok: " + list.Description);

        resolve();
      })
      .catch(err => {
        reject(err);
      });
  });
}

export const getCareersPageLists = _getCareersPageLists;
export const updateLists = _updateLists;
export const upsertList = _upsertList;
export const getCareersPageCategories = _getCareersPageCategories;
export const getCareersPagesListsFilterByCategories = _getCareersPagesListsFilterByCategories;