import React, { Component } from "react";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import classNames from "classnames";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Search from "@material-ui/icons/Search";

import withStyles from "@material-ui/core/styles/withStyles";
import styles from "../jss/style.jsx";
import { connect } from "react-redux";
import { filterUniversitiesByNameAndGroupCode } from "../actions/universitiesActions";
import SectionSearchElement from "./SectionSearchElement.jsx";

import { fetchUniversities } from "../actions/universitiesActions";

class SectionSearch extends Component {
  constructor(props) {
    super(props);
  }

  onChange = event => {
    this.props.filterUniversitiesByNameAndGroupCode(
      this.props.universities.all,
      event.target.value
    );
  };

  componentDidMount() {
    this.props.fetchUniversities();
  }

  render() {
    console.log(this.props);
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={8}>
          <CustomInput
            formControlProps={{
              fullWidth: true,
              className: classes.cardFormSearch
            }}
            inputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              placeholder: "Buscar una universidad ...",
              onChange: e => this.onChange(e)
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={4}>
          <Button
            color="primary"
            block
            className={classes.subscribeButton}
            style={{ marginTop: 10 }}
          >
            buscar{" "}
          </Button>
        </GridItem>
        <GridItem>
          {this.props.universities.filtered &&
            this.props.universities.filtered
              .slice(0, 5)
              .map((university, key) => {
                return (
                  <SectionSearchElement
                    key={key}
                    element={{
                      title: university.Name,
                      subtitle: university.ShortName
                    }}
                    onClick={() => {
                      this.props.universityDetail(
                        university,
                        this.props.filtered
                      );
                      this.props.filterUniversitiesByNameAndGroupCode([], ""); //Para limpiar las filtradas
                    }}
                  />
                );
              })}
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => ({
  universities: state.universityPageReducer
});

const mapDispatchToProps = {
  filterUniversitiesByNameAndGroupCode,
  fetchUniversities
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SectionSearch));
