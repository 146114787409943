import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoginComponent from './LoginPage';
import { resetUserDataState } from 'actions/loginActions';
import { signIn, signInBasicCredentials, registerBasicCredentialsUser } from 'actions/authActions';
import { Redirect } from 'react-router-i18n';

class LoginContainer extends Component {

    constructor(props) {
        super(props);
    }
    
    render() {

        if (this.props.auth && this.props.auth.uid)
            return <Redirect to="/home" />

        return (
            <div>
                <LoginComponent {...this.props}
                    
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    country: state.country
});

export default connect(mapStateToProps, { signIn, signInBasicCredentials, registerBasicCredentialsUser, resetUserDataState })(LoginContainer);
