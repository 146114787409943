export const safeForumTitle = (title, op1) => {
  return safeForumText(title, op1);
};

export const safeForumText = (title, op1) => {
  let ret = title.replace(/<(?:.|\n)*?>/gm, "").replace("&nbsp;", "");

  if (op1) ret = '"' + ret + '"';

  return ret;
};

export const safeForumTextNoImg = (text, op1) => {
  if (!text) return "";
  return safeForumText(text.replace("<img", "<div"));
};

export const takeCharacters = (text, len) => {
  try {
    return text.substring(0, len) + "...";
  } catch (e) {
    return text;
  }
};

export const getTagString = (tags, filters) => {
  let tagsString = "";

  tags.forEach(tag => {
    if (tag.toLowerCase() != filters) tagsString += tag + " ";
  });

  return tagsString;
};
export const getImgFromTopicContent = (content, defaultImg) => {
  let img_1 = defaultImg;
  try {
    let rex3 = /<img[^>]+src="([^">]+)/g;
    img_1 = rex3
      .exec(content.replace(new RegExp("'", "g"), '"'))[1]
      .replace("#038;", "");
  } catch (e) {}

  return img_1;
};

export const timeSince = date => {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return interval + " years";
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + " months";
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + " days";
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + " hours";
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + " minutes";
  }
  return Math.floor(seconds) + " seconds";
};

export const addWordpressIframeEvents = (frame, link, $this) => {
  window.addEventListener("message", event => {
    if (event.data == "close") {
      $this.handleClose();
    } else if (event.data == "reload") {
      $this.setState({ openModal: true });
      frame.src = link;

      checkIframeLoadedAndPostMessage(frame, "embedded");
      // frame.contentWindow.postMessage("embedded", '*');
    } else if (event.data == "notLoggedIn") {
      //var popUp = window.open(frame.src + "?login=true");
      //abro un pop up a otra pagina de mi mismo dominio porque
      //var popUp = window.open(frame.src + "?login=true");

      frame.src = link + "?login=true";
      $this.setState({ openModal: false });
    } else {
      //submit
      frame.style.display = "none";
      //le doy un poquito a que llegue el post del comentario desde el iframe a wordpress
      setTimeout(function() {
        document.location.reload();
      }, 500);
    }
  });
};

export const checkIframeLoadedAndPostMessage = (iframe, messageToPost) => {
  iframe.style.display = "none";
  try {
    //si estan en el mismo dominio eso no pincha
    var iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

    // Check if loading is complete
    if (iframeDoc.readyState == "complete") {
      //iframe.contentWindow.alert("Hello");
      iframe.contentWindow.onload = function() {};
      // The loading is complete, call the function we want executed once the iframe is loaded
      //afterLoading();
      iframe.style.display = "";
      let post = iframe.contentWindow.postMessage(messageToPost, "*");
      return;
    }

    // If we are here, it is not loaded. Set things up so we check   the status again in 100 milliseconds
    window.setTimeout(
      this.checkIframeLoadedAndPostMessage(iframe, messageToPost),
      100
    );
  } catch (e) {
    //this.setState({ openModal: false });
    window.setTimeout(() => {
      //this.setState({ openModal: true });
      iframe.style.display = "";
      iframe.contentWindow.postMessage(messageToPost, "*");
    }, 2000);

    console.log(e);
  }
};
