import {
  FETCH_COUNTRY_END,
  FETCH_COUNTRY_ERROR,
} from "./types";

const initialState = {
  code: "ar",
  isReady: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COUNTRY_END:
      return {
        code: action.payload,
        isReady: true
      };
    case FETCH_COUNTRY_ERROR:
    default:
      return state;
  }
};
