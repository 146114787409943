import React, { Component } from "react";
import { connect } from "react-redux";
import UniversitiesComponent from "./UniversitiesPage";
import { handleGeneralError } from "helpers/errorHelper.js";

import { getCountryWithLocation } from '../../i18n'


const universityServices = require("services/universityservices");

class UniversitiesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allUniversities: [],
      filteredUniversities: [],
      isLoadingUniversities: true
    };

    this.addFavoriteUniversity = this.addFavoriteUniversity;
    this.deleteFavoriteUniversity = this.deleteFavoriteUniversity;
  }

  searchInputOnChange = e => {
    var filteredUniversities = universityServices.filterUniversitiesByNameAndGroupCode(
      this.state.allUniversities,
      e.target.value
    );
    this.setState({
      filteredUniversities: filteredUniversities.filter(
        u => u.IsPrimary == true
      )
    });
  };

  componentDidMount() {
    this.fetchUniversities();
  }

  fetchDetail(university) {
    this.props.history.push(`/${this.props.country.code}/universities/${university.Id}`, {
      university,
      universities: this.state.allUniversities
    });
  }

  fetchUniversities() {
    this.setState({ isLoadingUniversities: true }, () =>
      universityServices
        .getUniversitiesByCountry(getCountryWithLocation(this.props.country))
        .then(universities => {
          this.setState({
            allUniversities: universities,
            filteredUniversities: universities.filter(u => u.IsPrimary == true),
            isLoadingUniversities: false
          });
        })
        .catch(e => {
          this.setState({ isLoadingUniversities: false });
          handleGeneralError(this.props.auth, e, this.props.history);
        })
    );
  }

  render() {
    return (
      <div>
        <UniversitiesComponent
          auth={this.props.auth}
          isLoadingUniversities={this.state.isLoadingUniversities}
          universities={this.state.filteredUniversities}
          fetchDetail={this.fetchDetail.bind(this)}
          addFavoriteUniversity={this.addFavoriteUniversity}
          deleteFavoriteUniversity={this.deleteFavoriteUniversity}
          searchInputOnChange={this.searchInputOnChange.bind(this)}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  country: state.country
});

export default connect(mapStateToProps)(UniversitiesContainer);
