import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchDetail, fetchDetailById } from "./detailActions";
import DetailPage from "./ProductPage";
import { handleGeneralError } from "helpers/errorHelper.js";
import { debug } from "util";

import { getCountryWithLocation } from '../../i18n'

const universityServices = require("services/universityservices");
const playlistItemsServices = require("services/youtube/playlistitemsservices");

class DetailContainer extends Component {
  constructor(props) {
    super(props);

    this.state = { relatedUniversities: null, university: null, videos: null };
  }

  async componentDidMount() {
    let university;
    let universities;
    let videos;
    if (this.props.location.state && this.props.location.state.university) {
      university = this.props.location.state.university;
      universities = this.props.location.state.universities;
    } else {
      try {
        universities = await universityServices.getUniversitiesByCountry(getCountryWithLocation(this.props.country));
        university = universities.filter(
          u => u.Id == this.props.match.params.universityUid
        )[0];
      } catch (e) {
        handleGeneralError(this.props.auth, e, this.props.history);
      }
    }

    if (university && universities) {
      var primaryUniversity = universities
        .filter(u => u.GroupCode == university.GroupCode)
        .filter(u => u.IsPrimary == true);

      let selectedUniversity =
        primaryUniversity.length > 0 ? primaryUniversity[0] : null;

      if (selectedUniversity === null) {
        let auxUniversity = universities.filter(
          u => u.GroupCode == university.GroupCode
        );
        if (auxUniversity.length > 0) {
          selectedUniversity = auxUniversity;
        }
      }

      var relatedUniversities =
        selectedUniversity &&
        universities.filter(
          relatedUniversity =>
            selectedUniversity.GroupCode == relatedUniversity.GroupCode &&
            selectedUniversity.Id != relatedUniversity.Id
        );

      try {
        let result = await playlistItemsServices.getPlaylistVideos(
          university.YoutubePlaylistId
        );
        videos = result.data.items.map(element => {
          return {
            thumbnail: `${element.snippet.thumbnails.default.url}`,
            original: `${element.snippet.thumbnails.default.url}`,
            embedUrl: `https://www.youtube.com/embed/${element.snippet.resourceId.videoId}`
          };
        });
      } catch (e) {
        videos = [];
        console.log(e);
      }

      this.setState({
        university: selectedUniversity,
        relatedUniversities,
        videos
      });
    }
  }

  render() {
    return (
      <div>
        <DetailPage
          auth={this.props.auth}
          university={this.state.university}
          relatedUniversities={this.state.relatedUniversities}
          videos={this.state.videos}
          history={this.props.history}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  detail: state.detailUniversityReducer,
  country: state.country
});

const mapDispatchToProps = {
  fetchDetail,
  fetchDetailById
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailContainer);
