import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import signupPageStyle from "assets/jss/material-ui-styles/views/signupPageStyle.jsx";

import { signOut } from "actions/authActions";

class SignoutPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    signOut(this.props.history);
  }
  render() {
    return <div>loading</div>;
  }
}

export default withStyles(signupPageStyle)(SignoutPage);
