import { FETCH_UNIVERSITIES_WITH_DISTANCE, FETCH_FAVORITE_UNIVERSITIES, FETCH_UNIVERSITIES_ERROR, ADD_UNIVERSITY_TO_FAVORITE, DELETE_UNIVERSITY_FROM_FAVORITE, FILTER_UNIVERSITIES, UPDATE_NEW_UNIVERSITY, SHOW_FILTERS, FORCE_SHOW_FILTERS, FETCH_USER_GEOLOCATION, FETCH_UNIVERSITIES_DISTANCES, FETCH_UNIVERSITIES_TRAVEL_DISTANCES, FILTER_UNIVERSITY_COUNTRY } from 'views/UniversitiesPage/types/universitiesTypes';
import { FETCH_USER } from 'types/authTypes';

var initialState = {
    showFilters: true,
    showFiltersForced: false,
    favoriteSection: {
        universities: [],
    },
    productSection: {
        universities: [],
        allUniversities: [],
        checked: [1, 9, 27],
        priceRange: [1, 10],
        loading: false,
        categories: ["Ingeniería y Tecnología", "Informática", "Arquitectura", "Construcción", "Industria", "Delineación", "Dibujo técnico", "Sociales", "Derecho", "Administración", "Comercio y trabajo", "Economía", "Ciencias políticas", "Medicina", "Enfermería", "Nutrición", "Odontología", "Otros"],
        filters: {
            duration: {
                value: 10,
                operator: "<="
            },
            categoryType: {
                value: ["Ingeniería y Tecnología", "Informática", "Arquitectura", "Construcción", "Industria", "Delineación", "Dibujo técnico", "Sociales", "Derecho", "Administración", "Comercio y trabajo", "Economía", "Ciencias políticas", "Medicina", "Enfermería", "Nutrición", "Odontología", "Otros"],
                operator: "in"
            }
            , name: {
                value: [""],
                operator: "inStr"
            },
            countryCode: {
                value: "0",
                operator: "=="
            }
        }
    },
    admin: {
        upsertUniversityData:
        {
            uid: ''
            , name: ''
            , iconImage: ''
            , backgroundImage: ''
            , shortDescription: ''
            , longDescription: ''
            , institutionType: '' //universidad, instituto universitario
            , institutionCategoryType: '' //privada, publica, mixta
            , youtubeId: ''
            , sites: [
                {
                    uid: '',
                    name: '',
                    isPrimary: true,
                    isCampus: true,
                    address: '',
                    telephone: '',
                    geolocation: {
                        latitude: 0, longitude: 0,
                        countryCode: 1, //argentina, etc
                        countryState: 1 //buenos aires, etc
                    },
                    socialLinks: {
                        facebookUri: '',
                        googleUri: '',
                        linkedinUri: '',
                        twitterUri: '',
                    },
                    careers: [{
                        careerId: '',
                    }],
                    facilities: [{
                        facilityType: 'other', //labs, ayuda idioma extranjeros, biblioteca, centro de invest, etc
                        title: '', //LABORATORIOS
                        descriptionHtml: '' //puede tener Listas etc
                    }],
                    feeds: [{
                        //feedId: '', //estructura de feeds ? Ej: fecha, uid, descripcion, etc 
                        uid: '',
                        message: '',
                        date: '',
                        locationPoints: 0,
                        teachersPoints: 0,
                        learningsQualityPoints: 0,
                        facilitiesPoints: 0
                    }],
                    questions: [{
                        id: '',
                        uid: '',
                        message: '',
                        date: '',
                        answers: [{
                            id: '',
                            quotedMessageId: '', //referencia al id
                            uid: '',
                            message: '',
                            date: '',
                        }]
                    }
                    ]
                }]


        }
    },
    user: null,
    userGeolocation:
    {
        error: false,
        loading: true,
        geoLocation: { lat: 1, long: 1 }
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_UNIVERSITIES_WITH_DISTANCE:
            return { ...state, productSection: { ...state.productSection, universities: action.payload.universities, allUniversities: action.payload.universities } };
        case ADD_UNIVERSITY_TO_FAVORITE, DELETE_UNIVERSITY_FROM_FAVORITE:
            return { ...state, favoriteSection: { ...state.favoriteSection, universities: action.payload.universities } };
        case FETCH_FAVORITE_UNIVERSITIES:
            return { ...state, favoriteSection: { ...state.favoriteSection, universities: action.payload } };
        case FETCH_USER:
            return { ...state, user: action.payload };
        case FILTER_UNIVERSITIES:
            return { ...state, productSection: { ...state.productSection, universities: action.payload.filteredUniversities, filters: action.payload.filters } };
        case UPDATE_NEW_UNIVERSITY:
            var obj = {};
            obj[action.payload.field] = action.payload.value;

            var ret = {
                ...state,
                admin: {
                    ...state.admin,
                    upsertUniversityData: {
                        ...state.admin.upsertUniversityData,
                        ...obj
                    }
                }
            }
            return ret;
        case SHOW_FILTERS:
            return { ...state, showFilters: action.payload.value };
        case FORCE_SHOW_FILTERS:
            return { ...state, showFiltersForced: action.payload.value };
        case FETCH_USER_GEOLOCATION:
            return { ...state, userGeolocation: action.payload.value };

        case FETCH_UNIVERSITIES_DISTANCES:
            return { ...state, productSection: { ...state.productSection, universities: action.payload.universities, allUniversities: action.payload.universities } };

        case FETCH_UNIVERSITIES_TRAVEL_DISTANCES:

            state.productSection.allUniversities.forEach((university, index) => {
                for (var key in action.payload.universities) {
                    if (action.payload.universities[key].uid == university.uid) {
                        state.productSection.allUniversities[index] = action.payload.universities[key];
                    }
                }
                return false;
            });

            return { ...state, productSection: { ...state.productSection, universities: state.productSection.allUniversities, allUniversities: state.productSection.allUniversities } };

        case FILTER_UNIVERSITY_COUNTRY:
            return {
                ...state,
                productSection: {
                    ...state.productSection,
                    universities: action.payload.filteredUniversities,
                    filters: {
                        ...state.productSection.filters,
                        countryCode: {
                            ...state.productSection.filters.countryCode,
                            value: action.payload.value
                        }
                    }
                }
            };
            return

        case FETCH_UNIVERSITIES_ERROR: //TODO MOSTRAR MODAL
        default:
            return state;
    }
};
