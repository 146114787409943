import headerLinksStyle from "assets/jss/material-ui-styles/components/headerLinksStyle.jsx";
import {
  container,
  mrAuto,
  title,
  blackColor,
  whiteColor,
  grayColor,
  hexToRgb,
  main,
  mainRaised
} from "assets/jss/material-ui-styles.jsx";

const headersSection = theme => ({
  ...headerLinksStyle(theme),
  sectionBlank: {
    height: "70px",
    display: "block"
  },
  main: {
    ...main,
    ...mainRaised
  },
  containerCustom: {
    ...container,
    zIndex: "2",
    position: "relative"
  },
  container: {
    ...container,
    zIndex: "2",
    position: "relative",
    "& h1, & h4, & h6": {
      color: whiteColor
    }
  },
  conatinerHeader2: {
    ...container,
    zIndex: "2",
    position: "relative",
    "& h1, & h4, & h6": {
      color: whiteColor
    },
    paddingTop: "25vh"
  },
  title,
  pageHeader: {
    position: "relative",
    minHeight: "100vh",
    // maxHeight: "1600px",
    backgroundPosition: "50%",
    backgroundSize: "cover",
    margin: "0",
    padding: "0",
    border: "0",
    display: "flex",
    "&:before": {
      background: "rgba(" + hexToRgb(blackColor) + ", 0.5)"
    },
    "&:after,&:before": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''"
    }
  },
  iframeContainer: {
    "& > iframe": {
      width: "100%",
      boxShadow:
        "0 16px 38px -12px rgba(" +
        hexToRgb(blackColor) +
        ", 0.56), 0 4px 25px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.2)"
    }
  },
  mrAuto,
  textCenter: {
    textAlign: "center"
  },
  card: {
    marginTop: "60px"
  },
  formControl: {
    margin: "0",
    padding: "8px 0 0 0"
  },
  textRight: {
    textAlign: "right"
  },
  button: {
    margin: "0 !important"
  },
  customFormControlClasses: {
    margin: 0,
    paddingTop: 18,
  },
  input: {
    color: grayColor[13],
    "&,&::placeholder": {
      fontSize: "14px",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: "400",
      lineHeight: "1.42857",
      opacity: "1"
    },
    "&::placeholder": {
      color: grayColor[12]
    }
  },
  whiteInput: {
    "&,&::placeholder": {
      color: whiteColor,
      opacity: "1"
    }
  },
  recommendation: {
    //backgroundColor: "#805a5a",
    "&:hover": {
      background: "#827676"
      //color: "#fff",
    },
    "&:focus": {}
  },
  recommendationSelected: {
    backgroundColor: "#fff",
    color: "rgb(0,0,0)",
    "&:hover": {
      background: "#fff",
      color: "#d6dadc"

      // "&:hover, &:focus": {
      //   background: "rgba(" + hexToRgb(blackColor) + ", 0.5)",
      //   color: "#d6dadc",
    },
    "&:focus": {
      backgroundColor: "#fff",
      color: "rgba(" + hexToRgb(blackColor) + ", 1)"
    }
  },
  datetime: {
    color: "#222",
    display: "flex",
    justifyContent: "center",
    "& .rdtPicker": {
      "&:after,&:before": {
        display: "none"
      }
    }
  }
});

export default headersSection;
