import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Share from "@material-ui/icons/Share";
import TrendingUp from "@material-ui/icons/TrendingUp";
import Grade from "@material-ui/icons/Grade";
import ThumbUp from "@material-ui/icons/ThumbUp";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Info from "components/Typography/Info.jsx";
import Danger from "components/Typography/Danger.jsx";
import Success from "components/Typography/Success.jsx";

import Button from "components/CustomButtons/Button.jsx";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

import styles from "../jss/sections/sectionCards.jsx";

import Media from "components/Media/Media.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Close from "@material-ui/icons/Close";

import CircularProgress from "@material-ui/core/CircularProgress";

import { authRef } from "config/firebase";

import { safeForumTitle } from "helpers/topicHelper.js";

const topicServices = require("services/topicservices");
const forumFilter = "ipu";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class SectionMural extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeRotate1: "",
      activeRotate2: "",
      openModal: false
    };
  }

  componentDidMount() {
    this.adjustRotationCards();
  }

  componentDidUpdate() {
    this.adjustRotationCards();
  }

  adjustRotationCards() {
    const { classes } = this.props;
    var rotatingCards = document.getElementsByClassName(classes.cardRotate);
    for (let i = 0; i < rotatingCards.length; i++) {
      var rotatingCard = rotatingCards[i];
      var rotatingWrapper = rotatingCard.parentElement;
      var cardWidth = rotatingCard.parentElement.offsetWidth;
      var cardHeight = rotatingCard.children[0].children[0].offsetHeight;
      rotatingWrapper.style.height = cardHeight + "px";
      rotatingWrapper.style["margin-bottom"] = 30 + "px";
      var cardFront = rotatingCard.getElementsByClassName(classes.front)[0];
      var cardBack = rotatingCard.getElementsByClassName(classes.back)[0];
      cardFront.style.height = cardHeight + 35 + "px";
      cardFront.style.width = cardWidth + "px";
      cardBack.style.height = cardHeight + 35 + "px";
      cardBack.style.width = cardWidth + "px";
    }
  }

  forumTitleToText(title, op1) {
    return safeForumTitle(title, op1);
  }
  groupElementsByQuantity(elements, quantity) {
    let index = 0;
    let groups = [];
    let groupIndex = 0;
    while (elements.length > index) {
      for (
        let j = groupIndex * quantity;
        j < groupIndex * quantity + quantity;
        j++
      ) {
        index++;

        if (elements[j]) {
          groups[groupIndex] = groups[groupIndex] || [];
          groups[groupIndex].push(elements[j]);
        }
      }
      groupIndex++;
    }

    groups.forEach(group => {
      if (group.length == 2) {
        groups[groups.length] = [];
        groups[groups.length - 1].push(group[1]);

        group.length = 1;
      } else if (group.length == 4) {
        groups[groups.length] = [];
        groups[groups.length - 1].push(group[3]);

        group.length = 3;
      }
    });
    return groups;
  }

  openNewTopicDialog() {
    this.setState({ openNewTopicModal: true });
  }

  newTopic(event) {
    this.setState({ newTopicLoading: true });
    authRef.currentUser.getIdToken().then(token => {
      topicServices
        .newTopic(
          token,
          null,
          this.state.newTopicTitle,
          this.state.newTopicContent
        )
        .then(() => {
          this.setState({ openNewTopicModal: false, newTopicLoading: false });

          window.location.reload();
        })
        .catch(e => {
          console.log(e);

          this.setState({ openErrorModal: true, newTopicLoading: false });
        });
    });
  }

  render() {
    let props = this.props;
    let j = 0;
    const { classes, ...rest } = this.props;
    let cardsWithContentBodyStyle = {
      minHeight: 95,
      maxHeight: 95,
      overflow: "hidden",
      textOverflow: "ellipsis"
    };
    let tweetsWithContentBodyStyle = {
      minHeight: 55,
      maxHeight: 65,
      overflow: "hidden",
      textOverflow: "ellipsis"
    };

    let titleStyle = {
      maxHeight: 50,
      overflow: "hidden",
      textOverflow: "ellipsis"
    };

    let filterTag_1 = "IPU-CARRERA";
    let filterTag_2 = "null";

    let topics = [];

    //me quedo con los topics que corresponden a esta seccion
    props.topics.forEach(topic => {
      //en este formato solo muestro los topics que no son del IPU
      if (topic.forum_title.toLowerCase() == forumFilter) {
        return;
      }

      let expectedTopics = topic.tags.find(tag => {
        if (tag.toUpperCase() == filterTag_1) return true;
        return false;
      });

      if (expectedTopics && expectedTopics.length != 0) {
        return;
      }

      topics.push(topic);
    });

    let groupedTopics = this.groupElementsByQuantity(topics, 6);

    let iframeWidth = 300;
    let iframeHeight = 380;
    if (window.innerWidth <= 400) {
      iframeWidth = 250;
      iframeHeight = 380;
    }

    return (
      <div>
        {/* NOTICE MODAL START */}

        <Dialog
          style={{ maxWidth: "auto" }}
          classes={{
            root: classes.modalRoot,
            paper: classes.modal
          }}
          open={this.state.openNewTopicModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => {
            this.setState({ openNewTopicModal: false });
          }}
          aria-labelledby="notice-modal-slide-title"
          aria-describedby="notice-modal-slide-description"
        >
          <DialogTitle
            id="notice-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              simple
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              onClick={() => this.setState({ openNewTopicModal: false })}
            >
              {" "}
              <Close className={classes.modalClose} />
            </Button>
            <h4 className={classes.modalTitle}>Compartí tus ideas</h4>
          </DialogTitle>
          <DialogContent
            id="notice-modal-slide-description"
            className={classes.modalBody}
          >
            {!this.state.newTopicLoading ? (
              <Media
                avatar={
                  this.props.auth && this.props.auth.photoURL
                    ? this.props.auth.photoURL
                    : "/img/unknown_avatar.png"
                }
                body={
                  <div>
                    <CustomInput
                      labelText="Título"
                      id="newTopicTitle"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state.newTopicTitle,
                        onChange: e =>
                          this.setState({ newTopicTitle: e.target.value }),
                        multiline: false
                      }}
                    />

                    <CustomInput
                      labelText="Contenido"
                      id="newTopicContent"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state.newTopicContent,
                        onChange: e =>
                          this.setState({ newTopicContent: e.target.value }),
                        multiline: true,
                        rows: 5
                      }}
                    />
                  </div>
                }
                footer={
                  <Button
                    color="primary"
                    round
                    className={classes.footerButtons}
                    onClick={event => this.newTopic()}
                  >
                    Crear
                  </Button>
                }
              />
            ) : (
              <GridContainer justify="center">
                <GridItem xs={1} sm={1} md={1}>
                  <CircularProgress className={classes.progress} />
                </GridItem>
              </GridContainer>
            )}
          </DialogContent>
        </Dialog>

        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: classes.modal
          }}
          open={this.state.openErrorModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => {
            this.setState({ openErrorModal: false });
          }}
          aria-labelledby="notice-modal-slide-title"
          aria-describedby="notice-modal-slide-description"
        >
          <DialogTitle
            id="notice-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h4 className={classes.modalTitle}>Hubo un error</h4>
          </DialogTitle>
          <DialogContent
            id="notice-modal-slide-description"
            className={classes.modalBody}
          >
            Por favor reintentá
          </DialogContent>
        </Dialog>

        {/* NOTICE MODAL END */}
        <GridContainer>
          {/* <GridItem xs={12} sm={12} md={12} lg={12}>
            <div style={{ textAlign: "right", marginTop: 20 }}>
              <Button color="info" round onClick={(e) => this.openNewTopicDialog(e)}>Creá tu post</Button>
            </div>
          </GridItem> */}

          {groupedTopics &&
            groupedTopics.length != 0 &&
            groupedTopics.map((topicGroup, i) => {
              //0,1,2,3,4,5
              let html = [];
              if (topicGroup[0]) {
                let topic_1 = topicGroup[0];

                let topic_2 = topicGroup[1];

                let tagsString_1 = "";
                topic_1.tags.forEach(tag => {
                  if (tag != filterTag_1 && tag != filterTag_2)
                    tagsString_1 += tag + " ";
                });

                let tagsString_2 = "";
                if (topic_2) {
                  topic_2.tags.forEach(tag => {
                    if (tag != filterTag_1 && tag != filterTag_2)
                      tagsString_2 += tag + " ";
                  });
                }

                let rex = /<img[^>]+src="([^">]+)/g;
                let avatar_1 = rex
                  .exec(
                    topic_1.author_avatar.replace(new RegExp("'", "g"), '"')
                  )[1]
                  .replace("#038;", "");

                let avatar_2;

                if (topic_2) {
                  let rex2 = /<img[^>]+src="([^">]+)/g;
                  avatar_2 = rex2
                    .exec(
                      topic_2.author_avatar.replace(new RegExp("'", "g"), '"')
                    )[1]
                    .replace("#038;", "");
                }

                let img_1 = "";
                try {
                  let rex3 = /<img[^>]+src="([^">]+)/g;
                  img_1 = rex3
                    .exec(topic_1.content.replace(new RegExp("'", "g"), '"'))[1]
                    .replace("#038;", "");
                } catch (e) {
                  img_1 = avatar_1;
                }

                //para que no se muestre la img en el previw
                let content_1 = this.forumTitleToText(
                  topic_1.content.replace("<img", "<div")
                );

                let content_2;
                if (topic_2) {
                  content_2 =
                    '"' +
                    this.forumTitleToText(
                      topic_2.content.replace("<img", "<div")
                    ) +
                    '"';
                }
                html.push(
                  <GridItem xs={12} sm={6} md={6} lg={4} key={topic_1.id}>
                    <Card blog onClick={() => this.props.topicDetail(topic_1)}>
                      <CardHeader image>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <img src={img_1} alt="..." style={{ height: 213 }} />
                          <div
                            style={titleStyle}
                            className={classes.cardTitleAbsolute}
                            dangerouslySetInnerHTML={{
                              __html: this.forumTitleToText(topic_1.title)
                            }}
                          />
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${img_1})`,
                            opacity: "1"
                          }}
                        />
                      </CardHeader>
                      <CardBody>
                        <Info>
                          <h6 className={classes.cardCategory}>
                            {tagsString_1}
                          </h6>
                        </Info>
                        <div
                          style={cardsWithContentBodyStyle}
                          className={classes.cardDescription}
                          dangerouslySetInnerHTML={{ __html: content_1 }}
                        />
                      </CardBody>
                    </Card>
                    {topic_2 && (
                      <Card
                        color="info"
                        onClick={() => this.props.topicDetail(topic_2)}
                      >
                        <CardBody color>
                          <h5 className={classes.cardCategorySocialWhite}>
                            <Grade />
                            {tagsString_2}
                          </h5>
                          <h4 className={classes.cardTitleWhite}>
                            <div
                              style={{
                                titleStyle,
                                textTransform: "uppercase",
                                minHeight: 30
                              }}
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                              dangerouslySetInnerHTML={{
                                __html: this.forumTitleToText(topic_2.title)
                              }}
                            />
                          </h4>
                        </CardBody>
                        <CardFooter>
                          <div className={classes.authorWhite}>
                            <a href="#pablo" onClick={e => e.preventDefault()}>
                              <img
                                src={avatar_2}
                                alt="..."
                                className={`${classes.imgRaised} ${
                                  classes.avatar
                                }`}
                              />
                              <span>{topic_1.author_name}</span>
                            </a>
                          </div>
                          <div
                            className={`${classes.statsWhite} ${
                              classes.mlAuto
                            }`}
                          >
                            <Share />
                            {topic_2.reply_count}
                          </div>
                        </CardFooter>
                      </Card>
                    )}
                  </GridItem>
                );
              }

              if (topicGroup[2]) {
                let topic_1 = topicGroup[2];

                let topic_2 = topicGroup[3];

                let tagsString_1 = "";
                topic_1.tags.forEach(tag => {
                  if (tag != filterTag_1 && tag != filterTag_2)
                    tagsString_1 += tag + " ";
                });

                let tagsString_2 = "";
                if (topic_2) {
                  topic_2.tags.forEach(tag => {
                    if (tag != filterTag_1 && tag != filterTag_2)
                      tagsString_2 += tag + " ";
                  });
                }

                let rex = /<img[^>]+src="([^">]+)/g;
                let avatar_1 = rex
                  .exec(
                    topic_1.author_avatar.replace(new RegExp("'", "g"), '"')
                  )[1]
                  .replace("#038;", "");

                let avatar_2;

                if (topic_2) {
                  let rex2 = /<img[^>]+src="([^">]+)/g;
                  avatar_2 = rex2
                    .exec(
                      topic_2.author_avatar.replace(new RegExp("'", "g"), '"')
                    )[1]
                    .replace("#038;", "");
                }

                let img_1 = "";
                try {
                  let rex3 = /<img[^>]+src="([^">]+)/g;
                  img_1 = rex3
                    .exec(topic_1.content.replace(new RegExp("'", "g"), '"'))[1]
                    .replace("#038;", "");
                } catch (e) {
                  img_1 = avatar_1;
                }

                let img_2 = "";
                if (topic_2) {
                  try {
                    let rex4 = /<img[^>]+src="([^">]+)/g;
                    img_2 = rex4
                      .exec(
                        topic_2.content.replace(new RegExp("'", "g"), '"')
                      )[1]
                      .replace("#038;", "");
                  } catch (e) {
                    img_2 = avatar_2;
                  }
                }

                //para que no se muestre la img en el previw
                let content_1 = this.forumTitleToText(
                  topic_1.content.replace("<img", "<div")
                );

                let content_2;
                if (topic_2) {
                  content_2 =
                    '"' +
                    this.forumTitleToText(
                      topic_2.content.replace("<img", "<div")
                    ) +
                    '"';
                }

                html.push(
                  <GridItem xs={12} sm={6} md={6} lg={4} key={topic_1.id}>
                    <Card onClick={() => this.props.topicDetail(topic_1)}>
                      <CardBody>
                        <Danger>
                          <h6 className={classes.cardCategory}>
                            <TrendingUp /> {tagsString_1}
                          </h6>
                        </Danger>
                        <h4 className={classes.cardTitle}>
                          <div
                            style={titleStyle}
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                            dangerouslySetInnerHTML={{
                              __html: this.forumTitleToText(topic_1.title)
                            }}
                          />
                        </h4>
                      </CardBody>
                      <CardFooter>
                        <div className={classes.author}>
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img
                              src={avatar_1}
                              alt="..."
                              className={`${classes.imgRaised} ${
                                classes.avatar
                              }`}
                            />
                            <span>{topic_1.author_name}</span>
                          </a>
                        </div>
                        <div className={`${classes.stats} ${classes.mlAuto}`}>
                          <Share />
                          {topic_1.reply_count}
                        </div>
                      </CardFooter>
                    </Card>
                    {topic_2 && (
                      <Card
                        blog
                        onClick={() => this.props.topicDetail(topic_2)}
                      >
                        <CardHeader image>
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img
                              src={img_2}
                              alt="..."
                              style={{ height: 165 }}
                            />
                          </a>
                          <div
                            className={classes.coloredShadow}
                            style={{
                              backgroundImage: `url(${img_2})`,
                              opacity: "1"
                            }}
                          />
                        </CardHeader>
                        <CardBody>
                          <Success>
                            <h6 className={classes.cardCategory}>
                              {tagsString_2}
                            </h6>
                          </Success>
                          <h4 className={classes.cardTitle}>
                            <div
                              style={titleStyle}
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                              dangerouslySetInnerHTML={{
                                __html: this.forumTitleToText(topic_2.title)
                              }}
                            />
                          </h4>
                          <p
                            className={classes.cardDescription}
                            dangerouslySetInnerHTML={{ __html: content_2 }}
                            style={cardsWithContentBodyStyle}
                          />
                        </CardBody>
                        <CardFooter>
                          <div className={classes.author}>
                            <a href="#pablo" onClick={e => e.preventDefault()}>
                              <img
                                src={avatar_2}
                                alt="..."
                                className={`${classes.imgRaised} ${
                                  classes.avatar
                                }`}
                              />
                              <span>{topic_2.author_name}</span>
                            </a>
                          </div>
                          <div className={`${classes.stats} ${classes.mlAuto}`}>
                            {/* <Schedule />
                        5 min de lectura */}
                            <Share />
                            {topic_2.reply_count}
                          </div>
                        </CardFooter>
                      </Card>
                    )}
                  </GridItem>
                );
              }

              if (topicGroup[4]) {
                let topic_1 = topicGroup[4];

                let topic_2 = topicGroup[5];

                let tagsString_1 = "";
                topic_1.tags.forEach(tag => {
                  if (tag != filterTag_1 && tag != filterTag_2)
                    tagsString_1 += tag + " ";
                });

                let tagsString_2 = "";
                if (topic_2) {
                  topic_2.tags.forEach(tag => {
                    if (tag != filterTag_1 && tag != filterTag_2)
                      tagsString_2 += tag + " ";
                  });
                }

                let rex = /<img[^>]+src="([^">]+)/g;
                let avatar_1 = rex
                  .exec(
                    topic_1.author_avatar.replace(new RegExp("'", "g"), '"')
                  )[1]
                  .replace("#038;", "");

                let avatar_2;

                if (topic_2) {
                  let rex2 = /<img[^>]+src="([^">]+)/g;
                  avatar_2 = rex2
                    .exec(
                      topic_2.author_avatar.replace(new RegExp("'", "g"), '"')
                    )[1]
                    .replace("#038;", "");
                }

                let img_1 = "";
                try {
                  let rex3 = /<img[^>]+src="([^">]+)/g;
                  img_1 = rex3
                    .exec(topic_1.content.replace(new RegExp("'", "g"), '"'))[1]
                    .replace("#038;", "");
                } catch (e) {
                  img_1 = avatar_1;
                }

                let img_2 = "";
                if (topic_2) {
                  try {
                    let rex4 = /<img[^>]+src="([^">]+)/g;
                    img_2 = rex4
                      .exec(
                        topic_2.content.replace(new RegExp("'", "g"), '"')
                      )[1]
                      .replace("#038;", "");
                  } catch (e) {
                    img_2 = avatar_2;
                  }
                }

                //para que no se muestre la img en el previw
                let content_1 = this.forumTitleToText(
                  topic_1.content.replace("<img", "<div")
                );

                let content_2;
                if (topic_2) {
                  content_2 =
                    '"' +
                    this.forumTitleToText(
                      topic_2.content.replace("<img", "<div")
                    ) +
                    '"';
                }

                html.push(
                  <GridItem xs={12} sm={6} md={6} lg={4} key={topic_1.id}>
                    <Card blog onClick={() => this.props.topicDetail(topic_1)}>
                      <CardHeader image>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <img src={img_1} alt="..." style={{ height: 213 }} />
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${img_1})`,
                            opacity: "1"
                          }}
                        />
                      </CardHeader>
                      <CardBody>
                        <Danger>
                          <h6 className={classes.cardCategory}>
                            <ThumbUp /> {tagsString_1}
                          </h6>
                        </Danger>
                        <h4 className={classes.cardTitle}>
                          <div
                            style={titleStyle}
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                            dangerouslySetInnerHTML={{
                              __html: this.forumTitleToText(topic_1.title)
                            }}
                          />
                        </h4>
                      </CardBody>
                      <CardFooter>
                        <div className={classes.author}>
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img
                              src={avatar_1}
                              alt="..."
                              className={`${classes.imgRaised} ${
                                classes.avatar
                              }`}
                            />
                            <span>{topic_1.author_name}</span>
                          </a>
                        </div>
                        <div className={`${classes.stats} ${classes.mlAuto}`}>
                          <Share />
                          {topic_1.reply_count}
                        </div>
                      </CardFooter>
                    </Card>
                    {topic_2 && (
                      <Card
                        color="success"
                        onClick={() => this.props.topicDetail(topic_2)}
                      >
                        <CardBody color>
                          <h5 className={classes.cardCategorySocialWhite}>
                            <i className="far fa-newspaper" />
                            {tagsString_2}
                          </h5>
                          <h4 className={classes.cardTitleWhite}>
                            <div
                              style={titleStyle}
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                              dangerouslySetInnerHTML={{
                                __html: this.forumTitleToText(
                                  topic_2.title,
                                  true
                                )
                              }}
                            />
                          </h4>
                          <p
                            className={classes.cardDescriptionWhite}
                            dangerouslySetInnerHTML={{ __html: content_2 }}
                            style={tweetsWithContentBodyStyle}
                          />
                          <div
                            className={`${classes.stats} ${
                              classes.justifyContentCenter
                            }`}
                          >
                            <Button color="white" round>
                              Ver topic
                            </Button>
                          </div>
                        </CardBody>
                      </Card>
                    )}
                  </GridItem>
                );
              }
              return html;
            })}
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(styles)(SectionMural);
