import React, { Component } from "react";
import HomePage from "./HomePage";
import { connect } from "react-redux";

class HomePageContainer extends Component {
  render() {
    return <HomePage
      user={this.props.auth}
      groupedSteps={this.props.progress.groupedSteps}
      currentStep={this.props.progress.currentStep}
      isLoading={this.props.progress.isLoading}
      {...this.props} />;
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  progress: state.progress
});

export default connect(mapStateToProps)(HomePageContainer);
