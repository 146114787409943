import React, { Component } from "react";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";

import CardBody from "components/Card/CardBody.jsx";

import Button from "components/CustomButtons/Button.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui icons
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import styles from "../jss/sections/favoritesStyle.jsx";

import Subject from "@material-ui/icons/Subject";

class SectionFavorites extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    return (
      <div style={{ marginLeft: 20 }}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <h3 style={{ textTransform: "capitalize" }}>
              {this.props.listDescription}
            </h3>
          </GridItem>
          {this.props.careers &&
            this.props.careers.map((career, index) => {
              return (
                <GridItem key={index} xs={12} sm={4} md={4}>
                  <Card
                    raised
                    pricing
                    background
                    style={{
                      backgroundImage: `url(${career.ListImage})`,
                      maxWidth: 300
                    }}
                  >
                    <CardBody raised pricing background>
                      <h6
                        className={classes.cardCategoryWhite}
                        onClick={e => {
                          e.preventDefault();
                          this.props.careerDetail(career, { Careers: [] });
                        }}
                      >
                        {career.Name}
                      </h6>

                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <Button
                        justIcon
                        color="rose"
                        simple
                        style={{ zIndex: 10 }}
                        onClick={e => {
                          e.preventDefault();
                          this.props.addCareerToFavorite(career);
                        }}
                      >
                        {this.props.favoriteCareers.filter(
                          car => car.Name == career.Name
                        ).length > 0 ? (
                          <Favorite />
                        ) : (
                          <FavoriteBorder />
                        )}
                      </Button>

                      <Button
                        simple
                        color="white"
                        onClick={e => {
                          e.preventDefault();
                          this.props.careerDetail(career, { Careers: [] });
                        }}
                      >
                        <Subject /> Ver
                      </Button>
                    </CardBody>
                  </Card>
                </GridItem>
              );
            })}
        </GridContainer>
      </div>
    );
  }
}
export default withStyles(styles)(SectionFavorites);
