import React from "react";
import ProgressStepPageContainer from '../ProgressStepPage/ProgressStepPageContainer';
import withStyles from "@material-ui/core/styles/withStyles";
import headersStyle from "assets/jss/material-ui-styles/views/sectionsSections/headersStyle.jsx";
import { addStepToProgress } from '../../redux/progress/actions';
import { connect } from "react-redux";
import SectionProducts from "views/CareersPage/Sections/SectionProducts.jsx";
import { getPlaylistVideos } from "services/youtube/playlistitemsservices";
import { CareerDetailModal } from "./CareerDetailModal";
import { FIRST_STEP_TEN_CAREERS, addFavoriteCareerToStep, deleteFavoriteCareerFromStep, getFavoriteCareersFromStep } from "../../services/favoriteCareersServices";
import { getCareersPagesListsFilterByCategories } from "../../services/careerspageservices";
import { Link } from "react-router-i18n";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { STEP_FEELING_REQUIRED_CAREERS } from "types/progressStepsTypes";


class ProgressStepFeelingContainer extends ProgressStepPageContainer {
  constructor(props) {
    super(props);
  }

  getStateToSave() {
    let infoToDisplay = this.state.careers.map(career => career.Name);
    return { ...this.state, infoToDisplay };
  }

  executeCustomComponentDidMount() {
    if (this.props.progress.steps) {
      const [step] = this.props.progress.steps.filter(step => step.Code == "STEP_CATEGORIES");
      if (step && step.state && step.state.categoriesSelected) {
        const careerPageListFilterByCategories = getCareersPagesListsFilterByCategories(step.state.categoriesSelected);
        const favoriteCareersFromStep = getFavoriteCareersFromStep(this.props.auth.uid, FIRST_STEP_TEN_CAREERS);
        Promise.all([careerPageListFilterByCategories, favoriteCareersFromStep]).then(result => {
          const [lists, { careers }] = result;
          this.setState({ lists, careers, disableButton: !this.isFavoriteCountEnough(careers) });
        })
      }
    }
  }

  getDefaultState() {
    return {
      lists: [],
      careers: [],
      detail: {},
      disableButton: true
    };
  }

  handleFavorite(career) {
    const step = this.props.progress.steps.filter(step => step.Code == "STEP_FEELING");
    if (step && step.state && step.state.timestamp) return;

    if (this.isFavorite(career)) {
      deleteFavoriteCareerFromStep(this.props.auth.uid, FIRST_STEP_TEN_CAREERS, career).then(result => {
        const careersFiltered = this.state.careers.filter(c => c.Id != career.Id);
        this.setState({ careers: careersFiltered, disableButton: !this.isFavoriteCountEnough(careersFiltered) })
      });
    } else {
      addFavoriteCareerToStep(this.props.auth.uid, FIRST_STEP_TEN_CAREERS, career).then(result => {
        const careers = [...this.state.careers, career];
        this.setState({ careers: careers, disableButton: !this.isFavoriteCountEnough(careers) })
      });
    }
  }

  isFavoriteCountEnough(favoriteCareers) {
    return favoriteCareers.length >= STEP_FEELING_REQUIRED_CAREERS;
  }

  isFavorite(career) {
    return this.state.careers.filter(c => c.Id == career.Id).length > 0;
  }

  getFavoriteText(career) {
    if (this.isFavorite(career)) {
      return "Eliminar de favoritos";
    } else {
      return "Agregar a favoritos";
    }
  }

  careerDetail(career) {
    getPlaylistVideos(career.YoutubePlaylistId)
      .then(response => {
        const videos = this.mapYoutubeVideos(response.data);
        const careerDetail = { ...career, videos };
        this.setState({ detail: careerDetail })

      })
      .catch(e => console.log(e))
  }

  mapYoutubeVideos = data => {
    if (data)
      return data.items.map(element => {
        return {
          thumbnail: `${element.snippet.thumbnails.default.url}`,
          original: `${element.snippet.thumbnails.default.url}`,
          embedUrl: `https://www.youtube.com/embed/${element.snippet.resourceId.videoId}`
        };
      });
    else return [];
  };

  getChildren() {
    return <GridContainer alignItems="center">
      <GridItem>{
        this.state.lists
          .map(list => {
            return <SectionProducts
              list={list}
              favoriteCareers={this.state.careers}
              careerDetail={this.careerDetail.bind(this)}
              addCareerToFavorite={this.handleFavorite.bind(this)}
              deleteCareerFromFavorite={this.handleFavorite.bind(this)}
              backgroundColor="transparent"
              cardStyleColor="white"
            />
          })}
      </GridItem>
      <GridItem style={{ textAlign: "center" }}>
        <Link to="/careers"><h4><b>Ver más categorías</b></h4></Link>
      </GridItem>
      <GridItem style={{ textAlign: "center" }}><h4>Vas {this.state.careers.length} de 5 favoritas</h4></GridItem>
      <GridItem>
        {this.state.detail &&
          this.state.detail.Name && //Solo pregutnamos por el nombre porque inicializamos detail como {}
          <CareerDetailModal
            detail={this.state.detail}
            closeButton={() => { this.setState({ detail: {} }) }}
            handleFavorite={this.handleFavorite.bind(this)}
            favoriteText={this.getFavoriteText(this.state.detail)}
          />}
      </GridItem>
    </GridContainer>;
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  progress: state.progress
});

const mapDispatchToProps = {
  addStepToProgress
}

export default withStyles(headersStyle)(connect(mapStateToProps, mapDispatchToProps)(ProgressStepFeelingContainer));