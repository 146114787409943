// import { createCORSRequest } from "helpers/networkHelper.js";

const axios = require("axios");
const { cors_api_host } = require("helpers/networkHelper.js");

// const playlistUrl = "https://www.googleapis.com/youtube/v3/playlists?channelId={channelId}&key={key}&part={part}"
// const playlistPart = "id";
const playlistItemsUrl =
  "https://www.googleapis.com/youtube/v3/playlistItems?playlistId={playlistId}&part={part}&key={key}";
const playlistItemsPart = "id,snippet";
// const playlistItemsPart = "id";
// const channel_id = "UCJhjE7wbdYAae1G25m0tHAA";
const key = "AIzaSyDM8KCWyJE5xz-nMrB1_Cp6w6zwfQAxXrc";
const playlistPart = "id";
export function getPlaylistVideos(playlistId) {
  if (!playlistId) {
    return;
  }

  var url = playlistItemsUrl
    .replace("{playlistId}", playlistId)
    .replace("{part}", playlistItemsPart)
    .replace("{key}", key);
  
  return axios
    .get(url, {
      headers: {
        Accept: `application/vnd.iman.v1+json, application/json, text/plain, */*`,
        "Content-Type": "application/json; charset=UTF-8"
      }
    })
    .catch(err => {
      console.log("Error en playlistitemsservices", err, err.message);
      throw err;
    });
}