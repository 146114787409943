import React, { Component } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import styles from "../jss/sections/favoritesStyle.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Info from "components/Typography/Info.jsx";
import Subject from "@material-ui/icons/Subject";

class SectionProductsCards extends Component {
  constructor(props) {
    super(props);
  }

  getDescription(desc) {
    let l = 150;
    if (desc.length < l) return desc;

    return desc.substring(0, l) + " ...";
  }

  render() {
    const { classes, universities } = this.props;
    console.log(this.props);
    return (
      <div style={{ marginLeft: 20 }}>
        <GridContainer justify="center">
          {universities &&
            universities.map((university, index) => {
              var isFavorite =
                this.props.favoriteUniversities &&
                this.props.favoriteUniversities.find(
                  fav => fav.ShortName == university.ShortName
                );

              return (
                <GridItem
                  key={index}
                  xs={12}
                  sm={10}
                  md={10}
                  className={`${classes.mlAuto} ${classes.mrAuto}`}
                >
                  <Card plain blog className={classes.card}>
                    <GridContainer>
                      <GridItem xs={12} sm={4} md={4}>
                        <CardHeader
                          image
                          plain
                          style={{ cursor: "pointer" }}
                          onClick={e => {
                            e.preventDefault();
                            this.props.fetchDetail(university, universities);
                          }}
                        >
                          <a href="#">
                            <img
                              src={
                                "/img/universities/" +
                                university.ImagesFolder +
                                "/university-background.jpg"
                              }
                              alt="..."
                            />
                          </a>
                          <div
                            className={classes.coloredShadow}
                            style={{
                              backgroundImage: `url('/img/universities/${university.ImagesFolder}/university-background.jpg')`,
                              opacity: "1"
                            }}
                          />
                          <div
                            className={classes.coloredShadow}
                            style={{
                              backgroundImage: `url('/img/universities/${university.ImagesFolder}/university-background.jpg')`,
                              opacity: "1"
                            }}
                          />
                        </CardHeader>
                      </GridItem>
                      <GridItem
                        xs={12}
                        sm={8}
                        md={8}
                        style={{ cursor: "pointer" }}
                        onClick={e => {
                          e.preventDefault();
                          this.props.fetchDetail(university, universities);
                        }}
                      >
                        <Info>
                          <h6 className={classes.cardCategory}>
                            {university.InstitutionCategoryType}
                          </h6>
                        </Info>
                        {" - "}
                        <Info>
                          <h6
                            className={classes.cardCategory}
                            style={{ color: "#269030" }}
                          >
                            {university.InstitutionType}
                          </h6>
                        </Info>
                        <h3 className={classes.cardTitle}>
                          <a
                            href="javascript:(void)"
                            onClick={e => e.preventDefault()}
                          >
                            {university.Name}

                            <Button simple color="white">
                              <Subject /> Ver
                            </Button>
                          </a>
                        </h3>
                        <p className={classes.description}>
                          {this.getDescription(university.Description)}
                          <a href="#"> ver </a>

                          <Button simple color="white">
                            <Subject /> Ver
                          </Button>
                        </p>
                      </GridItem>
                    </GridContainer>
                  </Card>
                </GridItem>
              );
            })}
        </GridContainer>
      </div>
    );
  }
}
export default withStyles(styles)(SectionProductsCards);
