import { FETCH_USER, FETCH_PLAN } from "../types/authTypes";
import { authRef } from "config/firebase";
import { upsertUser, getUser } from "../services/userservices";

export const fetchPlan = (plan, callback) => dispatch => {
  dispatch({
    type: FETCH_PLAN,
    payload: plan
  });
  callback();
}

export const fetchUser = () => dispatch => {
  authRef.onAuthStateChanged(userFromFirebase => {
    if (userFromFirebase) {
      if (userFromFirebase.uid) {
        getUser(userFromFirebase.uid).then(user => {
          if (user) {
            user.isLoggedIn = true;
            dispatch({
              type: FETCH_USER,
              payload: user
            });
          } else {
            dispatch({
              type: FETCH_USER,
              payload: { isLoggedIn: false }
            });
            console.log("No encuentra al usuario");
          }
        })
      } else {
        dispatch({
          type: FETCH_USER,
          payload: { isLoggedIn: false }
        });
        console.log("Que lindo mi usuario, se rompió mi usuario");
      }
    } else {
      dispatch({
        type: FETCH_USER,
        payload: { isLoggedIn: false }
      });
    }
  });
};

export const signIn = (provider, countryCode) => async => {
  return authRef
    .signInWithPopup(provider)
    .then(result => {
      if (result.additionalUserInfo && result.additionalUserInfo.isNewUser) {
        let { user } = result;
        upsertUser({
          uid: user.uid,
          email: user.email,
          emailVerified: user.emailVerified,
          name: user.displayName,
          photoURL: user.photoURL,
          loginPhoneNumber: user.phoneNumber,
          plan: "initial",
          countryCode
        });
      }
    })
    .catch(e => console.log(e));
};

export const signOut = history => {
  return authRef
    .signOut()
    .then(() => {
      history.push("/");
    })
    .catch(e => {
      console.log(e);
    });
};

export const signInBasicCredentials = (email, pass) => dispatch => {
  return authRef
    .signInWithEmailAndPassword(email, pass)
    .then(result => {
      console.log(result)
    })
    .catch(e => {
      console.log(e)
    });
};

export const registerBasicCredentialsUser = (
  email,
  pass,
  displayName
) => dispatch => {
  return authRef
    .createUserWithEmailAndPassword(email, pass)
    .then(result => {
      result.user
        .updateProfile({
          displayName: displayName,
        })
        .then(updatedUserResult => {
          let { user } = result;
          upsertUser({
            uid: user.uid,
            email: user.email,
            name: user.displayName,
            plan: "initial"
          });
        })
    })
    .catch(e => console.log(e));
};
