var firebase = require("../config/firebase");

const firestore = firebase.db;
const colRef = firestore.collection("favorite_careers");

export function addFavoriteCareerToStep(userUID, step, career) {
  return colRef
    .doc(userUID)
    .collection(step)
    .doc(career.Id)
    .set(career)
    .catch(err => console.log(err));
}

export function getFavoriteCareersFromStep(userUID, step) {
  return colRef
    .doc(userUID)
    .collection(step)
    .get()
    .then(snapshot => {
      let careers = snapshot.docs.map(doc => {
        if (doc.exists) return doc.data();
      });

      return { careers, step };
    })
    .catch(err => console.log(err));
}

export function deleteFavoriteCareerFromStep(userUID, step, career) {
  return colRef
    .doc(userUID)
    .collection(step)
    .doc(career.Id)
    .delete()
    .catch(err => console.log(err));
}

export const FIRST_STEP_TEN_CAREERS = "step_one";
export const SECOND_STEP_FIVE_CAREERS = "step_two";
export const THIRD_STEP_THREE_CAREERS = "step_three";
export const FINAL_STEP_SINGLE_CAREER = "step_final";
export const DEFAULT_CAREER_LIST = "default";
const STEPS = [
  FIRST_STEP_TEN_CAREERS,
  SECOND_STEP_FIVE_CAREERS,
  THIRD_STEP_THREE_CAREERS,
  FINAL_STEP_SINGLE_CAREER
];
