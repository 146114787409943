import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui icons
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import Button from "components/CustomButtons/Button.jsx";

import styles from "../jss/sections/productsStyle.jsx";
import SingleLineGridList from "../Sections/SingleLineGridList";
import TrackVisibility from "react-on-screen";

const homeServices = require("services/homeservices");

class SectionProducts extends React.Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   tracked: false
    // };

    this.tracked = false;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  handleListView(classes) {
    // if (!this.tracked) {
    //   this.tracked = true;

    //   homeServices.setTrackedCareer(this.props.list).then(() => {});
    // }

    return (
      <div className={classes.container}>
        {this.props.list && (
          <div>
            <SingleLineGridList
              elements={this.props.list.Careers.map(career => {
                return {
                  ...career,
                  imgSrc: career.ListImage,
                  handleTileClick: () => {
                    this.props.careerDetail(career, this.props.list.Careers);
                  },
                  actionIcon: (
                    <div>
                      <Button
                        justIcon
                        color="rose"
                        simple
                        size="lg"
                        style={{ zIndex: 2 }}
                        onClick={e => {
                          e.stopPropagation();
                          if (
                            this.props.favoriteCareers.filter(
                              car => car.Name == career.Name
                            ).length > 0
                          ) {
                            this.props.deleteCareerFromFavorite(career);
                          } else {
                            this.props.addCareerToFavorite(career);
                          }
                        }}
                      >
                        {this.props.favoriteCareers.filter(
                          car => car.Name == career.Name
                        ).length > 0 ? (
                            <Favorite />
                          ) : (
                            <FavoriteBorder />
                          )}
                      </Button>
                    </div>
                  )
                };
              })}
              listDescription={this.props.list.Description}
              backgroundColor={this.props.backgroundColor}
              cardStyleColor={this.props.cardStyleColor}
            />
          </div>
        )}
      </div>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.section} style={{ padding: 0 }}>
        {/* <TrackVisibility offset={1000}>
          {({ isVisible }) =>
            (isVisible || this.tracked) && this.handleListView(classes)
          }
        </TrackVisibility> */}
        {this.handleListView(classes)}
      </div>
    );
  }
}

export default withStyles(styles)(SectionProducts);
