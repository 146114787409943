import CareersComponent from "./CareersPage";
import NotificationComponent from "components/Notification/NotificationComponent";

import React, { Component } from "react";
import { connect } from "react-redux";

import {
  fetchFavoriteCareersFromStep,
  addFavoriteCareer,
  deleteFavoriteCareer
} from "../../redux/favorite_careers/actions";


import { addNotification } from "../../redux/notifications/actions";

import { fetchCareers } from "./actions/careersPageActions";

import { STEP_FEELING_REQUIRED_CAREERS } from "types/progressStepsTypes";
import { FIRST_STEP_TEN_CAREERS } from "../../services/favoriteCareersServices";

const homeServices = require("services/homeservices");

class CareersContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      career: null,
      relatedCareers: null,
      stepDone_focus: false,
      notifystepDone_focus: false,
      isLoadingProgress: true
    };

    this.careerDetail = this.careerDetail.bind(this);
    this.addFavoriteCareer = this.addFavoriteCareer;
    this.deleteFavoriteCareer = this.deleteFavoriteCareer;
  }

  addFavoriteCareer = career => {
    if (!this.props.auth || !this.props.auth.isLoggedIn) {
      this.props.history.push("/login-page");
      return;
    }

    if (this.props.progress.data.STEP_FEELING) return;

    return this.props
      .addFavoriteCareer(
        this.props.auth.uid,
        this.props.favoriteCareers.lastStep,
        career,
        this.props.favoriteCareers.count + 1,
        STEP_FEELING_REQUIRED_CAREERS
      )
      .then(r => {
        this.innerSetFavoriteCareerCount(
          this.props.auth,
          this.props.favoriteCareers.count
        );
      });
  };

  deleteFavoriteCareer = career => {
    if (this.props.progress.data.STEP_FEELING) return;

    return this.props
      .deleteFavoriteCareer(
        this.props.auth.uid,
        this.props.favoriteCareers.lastStep,
        career
      )
      .then(r => {
        this.innerSetFavoriteCareerCount(
          this.props.auth,
          this.props.favoriteCareers.count
        );
      });
  };

  innerSetFavoriteCareerCount(user, count) {
    homeServices.setFavoriteCareerCount(user, count);
    if (
      !this.props.progress.data.STEP_FEELING &&
      count >= STEP_FEELING_REQUIRED_CAREERS
    ) {
      homeServices.setStepFeeling(user);
      let message = (
        <b>
          Excelente, ya podés avanzar. <br /> <a href="/home">Siguiente paso</a>
        </b>
      );
      this.props.addNotification(message, 4000);
    }
  }

  careerDetail(career, list) {
    this.props.history.push(`/${this.props.country.code}/careers/${career.Id}`, {
      career,
      otherCareersByCategory: list
    });
  }

  componentDidMount() {
    if (
      this.props.careerPage &&
      this.props.careerPage.list &&
      this.props.careerPage.list.length <= 0
    )
      this.props.fetchCareers();

    if (this.props.auth && this.props.auth.isLoggedIn) {
      if (
        this.props.favoriteCareers &&
        this.props.favoriteCareers.list &&
        this.props.favoriteCareers.list.length <= 0
      ) {
        this.props.fetchFavoriteCareersFromStep(
          this.props.auth.uid,
          FIRST_STEP_TEN_CAREERS
        );
      }
    }
  }

  render() {
    let careersPageError = this.props.careerPage.fetchError;
    if (this.state.progressError) careersPageError = this.state.progressError;

    return (
      <div>
        <NotificationComponent />
        <CareersComponent
          {...this.props}
          lists={this.props.careerPage.list}
          isLoadingCareersList={this.props.careerPage.isLoadingCareersList}
          careersPageError={careersPageError}
          favoriteCareers={this.props.favoriteCareers.list}
          careerDetail={this.careerDetail}
          addCareerToFavorite={this.addFavoriteCareer}
          deleteCareerFromFavorite={this.deleteFavoriteCareer}
          setFavoriteCareerCount={this.setFavoriteCareerCount}
          progress={this.props.progress}
          isLoadingFavoriteCareers={
            this.props.favoriteCareers.isLoadingFavoriteCareers
          }
          isLoadingProgress={this.props.progress.isLoading}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  careerPage: state.careersPageReducer,
  favoriteCareers: state.favoriteCareers,
  progress: state.progress,
  country: state.country
});

export default connect(mapStateToProps, {
  fetchFavoriteCareersFromStep,
  addFavoriteCareer,
  deleteFavoriteCareer,
  fetchCareers,
  addNotification
})(CareersContainer);
