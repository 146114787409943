var firebase = require("config/firebase");
const uuidv4 = require("uuid/v4");

const firestore = firebase.db;
const colRef_conversations = firestore.collection("conversations");
const colRef_conversationMessages = firestore.collection(
  "conversationMessages"
);

function _addMessage(
  conversationId,
  senderUid,
  senderName,
  receiverUid,
  senderEmail,
  content
) {
  return new Promise(function (resolve, reject) {
    let id = conversationId;
    if (!id) id = colRef_conversations.doc().id;

    let now = new Date();
    colRef_conversations
      .doc(id)
      .set({
        uid: id,
        senderUid: senderUid,
        senderEmail: senderEmail,
        receiverUid: receiverUid,
        created: now
      })
      .then(() => {
        let message = {
          author_name: senderName,
          content: content,
          author_uid: senderUid,
          conversationUid: id,
          post_date: now
        };

        let conversationMessagesId = colRef_conversationMessages.doc().id;

        colRef_conversationMessages
          .doc(conversationMessagesId)
          .set(message)
          .then(() => {
            resolve(message);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      })
      .catch(err => {
        console.log(err);
        reject(err);
      });
  });
}

function _getConversation(senderUid, receiverUid) {
  return new Promise(function (resolve, reject) {
    colRef_conversations
      .where("senderUid", "==", senderUid)
      .where("receiverUid", "==", receiverUid)
      .get()
      .then(snapshotConversations => {
        if (snapshotConversations.docs.length != 0) {
          let conversation = snapshotConversations.docs[0].data();

          colRef_conversationMessages
            .where("conversationUid", "==", conversation.uid)
            .get()
            .then(snapshotMessages => {
              let messages = snapshotMessages.docs.map(messageDoc => {
                return messageDoc.data();
              });

              resolve({ conversation: conversation, messages: messages });
              return;
            })
            .catch(err => {
              console.log(err);
              reject(err);
            });
        } else {
          resolve(null);
        }
      })
      .catch(err => {
        console.log(err);
        reject(err);
      });
  });
}

export const addMessage = _addMessage;
export const getConversation = _getConversation;
