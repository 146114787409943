export const FirebaseConfig = {
  apiKey: "AIzaSyAAjDTvZpAiCOxe5jjN2TP1CfwlNmyQbII",
  authDomain: "woiro-test.firebaseapp.com",
  databaseURL: "https://woiro-test.firebaseio.com",
  projectId: "woiro-test",
  storageBucket: "woiro-test.appspot.com",
  messagingSenderId: "379081254623",
  appId: "1:379081254623:web:4c4499c3148a50077b2b6a",
  measurementId: "G-R503QN538S"
};

export const AppConfig = {
  gcfUrl: "https://us-central1-woiro-test.cloudfunctions.net",
  mercadopago: {
    ar: {
      publicApiKey: "APP_USR-59879e5a-f208-4f68-b54e-fcfd2fc59726",
      accessToken: "APP_USR-1792249009710369-010812-d48c940d78cb535b850624b6db03a182-392570260"
    },
    cl: {
      publicApiKey: "APP_USR-60525a0b-de28-487d-9f48-315ed2b4e1c8",
      accessToken: "APP_USR-7820755872442600-100213-765fd59e816e013f4001b091e5b0e87f-645928342"
    }
  }
};
//En desa cambia el precio nomás, después vamos contra prod
// mercadopago: {
//   publicApiKey: "TEST-ea687b14-1c6c-42de-bb32-752483574fda",
//     accessToken: "TEST-1792249009710369-010812-37b84cebbd3c3a44aad30e3e6f876478-392570260"
// }