/* eslint-disable */
import React from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import classNames from "classnames";
import Hidden from "@material-ui/core/Hidden"

export default function sectionLineLife(props) {
  const { classes } = props;
  const steps = props.steps && props.steps.length > 0 && props.steps
    .sort(function (a, b) {
      return a.year - b.year;
    })
    .map(element => {
      const icon = (
        <span style={{ color: "black" }}>
          <b>{element.year}</b>
        </span>
      );
      return (
        <Step key={element.year} active={true}>
          <StepLabel icon={icon}>
            <span>
              <b>{element.text}</b>
            </span>
          </StepLabel>
        </Step>
      );
    }) || [];

  return (
    <GridContainer justify="center">
      {
        steps.length > 0 &&
        <GridItem
          className={classNames(classes.textRight)}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Hidden only={['md', 'lg', 'xl']}>
            <Stepper
              style={{ width: "95%" }}
              orientation="vertical"
            >
              {steps}
            </Stepper>
          </Hidden>
          <Hidden only={['xs', 'sm']}>
            <Stepper
              style={{ width: "95%" }}
              alternativeLabel
              orientation="horizontal"
            >
              {steps}
            </Stepper>
          </Hidden>
        </GridItem>
      }
      <GridItem
        xs={12}
        sm={6}
        md={6}
        className={classNames(classes.textRight)}
      >
        <GridContainer>
          <GridItem
            xs={12}
            sm={12}
            md={12}
            className={classNames(classes.textRight)}
          >
            <CustomInput
              id="year"
              particularInputClasses={classNames(classes.input, classes.whiteInput)}
              formControlProps={{
                fullWidth: true,
                className: classes.customFormControlClasses
              }}
              inputProps={{
                value: props.year,
                onChange: e => {
                  props.setValueInState("year", e.target.value);
                },
                placeholder: "Año"
              }}
              error={props.year != "" && !props.isValidYear(props.year)}
            />
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={12}
            className={classNames(classes.textRight)}
          >
            <CustomInput
              id="evento"
              particularInputClasses={classNames(classes.input, classes.whiteInput)}
              formControlProps={{
                fullWidth: true,
                className: classes.customFormControlClasses
              }}
              inputProps={{
                value: props.text,
                onChange: e => props.setValueInState("text", e.target.value),
                placeholder: "Evento"
              }}
            />
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
}