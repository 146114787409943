import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./registerServiceWorker";
import { createBrowserHistory } from "history";
import "assets/scss/material-ui-styles.css";
import App from "./App";
import store from "./store";
import ReactGA from 'react-ga';
import ErrorBoundary from "components/Error/ErrorBoundary";

ReactGA.initialize('UA-158831760-1');
var history = createBrowserHistory();
history.listen(location => ReactGA.pageview(location.pathname));

ReactDOM.render(
  <ErrorBoundary>
    <App history={history} store={store} />
  </ErrorBoundary>,
  document.getElementById("root"));

// serviceWorker.register();