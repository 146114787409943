import React from "react";
import cardProfile1Square from "assets/img/faces/card-profile1-square.jpg";
import cardProfile2Square from "assets/img/faces/card-profile2-square.jpg";
import marc from "assets/img/faces/marc.jpg";
import cardProfile7Square from "assets/img/faces/card-profile7-square.jpg";
import christian from "assets/img/faces/christian.jpg";

const notificationServices = require('services/notificationservices');
const userservices = require('services/userservices');
const userMessageservices = require('services/userMessageservices');

// const firebase = require('config/firebase');
// const firestore = firebase.db;
// const colRef_MentorMessages = firestore.collection("mentorMessages");


export function setCurrentMentor(user, mentor) {
    return userservices.setMentor(user, mentor.uid);
}

export function getCurrentMentor(user) {
    return userservices.getMentor(user);
}

export function sendMessage(user, currentUserEmail, receiverUid, receiverEmail, message, conversationId) {

    // let userMessageData = {
    //     message: message,
    //     email: currentUserEmail,
    //     userUid: user.uid,
    //     receiverUid: receiverUid,
    //     conversationId: conversationId
    // };



    //guardo el msj
    return userMessageservices.addMessage(conversationId, user.uid, user.displayName, receiverUid, currentUserEmail, message)
        .then((addedMessage) => {

            //mando el mail
            notificationServices.sendEmailToMentor(addedMessage, receiverEmail)
                .then(res => {

                })
                .finally(() => { })
                .catch(err => console.log(err));

        });

}

export function getMentors() {
    return [
        {
            uid: 'pat',
            name: 'Pat',
            area: 'Calidad y procesos',
            description: <div>Lorem ipsum dolor sit amet consectetur adipiscing elit pretium, nullam facilisis dictumst posuere sagittis molestie iaculis quisque, fringilla hac habitasse vel metus hendrerit tristique tempus, risus felis velit parturient purus euismod dui.</div>,
            selected: false,
            avatarUrl: cardProfile7Square,
            email: "abdalamichel@gmail.com"
        },
        {
            uid: 'rebu',
            name: 'Rebu',
            area: 'Marketing analítico',
            description: <div>Lorem ipsum dolor sit amet consectetur adipiscing elit pretium, nullam facilisis dictumst posuere sagittis molestie iaculis quisque, fringilla hac habitasse vel metus hendrerit tristique tempus, risus felis velit parturient purus euismod dui.</div>,
            selected: false,
            avatarUrl: cardProfile2Square,
            email: "abdalamichel@gmail.com"
        },
        {
            uid: 'gon',
            name: 'Irish',
            area: 'Informática',
            description: <div>Lorem ipsum dolor sit amet consectetur adipiscing elit pretium, nullam facilisis dictumst posuere sagittis molestie iaculis quisque, fringilla hac habitasse vel metus hendrerit tristique tempus, risus felis velit parturient purus euismod dui.</div>,
            selected: false,
            avatarUrl: cardProfile1Square,
            email: "abdalamichel@gmail.com"
        },
        {
            uid: 'mik',
            name: 'Mike',
            area: 'Tecnología',
            description: <div>Lorem ipsum dolor sit amet consectetur adipiscing elit pretium, nullam facilisis dictumst posuere sagittis molestie iaculis quisque, fringilla hac habitasse vel metus hendrerit tristique tempus, risus felis velit parturient purus euismod dui.</div>,
            selected: false,
            avatarUrl: christian,
            email: "abdalamichel@gmail.com"
        },
        {
            uid: 'pab',
            name: 'Pabl',
            area: 'Administración',
            description: <div>Lorem ipsum dolor sit amet consectetur adipiscing elit pretium, nullam facilisis dictumst posuere sagittis molestie iaculis quisque, fringilla hac habitasse vel metus hendrerit tristique tempus, risus felis velit parturient purus euismod dui.</div>,
            selected: false,
            avatarUrl: marc,
            email: "abdalamichel@gmail.com"
        },
    ];
}