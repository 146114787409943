import {
    container,
    title,
    description,
    mrAuto,
    mlAuto
} from "assets/jss/material-ui-styles.jsx";

import imagesStyles from "assets/jss/material-ui-styles/imagesStyles.jsx";


const assesmentProgressStyle = {
    container,
    title,
    mrAuto,
    mlAuto,
    description: {
        ...description,
        marginBottom: "70px"
    },
    textCenter: {
        textAlign: "center!important"
    },
    selectUnderlineRoot: {
        "& > div": {
            marginTop: "13px"
        }
    },
    circularProgressCustom:{
        width: '100% !important'
    },
    section: {
        textAlign: "center",
        paddingBottom: "0",
        backgroundPosition: "50%",
        backgroundSize: "cover",
        padding: "70px 0",
        "& p": {
            fontSize: "1.188rem",
            lineHeight: "1.5em",
            color: "#555",
            marginBottom: "30px"
        }
    },
    quoteText: {
        fontSize: "1.5rem !important"
    },
    ...imagesStyles
};

export default assesmentProgressStyle;
