import { FETCH_DETAIL } from './detailTypes';
const playlistItemsServices = require("services/youtube/playlistitemsservices")
const universityServices = require("services/universityservices");

export const fetchDetail = (university, list) => (dispatch) => {
    return playlistItemsServices.getPlaylistVideos(university.YoutubePlaylistId)
        .then(response => {
            dispatch({
                type: FETCH_DETAIL,
                payload: {
                    university,
                    videos: response.data.items.map(element => {
                        return {
                            thumbnail: `${element.snippet.thumbnails.default.url}`,
                            original: `${element.snippet.thumbnails.default.url}`,
                            embedUrl: `https://www.youtube.com/embed/${element.snippet.resourceId.videoId}`
                        };
                    })
                }
            });
        })
        .catch(e => console.log(e));
}

export const fetchDetailById = (uid) => dispatch => {
    return universityServices.getUniversity(uid).then(university => {
        playlistItemsServices.getPlaylistVideos(university.YoutubePlaylistId)
        .then(response => {
            dispatch({
                type: FETCH_DETAIL,
                payload: {
                    university,
                    videos: response.data.items.map(element => {
                        return {
                            thumbnail: `${element.snippet.thumbnails.default.url}`,
                            original: `${element.snippet.thumbnails.default.url}`,
                            embedUrl: `https://www.youtube.com/embed/${element.snippet.resourceId.videoId}`
                        };
                    })
                }
            });
        })
        .catch(e => console.log(e));

    })
    .catch(e => console.log(e));
}
