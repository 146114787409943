import React from "react";
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Slide from "@material-ui/core/Slide";
import IconButton from '@material-ui/core/IconButton';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.jsx";


function Transition(props) {
    return <Slide direction="down" {...props} />;
}

export default function Interpretation(props) {
    return <Dialog
        open={true}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        fullScreen
    >
        <DialogTitle>
            <IconButton
                edge="start"
                color="inherit"
                onClick={props.close.action}
                aria-label="close">
                <ChevronLeft style={{ float: "left" }} />
            </IconButton>
            {props.title}
        </DialogTitle>
        <DialogContent
            id="notice-modal-slide-description"
        >
            {props.text}
        </DialogContent>
        <DialogActions>
            {props.close && <Button onClick={props.close.action}>{props.close.text}</Button>}
            {props.next && <Button onClick={props.next.action}>{props.next.text}</Button>}
        </DialogActions>
    </Dialog>
}