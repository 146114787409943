export const cors_api_host = "cors-anywhere.herokuapp.com";
export const createCORSRequest = (method, url) => {
  var cors_api_url = "https://" + cors_api_host + "/";
  var slice = [].slice;
  var origin = window.location.protocol + "//" + window.location.host;

  var xhr = new XMLHttpRequest();

  /* store the origin open method */
  var originalOpen = xhr.open;

  // this.open = function(method, url, async, user, password) {
  //   console.log(url);
  //   originalOpen.call(this, method, url, async, user, password);
  // }

  // xhr.open = function(method, url, async, user, password) {
  //   debugger;
  //   var args = slice.call(arguments);
  //   var targetOrigin = /^https?:\/\/([^\/]+)/i.exec(url);
  //   if (
  //     targetOrigin &&
  //     targetOrigin[0].toLowerCase() !== origin &&
  //     targetOrigin[1] !== cors_api_host
  //   ) {
  //     url = cors_api_url + url;
  //   }
  //   console.log("target url: ", url);
  //   return originalOpen.call(this, method, url, async, user, password);
  //   //return xhr.open.apply(this, args);
  // };
  //xhr.responseType = 'json';
  xhr.overrideMimeType("application/json");
  if ("withCredentials" in xhr) {
    // XHR for Chrome/Firefox/Opera/Safari.
    xhr.open(method, url, true);
  } else {
    // CORS not supported.
    xhr = null;
  }
  return xhr;
};
