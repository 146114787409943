var firebase = require("../config/firebase");

const firestore = firebase.db;
const testsRef = firestore.collection("tests");

export function getTest(testCode) {
    return testsRef.doc(testCode)
        .get()
        .then(result => {
            if (result.exists) {
                return result.data();
            }
        })
}