import React from "react";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

export default function Test(props) {
    const { questions, onClick, loadTestData } = props;
    return questions.map((question, index) => {
        return <GridContainer alignItems="center" justifyContent="center">
            <GridItem key={question.Text} style={{ textAlign: "center" }}>
                <h4 onClick={index == 0 ? loadTestData : undefined}>{question.Order}. {question.Text}</h4>
            </GridItem>
            <GridItem style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <ThemeProvider theme={theme}>
                    <Autocomplete key={question.Text}
                        id="combo-box-demo"
                        options={question.Rates}
                        getOptionLabel={(rate) => rate.Text || rate.Value}
                        style={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Puntaje" variant="outlined" color="primary" />}
                        onChange={(event, optionLabel) => { if (optionLabel) { onClick(question.Order, optionLabel.Value) } }}
                        noOptionsText="Elegí un valor entre los definidos"
                    />
                </ThemeProvider>
            </GridItem>
        </GridContainer>
    })
}

const theme = createMuiTheme({
    overrides: {
        MuiIconButton: {
            root: {
                color: "white"
            }
        },
        MuiOutlinedInput: {
            root: {
                color: "white",
                position: 'relative',
                '& $notchedOutline': {
                    borderColor: 'white',
                },
                '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
                    borderColor: 'white',
                    // Reset on touch devices, it doesn't add specificity
                    '@media (hover: none)': {
                        borderColor: 'white',
                    },
                },
                '&$focused $notchedOutline': {
                    borderColor: 'white',
                    borderWidth: 1,
                },
            },
        },
        MuiFormLabel: {
            root: {
                color: 'white',
                '&$focused': {
                    color: 'white'
                }
            }
        }
    }
});
