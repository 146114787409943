import React from "react";
import ProgressStepPageContainer from '../ProgressStepPage/ProgressStepPageContainer';
import withStyles from "@material-ui/core/styles/withStyles";
import headersStyle from "assets/jss/material-ui-styles/views/sectionsSections/headersStyle.jsx";
import { addStepToProgress } from '../../redux/progress/actions';
import { connect } from "react-redux";

class ProgressStepLocationContainer extends ProgressStepPageContainer {
  constructor(props) {
    super(props);
  }

  getDefaultState() {
    return {
      disableButton: false
    };
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  progress: state.progress
});

const mapDispatchToProps = {
  addStepToProgress
}

export default withStyles(headersStyle)(connect(mapStateToProps, mapDispatchToProps)(ProgressStepLocationContainer));