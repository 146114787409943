/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import Button from "components/CustomButtons/Button.jsx";
import footerStyle from "assets/jss/material-ui-styles/components/footerStyle.jsx";

function Footer(props) {
  const { children, content, classes, theme, big, className } = props;
  const themeType =
    theme === "transparent" || theme == undefined ? false : true;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes[theme]]: themeType,
    [classes.big]: big || children !== undefined,
    [className]: className !== undefined
  });
  const aClasses = classNames({
    [classes.a]: true
  });

  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        {children !== undefined && children != null ? (
          <div>
            <div className={classes.content}>{children}</div>
            <hr />
          </div>
        ) : (
          <div>
            <div className={classes.left}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a href="contact-us" className={classes.block}>
                    Contacto
                  </a>
                </ListItem>
                {/* <ListItem className={classes.inlineBlock}>
                                        <a href="/presentation"
                                            className={classes.block}>Instituci&oacute;n</a>
                                    </ListItem> */}
                <ListItem className={classes.inlineBlock}>
                  <Button
                    justIcon
                    simple
                    color="twitter"
                    className={classes.btn}
                    onClick={e => {
                      window.open("https://twitter.com/somoswoiro");
                    }}
                  >
                    <i className="fab fa-twitter" />
                  </Button>
                  <Button
                    justIcon
                    simple
                    color="facebook"
                    className={classes.btn}
                    onClick={e => {
                      window.open("https://www.facebook.com/woiroedu");
                    }}
                  >
                    <i className="fab fa-facebook" />
                  </Button>
                  <Button
                    justIcon
                    simple
                    color="google"
                    className={classes.btn}
                    onClick={e => {
                      window.open("https://www.instagram.com/somoswoiro/");
                    }}
                  >
                    <i className="fab fa-instagram" />
                  </Button>
                </ListItem>
              </List>
            </div>
          </div>
        )}
        {content}
        <div className={classes.clearFix} />
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.oneOf(["dark", "white", "transparent"]),
  big: PropTypes.bool
  //, content: PropTypes.node.isRequired
};

export default withStyles(footerStyle)(Footer);
