import { combineReducers } from "redux";
import authReducer from "./authReducer";
import loginReducer from "./loginReducer";
import careersReducer from "views/CareersPage/reducers/careersReducer";
import detailReducer from "views/ProductPage/detailReducer";
import detailUniversityReducer from "views/UniversityDetailPage/detailReducer";
import universityReducer from "views/UniversitiesPage/reducers/universityReducer";
import universityPageReducer from "views/UniversitiesPage/reducers/universityPageReducer";
import careersPageReducer from "views/CareersPage/reducers/careersPageReducer";
import favoriteCareersReducer from "../redux/favorite_careers/reducer";
import notifications from "../redux/notifications/reducer";
import progress from "../redux/progress/reducer";
import country from "../redux/country/reducer";

export default combineReducers({
  auth: authReducer,
  login: loginReducer,
  careers: careersReducer,
  detail: detailReducer,
  detailUniversityReducer,
  universityPage: universityReducer,
  universityPageReducer,
  careersPageReducer,
  favoriteCareers: favoriteCareersReducer,
  notifications,
  progress,
  country
});
