function _getUniversityTravelDistances(sourceLocation, universities) {

    const google = window.google;

    return new Promise(function (resolve, reject) {
        //ZARITA MODE...
        //reject(); return;

        var origins = sourceLocation.latitude + "," + sourceLocation.longitude;

        let universityDestinationsRequest = [];

        let processedSites = [];
        universities.forEach(university => {

            if (university.sites != null && university.sites.length != 0) {
                university.sites.forEach(site => {
                    processedSites.push(site);
                    universityDestinationsRequest.push(new google.maps.LatLng(site.geolocation.latitude, site.geolocation.longitude));

                });
            }
        });

        var service = new google.maps.DistanceMatrixService();
        service.getDistanceMatrix(
            {
                origins: [origins],
                destinations: universityDestinationsRequest,
                travelMode: google.maps.TravelMode.DRIVING,
                //unitSystem: google.maps.UnitSystem.IMPERIAL,
                unitSystem: google.maps.UnitSystem.METRIC,
                avoidHighways: false,
                avoidTolls: false
            }, function (response, status) {

                var i = 0;

                if (status != google.maps.DistanceMatrixStatus.OK) {
                    universities.forEach(university => {
                        university.sites.forEach(site => {
                            site.fetchTravelDistanceError = true;
                        });
                    });
                    console.log("_getUniversityTravelDistances Error");
                    reject();

                } else {

                    response.rows.forEach(destinationRow => {
                        destinationRow.elements.forEach(drElement => {
                            if (drElement.status === "ZERO_RESULTS") {
                                processedSites[i].fetchTravelDistanceError = true;
                            }
                            else {
                                processedSites[i].fetchTravelDistanceError = false;
                                processedSites[i].travelDistanceText = drElement.distance.text;
                                processedSites[i].travelDistance = drElement.distance.value;
                                processedSites[i].travelDuration = drElement.duration.text;
                            }
                            i++;
                        });
                    });

                    console.log("_getUniversityTravelDistances OK");
                    resolve(universities);
                }
            });
    });
}

function _getUserGeolocation() {

    return new Promise(
        function (resolve, reject) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function (position) {

                    resolve(position);
                    //console.log("Latitude: " + position.coords.latitude +
                    //    "<br>Longitude: " + position.coords.longitude);
                });
            } else {
                var reason = new Error('geolocation not available');
                reject(reason);
            }
        }
    );
}

function _getUniversityDistances(sourceLocation, universities) {
    let lat1 = sourceLocation.latitude;
    let lon1 = sourceLocation.longitude;

    universities.forEach(university => {

        if (typeof (university.geolocation) === 'undefined') {
            university.distance = 9999; //TODO - int.maxvalue
        } else {
            let lat2 = university.geolocation.latitude;
            let lon2 = university.geolocation.longitude;

            var p = 0.017453292519943295;    // Math.PI / 180
            var c = Math.cos;
            var a = 0.5 - c((lat2 - lat1) * p) / 2 +
                c(lat1 * p) * c(lat2 * p) *
                (1 - c((lon2 - lon1) * p)) / 2;

            university.distance = 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
        }


    });

    return universities;
}



export const getUserGeolocation = _getUserGeolocation;
export const getUniversityDistances = _getUniversityDistances;
export const getUniversityTravelDistances = _getUniversityTravelDistances;