import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import FormatAlignLeft from "@material-ui/icons/FormatAlignLeft";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// sections for this page
import SectionText from "./Sections/SectionText.jsx";
import SectionBlogInfo from "./Sections/SectionBlogInfo.jsx";
import SectionPostComments from "components/Topics/SectionPostComments.jsx";
import styles from "./jss/style.jsx";
import { connect } from "react-redux";
import { safeForumTitle } from "helpers/topicHelper.js";

const forumServices = require("services/forumServices");

class PostPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      topicLoaded: false
    };
  }

  fetchTopic() {
    return new Promise((resolve, reject) => {
      this.setState({ topicLoaded: false });
      let topicId = 0;

      if (this.props.topicId) {
        topicId = this.props.topicId;
      } else {
        topicId = this.props.location.pathname.split("/")[
          this.props.location.pathname.split("/").length - 1
        ];
      }

      let topic = null;

      forumServices
        .getPost(topicId)
        .then(post => {
          this.setState({
            topic: post,
            topicLoaded: true
          });

          resolve(topic);
        })
        .catch(e => {
          console.log(e);
          this.setState({
            post: null,
            topicLoaded: true
          });

          reject(e);
        });
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    this.fetchTopic();
  }

  render() {
    const { classes, ...rest } = this.props;

    if (!this.state.topic) return <div>loading</div>;

    let topic = this.state.topic.post;

    topic.total_replies = this.state.topic.messages.length;
    topic.replies = this.state.topic.messages;
    let img_1 = "";

    if (topic) {
      try {
        let rex3 = /<img[^>]+src="([^">]+)/g;
        img_1 = rex3
          .exec(topic.content.replace(new RegExp("'", "g"), '"'))[1]
          .replace("#038;", "");
      } catch (e) {
        img_1 = require("assets/img/backgrounds/IPU-imagen-04.jpg");
      }
    }

    let content = (
      <div className={classes.container}>
        <SectionText topic={topic} />
        <SectionBlogInfo topic={topic} />
        {this.state.topicLoaded ? (
          <SectionPostComments
            topic={topic}
            auth={this.props.auth}
            fetchTopic={this.fetchTopic.bind(this)}
          />
        ) : (
          <div>loading</div>
        )}
      </div>
    );

    //todo - refactor, esto es porque si viene del detalle de una carrera solo muestra los comentarios y si viene del detalle de un topic del foro muestra todo el bodoque del topic junto con sus comentarios
    if (this.props.topicId && this.state.topicLoaded)
      return (
        <SectionPostComments
          topic={topic}
          auth={this.props.auth}
          fetchTopic={this.fetchTopic.bind(this)}
        />
      );
    else if (this.props.topicId) return <div>loading</div>;
    else
      return (
        <div>
          <Header
            authenticatedUser={this.props.auth}
            links={<HeaderLinks authenticatedUser={this.props.auth} />}
            fixed
            color="transparent"
            forceTransparent={true}
            changeColorOnScroll={{
              height: 250,
              color: "white"
            }}
            {...rest}
          />
          <Parallax image={img_1} filter="dark">
            <div className={classes.container}>
              <GridContainer justify="center">
                <GridItem md={8} className={classes.textCenter}>
                  <h1
                    className={classes.title}
                    dangerouslySetInnerHTML={{
                      __html: topic.title
                    }}
                  />
                </GridItem>
              </GridContainer>
            </div>
          </Parallax>

          <div>
            <div className={classes.main}>
              <div className={classes.container}>{content}</div>
            </div>
          </div>

          <Footer />
        </div>
      );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default withStyles(styles)(connect(mapStateToProps)(PostPage));
