/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { Link } from "react-router-i18n";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import AccountBalance from "@material-ui/icons/AccountBalance";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ExitToApp from "@material-ui/icons/ExitToApp";
import School from "@material-ui/icons/School";
import Home from "@material-ui/icons/Home";
import Group from "@material-ui/icons/Group";

import Receipt from "@material-ui/icons/Receipt";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Hidden from "@material-ui/core/Hidden";

import headerLinksStyle from "assets/jss/material-ui-styles/components/headerLinksStyle.jsx";

function HeaderLinks({ ...props }) {

    const easeInOutQuad = (t, b, c, d) => {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t + b;
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
    };

    const smoothScroll = (e, target) => {
        if (window.location.pathname === "/sections") {
            var isMobile = navigator.userAgent.match(
                /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
            );
            if (isMobile) {
                // if we are on mobile device the scroll into view will be managed by the browser
            } else {
                e.preventDefault();
                var targetScroll = document.getElementById(target);
                scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
            }
        }
    };
    const scrollGo = (element, to, duration) => {
        var start = element.scrollTop,
            change = to - start,
            currentTime = 0,
            increment = 20;

        var animateScroll = function () {
            currentTime += increment;
            var val = easeInOutQuad(currentTime, start, change, duration);
            element.scrollTop = val;
            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    };
    const { classes, authenticatedUser, country } = props;

    return (
        <List className={classes.list + " " + classes.mlAuto}>
            {
                authenticatedUser && authenticatedUser.isLoggedIn && <div>
                    <ListItem className={classes.listItem}>
                        <Link to="/home" className={classes.dropdownLink}>
                            <Home className={classes.dropdownIcons} /> Mi Camino
                    </Link>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <Link to="/careers" className={classes.dropdownLink}>
                            <School className={classes.dropdownIcons} /> Carreras
                        </Link>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <Link to="/universities" className={classes.dropdownLink}>
                            <AccountBalance className={classes.dropdownIcons} /> Universidades
                        </Link>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <Link to="/community" className={classes.dropdownLink}>
                            <Group className={classes.dropdownIcons} />Comunidad
                        </Link>
                    </ListItem>
                </div>
            }
            {
                authenticatedUser && authenticatedUser.isLoggedIn &&
                <div>
                    <Hidden mdUp implementation="css">
                        <ListItem className={classes.listItem}>
                            <Link to="/profile-page" className={classes.dropdownLink}>
                                <AccountCircle className={classes.dropdownIcons} />Perfil
                            </Link>
                        </ListItem>
                        <ListItem className={classes.listItem}>
                            <Link to="/signout-page" className={classes.dropdownLink}>
                                <ExitToApp className={classes.dropdownIcons} />Salir
                            </Link>
                        </ListItem>
                    </Hidden>
                    <Hidden smDown implementation="css" className={classes.hidden}>
                        <ListItem className={classes.listItem}>
                            <CustomDropdown
                                left
                                caret={false}
                                hoverColor="info"
                                dropdownHeader={authenticatedUser.displayName}
                                buttonText={
                                    <img
                                        style={{ width: 30, height: 30 }}
                                        src={authenticatedUser.photoURL != null ? authenticatedUser.photoURL : require("assets/img/unknown_avatar.png")}
                                        className={classes.img}
                                        alt="profile"
                                    />
                                }
                                buttonProps={{
                                    className:
                                        classes.navLink + " " + classes.imageDropdownButton,
                                    color: "transparent"
                                }}
                                dropdownList={[
                                    <Link to="/profile-page" className={classes.dropdownLink}><AccountCircle className={classes.dropdownIcons} />Perfil</Link>,
                                    <Link to="/signout-page" className={classes.dropdownLink}><ExitToApp className={classes.dropdownIcons} />Salir</Link>
                                ]}
                            />
                        </ListItem>
                    </Hidden>
                </div>

            }
            {
                (!authenticatedUser || !authenticatedUser.isLoggedIn) &&
                <div>
                    <Button
                        color="primary"
                        target="_self"
                        size="md"
                        round>
                        <Link style={{ color: "white" }} to={"/login-page"}>
                            Ingresar
                        </Link>
                    </Button>
                </div>

            }
        </List>
    );
}



HeaderLinks.defaultProps = {
    hoverColor: "primary"
};

HeaderLinks.propTypes = {
    dropdownHoverColor: PropTypes.oneOf([
        "dark",
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "rose"
    ])
};

export default withStyles(headerLinksStyle)(HeaderLinks);
