import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from "classnames";

import CircularProgress from '@material-ui/core/CircularProgress';

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import style from "assets/jss/material-ui-styles/components/loadingStyle.jsx";

class Loading extends React.Component {

    render() {
        const { classes, className } = this.props;

        return (
            <GridContainer justify="center" style={this.props.style}>
                <GridItem
                    xs={1}
                    sm={1}
                    md={1}>
                    <CircularProgress  />
                </GridItem>
            </GridContainer>
        );
    }
}

export default withStyles(style)(Loading);