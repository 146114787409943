import React, { Component } from "react";
import { connect } from "react-redux";
import ComunityComponent from "./CommunityPage";
import { setTopic } from "./actions/communityActions";

class CommunityContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  topicDetail(topic) {
    this.props.setTopic(topic);
    this.props.history.push(`/topic/${topic.id}`);
  }

  render() {
    return (
      <div>
        <ComunityComponent
          {...this.props}
          topicDetail={this.topicDetail.bind(this)}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { setTopic }
)(CommunityContainer);
