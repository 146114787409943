import React from "react";
import ProgressStepPageContainer from '../ProgressStepPage/ProgressStepPageContainer';
import withStyles from "@material-ui/core/styles/withStyles";
import headersStyle from "assets/jss/material-ui-styles/views/sectionsSections/headersStyle.jsx";
import { addStepToProgress } from '../../redux/progress/actions';
import { connect } from "react-redux";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import classNames from "classnames"
import Datetime from "react-datetime";
import Button from "components/CustomButtons/Button.jsx";
import DeleteForever from "@material-ui/icons/DeleteForever";
import moment from 'moment'
import { sendMeetupNotification } from "../../services/notificationservices";


const defaultDate = moment().add(3, "days").set({ hour: 8, minute: 0 });

class ProgressStepMeetUpContainer extends ProgressStepPageContainer {
  constructor(props) {
    super(props);
  }

  executeCustomComponentDidMount() {
    if (this.props.auth.email && this.props.auth.telephone) {
      this.setState({
        telephone: this.props.auth.telephone,
        email: this.props.auth.email
      })
    }

    if (this.state.dates == null && this.state.datesAsString) {

      let dates = this.state.dateAsString.map(date => moment(date));

      this.setState({
        dates,
        date: defaultDate,
        dateAsString: this.formatDate(defaultDate)
      })
    }
  }

  getDefaultState() {
    return {
      dates: [],
      datesAsString: [],
      date: defaultDate,
      dateAsString: this.formatDate(defaultDate),
      telephone: this.props.auth.telephone,
      email: this.props.auth.email,
      comments: null,
      disableButton: true
    };
  }

  handleStepDone() {
    sendMeetupNotification({
      fechas: this.state.datesAsString,
      email: this.state.email,
      id: this.props.auth.uid,
      comments: this.state.comments,
      telephone: this.state.telephone
    });
    super.handleStepDone();
  }

  getStateToSave() {
    return {
      ...this.state,
      dates: null,
      date: null,
      dateAsString: null
    }
  }

  getButtons() {
    if (this.state.dates && this.state.dates.length == 3) {
      return [this.getDefaultButton()];
    }

    let button = <Button
      disabled={!this.state.date && this.state.dates && this.state.dates.length > 0}
      color="secondary"
      size="lg"
      style={{ width: "100%" }}
      onClick={this.onAddDate.bind(this)}
    >
      Agregar
  </Button>;

    return [button];
  }

  getChildren(classes) {
    return <GridContainer spacing={2} direction="column" justify="space-evenly" alignItems="center">
      <GridItem md={4} sm={4}>
        <CustomInput
          labelText="Email"
          particularInputClasses={classNames(classes.input, classes.whiteInput)}
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            value: this.state.email,
            onChange: e => this.setState({ email: e.target.value, disableButton: this.state.telephone == "" || e.target.value == "" })
          }}
        />
      </GridItem>
      <GridItem md={4} sm={4}>
        <CustomInput
          labelText="Teléfono"
          particularInputClasses={classNames(classes.input, classes.whiteInput)}
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            value: this.state.telephone,
            onChange: e => this.setState({ telephone: e.target.value, disableButton: this.state.email == "" || e.target.value == "" })
          }}
        />
      </GridItem>
      <GridItem md={4} sm={4}>
        <CustomInput
          labelText="Comentarios"
          particularInputClasses={classNames(classes.input, classes.whiteInput)}
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            value: this.state.comments,
            onChange: e => this.setState({ comments: e.target.value })
          }} />
      </GridItem>
      {
        this.state.datesAsString.map((date, index) => {
          return <GridItem md={4} sm={4} key={index}>
            <h4 className={classes.cardTitle}>{date}
              <Button
                simple
                color="primary"
                onClick={() => this.onDelete(index)}
              >
                <DeleteForever />
              </Button></h4>
          </GridItem>
        })
      }
      <GridItem md={4} sm={4}>
        <Datetime
          locale="es_AR"
          value={this.state.date}
          defaultValue={defaultDate}
          dateFormat="DD/MM/YYYY"
          timeConstraints={{
            hours: { min: 8, max: 20, step: 1 },
            minutes: { min: 0, max: 0, step: 1 }
          }}
          isValidDate={this.validDate}
          input={false}
          className={classNames(classes.datetime)}
          onChange={this.onCalendarChange.bind(this)}
        />
      </GridItem>
    </GridContainer >
  }


  onAddDate() {
    let indexOfDateToDelete = this.state.dates.findIndex(date => date.isSame(this.state.date));

    this.setState({
      dates: [...this.state.dates.filter((date, index) => index != indexOfDateToDelete), this.state.date].sort((date1, date2) => date1.diff(date2)),
      datesAsString: [...this.state.datesAsString.filter((date, index) => index != indexOfDateToDelete), this.state.dateAsString].sort((date1, date2) => moment(date1).diff(moment(date2))),
      date: defaultDate,
      dateAsString: this.formatDate(defaultDate)
    })
  }

  onDelete(indexToRemove) {
    let newDates = this.state.dates.filter((date, index) => index != indexToRemove);
    let newDatesAsString = this.state.datesAsString.filter((date, index) => index != indexToRemove);
    this.setState({ dates: newDates, datesAsString: newDatesAsString })
  }

  onCalendarChange(moment) {
    this.setState({ date: moment, dateAsString: this.formatDate(moment), disableButton: this.state.dates.length + 1 < 3 })
  }

  formatDate(moment) {
    return moment.format("DD/MM/YY hh:mm A");
  }

  validDate(current) {
    const date00 = defaultDate.set({ hour: 0, minute: 0, second: 0 });
    return current.isSameOrAfter(date00) && current.weekday() != 0 && current.weekday() != 6;
  };
}

const mapStateToProps = state => ({
  auth: state.auth,
  progress: state.progress
});

const mapDispatchToProps = {
  addStepToProgress
}

export default withStyles(headersStyle)(connect(mapStateToProps, mapDispatchToProps)(ProgressStepMeetUpContainer));