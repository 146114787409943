import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import Share from "@material-ui/icons/Share";
import ChatBubble from "@material-ui/icons/ChatBubble";
import Schedule from "@material-ui/icons/Schedule";
import TrendingUp from "@material-ui/icons/TrendingUp";
import Subject from "@material-ui/icons/Subject";
import WatchLater from "@material-ui/icons/WatchLater";
import People from "@material-ui/icons/People";
import Business from "@material-ui/icons/Business";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import Delete from "@material-ui/icons/Delete";
import Bookmark from "@material-ui/icons/Bookmark";
import Refresh from "@material-ui/icons/Refresh";
import Receipt from "@material-ui/icons/Receipt";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import Info from "components/Typography/Info.jsx";
import Danger from "components/Typography/Danger.jsx";
import Success from "components/Typography/Success.jsx";
import Warning from "components/Typography/Warning.jsx";
import Rose from "components/Typography/Rose.jsx";
import Button from "components/CustomButtons/Button.jsx";

import styles from "../jss/sections/sectionCards.jsx";

import cardBlog1 from "assets/img/examples/card-blog1.jpg";
import cardBlog2 from "assets/img/examples/card-blog2.jpg";
import cardBlog3 from "assets/img/examples/card-blog3.jpg";
import cardBlog5 from "assets/img/examples/card-blog5.jpg";
import cardBlog6 from "assets/img/examples/card-blog6.jpg";
import cardProfile1 from "assets/img/examples/card-profile1.jpg";
import cardProfile4 from "assets/img/examples/card-profile4.jpg";
import blog1 from "assets/img/examples/blog1.jpg";
import blog5 from "assets/img/examples/blog5.jpg";
import blog6 from "assets/img/examples/blog6.jpg";
import blog8 from "assets/img/examples/blog8.jpg";
import avatar from "assets/img/faces/avatar.jpg";
import christian from "assets/img/faces/christian.jpg";
import marc from "assets/img/faces/marc.jpg";
import office1 from "assets/img/examples/office1.jpg";
import color1 from "assets/img/examples/color1.jpg";
import color2 from "assets/img/examples/color2.jpg";
import color3 from "assets/img/examples/color3.jpg";
import NavPills from "components/NavPills/NavPills.jsx";
import SectionMural from "./SectionMural.jsx";

import { takeCharacters, getTagString, getImgFromTopicContent, safeForumTextNoImg } from "helpers/topicHelper.js";

class SectionCards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeRotate1: "",
      activeRotate2: "",
      topics: this.props.topics,
      filteredTopics: this.props.topics
    };

  }
  componentDidMount() {
    this.adjustRotationCards();
  }

  componentDidUpdate() {
    this.adjustRotationCards();
  }

  adjustRotationCards() {
    const { classes } = this.props;
    var rotatingCards = document.getElementsByClassName(classes.cardRotate);
    for (let i = 0; i < rotatingCards.length; i++) {
      var rotatingCard = rotatingCards[i];
      var rotatingWrapper = rotatingCard.parentElement;
      var cardWidth = rotatingCard.parentElement.offsetWidth;
      var cardHeight = rotatingCard.children[0].children[0].offsetHeight;
      rotatingWrapper.style.height = cardHeight + "px";
      rotatingWrapper.style["margin-bottom"] = 30 + "px";
      var cardFront = rotatingCard.getElementsByClassName(classes.front)[0];
      var cardBack = rotatingCard.getElementsByClassName(classes.back)[0];
      cardFront.style.height = cardHeight + 35 + "px";
      cardFront.style.width = cardWidth + "px";
      cardBack.style.height = cardHeight + 35 + "px";
      cardBack.style.width = cardWidth + "px";
    }
  }

  filterTopicsByTag(tagIndex) {
    let comunidadString = "COMUNIDAD";
    let filteredTopicsResult = [];
    let filterTag_1 = comunidadString;

    switch (tagIndex) {
      case 0: filterTag_1 = comunidadString; break; //comunidad
      case 1: filterTag_1 = "TESTIMONIOS"; break; //Testimonios
      case 2: filterTag_1 = "INNOVACIÓN"; break; //experiencias
      case 3: filterTag_1 = "CARRERAS"; break; //carreras
      case 4: filterTag_1 = "GENERAL"; break; //universidades

    }
    //me quedo con los topics que corresponden a esta seccion
    this.state.topics.forEach((topic) => {
      let expectedTopics = topic.tags.find(tag => { if (filterTag_1 == comunidadString || tag.toUpperCase() == filterTag_1) return true; return false; });

      //en este formato solo muestro los topics FILTRADOS
      if (expectedTopics == null || expectedTopics.length == 0) {
        return;
      }

      filteredTopicsResult.push(topic);
    });

    this.setState({ filteredTopics: filteredTopicsResult });
  }

  render() {
    let j = 0;
    let k = 0;
    const { classes, ...rest } = this.props;

    let topics = [];
    let forumFilter = "ipu";
    let filterTag = "ipu-comunidad";

    this.state.topics.forEach(topic => {

      let filteredByTags = topic.tags.find(tag => { if (tag.toLowerCase() == filterTag) return true; return false; });

      //en este formato solo muestro los topics del IPU
      if (topic.forum_title.toLowerCase() != forumFilter || filteredByTags == null || filteredByTags.length == 0) {
        return;
      }

      topics.push(topic);
    });

    return (
      <div {...rest} className="cd-section" id="cards">

        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8} className={classes.textCenter}>
            <NavPills
              alignCenter
              tabChangeFn={(index) => { this.filterTopicsByTag(index); }}
              tabs={[
                {
                  tabButton: "Comunidad",
                  tabContent: ""
                },
                {
                  tabButton: "Testimonios",
                  tabContent: ""
                },
                {
                  tabButton: "Innovación",
                  tabContent: ""
                },
                {
                  tabButton: "Carreras",
                  tabContent: ""
                },
                {
                  tabButton: "General",
                  tabContent: ""
                }
              ]}
            />
            <div className={classes.tabSpace} />
          </GridItem>
        </GridContainer>
        <div className={classes.sectionGray}>

          <div>
            {/* BLOG CARDS START */}
            <div>
              <div className={classes.container}>
                <SectionMural topics={this.state.filteredTopics} topicDetail={this.props.topicDetail} auth={this.props.auth} />

              </div>
            </div>
            {/* BLOG CARDS END */}

          </div>

          <br /><br />
        </div>
        <div className={classes.sectionWhite}>
          {/* MORPHING CARDS START */}

          <div id="morphingCards" className="cd-section">
            {topics && topics.length != 0 &&
              <div className={classes.container}>
                <div className={classes.textCenter}>
                  <h3 className={classes.title}>Destacados</h3>
                </div>
                <GridContainer>

                  {topics.map((topic, i) => {

                    j++;
                    let wrapperClass = classes.wrapperInfo;
                    let theColor = "info";
                    if ((j % 2) == 0) {
                      theColor = "success";
                      wrapperClass = classes.wrapperSuccess;
                    }
                    else if ((j % 3) == 0) {
                      theColor = "rose";
                      wrapperClass = classes.wrapperRose;
                    }

                    let tagsString = getTagString(topic.tags, filterTag);

                    let rex = /<img[^>]+src="([^">]+)/g;
                    let avatarAux = rex.exec(topic.author_avatar.replace(new RegExp("'", 'g'), "\""))[1];
                    avatarAux = avatarAux.replace("#038;", "");
                    //let avatarAux = topic.author_avatar.substring(topic.author_avatar.indexOf("src=") + 5, topic.author_avatar.indexOf("'", topic.author_avatar.indexOf("src=") +5)).replace("#038;", "");

                    //topic.author_avatar.substring(topic.author_avatar.indexOf("src=") + 4, 4);
                    //

                    return <GridItem xs={12} sm={6} md={6} lg={4} key={i}>
                      <div className={classes.rotatingCardContainer}>
                        <Card color={theColor} className={classes.cardRotate}>
                          <div
                            className={`${classes.front} ${wrapperClass}`}
                          >
                            <CardBody color className={classes.cardBodyRotate}>
                              <h5 className={classes.cardCategorySocialWhite} style={{ textTransform: 'uppercase', minHeight: 77 }}>
                                <i className="fab fa-dribbble" /> {tagsString}
                              </h5>
                              <h4 className={classes.cardTitleWhite} style={{ minHeight: 110 }}>
                                <a href="#pablo" onClick={e => e.preventDefault()}>
                                  "{topic.title}"
                            </a>
                              </h4>
                              <p className={classes.cardDescriptionWhite} dangerouslySetInnerHTML={{ __html: takeCharacters(safeForumTextNoImg(topic.content), 100) }}></p>
                            </CardBody>
                            <CardFooter>
                              <div className={classes.authorWhite}>
                                <a href="#pablo" onClick={e => e.preventDefault()}>
                                  <img
                                    src={avatarAux}
                                    alt="..."
                                    className={`${classes.imgRaised} ${
                                      classes.avatar
                                      }`}
                                  />

                                  {/* <div dangerouslySetInnerHTML={{ __html: topic.author_avatar }}></div> */}
                                  <span>{topic.author_name}</span>
                                </a>
                              </div>
                              <div
                                className={`${classes.statsWhite} ${
                                  classes.mlAuto
                                  }`}
                              >
                                <Share />
                                {topic.reply_count}
                              </div>
                            </CardFooter>
                          </div>
                          <div className={`${classes.back} ${wrapperClass}`}>
                            <CardBody className={classes.cardBodyRotate}>
                              <h5 className={classes.cardCategorySocialWhite} style={{ textTransform: 'uppercase', minHeight: 30 }}>
                                <i className="fab fa-dribbble" /> {tagsString}
                              </h5>
                              <h4 className={classes.cardTitleWhite}>
                                <a href="#pablo" onClick={e => e.preventDefault()}>
                                  "{topic.title}"
                            </a>
                              </h4>
                              <div>
                                <Button round color="white" onClick={e => this.props.topicDetail(topic)}>
                                  <Subject /> Ver
                            </Button>
                                {/* <Button simple color="white">
                                    <Bookmark /> Suscribirme
                                    <Share /> Compartir
                            </Button> */}
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </div>
                    </GridItem>

                  })}
                </GridContainer>
                <br /><br /><br />
              </div>
            }
          </div>

          {/* MORPHING CARDS END */}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(SectionCards);
