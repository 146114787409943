import React from "react";
import { registerEvent } from "helpers/eventsHelper.js";

import { withStyles } from "@material-ui/core/styles";

import style from "assets/jss/material-ui-styles/components/loadingStyle.jsx";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      info: "",
      error: ""
    };

    this.errorStack = [];

    // Only Chrome & Opera pass the error object.
    // window.onerror = function(message, file, line, col, error) {
    //   //console.log(message, "from", error.stack);
    // };

    // Only Chrome & Opera pass the error object.
    window.addEventListener("error", this.handleSeveral.bind(this));
  }

  handleSeveral(e) {
    console.log("from events handler", this.props);

    //console.log(e.error.message, "from", e.error.stack);
    if (!this.isErrorDuplicated(e))
      registerEvent(this.getAuth(), "E", e.error, e.error.stack);

    this.errorStack.push({ error: e.error, info: e.error.stack });
  }

  handleSeveralReactError(e, info) {
    //console.log(e.error.message, "from", e.error.stack);
    console.log("from events handler", this.props);

    //if (!this.isErrorDuplicated(e))
    registerEvent(this.getAuth(), "E", e, info);

    this.errorStack.push({ error: e, info: { e: e, info: info } });
  }

  getAuth() {
    if (
      this.props &&
      this.props.children &&
      this.props.children.props &&
      this.props.children.props.auth &&
      this.props.children.props.auth.isLoggedIn
    )
      return this.props.children.props.auth;

    if (this.props && this.props.auth && this.props.auth.isLoggedIn)
      return this.props.auth;

    return null;
  }

  isErrorDuplicated(error) {
    if (this.errorStack.length == 0) return false;

    if (
      error.message == this.errorStack[this.errorStack.length - 1].error.message
    )
      return true;

    return false;
  }

  componentWillUnmount() {
    window.removeEventListener("error", this.handleSeveralError);
  }

  componentDidCatch(error, info) {
    //this.setState({ hasError: true, info, error });
    //if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    // console.log(`Error: ${error}`);
    // console.log(`ErrorInfo: ${JSON.stringify(info)}`);
    //} else {
    //registerEvent('E', error, info);
    //}

    this.handleSeveralReactError(error, info);
  }

  render() {
    return this.props.children;
    // return this.state.hasError ? (
    //   <p>Something bad happened. :( </p>
    // ) : (
    //   this.props.children
    // );
  }
}

export default withStyles(style)(ErrorBoundary);
