import React, { useState } from "react";
// @material-ui/core components
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import Reply from "@material-ui/icons/Reply";
import Favorite from "@material-ui/icons/Favorite";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Media from "components/Media/Media.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import styles from "../jss/sections/sectionOwnDiscussionsStyle.jsx";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(styles);

const forumServices = require("services/forumServices");

function deleteReply(postId, replyId) {
  forumServices.deleteReply(postId, replyId).catch(e => console.log(e))
}

function deleteMessage(postId) {
  forumServices.deletePost(postId).catch(e => console.log(e))
}

export function ReplyComponent({ onReply, likes, onLike, isAdmin, postId }) {

  const classes = useStyles();
  let [input, setInput] = useState(false)
  let [inputValue, setInputValue] = useState("")

  return !input ? <div style={{ display: "flex", justifyContent: "flex-end" }}>
    {/* <Button
      color="danger"
      simple
      className={classes.footerButtons}
    >
      <Favorite className={classes.footerIcons} /> {likes}
    </Button> */}
    <Button
      color="primary"
      simple
      className={classes.footerButtons}
      onClick={() => setInput(true)}
    >
      <Reply className={classes.footerIcons} /> Responder
              </Button>
    {isAdmin && <Button
      color="primary"
      simple
      className={classes.footerButtons}
      onClick={() => deleteMessage(postId)}
    >Eliminar</Button>}
  </div> :
    <div>
      <CustomInput
        id="nice"
        labelText="Reponder"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          multiline: true,
          rowsMax: 3,
          value: inputValue,
          onChange: e => setInputValue(e.target.value)
        }}
      />
      <Tooltip
        id="tooltip-tina"
        title="Reply to comment"
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <Button onClick={() => {
          onReply(inputValue)
          setInputValue("");
        }} color="primary" round className={classes.footerButtons} disabled={inputValue.trim() == ""}>
          Responder
          </Button>
      </Tooltip>
    </div>
}

export function Comment({ title, description, time, name, avatar, onReply, replys, likes, onLike, isAdmin, id }) {

  const classes = useStyles();
  return <Media
    key={id}
    avatar={avatar}
    title={
      <span>
        {name}<small> {time.toLocaleString("es-AR")}</small>
      </span>
    }
    body={
      <p className={classes.color555}>
        {description}
      </p>
    }
    footer={<ReplyComponent postId={id} onReply={onReply} likes={likes} onLike={onLike} isAdmin={isAdmin} />}
    innerMedias={replys.map(reply => {
      return <Media
        key={reply.name}
        avatar={reply.avatar}
        title={
          <span>
            {reply.name} <small>· {reply.time.toLocaleString("es-AR")}</small>
          </span>
        }
        footer={
          isAdmin ?
            <div>
              <Button
                color="primary"
                simple
                className={classes.footerButtons}
                onClick={() => deleteReply(id, reply.id)}
              >Eliminar</Button>
            </div> : undefined
        }
        body={
          <span className={classes.color555}>
            <p>{reply.description}</p>
          </span>
        }
      />
    })}
  />
}

function getCommentModel(post, onReply, isAdmin) {
  return {
    title: post.title,
    description: post.content,
    time: post.created,
    name: post.author_name,
    avatar: post.senderAvatarUri,
    replys: post.replys || [],
    onReply: (comment) => onReply(post.uid, comment),
    isAdmin: isAdmin,
    id: post.id
  }
}

export function SectionComunityForum(props) {
  const classes = useStyles();
  const { posts, onReply, onPublish, isAdmin, avatar } = props;

  return (<div className={classes.section}>
    <GridContainer justify="center">
      <NewComment onPublish={onPublish} classes={classes} avatar={avatar} />
      <GridItem xs={12} sm={10} md={8}>
        {posts.map(post => <Comment {...getCommentModel(post, onReply, isAdmin)} />)}
      </GridItem>
    </GridContainer>
  </div>)
}

export function NewComment({ onPublish, classes, avatar }) {
  let [inputValue, setInputValue] = useState("")

  return <GridItem xs={12} sm={10} md={8} style={{ textAlign: "center" }}>
    <h3 className={classes.title}>¡Dejá tu comentario!</h3>
    <Media
      avatar={avatar}
      body={
        <div>
          <CustomInput
            labelText=""
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: inputValue,
              multiline: true,
              rowsMax: 5,
              onChange: (e) => setInputValue(e.target.value)
            }}
          />
        </div>
      }
      footer={
        <Button onClick={() => {
          onPublish(inputValue);
          setInputValue("");
        }} color="primary" round className={classes.footerButtons} disabled={inputValue.trim() == ""}>
          Publicar
    </Button>
      }
    />
  </GridItem>
}
