import React from "react";
import Check from "@material-ui/icons/Check";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Home from "@material-ui/icons/Home";
import { Link } from "react-router-i18n";
import SectionAssesmentProgress from "../../views/HomePage/Sections/SectionAssesmentProgress";

class StepDone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNotification: true
    };
  }

  render() {
    const icon = <SectionAssesmentProgress></SectionAssesmentProgress>
    return (
      <Snackbar
        place="bc"
        color="green"
        icon={Check}
        message={
          <div>
            <div>
              <span style={{ width: 100, color: "black" }}>
                {this.props.message ? (
                  this.props.message
                ) : (
                  <b>
                    Completaste un paso hacia el descubrimiento de tu carrera
                  </b>
                )}
              </span>
            </div>
            <div>
              <Link to="/home" style={{ display: "flex", float: "right" }}>
                <Home /> Ir a Mi Camino
              </Link>
            </div>
          </div>
        }
        open={this.state.showNotification}
        closeNotification={() => {
          this.setState({ showNotification: false });
        }}
        close
      />
    );
  }
}

export default StepDone;
