import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Snack from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import snackbarContentStyle from "assets/jss/material-ui-styles/components/whatsAppContentStyle.jsx";
import Button from "components/CustomButtons/Button.jsx";

function WhatssAppBar({ ...props }) {
  const { classes, message, color, close, icon, place, open } = props;
  var action = [];
  const messageClasses = classNames({
    [classes.iconMessage]: icon !== undefined
  });
  return (
    <div>
      <div
        className="fixedWhatsAppContainer"
        style={{}}
        onClick={e => {
          window.open("https://wa.me/5491173629603");
        }}
      >
        <img
          className={"waimgFixed"}
          style={{}}
          src={require("assets/img/whatsapp-icon.png")}
          alt="whatsapp"
          // className={imageClasses}
        />
      </div>
    </div>
  );
}

WhatssAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.node.isRequired,
  color: PropTypes.oneOf(["info", "success", "warning", "danger", "primary"]),
  close: PropTypes.bool,
  icon: PropTypes.func,
  place: PropTypes.oneOf(["tl", "tr", "tc", "br", "bl", "bc"]),
  open: PropTypes.bool
};

export default withStyles(snackbarContentStyle)(WhatssAppBar);
