import React from "react";
import { Link } from "react-router-i18n";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
import Close from "@material-ui/icons/Close";
// core components
import headerStyle from "assets/jss/material-ui-styles/components/headerStyle.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      forceLogoColor: false
    };
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
    this.headerColorChange = this.headerColorChange.bind(this);
  }
  handleDrawerToggle() {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  }
  componentDidMount() {
    //if (this.props.changeColorOnScroll) {
    window.addEventListener("scroll", this.headerColorChange);
    //}
    this.headerColorChange(true);
  }

  headerColorChange(initialize) {
    const {
      classes,
      color,
      changeColorOnScroll,
      forceTransparent
    } = this.props;
    const windowsScrollTop = window.pageYOffset;

    let ccos = changeColorOnScroll;
    //no le doy bola a lo que mandan, siempre el mismo comportamiento para todos por ahora
    //if (typeof (ccos) === 'undefined')
    ccos = { height: 50, color: "white" };

    //ej, el login que usa transaparente
    if (!forceTransparent) {
      if (initialize == true) {
        document.body.getElementsByTagName("header")[0].style = 'display:""';

        //dejo fijo el color de fondo
        document.body
          .getElementsByTagName("header")[0]
          .classList.remove(classes[color]);
        document.body
          .getElementsByTagName("header")[0]
          .classList.add(classes[ccos.color]);
        return;
      }

      if (windowsScrollTop > ccos.height) {
        document.body.getElementsByTagName("header")[0].style = "display:none";
      } else {
        document.body.getElementsByTagName("header")[0].style = 'display:""';

        //dejo fijo el color de fondo
        document.body
          .getElementsByTagName("header")[0]
          .classList.remove(classes[color]);
        document.body
          .getElementsByTagName("header")[0]
          .classList.add(classes[ccos.color]);
      }
    } else {
      if (windowsScrollTop > ccos.height) {
        document.body
          .getElementsByTagName("header")[0]
          .classList.remove(classes[color]);
        document.body
          .getElementsByTagName("header")[0]
          .classList.add(classes[ccos.color]);
      } else {
        document.body
          .getElementsByTagName("header")[0]
          .classList.add(classes[color]);
        document.body
          .getElementsByTagName("header")[0]
          .classList.remove(classes[ccos.color]);
      }
    }
  }
  componentWillUnmount() {
    //if (this.props.changeColorOnScroll) {
    window.removeEventListener("scroll", this.headerColorChange);
    //}
  }
  render() {
    const {
      classes,
      color,
      links,
      fixed,
      absolute,
      authenticatedUser
    } = this.props;
    const appBarClasses = classNames({
      [classes.appBar]: true,
      [classes[color]]: color,
      [classes.absolute]: absolute,
      [classes.fixed]: fixed
    });

    let logo = (
      <img
        ref="logo"
        id="woiro.logo"
        src={"/img/Logo3x.png"}
        style={{ width: 60 }}
      />
    );

    let headerToolbarStyles = { maxWidth: 1200 };

    return (
      <AppBar className={appBarClasses} ref="appBar" style={{ display: "" }}>
        <Toolbar className={classes.container} style={headerToolbarStyles}>
          <Button className={classes.title}>
            <Link to="/">{logo}</Link>
          </Button>

          {links && authenticatedUser && (
            <div>
              <Hidden smDown implementation="css" className={classes.hidden}>
                <div className={classes.collapse}>{links}</div>
              </Hidden>

              <Hidden mdUp>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={this.handleDrawerToggle}
                >
                  <Menu />
                </IconButton>
              </Hidden>
            </div>
          )}

          {/* esto sirve para la landing o la pagina de contacto que solo muestran el link de ingresar si el user no esta logueado*/}
          {links && //si no pregunto por esto entonces en el login me lo muestra tmb...
            !authenticatedUser && (
              <div>
                <div className={classes.collapse}>{<HeaderLinks />}</div>
              </div>
            )}
        </Toolbar>

        {links && authenticatedUser ? (
          <Hidden mdUp implementation="css">
            <Drawer
              variant="temporary"
              anchor={"right"}
              open={this.state.mobileOpen}
              classes={{
                paper: classes.drawerPaper
              }}
              onClose={this.handleDrawerToggle}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={this.handleDrawerToggle}
                className={classes.closeButtonDrawer}
              >
                <Close />
              </IconButton>
              <div className={classes.appResponsive} style={{ marginTop: 50 }}>
                {links}
              </div>
            </Drawer>
          </Hidden>
        ) : null}
      </AppBar>
    );
  }
}

Header.defaultProp = {
  color: "white"
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark"
  ]),
  links: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // this.props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // this.props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark"
    ]).isRequired
  })
};

export default withStyles(headerStyle)(Header);
