export const FirebaseConfig = {
  apiKey: "AIzaSyAKNpcklNlx3ZziXy4tep7KuD4STF26ACs",
  authDomain: "site.woiro.com",
  databaseURL: "https://instituto-pre-universitario.firebaseio.com",
  projectId: "instituto-pre-universitario",
  storageBucket: "instituto-pre-universitario.appspot.com",
  messagingSenderId: "271168730878"
};

export const AppConfig = {
  gcfUrl: "https://us-central1-instituto-pre-universitario.cloudfunctions.net",
  mercadopago: {
    ar: {
      publicApiKey: "APP_USR-59879e5a-f208-4f68-b54e-fcfd2fc59726",
      accessToken: "APP_USR-1792249009710369-010812-d48c940d78cb535b850624b6db03a182-392570260"
    },
    cl: {
      publicApiKey: "APP_USR-60525a0b-de28-487d-9f48-315ed2b4e1c8",
      accessToken: "APP_USR-7820755872442600-100213-765fd59e816e013f4001b091e5b0e87f-645928342"
    }
  }
};
