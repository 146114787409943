import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components used to create a google map
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import BusinessCenter from "@material-ui/icons/BusinessCenter";
// core components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Footer from "components/Footer/Footer.jsx";

import contactUsStyle from "assets/jss/material-ui-styles/views/contactUsStyle.jsx";
import { authRef, provider } from "config/firebase";
import { connect } from "react-redux";

import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import DoneAll from "@material-ui/icons/DoneAll";

const notificationServices = require("services/notificationservices");

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

const CustomSkinMap = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={14}
      defaultCenter={{ lat: -34.6001031, lng: -58.3917387 }}
      defaultOptions={{
        scrollwheel: false,
        zoomControl: true,
        styles: [
          {
            featureType: "water",
            stylers: [
              { saturation: 43 },
              { lightness: -11 },
              { hue: "#0088ff" }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: [
              { hue: "#ff0000" },
              { saturation: -100 },
              { lightness: 99 }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#808080" }, { lightness: 54 }]
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.fill",
            stylers: [{ color: "#ece2d9" }]
          },
          {
            featureType: "poi.park",
            elementType: "geometry.fill",
            stylers: [{ color: "#ccdca1" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#767676" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#ffffff" }]
          },
          { featureType: "poi", stylers: [{ visibility: "off" }] },
          {
            featureType: "landscape.natural",
            elementType: "geometry.fill",
            stylers: [{ visibility: "on" }, { color: "#b8cb93" }]
          },
          { featureType: "poi.park", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.sports_complex",
            stylers: [{ visibility: "on" }]
          },
          { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.business",
            stylers: [{ visibility: "simplified" }]
          }
        ]
      }}
    >
      <Marker position={{ lat: -34.605662, lng: -58.37268 }} />
    </GoogleMap>
  ))
);

class ContactUsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.user.displayName,
      email: this.props.user.email,
      whatsAppNumber: "",
      loading: true,
      message: ""
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    if (this.props.user) {
      this.setState({ loading: false });
    }
  }

  handleMessageChange = event => {
    this.setState({ message: event.target.value });
  };

  sendMessage = event => {
    this.setState({ showLoading: true });

    let userMessage = {
      message: this.state.message,
      email: this.state.email,
      name: this.state.name,
      whatsAppNumber: this.state.whatsAppNumber,
      userUid: this.props.user && this.props.user.uid
    };
    // userMessageServices.addMessage(userMessage).then(() => {
    //     this.setState({ showLoading: false });
    // }).catch((e) => {
    //     this.setState({ showLoading: false });
    // });
    notificationServices
      .sendContactNotification(userMessage)
      .then(res => {
        this.setState({ openSuccessModal: true, showLoading: false });
      })
      .finally(() => {
        this.setState({ message: "" });
      })
      .catch(err => console.log(err));
  };

  handleNameChange = event => {
    this.setState({ name: event.target.value });
  };

  handleEmailChange = event => {
    this.setState({ email: event.target.value });
  };

  handleWhatsAppChange = event => {
      this.setState({ whatsAppNumber: event.target.value.replace(/[^0-9]/g, '') });
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: classes.modal
          }}
          open={this.state.openSuccessModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => {
            this.setState({ openSuccessModal: false });
          }}
          aria-labelledby="notice-modal-slide-title"
          aria-describedby="notice-modal-slide-description"
        >
          <DialogTitle
            id="notice-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
            style={{textAlign: "center"}}
          >
            <h4 className={classes.modalTitle}>¡A la brevedad te vamos a estar contactando!</h4>
            <h4 className={classes.modalTitle}>Si querés también podés contactarnos al <a href="https://wa.me/5491173629603">+5491173629603</a></h4>
          </DialogTitle>
          <DialogContent
            id="notice-modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer justify="center">
              <GridItem xs={1} sm={1} md={1}>
                <DoneAll />
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
        <Header
          authenticatedUser={this.props.user}
          links={
            <HeaderLinks
              dropdownHoverColor="dark"
              authenticatedUser={this.props.user}
            />
          }
          fixed
          color="dark"
        />
        <div className={classes.bigMap}>
          <CustomSkinMap
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAKNpcklNlx3ZziXy4tep7KuD4STF26ACs"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={
              <div
                style={{
                  height: `100%`,
                  borderRadius: "6px",
                  overflow: "hidden"
                }}
              />
            }
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.contactContent}>
            <div className={classes.container}>
              <Dialog
                classes={{
                  root: classes.modalRoot,
                  paper: classes.modal
                }}
                open={this.state.showLoading}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => { }}
                aria-labelledby="notice-modal-slide-title"
                aria-describedby="notice-modal-slide-description"
              >
                <DialogContent
                  id="notice-modal-slide-description"
                  className={classes.modalBody}
                >
                  <CircularProgress className={classes.progress} />
                </DialogContent>
              </Dialog>

              <h2 className={classes.title}>Dejanos un mensaje</h2>
              <GridContainer>
                <GridItem md={6} sm={6}>
                  <p>
                    Indicanos lo que necesites y nosotros nos vamos a comunicar
                    con vos
                    <br />
                    <br />
                  </p>
                  <form>
                    <CustomInput
                      labelText="Nombre"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: e => {
                          this.handleNameChange(e);
                        },
                        value: this.state.name
                      }}
                    />
                    <CustomInput
                      labelText="Email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: e => {
                          this.handleEmailChange(e);
                        },
                        value: this.state.email
                      }}
                    />
                    <CustomInput
                      labelText="Número de WhatsApp"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: e => {
                          this.handleWhatsAppChange(e);
                        },
                        value: this.state.whatsAppNumber
                      }}
                    />
                    <CustomInput
                      labelText="Mensaje"
                      id="float"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        multiline: true,
                        rows: 6,
                        value: this.state.message,
                        onChange: e => {
                          this.handleMessageChange(e);
                        }
                      }}
                    />
                    <div className={classes.textCenter}>
                      <Button
                        color="primary"
                        round
                        onClick={e => {
                          this.sendMessage();
                        }}
                      >
                        Enviar
                      </Button>
                    </div>
                  </form>
                </GridItem>
                <GridItem md={4} sm={4} className={classes.mlAuto}>
                  <InfoArea
                    className={classes.info}
                    title="Visitanos en nuestra oficina"
                    description={
                      <p>
                        Montevideo 751 - Piso 1° <br /> CP. C1019ABO <br />{" "}
                        Ciudad de Buenos Aires, <br /> Argentina
                      </p>
                    }
                    icon={PinDrop}
                    iconColor="primary"
                  />
                  <InfoArea
                    className={classes.info}
                    title="Tambi&eacute;n pod&eacute;s llamarnos "
                    description={
                      <p>
                        Tel. 4813-1014 <br /> Lun - Vie, 9:00-18:00 hs
                      </p>
                    }
                    icon={Phone}
                    iconColor="primary"
                  />
                  <InfoArea
                    className={classes.info}
                    title="Informaci&oacute;n legal"
                    description={
                      <p>
                        Woiro SAS <br />
                      </p>
                    }
                    icon={BusinessCenter}
                    iconColor="primary"
                  />
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth
});

export default connect(mapStateToProps)(
  withStyles(contactUsStyle)(ContactUsPage)
);
