import React from "react";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { CareerDetail } from "../ProductPage/CareerDetail";
import { DialogActions } from "@material-ui/core";
import Button from "components/CustomButtons/Button.jsx";
import Favorite from "@material-ui/icons/Favorite";

function Transition(props) {
    return <Slide direction="down" {...props} />;
}

export function CareerDetailModal(props) {
    return <Dialog
        open={true}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        fullScreen
    >
        <DialogTitle>
            <IconButton
                edge="start"
                color="inherit"
                onClick={props.closeButton}
                aria-label="close">
                <ChevronLeft style={{ float: "left" }} />
            </IconButton>
            {props.detail.Name}
        </DialogTitle>
        <DialogContent
            id="notice-modal-slide-description"
        >
            <GridContainer>
                <GridItem>
                    <CareerDetail
                        career={{ ...props.detail, ShowName: false }}
                        classes={null}
                        favButton={null}
                    />
                </GridItem>
            </GridContainer>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.closeButton}>Volver</Button>
            <Button onClick={() => props.handleFavorite(props.detail)}>{props.favoriteText}</Button>
        </DialogActions>
    </Dialog>
}