import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import LockOutline from "@material-ui/icons/LockOutlined";
import Check from "@material-ui/icons/Check";
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { facebookProvider, googleProvider, twitterProvider } from "config/firebase";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Instruction from "components/Instruction/Instruction.jsx";

import signupPageStyle from "assets/jss/material-ui-styles/views/signupPageStyle.jsx";

import image from "assets/img/login_background.jpg";
import Loading from "components/Loader/Loading.jsx";

function Transition(props) {
    return <Slide direction="down" {...props} />;
}

class LoginComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            termsChecked: true,
            registrationPassword: '',
            registrationEmail: ''
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    }

    signUp(provider) {
        if (!this.state.termsChecked) {
            this.setState({
                noticeModal: true, signupModal: false, errorCode: 'terms-required', functionalError: true
                , errorTitle: 'Aceptar condiciones'
                , errorMessage: 'Necesitamos que aceptes nuestros términos y condiciones de registración'
            });
            return;
        }
        this.setState({ loadingExternaLogin: true });
        this.props.signIn(provider, this.props.country.code)
            .then(() => {
                console.log('Login ok');
                this.setState({ loadingExternaLogin: false });
            })
            .catch((e) => {
                console.log('Login error', e);

                this.loginError(e);
            });
    }

    loginError(error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        if (errorCode == 'auth/account-exists-with-different-credential') {
            this.setState({
                noticeModal: true, errorCode: errorCode, functionalError: true
                , errorTitle: 'Autenticación duplicada'
                , errorMessage: 'Ingresaste previamente con otro mecanismo de autenticación. Por favor volvé a intentar.'
                , loadingExternaLogin: false
            });
        }
        else {
            this.setState({
                noticeModal: true, signupModal: false, errorCode, functionalError: false, errorMessage: errorMessage
                , loadingExternaLogin: false
            });
        }
    }

    openSignUp() {
        this.props.history.push('/signup');
    }

    handleTermsToggle(e) {
        this.setState({ termsChecked: !this.state.termsChecked });
    }

    internalSignInBasicCredentials(event) {
        event.preventDefault();
        if (this.state.registrationPassword == '' || this.state.registrationEmail == '') {
            this.setState({
                noticeModal: true, signupModal: false, errorCode: 'user-password-required', functionalError: true
                , errorTitle: 'Campo requerido'
                , errorMessage: 'Necesitamos que completes todos los datos para poder ingresar'
            });
            return;
        }

        this.setState({ loadingExternaLogin: true });
        this.props.signInBasicCredentials(this.state.registrationEmail, this.state.registrationPassword)
            .then(() => {
                console.log('Login ok');
                this.setState({ loadingExternaLogin: false });
            })
            .catch((e) => {
                console.log('Login error', e);

                this.loginError(e);
            });
    }

    handleClickOpen(modal) {
        var x = [];
        x[modal] = true;
        this.setState(x);
    }

    handleClose(modal) {
        var x = [];
        x[modal] = false;
        this.setState(x);
        this.props.resetUserDataState();
    }

    render() {
        const { classes, ...rest } = this.props;
        return (
            <div>
                <Header
                    absolute
                    color="transparent"
                    forceTransparent={true} />

                {/* NOTICE MODAL START */}
                <Dialog
                    classes={{
                        root: classes.modalRoot,
                        paper: classes.modal
                    }}
                    open={this.state.noticeModal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => this.handleClose("noticeModal")}
                    aria-labelledby="notice-modal-slide-title"
                    aria-describedby="notice-modal-slide-description">
                    <DialogTitle
                        id="notice-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}>
                        <h4 className={classes.modalTitle}>
                            Ups, algo no sali&oacute; como lo esperabamos
                      </h4>
                    </DialogTitle>
                    <DialogContent id="notice-modal-slide-description" className={classes.modalBody}>
                        {!this.state.functionalError &&
                            <div>
                                <Instruction
                                    title="1. Intentalo nuevamente"
                                    text={
                                        <span>
                                            Volv&eacute; a intentar{" "}.
                                            De ser posible, utiliz&aacute; otro medio de autenticaci&oacute;n.</span>
                                    }
                                    image={null}
                                    className={classes.instructionNoticeModal}
                                    imageClassName={classes.imageNoticeModal}
                                />
                                <Instruction
                                    title="2. Si el error persiste..."
                                    text={
                                        <span>
                                            No dudes en comunicarte con nosotros, te dejamos un atajo a nuestros medios de comunicaci&oacute;n,{" "}
                                            <a href="/contact-us">contactanos</a>.
                          </span>
                                    }
                                    image={null}
                                    className={classes.instructionNoticeModal}
                                    imageClassName={classes.imageNoticeModal}
                                />
                            </div>
                        }

                        {this.state.functionalError &&
                            <div>
                                <Instruction
                                    title={this.state.errorTitle}
                                    text={
                                        <span>
                                            {this.state.errorMessage}
                                        </span>
                                    }
                                    image={null}
                                    className={classes.instructionNoticeModal}
                                    imageClassName={classes.imageNoticeModal}
                                />
                            </div>
                        }
                        <p>{this.state.errorCode}</p>
                    </DialogContent>
                    <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter}>
                        <Button
                            onClick={() => this.handleClose("noticeModal")}
                            color="primary"
                            round>
                            Cerrar
                      </Button>
                    </DialogActions>
                </Dialog>
                {/* NOTICE MODAL END */}

                <div
                    className={classes.pageHeader}
                    style={{
                        backgroundImage: "url(" + image + ")",
                        backgroundSize: "cover",
                        backgroundPosition: "top center"
                    }}>
                    <div className={classes.container}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={10} md={10}>
                                <Card className={classes.cardSignup}>
                                    <h2 className={classes.cardTitle}>Ingresar</h2>
                                    <CardBody>
                                        <GridContainer justify="center">
                                            <GridItem xs={12} sm={5} md={5}>
                                                <div className={classes.textCenter}>
                                                    {
                                                        this.state.loadingExternaLogin ? <Loading /> :
                                                            <div>
                                                                <div className={classes.textCenter}>

                                                                    <h4 className={classes.socialTitle}>Conectate con una de tus redes sociales</h4>
                                                                </div>
                                                                {/* <Button justIcon round color="twitter" onClick={e => this.signUp(twitterProvider, this.props.loginError)}>
                                                        <i className="fab fa-twitter" />
                                                    </Button>
                                                    {` `} */}

                                                                <Button justIcon round color="facebook" onClick={e => this.signUp(facebookProvider)}>
                                                                    <i className="fab fa-facebook-f" />
                                                                </Button>
                                                                {` `}

                                                                <Button justIcon round color="google" onClick={e => this.signUp(googleProvider)}>
                                                                    <i className="fab fa-google" />
                                                                </Button>
                                                                {` `}
                                                                <h4 className={classes.socialTitle}>tambi&eacute;n pod&eacute;s usar tu correo</h4>
                                                            </div>
                                                    }
                                                </div>
                                                <form className={classes.form}>
                                                    <CustomInput
                                                        id="registrationEmail"
                                                        formControlProps={{
                                                            fullWidth: true,
                                                            className: classes.customFormControlClasses
                                                        }}
                                                        inputProps={{
                                                            value: this.state.registrationEmail,
                                                            onChange: (e) => { this.setState({ registrationEmail: e.target.value }) },
                                                            startAdornment: (
                                                                <InputAdornment
                                                                    position="start"
                                                                    className={classes.inputAdornment}
                                                                >
                                                                    <Email
                                                                        className={classes.inputAdornmentIcon}
                                                                    />
                                                                </InputAdornment>
                                                            ),
                                                            placeholder: "Email..."
                                                        }}
                                                    />
                                                    <CustomInput
                                                        id="registrationPassword"
                                                        formControlProps={{
                                                            fullWidth: true,
                                                            className: classes.customFormControlClasses
                                                        }}
                                                        inputProps={{
                                                            value: this.state.registrationPassword,
                                                            onChange: (e) => { this.setState({ registrationPassword: e.target.value }) },
                                                            startAdornment: (
                                                                <InputAdornment
                                                                    position="start"
                                                                    className={classes.inputAdornment}
                                                                >
                                                                    <LockOutline
                                                                        className={classes.inputAdornmentIcon}
                                                                    />
                                                                </InputAdornment>
                                                            ),
                                                            placeholder: "Password..."
                                                        }}
                                                    />

                                                    <div className={classes.textCenter}>
                                                        <Button round color="primary" onClick={e => this.internalSignInBasicCredentials(e)}>Ingresar</Button>
                                                    </div>
                                                    <p style={{ marginTop: 20, marginBottom: -20 }} className={`${classes.description} ${classes.textCenter}`}>Aún no tengo cuenta</p>
                                                    <div className={classes.textCenter}>
                                                        <Button simple color="info" size="lg" onClick={e => this.openSignUp(e)}>Registrarme</Button>
                                                    </div>
                                                </form>
                                            </GridItem>
                                        </GridContainer>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    </div>
                    <Footer className={classes.footer} />
                </div>
            </div>
        );
    }
}

export default withStyles(signupPageStyle)(LoginComponent);
