import React from "react";
import ProgressStepPageContainer from '../ProgressStepPage/ProgressStepPageContainer';
import withStyles from "@material-ui/core/styles/withStyles";
import headersStyle from "assets/jss/material-ui-styles/views/sectionsSections/headersStyle.jsx";
import { addStepToProgress } from '../../redux/progress/actions';
import { connect } from "react-redux";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Input from 'react-phone-number-input/input';
import { isValidPhoneNumber } from 'react-phone-number-input'
// import 'react-phone-number-input/style.css'


import { upsertUser } from "../../services/userservices";


class ProgressStepProfessionalGenerationalTreeContainer extends ProgressStepPageContainer {
  constructor(props) {
    super(props);
  }

  getDefaultState() {
    return {
      disableButton: true,
      telephone: ""
    };
  }

  handleStepDone() {
    upsertUser({
      uid: this.props.auth.uid,
      telephone: this.state.telephone,
      telephoneConfirmedByUser: true
    }).then(() => {
      super.handleStepDone()
    })
  }

  getChildren(classes) {
    return <GridContainer spacing={2} direction="column" justify="space-evenly" alignItems="center">
      <GridItem xs={12} sm={3} md={3}>
        <Input
          country={this.props.country.code.toString().toUpperCase()}
          placeholder="Celular"
          value={this.state.telephone}
          onChange={value => {
            this.setState({
              telephone: value,
              disableButton: !isValidPhoneNumber(value)
            })
          }}
          error={this.state.telephone ? (isValidPhoneNumber(this.state.telephone) ? undefined : "Número teléfonico invalido") : "Numero teléfonico requerido"}
        />
      </GridItem>
    </GridContainer >
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  progress: state.progress,
  country: state.country
});

const mapDispatchToProps = {
  addStepToProgress
}

export default withStyles(headersStyle)(connect(mapStateToProps, mapDispatchToProps)(ProgressStepProfessionalGenerationalTreeContainer));