import React from "react";
import ProgressStepPageContainer from '../ProgressStepPage/ProgressStepPageContainer';
import withStyles from "@material-ui/core/styles/withStyles";
import headersStyle from "assets/jss/material-ui-styles/views/sectionsSections/headersStyle.jsx";
import { addStepToProgress } from '../../redux/progress/actions';
import { connect } from "react-redux";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { getTest } from "../../services/testsservices";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import classNames from "classnames";
import groupBy from "lodash/groupBy"

const groupedQuestionsQuantity = 1;
const inputRegex = /{input}/g;

class ProgressStepIncompletePhrasesContainer extends ProgressStepPageContainer {
  constructor(props) {
    super(props);
  }

  getStateToSave() {
    return { ...this.state, infoToDisplay: this.state.results };
  }

  calculateResults() {
    const groupedQuestions = groupBy(this.state.questions, "GroupCode");
    console.log(groupedQuestions);
    const results = Object.keys(groupedQuestions).map(key => {
      return {
        [key]: groupedQuestions[key].reduce((previous, actual) => {
          return [...previous, actual.Text]
        }, [])
      }
    })
    // console.log(JSON.stringify(results))
    this.setState({ results }, this.handleStepDone)
  }

  executeCustomComponentDidMount() {
    getTest("FRASESINCOMPLETAS").then(test => {
      const { Questions } = test;
      console.log(Questions);
      this.setState({ questions: Questions })
    })
  }

  getDefaultState() {
    return {
      questions: [],
      disableButton: true,
      questionsGroup: 0,
      text: "",
      text2: "",
      results: []
    };
  }

  getChildren(classes) {
    return this.getQuestions().map((question, index) => {
      let text = question.Text;
      let textsToReplace = [this.state.text];
      let hasMoreThanOnePlaceholder = this.hasMoreThanOnePlaceholder(text);
      if (hasMoreThanOnePlaceholder) {
        textsToReplace.push(this.state.text2);
      }

      textsToReplace.forEach(fill => {
        let textReplacement = fill != "" ? fill : "............."
        text = text.replace("{input}", textReplacement);
      })

      return <GridContainer alignItems="center" justify="center">
        <GridItem>
          <h4 onClick={index == 0 ? this.loadTestData.bind(this) : undefined}><b>{question.Order}.{text}</b></h4>
        </GridItem>
        <GridItem
          xs={12}
          sm={6}
          md={6}
          className={classNames(classes.textRight)}
        >
          <CustomInput
            id="text1"
            particularInputClasses={classNames(classes.input, classes.whiteInput)}
            formControlProps={{
              fullWidth: true,
              className: classes.customFormControlClasses
            }}
            inputProps={{
              value: this.state.text,
              onChange: e => this.setState({ text: e.target.value, disableButton: hasMoreThanOnePlaceholder && this.state.text2.trim() == "" }),
              placeholder: "Espacio 1"
            }}
          />
        </GridItem>
        {hasMoreThanOnePlaceholder && <GridItem
          xs={12}
          sm={6}
          md={6}
          className={classNames(classes.textRight)}
        >
          <CustomInput
            id="text2"
            particularInputClasses={classNames(classes.input, classes.whiteInput)}
            formControlProps={{
              fullWidth: true,
              className: classes.customFormControlClasses
            }}
            inputProps={{
              value: this.state.text2,
              onChange: e => this.setState({ text2: e.target.value, disableButton: this.state.text.trim() == "" }),
              placeholder: "Espacio 2"
            }}
          />
        </GridItem>}
      </GridContainer>
    });
  }

  getButtons() {
    if ((this.state.questionsGroup + 1) * groupedQuestionsQuantity >= this.state.questions.length) {
      return [
        <Button
          onClick={this.handleNext.bind(this)}
          disabled={this.state.disableButton}
          color="primary"
          size="lg"
          style={{ width: "100%" }}
        >
          Siguiente Paso
          </Button>
      ]
    }

    return [
      <Button
        onClick={this.handleNext.bind(this)}
        disabled={this.state.disableButton}
        color="primary"
        size="lg"
        style={{ width: "100%" }}
      >
        Siguiente Frase
          </Button>
    ];
  }

  hasMoreThanOnePlaceholder(text) {
    return (text.match(inputRegex) || []).length > 1
  }

  handleNext() {
    let [question] = this.getQuestions();
    let text = question.Text;
    let textsToReplace = [this.state.text];
    let hasMoreThanOnePlaceholder = this.hasMoreThanOnePlaceholder(text);
    if (hasMoreThanOnePlaceholder) {
      textsToReplace.push(this.state.text2);
    }

    textsToReplace.forEach(fill => {
      text = text.replace("{input}", fill);
    })

    question.Text = text;

    if ((this.state.questionsGroup + 1) * groupedQuestionsQuantity >= this.state.questions.length) {
      this.calculateResults()
    } else {
      this.setState({
        questionsGroup: this.state.questionsGroup + 1,
        text: "",
        text2: "",
        disableButton: true
      })
    }
  }

  getQuestions() {
    let questions = [...this.state.questions];
    const inicio = this.state.questionsGroup * groupedQuestionsQuantity;
    const final = this.state.questionsGroup * groupedQuestionsQuantity + groupedQuestionsQuantity;

    return questions.slice(inicio, final);
  }

  loadTestData(event) {
    if (this.props.auth.rols && this.props.auth.rols.includes("admin")) {
      let text = "texto de prueba";
      this.state.questions.forEach(question => {
        question.Text = question.Text.replace(inputRegex, text);
      });
      this.setState({ questionsGroup: (this.state.questions.length / groupedQuestionsQuantity) - 1, disableButton: false })
    }
  }

  // loadTestData() {
  //   let array = [
  //     "Para elegir algo",
  //     "Trabajar bien",
  //     "Seguir una carrera en la universidad de Buenos Aires",
  //     "Mis profesores piensan que yo soy un buen estudiante",
  //     "Si yo fuera famoso podría viajar por el mundo",
  //     "Rara vez tomo una decisión importante que sirva de algo",
  //     "Después de terminar (la secundaria) o (la preparatoria) no se que voy a estudiar",
  //     "Lo que más me atrae de un trabajo es lo importante que puede llegar a ser",
  //     "Me resulta difícil la química ",
  //     "Cuando pienso en la universidad siento que va a ser muy difícil",
  //     "En esta sociedad, más vale la pena hacer algo que te gusta que hacer algo que no te llama la atención ",
  //     "Me da miedo que me vaya mal en todo",
  //     "El tipo de trabajo que más me gusta usa la tecnología ",
  //     "Cuando dudo entre dos cosas tardo mucho en decidir por una",
  //     "En la vida lo más importante es ser feliz ",
  //     "En cuanto a profesiones, la diferencia entre chicos y chicas no importa",
  //     "Antes de hacer algo importante hay que pensarlo bien",
  //     "Mis padres quisieran que yo haga lo que mas me guste",
  //     "Pensar en el futuro puede cambiar el presente ",
  //     "Estudiar es una de las cosas mas importantes de la vida",
  //     "Lo que más me pone nervioso(a) es decidir que hacer con mi vida ",
  //     "La mayor satisfacción de un trabajo es que te vaya bien en eso",
  //     "La sociedad espera que uno (a) haga su trabajo bien ",
  //     "Es difícil elegir una profesión cuando no tenes idea de que te gusta ",
  //     "En la vida quiero llegar a ser alguien importante ",
  //     "El problema con la mayor parte de los trabajos es el horario",
  //     "Cuando tengo que tomar una decisión pienso si perjudica a alguien ",
  //     "Mis compañeros piensan que yo soy una buena persona",
  //     "Si no estudiara trabajaría de alguna manera ",
  //     "Siempre quise ser famoso ",
  //     "Lo que más me preocupa es que voy a estudiar ",
  //     "Elegir siempre me causo temor",
  //     "El deseo más grande de un profesional es ser el mejor en lo que hace",
  //     "Cuando sea mayor podré trabajar ",
  //     "Necesito ir a la escuela ",
  //     "Una profesión brinda la oportunidad para ser alguien en la vida ",
  //     "Siempre quise ir al futuro pero nunca lo podré hacer",
  //     "Lo que más quiero de un trabajo es que me guste mucho",
  //     "Si estudiara , seria algo que me guste ",
  //     "Lo difícil de tomar una decisión es que puede salir mal",
  //     "Lo que más me disgusta de un trabajo es que te puede ir mal",
  //     "Puedo ser feliz",
  //     ""
  //   ];


  //   this.state.questions.forEach((question, index) => {
  //     question.Text = array[index]
  //   });
  //   this.setState({ questionsGroup: (this.state.questions.length / groupedQuestionsQuantity) - 1, disableButton: false })
  // }

}

const mapStateToProps = state => ({
  auth: state.auth,
  progress: state.progress
});

const mapDispatchToProps = {
  addStepToProgress
}

export default withStyles(headersStyle)(connect(mapStateToProps, mapDispatchToProps)(ProgressStepIncompletePhrasesContainer));