import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import assesmentProgressStyle from "assets/jss/material-ui-styles/views/homeSections/assesmentProgressStyle.jsx";

class SectionAssesmentProgress extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { classes } = this.props;
        const percentage = this.props.progress;
        return (
            <div className={classes.section} style={{ paddingTop: 20 }}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={10} md={8} style={{ maxWidth: 150 }}>
                        <CircularProgressbar
                            className={classes.circularProgressCustom}
                            percentage={percentage}
                            text={`${percentage}%`}
                            strokeWidth={12}
                        />
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}
export default withStyles(assesmentProgressStyle)(SectionAssesmentProgress);

