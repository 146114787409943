import ContactUsPage from "views/ContactUsPage/ContactUsPage.jsx";
import LandingPage from "views/LandingPage/LandingPage.jsx";
import LandingInstitutionsPage from "views/LandingPage/LandingInstitutionsPage.jsx";

import HomePageContainer from "views/HomePage/HomePageContainer.jsx";
import ProfilePage from "views/ProfilePage/ProfilePage.jsx";
import ProductPage from "views/ProductPage/DetailContainer.jsx";
import UniversityDetailPage from "views/UniversityDetailPage/DetailContainer.jsx";
import LoginPage from "views/LoginPage/LoginContainer";
import SignupPage from "views/SignupPage/SignupContainer";
import SignoutPage from "views/SignoutPage/SignoutPage.jsx";
import CareersPage from "views/CareersPage/CareersContainer";
import CareerAdminDetailPage from "views/CareerAdminDetailPage/CareerAdminDetailContainer";
import CareerListsAdminDetailPage from "views/CareerListsAdminDetailPage/CareerListsAdminDetailContainer";

import LogsAdminContainer from "views/LogsAdminPage/LogsAdminContainer";
import AdminProfilePage from "views/AdminProfilePage/AdminProfilePage";

import UniversitiesPage from "views/UniversitiesPage/UniversitiesContainer.jsx";
import AdminContainer from "containers/AdminContainer";
import CommunityContainer from "views/CommunityPage/CommunityContainer.jsx";
import TopicContainer from "views/TopicPage/TopicContainer.jsx";
import PostContainer from "views/PostPage/PostContainer.jsx";
import ErrorContainer from "views/ErrorPage/ErrorContainer.jsx";
import CareersAdminContainer from "views/CareersAdminPage/CareersAdminContainer.jsx";

import TYCContainer from "views/TYCPage/TYCPage.jsx";

import ProgressStepCategoriesContainer from "views/ProgressStepCategoriesPage/ProgressStepCategoriesContainer.jsx";
import ProgressStepFeelingContainer from "views/ProgressStepFeelingPage/ProgressStepFeelingContainer.jsx";
import ProgressStepUserProfileContainer from "views/ProgressStepUserProfilePage/ProgressStepUserProfileContainer.jsx";
import ProgressStepExperienceContainer from "views/ProgressStepExperiencePage/ProgressStepExperienceContainer.jsx";
import ProgressStepForumContainer from "views/ProgressStepForumPage/ProgressStepForumContainer.jsx";
import ProgressStepTestContainer from "views/ProgressStepTestPage/ProgressStepTestContainer.jsx";
import ProgressStepProfessionalGenerationalTreeContainer from "views/ProgressStepProfessionalGenearationalTree/ProgressStepProfessionalGenerationalTreeContainer.jsx";
import ProgressStepFocusContainer from "views/ProgressStepFocusPage/ProgressStepFocusContainer.jsx";
import ProgressStepTourContainer from "views/ProgressStepTourPage/ProgressStepTourContainer.jsx";
import ProgressStepStudyContainer from "views/ProgressStepStudyPage/ProgressStepStudyContainer.jsx";
import ProgressStepWorkContainer from "views/ProgressStepWorkPage/ProgressStepWorkContainer.jsx";
import ProgressStepSelectionContainer from "views/ProgressStepSelectionPage/ProgressStepSelectionContainer.jsx";
import ProgressStepLocationContainer from "views/ProgressStepLocationPage/ProgressStepLocationContainer.jsx";
import ProgressStepEnrollContainer from "views/ProgressStepEnrollPage/ProgressStepEnrollContainer.jsx";
import ProgressStepCelebrateContainer from "views/ProgressStepCelebratePage/ProgressStepCelebrateContainer.jsx";
import ProgressStepColaborateContainer from "views/ProgressStepColaboratePage/ProgressStepColaborateContainer.jsx";
import ProgressStepShareContainer from "views/ProgressStepSharePage/ProgressStepShareContainer.jsx";
import ProgressStepIAMIContainer from "views/ProgressIAMIPage/ProgressIAMIContainer.jsx";
import ProgressStepCIPContainer from "views/ProgressCIPPage/ProgressCIPContainer.jsx";
import ProgressStepIncompletePhrasesContainer from "views/ProgressStepIncompletePhrasesPage/ProgressStepIncompletePhrasesContainer.jsx";
import ProgressStepPlansContainer from "views/ProgressStepPlansPage/ProgressStepPlansContainer.jsx";
import ProgressStepMeetUpContainer from "views/ProgressStepMeetUpPage/ProgressStepMeetUpContainer.jsx";

import { getLocales } from "../i18n";

const base = `/:locale(${getLocales().join("|")})`;
const applyBaseToRoutes = (routes = []) =>
  routes.map((route) => ({ ...route, path: `${base}${route.path}` }));

var indexRoutes = [
  { path: "/contact-us", name: "ContactUsPage", component: ContactUsPage },
  { path: "/signIn", name: "LoginPage", component: LoginPage },
  {
    path: "/login-page",
    name: "SignupPage",
    component: SignupPage,
    requireAuth: false,
  },
  {
    path: "/terms-and-conditions",
    name: "TYCContainer",
    component: TYCContainer,
    requireAuth: false,
  },

  { path: "/profile-page", name: "ProfilePage", component: ProfilePage },
  {
    path: "/product-page",
    name: "ProductPage",
    component: ProductPage,
    requireAuth: true,
  },
  {
    path: "/home",
    name: "HomePage",
    component: HomePageContainer,
    requireAuth: true,
  },
  {
    path: "/signup",
    name: "SignupPage",
    component: SignupPage,
    requireAuth: false,
  },
  {
    path: "/signout-page",
    name: "SignoutPage",
    component: SignoutPage,
    requireAuth: false,
  },
  {
    path: "/careers",
    name: "CareersPage",
    component: CareersPage,
    requireAuth: true,
  },
  {
    path: "/ver-carreras",
    name: "CareersPage",
    component: CareersPage,
    requireAuth: false,
  },
  {
    path: "/universities",
    name: "UniversitiesPage",
    component: UniversitiesPage,
    requireAuth: true,
  },
  {
    path: "/admin",
    name: "AdminContainer",
    component: AdminContainer,
    requireAuth: true,
  },
  {
    path: "/careers/:careerUid",
    name: "CareerDetailPage",
    component: ProductPage,
    requireAuth: false,
  },
  {
    path: "/universities/:universityUid",
    name: "UniversityDetailPage",
    component: UniversityDetailPage,
    requireAuth: true,
  },
  {
    path: "/community",
    name: "CommunityContainer",
    component: CommunityContainer,
    requireAuth: true,
  },
  {
    path: "/topic/:topic",
    name: "TopicContainer",
    component: TopicContainer,
    requireAuth: true,
  },
  {
    path: "/post/:post",
    name: "PostContainer",
    component: PostContainer,
    requireAuth: true,
  },
  {
    path: "/step-plans",
    name: "ProgressStepPlansPage",
    component: ProgressStepPlansContainer,
    requireAuth: true,
  },
  {
    path: "/step-meet-our-team",
    name: "MeetUpContainer",
    component: ProgressStepMeetUpContainer,
    requireAuth: true,
  },
  {
    path: "/error",
    name: "ErrorContainer",
    component: ErrorContainer,
    requireAuth: true,
  },
  {
    path: "/step-user-profile",
    name: "ProgressStepUserProfileContainer",
    component: ProgressStepUserProfileContainer,
    requireAuth: true,
  },

  {
    path: "/step-categories",
    name: "ProgressStepCategoriesContainer",
    component: ProgressStepCategoriesContainer,
    requireAuth: true,
  },
  {
    path: "/step-feeling",
    name: "ProgressStepFeelingContainer",
    component: ProgressStepFeelingContainer,
    requireAuth: true,
  },
  {
    path: "/step-experience",
    name: "ProgressStepExperienceContainer",
    component: ProgressStepExperienceContainer,
    requireAuth: true,
  },
  {
    path: "/step-forum",
    name: "ProgressStepForumContainer",
    component: ProgressStepForumContainer,
    requireAuth: true,
  },
  {
    path: "/step-test",
    name: "ProgressStepTestContainer",
    component: ProgressStepTestContainer,
    requireAuth: true,
  },
  {
    path: "/step-test-professional-generational-tree",
    name: "ProgressStepProfessionalGenerationalTreeContainer",
    component: ProgressStepProfessionalGenerationalTreeContainer,
    requireAuth: true,
  },
  {
    path: "/step-focus",
    name: "ProgressStepFocusContainer",
    component: ProgressStepFocusContainer,
    requireAuth: true,
  },
  {
    path: "/step-study",
    name: "ProgressStepStudyContainer",
    component: ProgressStepStudyContainer,
    requireAuth: true,
  },
  {
    path: "/step-tour",
    name: "ProgressStepTourContainer",
    component: ProgressStepTourContainer,
    requireAuth: true,
  },
  {
    path: "/step-work",
    name: "ProgressStepWorkContainer",
    component: ProgressStepWorkContainer,
    requireAuth: true,
  },
  {
    path: "/step-selection",
    name: "ProgressStepSelectionContainer",
    component: ProgressStepSelectionContainer,
    requireAuth: true,
  },
  {
    path: "/step-location",
    name: "ProgressStepLocationContainer",
    component: ProgressStepLocationContainer,
    requireAuth: true,
  },
  {
    path: "/step-Enroll",
    name: "ProgressStepEnrollContainer",
    component: ProgressStepEnrollContainer,
    requireAuth: true,
  },
  {
    path: "/step-celebrate",
    name: "ProgressStepCelebrateContainer",
    component: ProgressStepCelebrateContainer,
    requireAuth: true,
  },
  {
    path: "/step-colaborate",
    name: "ProgressStepColaborateContainer",
    component: ProgressStepColaborateContainer,
    requireAuth: true,
  },
  {
    path: "/step-share",
    name: "ProgressStepShareContainer",
    component: ProgressStepShareContainer,
    requireAuth: true,
  },
  {
    path: "/step-iami",
    name: "ProgressStepIAMIContainer",
    component: ProgressStepIAMIContainer,
    requireAuth: true,
  },
  {
    path: "/step-cip",
    name: "ProgressStepCIPContainer",
    component: ProgressStepCIPContainer,
    requireAuth: true,
  },
  {
    path: "/step-frases-incompletas",
    name: "ProgressStepIncompletePhrasesContainer",
    component: ProgressStepIncompletePhrasesContainer,
    requireAuth: true,
  },
  {
    path: "/careers-admin",
    name: "CareersAdminContainer",
    component: CareersAdminContainer,
    requireAuth: true,
  },
  {
    path: "/careers-admin/:careerUid",
    name: "CareerAdminDetailPage",
    component: CareerAdminDetailPage,
    requireAuth: true,
  },
  {
    path: "/create-career/:careerUid",
    name: "CareerAdminDetailPage",
    component: CareerAdminDetailPage,
    requireAuth: true,
  },
  {
    path: "/careers-list-admin/:listuid",
    name: "CareerListsAdminDetailPage",
    component: CareerListsAdminDetailPage,
    requireAuth: true,
  },
  {
    path: "/admin/logs",
    name: "LogsAdminContainer",
    component: LogsAdminContainer,
    requireAuth: true,
  },
  {
    path: "/admin/userprofile",
    name: "AdminProfilePage",
    component: AdminProfilePage,
    requireAuth: true,
  },
  {
    path: "/instituciones",
    name: "LandingInstitutionsPage",
    component: LandingInstitutionsPage,
  },
  { path: "/", name: "LandingPage", component: LandingPage },
];

export default applyBaseToRoutes(indexRoutes);
