import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import productStyle from "../jss/sections/productStyle.jsx";

import InfoArea from "components/InfoArea/InfoArea.jsx";
import ChatBubbleOutline from "@material-ui/icons/ChatBubbleOutline";
import Group from "@material-ui/icons/Group";
import Mood from "@material-ui/icons/Mood";

import Book from "@material-ui/icons/Book";
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
import I18n from "../../../i18n/index.js";
function SectionText({ ...props }) {
  const { classes } = props;
  const imgClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h2 className={classes.title}><I18n t="LANDING_ABOUT_TITLE" /></h2>
          <h5 className={classes.description}>
            <I18n t="LANDING_ABOUT_TEXT" />
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={4} md={4}>
            <InfoArea
              title={<I18n t="LANDING_ABOUT_APRENDE_TITLE" />}
              description={<I18n t="LANDING_ABOUT_APRENDE_TEXT" />}
              icon={Group}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <InfoArea
              title={<I18n t="LANDING_ABOUT_CONOCETE_TITLE" />}
              description={<I18n t="LANDING_ABOUT_CONOCETE_TEXT" />}
              icon={Fingerprint}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <InfoArea
              title={<I18n t="LANDING_ABOUT_EXPLORA_TITLE" />}
              description={<I18n t="LANDING_ABOUT_EXPLORA_TEXT" />}
              icon={Book}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>

    // <div className={classes.section}>
    //   <GridContainer justify="center">
    //     <GridItem xs={12} sm={8} md={8}>
    //       <h3 className={classes.title}>
    //         Woiro: la primera plataforma de orientación vocacional on-line donde
    //         lo importante sos vos y no los algoritmos.
    //       </h3>

    //       <GridContainer style={{ paddingTop: 30, paddingBottom: 40 }}>
    //         <GridItem md={6} sm={6}>
    //           <InfoArea
    //             style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 20 }}
    //             title="Información"
    //             description={
    //               <span>
    //                 <p>
    //                   Toda la información de carreras y facultades para decidir
    //                   tu futuro
    //                 </p>
    //               </span>
    //             }
    //             icon={Info}
    //             iconColor="rose"
    //           />
    //         </GridItem>
    //         <GridItem md={6} sm={6}>
    //           <InfoArea
    //             style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 20 }}
    //             title="Comunidad"
    //             description={
    //               <span>
    //                 <p>Testimonios de jóvenes profesionales</p>
    //               </span>
    //             }
    //             icon={ChatBubbleOutline}
    //             iconColor="rose"
    //           />
    //         </GridItem>
    //         <GridItem md={6} sm={6}>
    //           <InfoArea
    //             style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 20 }}
    //             title="Herramientas"
    //             description={
    //               <span>
    //                 <p>Conocemos las cosas que aceleran tu auto-conocimiento</p>
    //               </span>
    //             }
    //             icon={Build}
    //             iconColor="rose"
    //           />
    //         </GridItem>
    //         <GridItem md={6} sm={6}>
    //           <InfoArea
    //             style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 20 }}
    //             title="Nuestros profesionales"
    //             description={
    //               <span>
    //                 <p>Acompañamiento psicológico</p>
    //               </span>
    //             }
    //             icon={Mood}
    //             iconColor="rose"
    //           />
    //         </GridItem>
    //       </GridContainer>
    //       <br />

    //     </GridItem>
    //   </GridContainer>
    // </div>
  );
}

export default withStyles(productStyle)(SectionText);
