import React from "react";
import ProgressStepPageContainer from '../ProgressStepPage/ProgressStepPageContainer';
import withStyles from "@material-ui/core/styles/withStyles";
import headersStyle from "assets/jss/material-ui-styles/views/sectionsSections/headersStyle.jsx";
import { addStepToProgress } from '../../redux/progress/actions';
import { connect } from "react-redux";
import FastForward from "@material-ui/icons/FastForward";
import Button from "components/CustomButtons/Button.jsx";

class ProgressStepForumContainer extends ProgressStepPageContainer {
  constructor(props) {
    super(props);
  }

  executeCustomComponentDidMount() {
  }

  getDefaultState() {
    return {};
  }

  getButtons() {
    const callbackGoToCommunity = () => {
      this.props.history.push(`/${this.props.country.code}/community`);
    }

    const defaultButton = <Button
      disabled={this.state.disableButton}
      color="primary"
      size="lg"
      style={{ width: "100%" }}
      onClick={(event) => this.handleStepDone(event, callbackGoToCommunity.bind(this))}
    >
      Ir a comunidad
  </Button>;
    const skipButton = <Button
      color="white"
      simple
      size="lg"
      style={{ width: "100%" }}
      onClick={(event) => this.handleStepDone(event)}
    >
      <FastForward /> Saltar
  </Button>

    return [skipButton, defaultButton];
  }

}

const mapStateToProps = state => ({
  auth: state.auth,
  progress: state.progress
});

const mapDispatchToProps = {
  addStepToProgress
}

export default withStyles(headersStyle)(connect(mapStateToProps, mapDispatchToProps)(ProgressStepForumContainer));