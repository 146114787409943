import React, { Component } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import styles from "../jss/sections/favoritesStyle.jsx";

import Subject from "@material-ui/icons/Subject";

class SectionProductsCards extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { classes } = this.props;

    return (
      <div style={{ marginLeft: 20 }}>
        <GridContainer justify="center">
          {this.props.universities &&
            this.props.universities.map((university, index) => {
              return (
                <GridItem
                  style={{ cursor: "pointer" }}
                  key={index}
                  xs={12}
                  sm={4}
                  md={4}
                  onClick={e => {
                    e.preventDefault();
                    this.props.universityDetail(university, {
                      universities: []
                    });
                  }}
                >
                  <Card
                    raised
                    pricing
                    background
                    style={{
                      backgroundImage: `url('/img/universities/${
                        university.ImagesFolder
                      }/university-background.jpg')`,
                      maxWidth: 300
                    }}
                  >
                    <CardBody raised pricing background>
                      <h6 className={classes.cardCategoryWhite}>
                        {university.ShortName}
                      </h6>

                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />

                      <Button
                        justIcon
                        color="rose"
                        simple
                        size="lg"
                        style={{ zIndex: 2 }}
                        onClick={e => {
                          e.stopPropagation();
                          if (
                            this.props.favoriteUniversities &&
                            this.props.favoriteUniversities.filter(
                              fav => fav.ShortName == university.ShortName
                            ).length > 0
                          ) {
                            this.props.deleteFavoriteUniversity(university);
                          } else {
                            this.props.addFavoriteUniversity(university);
                          }
                        }}
                      >
                        {this.props.favoriteUniversities &&
                        this.props.favoriteUniversities.filter(
                          fav => fav.ShortName == university.ShortName
                        ).length > 0 ? (
                          <Favorite />
                        ) : (
                          <FavoriteBorder />
                        )}
                      </Button>

                      <Button simple color="white">
                        <Subject /> Ver
                      </Button>
                    </CardBody>
                  </Card>
                </GridItem>
              );
            })}
        </GridContainer>
      </div>
    );
  }
}
export default withStyles(styles)(SectionProductsCards);
