import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons

import Fade from "@material-ui/core/Fade";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

// core components
import cardStyle from "assets/jss/material-ui-styles/components/cardStyle.jsx";

const style = {
  "@keyframes blink": {
    from: { opacity: 0 },
    to: { opacity: 1 }
  },
  container: {
    animationName: "$blink"
  }
};

class Blinker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      in: true
    };
  }

  componentDidMount() {
    this.timerID = setInterval(() => {
      this.setState({
        in: !this.state.in
      });
    }, this.props.interval);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  render() {
    return <Fade in={this.state.in}>{this.props.children}</Fade>;
  }
}

export default withStyles(style)(Blinker);
