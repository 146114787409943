/* eslint-disable */
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import Button from "components/CustomButtons/Button.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import profilePageStyle from "assets/jss/material-ui-styles/views/profilePageStyle.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";

import { connect } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import DoneAll from "@material-ui/icons/DoneAll";
import SectionChat from "components/Chat/SectionChat.jsx";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { handleGeneralError } from "helpers/errorHelper.js";
const userServices = require("services/userservices");

let background = "/img/texture-blue.jpg";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class ProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      userData: null,
      loading: true,
      showLoading: false,
      openSuccessModal: false
    };

    let rnd = Math.floor(Math.random() * 3);

    if (rnd == 0) background = "/img/texture-blue.jpg";
    if (rnd == 1) background = "/img/texture-red.jpg";
    if (rnd == 2) background = "/img/texture-yellow.jpg";
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    userServices
      .getUser(this.props.user.uid)
      .then(userData => {
        if (!userData.displayName || userData.displayName == "")
          userData.displayName = this.props.user.displayName;

        if (!userData.displayName || userData.displayName == "")
          userData.displayName = this.props.user.name;

        if (!userData.email || userData.email == "")
          userData.email = this.props.user.email;

        this.setState({
          loading: false,
          userData: userData
        });
      })
      .catch(e => {
        handleGeneralError(this.props.user, e, this.props.history);
      });
  }

  handleEmailChange = event => {
    this.state.userData.email = event.target.value;
    this.setState({ userData: this.state.userData });
  };

  handleTelephoneChange = event => {
    this.state.userData.telephone = event.target.value;
    this.setState({ userData: this.state.userData });
  };

  handleDisplayNameChange = event => {
    this.state.userData.displayName = event.target.value;
    this.setState({ userData: this.state.userData });
  };

  handleAboutMeChange = event => {
    this.state.userData.aboutMe = event.target.value;

    this.setState({ userData: this.state.userData });
  };

  handleAgeChange = event => {
    this.state.userData.age = event.target.value;

    this.setState({ userData: this.state.userData });
  };

  handleSchoolChange = event => {
    this.state.userData.school = event.target.value;

    this.setState({ userData: this.state.userData });
  };

  handleBrothersChange = event => {
    this.state.userData.brothers = event.target.value;

    //tmb guardo pq no  le pongo blur...
    this.setState({ userData: this.state.userData }, this.saveUserProfile());
  };

  handleParentNameChange = event => {
    this.state.userData.parentName = event.target.value;

    this.setState({ userData: this.state.userData });
  };

  handleParentEmailChange = event => {
    this.state.userData.parentEmail = event.target.value;

    this.setState({ userData: this.state.userData });
  };

  saveUserProfile = (e, showModal) => {
    if (showModal) this.setState({ showLoading: true });

    userServices
      .upsertUser(this.state.userData)
      .then(() => {
        if (showModal)
          this.setState({ showLoading: false, openSuccessModal: true });
      })
      .catch(e => {
        console.log(e);
        if (showModal)
          this.setState({ showLoading: false, openSuccessModal: false });
      });
  };

  render() {
    const { classes, ...rest } = this.props;
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRoundedCircle,
      classes.imgFluid
    );

    var pageContent = (
      <div>
        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: classes.modal
          }}
          open={this.state.openSuccessModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => {
            this.setState({ openSuccessModal: false });
            this.props.history.push("/home");
          }}
          aria-labelledby="notice-modal-slide-title"
          aria-describedby="notice-modal-slide-description"
        >
          <DialogTitle
            id="notice-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h4 className={classes.modalTitle}>
              Gracias por completar tus datos así empezamos a conocernos.
            </h4>
          </DialogTitle>
          <DialogContent
            id="notice-modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer justify="center">
              <GridItem xs={1} sm={1} md={1}>
                <DoneAll />
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>

        <Header
          authenticatedUser={this.props.user}
          links={
            <HeaderLinks
              dropdownHoverColor="info"
              authenticatedUser={this.props.user}
            />
          }
          fixed
          color="transparent"
          forceTransparent={true}
          changeColorOnScroll={{
            height: 50,
            color: "white"
          }}
          {...rest}
        />
        <Parallax
          image={background}
          filter="dark"
          className={classes.parallax}
        />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <Dialog
              classes={{
                root: classes.modalRoot,
                paper: classes.modal
              }}
              open={this.state.showLoading}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => {}}
              aria-labelledby="notice-modal-slide-title"
              aria-describedby="notice-modal-slide-description"
            >
              <DialogContent
                id="notice-modal-slide-description"
                className={classes.modalBody}
              >
                <CircularProgress className={classes.progress} />
              </DialogContent>
            </Dialog>

            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img
                      src={
                        this.props.user.photoURL != null
                          ? this.props.user.photoURL + "?type=large"
                          : require("assets/img/unknown_avatar.png")
                      }
                      alt="avatar"
                      className={imageClasses}
                    />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>
                      {this.props.user && this.props.user.displayName}
                    </h3>
                  </div>
                </div>
              </GridItem>
            </GridContainer>

            <h2 className={classes.title}>
              Contanos un poco m&aacute;s de vos
            </h2>
            <GridContainer justify="center">
              <GridItem md={6} sm={12}>
                <form>
                  <CustomInput
                    labelText="Tu nombre"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => {
                        this.handleDisplayNameChange(e);
                      },
                      onBlur: e => {
                        this.saveUserProfile(e);
                      },
                      id: "profileDisplayName",
                      value: this.state.userData
                        ? this.state.userData.displayName
                        : ""
                      //value: this.props.user ? this.props.user.displayName : ""
                    }}
                  />
                  <CustomInput
                    labelText="Email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => {
                        this.handleEmailChange(e);
                      },
                      onBlur: e => {
                        this.saveUserProfile(e);
                      },
                      id: "profileEmail",
                      value: this.state.userData
                        ? this.state.userData.email
                        : ""
                      //value: this.props.user ? this.props.user.email : ""
                    }}
                  />
                  <CustomInput
                    labelText="Teléfono"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => {
                        this.handleTelephoneChange(e);
                      },
                      onBlur: e => {
                        this.saveUserProfile(e);
                      },
                      id: "profileTelephone",
                      value: this.state.userData
                        ? this.state.userData.telephone
                        : ""
                    }}
                  />

                  <CustomInput
                    labelText="Contanos algo sobre vos (hobbies, intereses, etc)"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      id: "profileAboutMe",
                      onChange: e => {
                        this.handleAboutMeChange(e);
                      },
                      onBlur: e => {
                        this.saveUserProfile(e);
                      },
                      multiline: true,
                      rows: 5,
                      value: this.state.userData
                        ? this.state.userData.aboutMe
                        : ""
                    }}
                  />
                </form>
              </GridItem>
              <GridItem md={6} sm={12}>
                <form>
                  <CustomInput
                    labelText="Edad"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => {
                        this.handleAgeChange(e);
                      },
                      onBlur: e => {
                        this.saveUserProfile(e);
                      },

                      value: this.state.userData ? this.state.userData.age : ""
                    }}
                  />
                  <CustomInput
                    labelText="Colegio"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => {
                        this.handleSchoolChange(e);
                      },
                      onBlur: e => {
                        this.saveUserProfile(e);
                      },
                      value: this.state.userData
                        ? this.state.userData.school
                        : ""
                    }}
                  />

                  <CustomInput
                    labelText="Nombre de tu papá o mamá"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => {
                        this.handleParentNameChange(e);
                      },
                      onBlur: e => {
                        this.saveUserProfile(e);
                      },
                      value: this.state.userData
                        ? this.state.userData.parentName
                        : ""
                    }}
                  />

                  <CustomInput
                    labelText="Email de tu papá o mamá"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => {
                        this.handleParentEmailChange(e);
                      },
                      onBlur: e => {
                        this.saveUserProfile(e);
                      },
                      value: this.state.userData
                        ? this.state.userData.parentEmail
                        : ""
                    }}
                  />

                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Cantidad de hermanos
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={
                        this.state.userData ? this.state.userData.brothers : 0
                      }
                      onChange={e => this.handleBrothersChange(e)}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select"
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        Cantidad de hermanos
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value="0"
                      >
                        Ninguno
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value="1"
                      >
                        Uno
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value="2"
                      >
                        Dos
                      </MenuItem>

                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value="3"
                      >
                        Tres
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value="4"
                      >
                        Más de tres
                      </MenuItem>
                    </Select>
                  </FormControl>
                </form>
              </GridItem>
            </GridContainer>

            <GridContainer justify="center">
              <GridItem md={12} sm={12}>
                <div className={classes.textCenter}>
                  <Button
                    color="primary"
                    round
                    onClick={e => {
                      this.saveUserProfile(e, true);
                    }}
                  >
                    Guardar
                  </Button>
                </div>
              </GridItem>
            </GridContainer>

            <GridContainer justify="center">
              <GridItem md={8} sm={12}>
                <h2 className={classes.title}>
                  Enviale un mensaje a tu mentor
                </h2>
                <SectionChat
                  title={<h3 className={classes.title}>Nuevo mensaje:</h3>}
                  auth={this.props.user}
                  receiverUid={""}
                  receiverEmail={"info@woiro.com"}
                  receiverAvatar={""}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>

        <Footer />
      </div>
    );

    return pageContent;
  }
}

const maptStateToProps = state => ({
  user: state.auth
});

export default connect(maptStateToProps)(
  withStyles(profilePageStyle)(ProfilePage)
);
