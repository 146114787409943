import { ADD_NOTIFICATION, DELETE_NOTIFICATION } from "./types";

export default (state = [], action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return [...state, action.payload];
    case DELETE_NOTIFICATION:
      return state.filter(
        notification => notification.uid != action.payload.uid
      );
    default:
      return state;
  }
};
