import React from "react";
import ProgressStepPageContainer from '../ProgressStepPage/ProgressStepPageContainer'
import withStyles from "@material-ui/core/styles/withStyles";
import headersStyle from "assets/jss/material-ui-styles/views/sectionsSections/headersStyle.jsx";
import { addStepToProgress } from '../../redux/progress/actions';
import { connect } from "react-redux";
import SectionLineLife from "./Sections/SectionLifeLine";
import Button from "components/CustomButtons/Button.jsx";
import Interpretation from "components/Tests/Interpretation.jsx";

class ProgressStepTestContainer extends ProgressStepPageContainer {
  constructor(props) {
    super(props);
  }

  getStateToSave() {
    let infoToDisplay = this.state.events.reduce((previous, event) => {
      return [...previous, `${event.year}: ${event.text}`]
    }, []);

    return { ...this.state, infoToDisplay };
  }

  executeCustomComponentDidMount() {
  }

  getDefaultState() {
    return {
      events: [],
      disableButton: false
    };
  }

  getInterpretationTitle() {
    return "Para reflexionar";
  }

  getInterpretationText() {
    return <p> La línea de vida es una representación de aquellos eventos que han marcado o tenido una influencia importante en tu historia de vida, desde que naciste hasta el día de hoy: momentos de cambio personal, experiencias importantes, logros, vínculos afectivos y proyecciones de momentos futuros.<br />
    Construir una línea de este tipo puede ayudarnos a entender qué visión tenemos de la vida, cuáles son nuestras prioridades, qué recuerdos conservamos de la infancia y cómo han podido influir en el presente, qué cambios hemos realizado a lo largo del tiempo, cómo vivimos en la actualidad y cómo nos planteamos el futuro.<br />
    La recapitulación de acontecimientos es necesaria para aprender de la historia y entender qué sucedió en un momento dado. Es importante comprender qué fue lo que pasó para poder así construir un futuro - lo que queremos que pase. La línea de vida nos ayuda a entender cómo llegamos hasta el momento presente; a recordar cuáles fueron las decisiones que se tomaron en cada instancia y qué nuevas situaciones y experiencias se desarrollaron a partir de ellas.<br /><br />
    Siempre tené en cuenta que tu pasado no te define como persona: lo más importante es reconocer tu historia, aceptarla, agradecer las cosas buenas, y aprender de las experiencias negativas, para que así te sirva de trampolín para proyectar tu futuro.</p>
  }

  getCloseButton() {
    return {
      action: () => { this.setState({ displayInterpretation: false }) },
      text: "Volver"
    };
  }

  getNextButton() {
    return {
      action: () => { this.setState({ displayInterpretation: false }, this.handleStepDone.bind(this)) },
      text: "Siguiente Paso"
    };
  }

  addStep() {
    this.setState({
      events: [
        ...this.state.events,
        { year: this.state.year, text: this.state.text }
      ],
      year: "",
      text: ""
    });
  }

  setValueInState(key, value) {
    this.setState({ [key]: value });
  }

  isValidYear(year) {
    return year >= 1900 && year <= new Date().getFullYear();
  }

  getButtons() {
    if (this.state.events.length >= 5) {
      const nextStepButton = <Button
        color="primary"
        size="lg"
        style={{ width: "100%" }}
        onClick={() => { this.setState({ displayInterpretation: true }) }}
        disabled={this.state.disableButton}
      >
        Siguiente Paso
    </Button>
      const addStepButton = <Button
        color="secondary"
        size="lg"
        style={{ width: "100%" }}
        onClick={this.addStep.bind(this)}
        disabled={
          !(this.state.text != "" && this.state.year != "" && this.isValidYear(this.state.year))
        }
      >
        Agregar
      </Button>

      return [addStepButton, nextStepButton];
    } else {
      const addStepButton = <Button
        color="primary"
        size="lg"
        style={{ width: "100%" }}
        onClick={this.addStep.bind(this)}
        disabled={
          !(this.state.text != "" && this.state.year != "" && this.isValidYear(this.state.year))
        }
      >
        Agregar
      </Button>
      return [addStepButton];
    }
  }

  getChildren() {
    return <div>
      <SectionLineLife
        classes={this.props.classes}
        year={this.state.year}
        text={this.state.text}
        steps={this.state.events}
        setValueInState={this.setValueInState.bind(this)}
        isValidYear={this.isValidYear}
      ></SectionLineLife>
      {this.state.displayInterpretation && <Interpretation
        text={this.getInterpretationText()}
        title={this.getInterpretationTitle()}
        close={this.getCloseButton()}
        next={this.getNextButton()}
      />}
    </div>;
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  progress: state.progress
});

const mapDispatchToProps = {
  addStepToProgress
}

export default withStyles(headersStyle)(connect(mapStateToProps, mapDispatchToProps)(ProgressStepTestContainer));