import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import styles from "../jss/sections/singleLineGridListStyle";
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Hidden from '@material-ui/core/Hidden';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

// import TrackVisibility from 'react-on-screen';

const homeServices = require("services/homeservices");

class SingleLineGridList extends React.Component {

    constructor(props) {
        super(props);

        let cols = 2;

        if (isWidthUp('xl', this.props.width)) {
            cols = 5;
        }
        else if (isWidthUp('lg', this.props.width)) {
            cols = 5;
        }
        else if (isWidthUp('md', this.props.width)) {
            cols = 3;
        }
        else if (isWidthUp('sm', this.props.width)) {
            cols = 3;

        }
        else if (isWidthUp('xs', this.props.width)) {
            cols = 2;

        }

        this.state = {
            cols: cols,
            colsToSlide: 1,
            isScrolling: false,
            stopPropagation: false
        };

        this.tileList = null;

        this.handleClick = direction => {
            this.tileList.scrollLeft += direction * (this.tileList.offsetWidth / this.state.cols) * this.state.colsToSlide;
        }

        this.setListRef = (element) => {
            this.tileList = ReactDOM.findDOMNode(element);
        };

        this.toggleScrolling = (isEnable) => {
            if (isEnable) {
                window.addEventListener('mousemove', this.onMouseMove);
                window.addEventListener('mouseup', this.onMouseUp);
            } else {
                window.removeEventListener('mousemove', this.onMouseMove);
                window.removeEventListener('mouseup', this.onMouseUp);
            }
        };

        this.onScroll = (event) => {
            // const setStopPropagation = () => this.timeout = setTimeout(() => {
            //     this.setState({
            //         stopPropagation: false
            //     })
            // }, 500);

            // console.log("ta andando...");
            // this.setState({ stopPropagation: true }, setStopPropagation);
        };

        this.onMouseMove = (event) => {
            const { clientX, scrollLeft, scrollTop, clientY } = this.state;
            this.tileList.scrollLeft = scrollLeft + (clientX + (event.clientX * -1));
            this.tileList.scrollTop = scrollTop - clientY + event.clientY;
        };

        this.timeout = null;

        this.onMouseUp = (event) => {

            let stopPropagation = false;
            let now = Date.now();

            if (now - mouseDownStart > 150) {
                stopPropagation = true;
            }
            this.setState({
                stopPropagation: stopPropagation,
                isScrolling: false,
                scrollLeft: 0, scrollTop: 0,
                clientX: 0, clientY: 0
            });
        };

        var mouseDownStart = null;
        this.onMouseDown = (event) => {
            mouseDownStart = Date.now();
            const { scrollLeft, scrollTop } = this.tileList;
            this.setState({ isScrolling: true, scrollLeft, scrollTop, clientX: event.clientX, clientY: event.clientY });
        };

        this.onClickCapture = (event) => {
            if (this.state.stopPropagation)
                event.stopPropagation();
        };
    }

    componentWillUpdate = (nextProps, nextState) => {
        if (this.state.isScrolling !== nextState.isScrolling) {
            this.toggleScrolling(nextState.isScrolling);
        }
    };

    handleVisibility(isVisible, element) {
        if (isVisible) {
            homeServices.setTrackedCareer(element).then(() => { })
        }
    }

    render() {
        const { classes } = this.props;

        //todo - responsive...
        let width = 0;
        let paddingLeft = 'inherit';
        let paddingRight = 'inherit';
        let chevronIconPositionLeft = 240;
        let chevronIconPositionTop = -50;

        let favIconPositionLeft = 125;
        let favIconPositionBottom = 135;

        let imgMinHeigth = 180;
        let gridListTileStyle = { height: 320, width: "60%", maxWidth: 180 };
        if (isWidthUp('xl', this.props.width)) {

            width = 1500;
            paddingLeft = 90;
            paddingRight = 90;
            chevronIconPositionLeft = 210;

            favIconPositionLeft = 125;
            favIconPositionBottom = 135;
            gridListTileStyle = { height: 320, maxWidth: 180 };
            imgMinHeigth = 180;
        }
        else if (isWidthUp('lg', this.props.width)) {

            width = 1500;
            paddingLeft = 90;
            paddingRight = 90;
            chevronIconPositionLeft = 210;

            favIconPositionLeft = 125;
            favIconPositionBottom = 135;
            gridListTileStyle = { height: 320, maxWidth: 180 };
            imgMinHeigth = 180;
        }
        else if (isWidthUp('md', this.props.width)) {

            width = 1000;
            paddingLeft = 80;
            paddingRight = 80;

            favIconPositionLeft = 125;
            favIconPositionBottom = 135;
            gridListTileStyle = { height: 320, maxWidth: 180 };
            imgMinHeigth = 180;
        }
        else if (isWidthUp('sm', this.props.width)) {

            width = 1200;
            paddingLeft = 80;
            paddingRight = 80;

            favIconPositionLeft = 125;
            favIconPositionBottom = 135;
            gridListTileStyle = { height: 320, width: "60%", maxWidth: 180 };
            imgMinHeigth = 180;

        }
        else if (isWidthUp('xs', this.props.width)) {

            width = 1500;
            paddingLeft = 110;
            paddingRight = 110;

            favIconPositionLeft = 120;
            favIconPositionBottom = 130;
            gridListTileStyle = { height: 320, width: "60%", maxWidth: 180 };
            imgMinHeigth = 180;
        }

        let isMobile = /Mobi/i.test(navigator.userAgent) || /Android/i.test(navigator.userAgent);
        //se necesita ocultar las barras solo en el caso que no sea mobile, no es por resolucion sino por tipo de dispositivo
        const listStyle = {
            //overflowX: isWidthUp('lg', this.props.width) ? "hidden" : "auto"
            //overflowX: isWidthUp('lg', width) ? "hidden" : "auto"
            overflowX: (isMobile) ? "auto" : "hidden"
        };

        const gridItemStyle = {
            width: width,
            paddingLeft: paddingLeft,
            paddingRight: paddingRight
        };

        // if (isMobileResolution)
        return (
            <div className={(classes.root)}
                style={{ backgroundColor: this.props.backgroundColor ? this.props.backgroundColor : '#ffffff' }}>
                <GridContainer justify={"center"} alignItems={"center"} direction={"row"}>
                    <Hidden only={["xs", "sm", "md"]}>
                        <GridItem xs={1} sm={1} md={1} lg={1} xl={1}>
                            <IconButton style={{
                                position: 'absolute', top: chevronIconPositionTop, left: chevronIconPositionLeft, zIndex: 10,
                                backgroundColor: '#999999a1'
                            }}>
                                <ChevronLeft onClick={() => this.handleClick(-1)} />
                            </IconButton>
                        </GridItem>
                    </Hidden>
                    <GridItem xs={4} sm={8} md={12} lg={10} xl={10}
                        style={gridItemStyle}>
                        <h3 style={{ textTransform: "capitalize" }}>{this.props.listDescription}</h3>


                        <GridList
                            id="gridList"
                            className={classes.gridList}
                            cols={this.state.cols}
                            style={listStyle}
                            ref={this.setListRef}
                            component="ul"
                            onMouseDown={this.onMouseDown}
                            onScroll={this.onScroll}
                        >
                            {this.props.elements.map((element, i) => {
                                let cardStyle = { marginTop: 0, cursor: "pointer" };
                                if (this.props.cardStyleColor) {
                                    cardStyle.color = this.props.cardStyleColor;
                                }

                                return <GridListTile
                                    draggable={false}
                                    component="li"
                                    key={i}
                                    onClick={element.handleTileClick}
                                    onClickCapture={e => this.onClickCapture(e)}
                                    style={gridListTileStyle} >
                                    <Card blog plain style={cardStyle}>
                                        <CardHeader image plain>
                                            <div>
                                                <div style={{
                                                    backgroundImage: `url(${element.imgSrc})`, height: 180, width: "100%", display: "block", backgroundSize: "auto 100%",
                                                    backgroundPositionX: "center", borderRadius: "6px"
                                                }}>
                                                    {/* <img src={element.imgSrc} alt="..." style={{ minHeight: imgMinHeigth }} /> */}
                                                </div>
                                                <div style={{ bottom: favIconPositionBottom, position: "absolute", left: favIconPositionLeft }}>

                                                    {element.actionIcon}</div>
                                            </div>
                                            <div
                                                className={classes.coloredShadow}
                                                style={{
                                                    backgroundImage: `url(${element.imgSrc})`,
                                                    opacity: "1"
                                                }}
                                            />
                                        </CardHeader>
                                        <CardBody plain>
                                            <h4 className={classes.cardCategory} style={{ padding: 0, margin: 0, textTransform: "capitalize", fontWeight: "bold" }}>{element.Name}</h4>

                                            <div className={classes.cardDescription} style={{ maxHeight: 50, overflowY: "hidden" }}>
                                                {element.DescriptionForList}
                                            </div>
                                        </CardBody>
                                    </Card>
                                </GridListTile>
                            })}
                        </GridList>
                    </GridItem>
                    <Hidden only={["xs", "sm", "md"]}>
                        <GridItem xs={1} sm={1} md={1} lg={1} xl={1}>
                            <IconButton style={{
                                position: 'absolute', top: chevronIconPositionTop, right: chevronIconPositionLeft, zIndex: 10,
                                backgroundColor: '#999999a1'
                            }}>
                                <ChevronRight onClick={() => this.handleClick(1)} />
                            </IconButton>
                        </GridItem>
                    </Hidden>
                </GridContainer>
            </div >
        );
    }
}

SingleLineGridList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withWidth()(withStyles(styles)(SingleLineGridList));
