import {
    section,
    container,
    cardTitle,
    coloredShadow,
    mlAuto,
    mrAuto
} from "assets/jss/material-ui-styles.jsx";

import customCheckboxRadioSwitch from "assets/jss/material-ui-styles/customCheckboxRadioSwitchStyle.jsx";

import tooltipsStyle from "assets/jss/material-ui-styles/tooltipsStyle.jsx";

const styles = {
    ...customCheckboxRadioSwitch,
    ...tooltipsStyle,
    coloredShadow,
    mlAuto,
    mrAuto,
    cardTitle: {
        ...cardTitle,
        textAlign: "center",
        marginBottom: "0px !important"
    },
    cardDescription: {
        color: "3e3d3d",
        textAlign: "center"
    },
    container: {
        ...container
    },
    description: {
        color: "3e3d3d"
    },
    section: {
        ...section,
        padding: "70px 0px"
    },
    priceContainer: {
        display: "inline-flex"
    },
    price: {
        fontSize: "18px",
        color: "#9a9a9a"
    },
    pullRight: {
        float: "right"
    },
    cardHeaderImage: {
        position: "relative",
        padding: "0",
        zIndex: "1",
        marginLeft: "15px",
        marginRight: "15px",
        marginTop: "-30px",
        borderRadius: "6px",
        "& img": {
            width: "100%",
            borderRadius: "6px",
            pointerEvents: "none"
        },
        "& a": {
            display: "block"
        }
    },
    justifyContentBetween: {
        WebkitBoxPack: "justify!important",
        justifyContent: "space-between !important"
    },
    customExpandPanel: {
        maxHeight: "273px",
        overflowY: "scroll",
        "&  label": {
            display: "flex"
        }
    },
    priceSlider: {
        fontWeight: "500"
    },
    refineButton: {
        margin: "-3px 0"
    },
    cardBodyRefine: {
        paddingLeft: "15px",
        paddingRight: "15px"
    },
    textLeft: {
        textAlign: "left"
    }
};

export default styles;
