import React, { Component } from "react";
import NotificationComponent from "../../components/Notification/NotificationComponent";
import { connect } from "react-redux";
import DetailPage from "./CareerListsAdminDetailPage";
const careerspageservices = require("services/careerspageservices");

class CareerListsAdminDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detail: {
        list: {}
      }
    };
  }

  saveList = list => {
    careerspageservices
      .upsertList(list)
      .then(() => {
        alert("ok");

        this.state.detail.list = list;
        this.setState({ detail: this.state.detail });
      })
      .catch(e => {
        console.log(e);
        alert("error");
      });
  };

  componentDidMount() {
    console.log("ASD:", this.props);
    //esto cachea !
    // if (this.props.location.state && this.props.location.state.list) {
    //   this.setState({
    //     detail: {
    //       list: this.props.location.state.list
    //     }
    //   });
    // } else {
    careerspageservices.getCareersPageLists().then(lists => {
      let uid = this.props.match.params.listuid;

      let list = lists.find(l => {
        return l.uid == uid;
      });
      this.setState({
        detail: {
          list: list
        }
      });
    });
    // }
  }

  render() {
    console.log(this.props);
    return (
      <div>
        <NotificationComponent />
        <DetailPage
          {...this.props}
          {...this.state}
          saveList={this.saveList.bind(this)}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CareerListsAdminDetailContainer);
