import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Media from "components/Media/Media.jsx";
import Button from "components/CustomButtons/Button.jsx";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import sectionCommentsStyle from "./sectionCommentsStyle.jsx";
import { connect } from "react-redux";

import CustomInput from "components/CustomInput/CustomInput.jsx";

import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import Reply from "@material-ui/icons/Reply";
import CircularProgress from "@material-ui/core/CircularProgress";
import DoneAll from "@material-ui/icons/DoneAll";
const topicServices = require("services/topicservices");
const forumServices = require("services/forumServices");
function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class SectionPostComments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openErrorModal: false,
      openSuccessModal: false,
      loading: false
    };
  }

  componentDidMount() {}

  postNewComment(event) {
    if (!this.props.auth || !this.props.auth.isLoggedIn) {
      window.location.href = "/login-page";
      return;
    }

    this.setState({
      loading: true
    });
    let FORUM_COMMUNITY = "COMMUNITY";

    forumServices
      .addPost(
        this.props.auth.uid,
        this.props.auth.name,
        this.props.auth.photoURL
          ? this.props.auth.photoURL + "?type=large"
          : "/img/unknown_avatar.png",
        FORUM_COMMUNITY,

        "",
        this.state.postReplyContent,
        null,
        this.props.topic.id
      )
      .then(() => {
        this.setState({
          loading: false,
          postReplyContent: "",
          openSuccessModal: true
        });
      })
      .catch(e => {
        console.log(e);

        this.setState({
          openErrorModal: true,
          loading: false
        });
      });
  }

  render() {
    let props = this.props;
    const { classes } = props;

    let topic = props.topic;

    if (this.state.loading)
      return (
        <div>
          <GridContainer justify="center">
            <GridItem xs={1} sm={1} md={1}>
              <CircularProgress className={classes.progress} />
            </GridItem>
          </GridContainer>
        </div>
      );
    return (
      <div className={classes.section}>
        {/* NOTICE MODAL START */}

        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: classes.modal
          }}
          open={this.state.openErrorModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => {
            this.setState({ openErrorModal: false });
          }}
          aria-labelledby="notice-modal-slide-title"
          aria-describedby="notice-modal-slide-description"
        >
          <DialogTitle
            id="notice-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h4 className={classes.modalTitle}>Hubo un error</h4>
          </DialogTitle>
          <DialogContent
            id="notice-modal-slide-description"
            className={classes.modalBody}
          >
            Por favor reintentá
          </DialogContent>
        </Dialog>

        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: classes.modal
          }}
          open={this.state.openSuccessModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => {
            this.setState({ openSuccessModal: false });
          }}
          aria-labelledby="notice-modal-slide-title"
          aria-describedby="notice-modal-slide-description"
        >
          <DialogTitle
            id="notice-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h4 className={classes.modalTitle}>¡Recibimos tu comentario!</h4>
          </DialogTitle>
          <DialogContent
            id="notice-modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer justify="center">
              <GridItem xs={1} sm={1} md={1}>
                <DoneAll />
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>

        {/* NOTICE MODAL END */}

        <GridContainer justify="center" id="topic-comments">
          <GridItem xs={12} sm={10} md={8}>
            {!this.props.onlyNewComment && (
              <div>
                {topic.total_replies ? (
                  <h3 className={classes.title}>
                    {topic.total_replies + " Comentarios"}
                  </h3>
                ) : (
                  <h3 className={classes.title}>{" Comentarios"}</h3>
                )}
                {topic.replies &&
                  topic.replies.map((reply, i) => {
                    let avatar_1;
                    let rex = /<img[^>]+src="([^">]+)/g;
                    avatar_1 = rex
                      .exec(
                        reply.author_avatar.replace(new RegExp("'", "g"), '"')
                      )[1]
                      .replace("#038;", "");

                    return (
                      <Media
                        key={i}
                        avatar={avatar_1}
                        title={
                          <span>
                            {reply.author_name}{" "}
                            <small>· {reply.post_date_since}</small>
                          </span>
                        }
                        body={
                          <p
                            className={classes.color555}
                            dangerouslySetInnerHTML={{ __html: reply.content }}
                          />
                        }
                        footer={
                          <div>
                            {/* <Tooltip
                          id="tooltip-tina"
                          title="Responder"
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <Button
                            color="primary"
                            simple
                            className={classes.footerButtons}
                          >
                            <Reply className={classes.footerIcons} /> Responder
                          </Button>
                        </Tooltip>
                        */}
                            <br />
                          </div>
                        }
                      />
                    );
                  })}
              </div>
            )}
            {this.props.onlyNewComment ? (
              <h3 className={classes.title}>
                Ayudanos a mejorar, escribinos tus ideas
              </h3>
            ) : (
              <h3 className={classes.title}>Compartí tu comentario</h3>
            )}
            <Media
              avatar={
                this.props.auth && this.props.auth.photoURL
                  ? this.props.auth.photoURL + "?type=large"
                  : "/img/unknown_avatar.png"
              }
              body={
                <CustomInput
                  labelText="Dejanos unas palabras"
                  id="postReplyContent"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.postReplyContent,
                    onChange: e =>
                      this.setState({ postReplyContent: e.target.value }),
                    multiline: true,
                    rows: 5
                  }}
                />
              }
              footer={
                <Button
                  color="primary"
                  round
                  className={classes.footerButtons}
                  onClick={event => this.postNewComment()}
                >
                  Responder
                </Button>
              }
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const maptStateToProps = state => ({
  user: state.auth
});

export default connect(maptStateToProps)(
  withStyles(sectionCommentsStyle)(SectionPostComments)
);
