import { FILTER_UNIVERSITIES_BY_NAME_AND_GROUP_CODE, FETCH_UNIVERSITIES } from 'views/UniversitiesPage/types/universitiesTypes';

var initialState = {
    all: [],
    filtered: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_UNIVERSITIES:
            return  {
                ...state,
                all: action.payload
            };
        case FILTER_UNIVERSITIES_BY_NAME_AND_GROUP_CODE:
            return  {
                ...state,
                filtered: action.payload
            };
        default:
            return state;
    }
};
