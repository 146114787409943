import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Header from "components/Header/Header.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Footer from "components/Footer/Footer.jsx";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import SectionFavorites from "views/CareersPage/Sections/SectionFavorites.jsx";
import SectionProducts from "views/CareersPage/Sections/SectionProducts.jsx";
import SectionSearch from "views/CareersPage/Sections/SectionSearch.jsx";

import Loading from "components/Loader/Loading.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";

import styles from "./jss/style.jsx";
import ChatBubbleOutline from "@material-ui/icons/ChatBubbleOutline";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import SectionProductsCards from "./Sections/SectionProductsCards.jsx";
import Build from "@material-ui/icons/Build";

import InfoArea from "components/InfoArea/InfoArea.jsx";
import SectionComments from "components/Topics/SectionComments.jsx";

import Button from "components/CustomButtons/Button.jsx";
import Tooltip from "@material-ui/core/Tooltip";
// import cardBlog3 from "assets/img/examples/card-blog3.jpg";

import Subject from "@material-ui/icons/Subject";

const cardBlog3 = "/img/texture-blue.jpg";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class CareersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoading: false
    };
  }

  showLoading() {
    this.setState({ showLoading: true });
  }

  hideLoading() {
    this.setState({ showLoading: false });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  goToCareerCreationPage() {
    this.props.history.push(`/create-career/new`);
  }

  render() {
    const { classes, ...rest } = this.props;

    let sortedLists = [];
    if (
      this.props.progress &&
      this.props.progress.data &&
      this.props.progress.data.favoriteLists
    ) {
      const favList = this.props.progress.data.favoriteLists;
      let filteredList = new Array();
      let restList = new Array();
      this.props.lists.forEach(l =>
        favList.findIndex(
          f => f.description.toLowerCase() == l.Description.toLowerCase()
        ) != -1
          ? filteredList.push(l)
          : restList.push(l)
      );

      sortedLists = filteredList.concat(restList);
    } else {
      //para el caso que no esté logueado...
      sortedLists = this.props.lists;
    }

    return (
      <div>
        <Header
          authenticatedUser={this.props.auth}
          links={<HeaderLinks authenticatedUser={this.props.auth} />}
          fixed
          color="transparent"
          forceTransparent={true}
          changeColorOnScroll={{
            height: 250,
            color: "white"
          }}
          {...rest}
        />
        <Parallax
          image={require("assets/img/backgrounds/background-careers.png")}
          filter="dark"
          className={classes.parallax}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem
                md={8}
                sm={8}
                className={classNames(
                  classes.mlAuto,
                  classes.mrAuto,
                  classes.textCenter
                )}
              >
                <h2 className={classes.mainTitle}>CARRERAS</h2>
                <h4 className={classes.subtitle}>
                  Explorá el mundo de alternativas que te ofrece la universidad.
                  ¡Tenemos más de 75 carreras para que conozcas! Primero vas a
                  encontrar las categorías que seleccionaste en el paso
                  anterior.
                </h4>
                <br />
                {/*<Button color="info" size="lg" round onClick={() => this.initJourney()} >Empezar</Button>*/}
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <Dialog
              classes={{
                root: classes.modalRoot,
                paper: classes.modal
              }}
              open={this.state.showLoading}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => { }}
              aria-labelledby="notice-modal-slide-title"
              aria-describedby="notice-modal-slide-description"
            >
              <DialogContent
                id="notice-modal-slide-description"
                className={classes.modalBody}
              >
                <CircularProgress className={classes.progress} />
              </DialogContent>
            </Dialog>

            {this.props.careersPageError ? (
              <div>
                <GridContainer justify="center">
                  <GridItem
                    xs={12}
                    sm={6}
                    md={6}
                    className={classNames(classes.mlAuto, classes.mrAuto)}
                  >
                    <div className={classes.textCenter}>
                      <h3 className={classes.title}>
                        Problemas para obtener tu información -(
                      </h3>
                      <p className={classes.description}>
                        {this.props.careersPageError.message}
                      </p>
                    </div>
                  </GridItem>
                </GridContainer>
              </div>
            ) : (
                <div>
                  {/* <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={6}>
                    <div className={classes.follow}>
                      <Tooltip
                        id="tooltip-top"
                        title="Seleccionar como favorito"
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <Button
                          onClick={() => {
                            this.handlePickMentor();
                          }}
                          justIcon
                          round
                          color="gray"
                          className={classes.followButton}
                        >
                          <i className="fas fa-star" style={{ color: "red" }} />
                          
                        </Button>
                      </Tooltip>
                    </div>
                  </GridItem>
                </GridContainer> */}

                  {this.props.lists == null ||
                    this.props.favoriteCareers == null ? (
                      <GridContainer justify="center" style={{ minHeight: 400 }}>
                        <GridItem xs={1} sm={1} md={1}>
                          <CircularProgress
                            className={classes.progress}
                            style={{ marginTop: 200 }}
                          />
                        </GridItem>
                      </GridContainer>
                    ) : (
                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          sm={6}
                          md={6}
                          className={classNames(classes.mlAuto, classes.mrAuto)}
                        >
                          <div className={classes.textCenter}>
                            {/* <h3 className={classes.title}>Buscador</h3> */}
                            {/* <p className={classes.description}>
                          Ingres&aacute; el nombre, una ubicaci&oacute;n
                          &oacute; un &aacute;rea de inter&eacute;s
                        </p> */}
                          </div>
                          <Card raised className={classes.cardSearch}>
                            <CardBody className={classes.cardBodySearch}>
                              <SectionSearch
                                careerDetail={this.props.careerDetail}
                                defaultItem={
                                  <div
                                    style={{
                                      content: "center"
                                    }}
                                  >
                                    <p
                                      className={classes.cardDescriptionWhite}
                                      style={{ marginTop: "10px" }}
                                    >
                                      ¿No encontrás la carrera que estás buscando? Podés sugerir que agreguemos
                                      la carrera que quieras. A Woiro lo
                                      construimos entre todos y queremos que seas
                                      tan parte como lo somos nosotros.
                                </p>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center"
                                      }}
                                    >
                                      <Button
                                        round
                                        color="primary"
                                        onClick={e => {
                                          this.goToCareerCreationPage();
                                        }}
                                      >
                                        Agregar
                                  </Button>
                                    </div>
                                  </div>
                                }
                              />
                            </CardBody>
                          </Card>
                        </GridItem>

                        <GridItem
                          xs={12}
                          sm={12}
                          md={12}
                          className={classNames(classes.mlAuto, classes.mrAuto)}
                        >
                          <div className={classes.textCenter}>
                            <h3 className={classes.title}>Mis favoritas</h3>
                            <p className={classes.description}>
                              Marcá como favoritas las carreras que te interesen y
                              tomate tu tiempo para conocerlas.
                        </p>
                          </div>
                          <SectionFavorites {...this.props} />
                        </GridItem>

                        {(this.props.isLoadingCareersList ||
                          this.props.isLoadingProgress) && (
                            <GridItem
                              xs={12}
                              sm={12}
                              md={12}
                              className={classNames(classes.mlAuto, classes.mrAuto)}
                            >
                              <Loading />
                            </GridItem>
                          )}

                        {!this.props.isLoadingCareersList &&
                          !this.props.isLoadingProgress &&
                          sortedLists &&
                          sortedLists.map((list, key) => {
                            let pushAddContent = null;
                            if (key == 3) {
                              pushAddContent = (
                                <Card
                                  background
                                  style={{ backgroundImage: `url(${cardBlog3})` }}
                                >
                                  <CardBody background>
                                    <a href="#" onClick={e => e.preventDefault()}>
                                      <h3 className={classes.cardTitleWhite}>
                                        Woiro-Wiki
                                  </h3>
                                    </a>
                                    <p className={classes.cardDescriptionWhite}>
                                      ¿No encontraste la carrera que buscabas? A
                                      Woiro lo construimos entre todos, animate a
                                      sugerir una nueva carrera.
                                </p>
                                    <Button
                                      round
                                      color="primary"
                                      onClick={e => {
                                        this.goToCareerCreationPage();
                                      }}
                                    >
                                      Agregar
                                </Button>
                                  </CardBody>
                                </Card>
                              );
                            }

                            if (list.Careers.length > 3) {
                              return (
                                <div>
                                  <GridItem
                                    key={key}
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    className={classNames(
                                      classes.mlAuto,
                                      classes.mrAuto
                                    )}
                                  >
                                    <SectionProducts
                                      {...this.props}
                                      list={list}
                                    />
                                  </GridItem>
                                  {pushAddContent}
                                </div>
                              );
                            } else if (list.Careers.length == 3) {
                              return (
                                <div>
                                  <GridItem key={key}>
                                    <SectionProductsCards
                                      {...this.props}
                                      careers={list.Careers}
                                      listDescription={list.Description}
                                      classes={null}
                                    />
                                  </GridItem>

                                  {pushAddContent}
                                </div>
                              );
                            }
                          })}
                      </GridContainer>
                    )}
                </div>
              )}
            <SectionComments
              onlyNewComment={true}
              topic={{ id: 664 }}
              auth={this.props.auth}
            />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(styles)(CareersPage);
