import React from "react";
import ProgressStepPageContainer from '../ProgressStepPage/ProgressStepPageContainer';
import withStyles from "@material-ui/core/styles/withStyles";
import headersStyle from "assets/jss/material-ui-styles/views/sectionsSections/headersStyle.jsx";
import { addStepToProgress } from '../../redux/progress/actions';
import { connect } from "react-redux";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import classNames from "classnames";
import PhoneInput, { isPossiblePhoneNumber, isValidPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';

class ProgressStepUserProfile extends ProgressStepPageContainer {
  constructor(props) {
    super(props);
  }

  getStateToSave() {
    let infoToDisplay = {
      "Nombre": this.state.name,
      "Email": this.state.email,
      "Teléfono": this.state.telephone,
      "Edad": this.state.age,
      "Localidad": this.state.city,
      "Colegio": this.state.school,
      "Why": this.state.why,
      "Hobbies": this.state.hobbies
    };
    return { ...this.state, infoToDisplay };
  }

  getDefaultState() {
    return {
      disableButton: true
    };
  }

  getChildren(classes) {
    return <GridContainer spacing={2} direction="column" justify="space-evenly" alignItems="center">
      <GridItem md={4} sm={4}>
        <CustomInput
          particularInputClasses={classNames(classes.input, classes.whiteInput)}
          formControlProps={{
            fullWidth: true,
            className: classes.customFormControlClasses
          }}
          inputProps={{
            value: this.state.name,
            onChange: e => this.setState({ name: e.target.value }, () => this.setState({ disableButton: this.checkInputs() })),
          }}
          labelText="Nombre y Apellido"
        />
      </GridItem>
      <GridItem md={4} sm={4}>
        <CustomInput
          particularInputClasses={classNames(classes.input, classes.whiteInput)}
          formControlProps={{
            fullWidth: true,
            className: classes.customFormControlClasses
          }}
          inputProps={{
            value: this.state.email,
            onChange: e => this.setState({ email: e.target.value }, () => this.setState({ disableButton: this.checkInputs() })),
          }}
          labelText="Email"
        />
      </GridItem>
      <GridItem md={4} sm={4}>
        {/* <PhoneInput
          international
          defaultCountry={this.props.country.id}
          placeholder="Celular"
          value={this.state.telephone}
          onChange={value => this.setState({ telephone: value })}
          inputComponent={this.telephoneRef}
        /> */}
        <CustomInput
          particularInputClasses={classNames(classes.input, classes.whiteInput)}
          formControlProps={{
            fullWidth: true,
            className: classes.customFormControlClasses
          }}
          inputProps={{
            value: this.state.telephone,
            onChange: e => this.setState({ telephone: e.target.value }, () => this.setState({ disableButton: this.checkInputs() })),
          }}
          labelText="Celular"
        />
      </GridItem>
      <GridItem md={4} sm={4}>
        <CustomInput
          particularInputClasses={classNames(classes.input, classes.whiteInput)}
          formControlProps={{
            fullWidth: true,
            className: classes.customFormControlClasses
          }}
          inputProps={{
            value: this.state.age,
            onChange: e => this.setState({ age: e.target.value }, () => this.setState({ disableButton: this.checkInputs() })),
          }}
          labelText="Edad"
        />
      </GridItem>
      <GridItem md={4} sm={4}>
        <CustomInput
          particularInputClasses={classNames(classes.input, classes.whiteInput)}
          formControlProps={{
            fullWidth: true,
            className: classes.customFormControlClasses
          }}
          inputProps={{
            value: this.state.city,
            onChange: e => this.setState({ city: e.target.value }, () => this.setState({ disableButton: this.checkInputs() })),
          }}
          labelText="Localidad"
        />
      </GridItem>
      <GridItem md={4} sm={4}>
        <CustomInput
          particularInputClasses={classNames(classes.input, classes.whiteInput)}
          formControlProps={{
            fullWidth: true,
            className: classes.customFormControlClasses
          }}
          inputProps={{
            value: this.state.school,
            onChange: e => this.setState({ school: e.target.value }, () => this.setState({ disableButton: this.checkInputs() })),
          }}
          labelText="Colegio"
        />
      </GridItem>
      <GridItem md={4} sm={4}>
        <CustomInput
          particularInputClasses={classNames(classes.input, classes.whiteInput)}
          formControlProps={{
            fullWidth: true,
            className: classes.customFormControlClasses
          }}
          inputProps={{
            multiline: true,
            rows: 3,
            value: this.state.why,
            onChange: e => this.setState({ why: e.target.value }, () => this.setState({ disableButton: this.checkInputs() })),
          }}
          labelText="¿Por qué elegiste hacer un proceso de orientación vocacional?"
        />
      </GridItem>
      <GridItem md={4} sm={4}>
        <CustomInput
          particularInputClasses={classNames(classes.input, classes.whiteInput)}
          formControlProps={{
            fullWidth: true,
            className: classes.customFormControlClasses
          }}
          inputProps={{
            multiline: true,
            rows: 5,
            value: this.state.hobbies,
            onChange: e => this.setState({ hobbies: e.target.value }, () => this.setState({ disableButton: this.checkInputs() })),
          }}
          labelText="Hobbies, intereses, etc."
        />
      </GridItem>
    </GridContainer >
  }

  checkInputs() {
    return !(this.state.name != "" &&
      this.state.email != "" &&
      this.state.telephone != "" &&
      this.state.age != "" &&
      this.state.city != "" &&
      this.state.school != "" &&
      this.state.why != "" &&
      this.state.hobbies != "");
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  progress: state.progress
});

const mapDispatchToProps = {
  addStepToProgress
}

export default withStyles(headersStyle)(connect(mapStateToProps, mapDispatchToProps)(ProgressStepUserProfile));