import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react component used to create nice image meadia player
import ImageGallery from "react-image-gallery";
import withStyles from "@material-ui/core/styles/withStyles";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
// core components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Footer from "components/Footer/Footer.jsx";
import Accordion from "components/Accordion/Accordion.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import productStyle from "assets/jss/material-ui-styles/views/productStyle.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Loading from "components/Loader/Loading.jsx";
import Button from "components/CustomButtons/Button.jsx";

import { NEW_CAREER_IDENTIFIER } from "services/careerservices";
class CareerAdminDetailPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showVideo: {},
      showFullscreenButton: true,
      showGalleryFullscreenButton: false,
      showPlayButton: true,
      showGalleryPlayButton: false,
      career: null
    };
  }

  innerSaveCareer() {
    this.state.career.uid = this.state.career.Id;

    this.props.saveCareer(this.state.career);
  }

  _resetVideo() {
    this.setState({ showVideo: {} });

    if (this.state.showPlayButton) {
      this.setState({ showGalleryPlayButton: true });
    }

    if (this.state.showFullscreenButton) {
      this.setState({ showGalleryFullscreenButton: true });
    }
  }

  _toggleShowVideo(url) {
    this.state.showVideo[url] = !Boolean(this.state.showVideo[url]);
    this.setState({
      showVideo: this.state.showVideo
    });

    if (this.state.showVideo[url]) {
      if (this.state.showPlayButton) {
        this.setState({ showGalleryPlayButton: false });
      }

      if (this.state.showFullscreenButton) {
        this.setState({ showGalleryFullscreenButton: false });
      }
    }
  }
  //generar componenete con los stylos
  _renderVideo(item) {
    return (
      <div className="image-gallery-image">
        {
          <div className="video-wrapper">
            <a
              className="close-video"
              onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
            />
            <iframe
              width="100%"
              height="250"
              src={item.embedUrl}
              frameBorder="0"
              allowFullScreen
            />
          </div>
        }
      </div>
    );
  }

  isCreatingCareer() {
    return this.state.career.uid == NEW_CAREER_IDENTIFIER;
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ career: nextProps.detail.career });
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    console.log(this.props);
    const { classes } = this.props;

    const collapse = [];

    if (this.state.career) {
      collapse.push({
        title: "Universidades Públicas",
        content: (
          <CustomInput
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.state.career.Publics,
              onChange: e => {
                this.state.career.Publics = e.target.value;
                this.setState({ career: this.state.career });
              }
            }}
          />
        )
      });
    }

    if (this.state.career) {
      collapse.push({
        title: "Universidades Privadas",
        content: (
          <CustomInput
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.state.career.Privates,
              onChange: e => {
                this.state.career.Privates = e.target.value;
                this.setState({ career: this.state.career });
              }
            }}
          />
        )
      });
    }

    if (this.state.career) {
      collapse.push({
        title: "Otras instituciones",
        content: (
          <CustomInput
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.state.career.OtherIntitutions,
              onChange: e => {
                this.state.career.OtherIntitutions = e.target.value;
                this.setState({ career: this.state.career });
              }
            }}
          />
        )
      });
    }

    if (!this.state.career) return <Loading />;

    return (
      <div className={classes.productPage}>
        <Header
          authenticatedUser={this.props.auth}
          links={
            <HeaderLinks
              dropdownHoverColor="rose"
              authenticatedUser={this.props.auth}
            />
          }
          fixed
          color="transparent"
          forceTransparent={true}
          changeColorOnScroll={{
            height: 100,
            color: "white"
          }}
        />
        <Parallax
          image={require("assets/img/backgrounds/IPU-imagen-05.jpg")}
          filter="dark"
          className={classes.pageHeader}
        />
        <div className={classNames(classes.section, classes.sectionGray)}>
          <div className={classes.container}>
            <div className={classNames(classes.main, classes.mainRaised)}>
              <GridContainer>
                <GridItem md={12} sm={12}>
                  <div
                    onClick={() => {
                      this.goBack();
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <ChevronLeft style={{ float: "left" }} />
                    Volver
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem md={12} sm={12}>
                  <h2>Compartí tu información, agregá una nueva carrera</h2>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem md={6} sm={6}>
                  <ImageGallery
                    showFullscreenButton={false}
                    showPlayButton={false}
                    showNav={true}
                    additionalClass={classes.customImageGalery}
                    startIndex={0}
                    items={[
                      {
                        thumbnail: this.props.detail.career.DetailImage,
                        original: this.props.detail.career.DetailImage
                      }
                    ].concat(
                      this.props.detail.careerVideos.map(video => {
                        return {
                          ...video,
                          renderItem: this._renderVideo.bind(this)
                        };
                      })
                    )}
                    // items={this.props.detail.careerVideos.map(video => {
                    //     return { ...video, renderItem: this._renderVideo.bind(this) };
                    // })}
                    originalClass={classes.customImageGalery}
                    style={{ minHeight: 200 }}
                  />
                </GridItem>
                <GridItem md={6} sm={6}>
                  {this.state.career && (
                    <CustomInput
                      labelText="Nombre"
                      inputProps={{
                        value: this.state.career.Name,
                        onChange: e => {
                          this.state.career.Name = e.target.value;
                          this.setState({ career: this.state.career });
                        }
                      }}
                      formControlProps={{
                        fullWidth: false,
                        className: classes.customFormControlClasses
                      }}
                    />
                  )}
                  {this.state.career && (
                    <CustomInput
                      labelText="Resumen"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        multiline: true,
                        rows: 5,
                        value: this.state.career.ShortDescription,
                        onChange: e => {
                          this.state.career.ShortDescription = e.target.value;
                          this.setState({ career: this.state.career });
                        }
                      }}
                    />
                  )}

                  {this.state.career && (
                    <Accordion
                      activeColor="rose"
                      collapses={[
                        {
                          title: "Descripción",
                          content: (
                            <CustomInput
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                multiline: true,
                                rows: 5,
                                value: this.state.career.LongDescription,
                                onChange: e => {
                                  this.state.career.LongDescription =
                                    e.target.value;
                                  this.setState({ career: this.state.career });
                                }
                              }}
                            />
                          )
                        },

                        ...collapse
                      ]}
                    />
                  )}

                  <GridContainer className={classes.pullRight}>
                    <Button
                      onClick={e => {
                        this.innerSaveCareer();
                      }}
                      round
                      color="primary"
                    >
                      Guardar
                    </Button>
                  </GridContainer>
                </GridItem>

                {!this.isCreatingCareer() && (
                  <GridItem md={6} sm={6}>
                    <GridContainer className={classes.pullLeft}>
                      <GridItem md={12} sm={12}>
                        <CustomInput
                          labelText="DetailImage"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            value: this.state.career.DetailImage,
                            onChange: e => {
                              this.state.career.DetailImage = e.target.value;
                              this.setState({ career: this.state.career });
                            }
                          }}
                        />
                        <CustomInput
                          labelText="ListImage"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            value: this.state.career.ListImage,
                            onChange: e => {
                              this.state.career.ListImage = e.target.value;
                              this.setState({ career: this.state.career });
                            }
                          }}
                        />
                        <CustomInput
                          labelText="YoutubePlaylistId"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            value: this.state.career.YoutubePlaylistId,
                            onChange: e => {
                              this.state.career.YoutubePlaylistId =
                                e.target.value;
                              this.setState({ career: this.state.career });
                            }
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                )}
                {!this.isCreatingCareer() && (
                  <GridItem md={6} sm={6}>
                    <CustomInput
                      labelText="Area"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state.career.Area,
                        onChange: e => {
                          this.state.career.Area = e.target.value;
                          this.setState({ career: this.state.career });
                        }
                      }}
                    />

                    <CustomInput
                      labelText="Categories"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state.career.Categories,
                        onChange: e => {
                          this.state.career.Categories = e.target.value;
                          this.setState({ career: this.state.career });
                        }
                      }}
                    />

                    <CustomInput
                      labelText="DescriptionForList"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state.career.DescriptionForList,
                        onChange: e => {
                          this.state.career.DescriptionForList = e.target.value;
                          this.setState({ career: this.state.career });
                        }
                      }}
                    />

                    <CustomInput
                      labelText="ForumTopicId"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state.career.ForumTopicId,
                        onChange: e => {
                          this.state.career.ForumTopicId = e.target.value;
                          this.setState({ career: this.state.career });
                        }
                      }}
                    />

                    <CustomInput
                      labelText="People"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state.career.People,
                        onChange: e => {
                          this.state.career.People = e.target.value;
                          this.setState({ career: this.state.career });
                        }
                      }}
                    />

                    <CustomInput
                      labelText="SearchTags"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state.career.SearchTags,
                        onChange: e => {
                          this.state.career.SearchTags = e.target.value;
                          this.setState({ career: this.state.career });
                        }
                      }}
                    />
                  </GridItem>
                )}
              </GridContainer>
            </div>
            {this.props.detail.otherCareersByCategory.length > 0 && (
              <div className={classes.relatedProducts}>
                <h3 className={classNames(classes.title, classes.textCenter)}>
                  También te podría interesar:
                </h3>
                <GridContainer>
                  {this.props.detail.otherCareersByCategory.map(
                    (career, index) => {
                      return (
                        <GridItem key={index} sm={6} md={3}>
                          <Card
                            product
                            onClick={() => {
                              this.props.careerDetail(
                                career,
                                this.props.detail.otherCareersByCategory
                              );
                              window.scrollTo(0, 0);
                            }}
                          >
                            <CardHeader image>
                              {/* <a href=""> */}
                              <img
                                src={career.DetailImage}
                                alt="relatedCareer"
                              />
                              {/* </a> */}
                            </CardHeader>
                            <CardBody>
                              <h6 className={classes.cardCategory}>
                                {career.categoryLabel}
                              </h6>
                              <h4 className={classes.cardTitle}>
                                {career.Name}
                              </h4>
                              <div className={classes.cardDescription}>
                                {career.ShortDescription}
                              </div>
                            </CardBody>
                            <CardFooter
                              className={classes.justifyContentBetween}
                            >
                              <div className={classes.price} />
                              <div className={classes.stats} />
                            </CardFooter>
                          </Card>
                        </GridItem>
                      );
                    }
                  )}
                </GridContainer>
              </div>
            )}
            {this.props.detail.career &&
              this.props.detail.career.People &&
              this.props.detail.career.People.length > 0 && (
                <div className={classes.relatedProducts}>
                  <h3 className={classNames(classes.title, classes.textCenter)}>
                    Quienes también estudiaroasdn esto
                  </h3>
                  <GridContainer>
                    {this.props.detail.career.People.split(";")
                      .filter(c => c != "")
                      .map((person, index) => {
                        var p = person.split("|");
                        var personData = {
                          name: p[0],
                          alias: p[1] != "" && `(${p[1]})`,
                          description: p[2],
                          image: p[3]
                        };

                        return (
                          <GridItem xs={12} sm={3} md={3} key={index}>
                            <Card profile plain>
                              <CardAvatar profile plain>
                                <a
                                  href="javascript:void(0)"
                                  onClick={e => e.preventDefault()}
                                >
                                  <img
                                    src={`/img/other_people/${
                                      personData.image
                                    }`}
                                    alt="..."
                                    className={classes.img}
                                  />
                                </a>
                              </CardAvatar>
                              <CardBody>
                                <h4 className={classes.cardTitle}>
                                  {personData.name} {personData.alias}
                                </h4>
                                <p className={classes.description}>
                                  {personData.description}
                                </p>
                              </CardBody>
                            </Card>
                          </GridItem>
                        );
                      })}
                  </GridContainer>
                </div>
              )}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(productStyle)(CareerAdminDetailPage);
