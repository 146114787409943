import { FETCH_DETAIL } from './detailTypes';

var initialState = {
    university: {},
    universityVideos: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DETAIL:
            return {
                ...state,
                university: action.payload.university,
                universityVideos: action.payload.videos
            };
        default:
            return state;
    }
}