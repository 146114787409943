import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import sectionForumStyle from "../jss/sections/sectionForumStyle.jsx";

import SectionOwnDiscussions from "views/CommunityPage/Sections/SectionOwnDiscussions.jsx";

const topicservices = require("services/topicservices");

class SectionForum extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      topics: [],
      topicsLoaded: false
    };
  }

  topicDetail(topic) {
    this.props.history.push(`/topic/${topic.id}`);
  }

  componentDidMount() {
    topicservices
      .getTopics()
      .then(topics => {
        this.setState({
          topics: topics,
          topicsLoaded: true
        });
      })
      .catch(e => {
        this.setState({
          topics: [],
          topicsLoaded: false
        });
      });
  }

  render() {
    const { classes, ...rest } = this.props;
    return (
      <div className="cd-section">
        {this.state.topicsLoaded && (
          <div className={classes.projects}>
            <div className={classes.container}>
              <GridContainer>
                <GridItem
                  xs={12}
                  sm={8}
                  md={8}
                  className={`${classes.mlAuto} ${classes.mrAuto} ${
                    classes.textCenter
                  }`}
                >
                  <h2 className={classes.title}>Últimas novedades</h2>
                </GridItem>
              </GridContainer>

              <SectionOwnDiscussions
                topics={this.state.topics}
                topicDetail={this.topicDetail.bind(this)}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default withStyles(sectionForumStyle)(SectionForum);
