import React, { Component } from 'react'
import { connect } from 'react-redux';
import TopicComponent from './TopicPage'

class TopicContainer extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  render() {

    return (
      <div>
        <TopicComponent
          {...this.props}
          {...this.state} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  detail: state.detail
});


export default connect(mapStateToProps, {})(TopicContainer);