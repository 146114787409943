// SDK de Mercado Pago
// const mercadopago = require('mercadopago');
const appConfig = require("config/appConfig");
const axios = require("axios");

// // Agrega credenciales
// mercadopago.configure({
//     access_token: appConfig.mercadoPagoAccesToken
// });

// Crea un objeto de preferencia
let preference = (planConfig, userInfo) => {
  return {
    items: [
      {
        title: planConfig.name,
        unit_price: parseFloat(planConfig.price),
        currency_id: planConfig.currency_id,
        quantity: 1,
      },
    ],
    binary_mode: true,
    external_reference: userInfo.uid,
    // back_urls: {
    //     success: "https://site.woiro.com/ar/step-plans?status=success",
    //     failure: "https://site.woiro.com/ar/step-plans?status=failure"
    // },
    // auto_return: "approved",
    // payer: {
    //     email:
    // }
  };
};

export function getPreferencesMercadoPago(planConfig, countryCode, userInfo) {
  let promises = planConfig
    .filter((plan) => plan.mercadopago)
    .map((plan) => {
      let config = {
        headers: {
          "cache-control": "no-cache",
          "Content-Type": "application/json",
        },
      };

      return axios.post(
        `https://api.mercadolibre.com/checkout/preferences?access_token=${appConfig.mercadopago[countryCode].accessToken}`,
        preference(plan, userInfo),
        config
      );
    });

  return Promise.all([...promises])
    .then((result) => {
      return result.map((response) => response.data.init_point);
    })
    .catch((e) => console.log(e));
}
