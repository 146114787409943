import * as firebase from "firebase";

import { FirebaseConfig } from "../config/keys";
firebase.initializeApp(FirebaseConfig);

export const db = firebase.firestore();
const settings = {};
db.settings(settings);

export const storage = firebase.storage();

export const authRef = firebase.auth();
export const facebookProvider = new firebase.auth.FacebookAuthProvider();
export const twitterProvider = new firebase.auth.TwitterAuthProvider();
export const googleProvider = new firebase.auth.GoogleAuthProvider();