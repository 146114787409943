import * as firebase from "firebase";
const firebaseConfig = require("config/firebase");
const firestore = firebaseConfig.db;
const colRef = firestore.collection("progress");


export function updateUserProgress(userUid, fieldname, value) {
  return colRef
    .doc(userUid)
    .set({ [fieldname]: value }, { merge: true })
    .catch(err => console.log(err));
}

export function setTrackedCareer(career) {
  return new Promise((resolve, reject) => {
    console.log("TrackedList: ", career.Description);
  });
}

export function getUserProgress(userUid) {
  return colRef
    .doc(userUid)
    .get()
    .then(function (doc) {
      if (doc && doc.exists) {
        return doc.data();
      }
      return null;
    })
    .catch(err => {
      console.log(err);
      throw err;
    });
}

export function getSteps(userUid) {
  let steps = firestore.collection("steps")
    .get()
    .then((querySnapshot) => querySnapshot.docs.map(doc => {
      if (doc.exists)
        return doc.data();
      return {}
    }));

  let progress = userUid && colRef
    .doc(userUid)
    .get()
    .then(function (doc) {
      if (doc && doc.exists) {
        return doc.data();
      }
      return {};
    }) || new Promise((resolve) => resolve({}));

  return Promise.all([progress, steps])
    .then((result) => {
      let [progress, steps] = result;
      steps = steps.sort((a, b) => { return a.Order - b.Order; });

      steps.forEach((element, index) => {
        if (Object.keys(progress).length > 0) {
          element.isCompleted = progress.hasOwnProperty(element.Code);
          element.isCurrent = !element.isCompleted && steps[index - 1] && steps[index - 1].isCompleted;
          element.state = progress[element.Code]
        }
        else {
          element.isCompleted = false;
          element.isCurrent = element.Order == 1 ? true : false;
          element.state = {}
        }
      });
      return steps;
    })
    .catch(err => {
      console.log(err);
      throw err;
    });
}

export function groupSteps(steps) {
  let duplicatedSteps = [...steps];
  let cant = 2;
  let groupedSteps = new Array();
  while (duplicatedSteps.length > 0) {
    let aux = duplicatedSteps.slice(0, cant);
    duplicatedSteps.splice(0, cant);

    if (cant == 2) {
      cant = 3;
    } else {
      aux = aux.reverse();
      cant = 2;
    }
    groupedSteps.push(aux);
  }
  return groupedSteps;
}

export function deleteStep(uid, stepCode) {
  return colRef.doc(uid).update({
    [stepCode]: firebase.firestore.FieldValue.delete()
  })
}