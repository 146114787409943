import { FETCH_DETAIL } from './detailTypes';

var initialState = {
    career: {},
    careerVideos: [],
    otherCareersByCategory: [],
}

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DETAIL:
            return {
                ...state,
                career: action.payload.career,
                careerVideos: action.payload.videos,
                otherCareersByCategory: action.payload.list
            };
        default:
            return state;
    }
}