import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import productStyle from "../jss/sections/productStyle.jsx";

import InfoArea from "components/InfoArea/InfoArea.jsx";
import ChatBubbleOutline from "@material-ui/icons/ChatBubbleOutline";
import Group from "@material-ui/icons/Group";
import Mood from "@material-ui/icons/Mood";

import Book from "@material-ui/icons/Book";
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
import I18n from "../../../i18n";

function SectionText({ ...props }) {
  const { classes } = props;
  const imgClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h2 className={classes.title}><I18n t="LANDING_QUEHACEMOS_TITLE" /></h2>
          <h5 className={classes.description}>
            ¿No te gusta ninguna carrera? ¿Te gustan demasiadas carreras? <br />
            ¿Te gusta una pero no sabés de qué vas a poder trabajar si la
            elegís?
            <br />
            ¿Tenés miedo de elegir algo que no te guste?
          </h5>
          <br />
          <h5 className={classes.description}>
            En Woiro te invitamos a recorrer juntos un camino de aprendizaje y
            <br />
            de auto conocimiento, en el que vas a descubrir carreras nuevas,
            <br />
            profundizar sobre otras que ya conocías, pero sobre todo te vas a
            conocer más a vos mismo.
          </h5>
          <br />
          <h5 className={classes.description}>
            Nuestro objetivo es darte una metodología y herramientas para
            <br />
            ayudarte a lo largo de este proceso. A medida que vayas pasando cada
            <br />
            etapa vas a encontrar desde charlas con psicólogos, tests online,
            <br />
            testimonios de jóvenes profesionales, un mentor personal y mucho
            más.
          </h5>
          <br />
          <h5 className={classes.description}>
            Te deseamos lo mejor en este desafío de descubrir lo que te apasiona
            <br />y moviliza, que sin duda es lo que siempre te va a hacer más
            feliz.
          </h5>
        </GridItem>
      </GridContainer>
    </div>

    // <div className={classes.section}>
    //   <GridContainer justify="center">
    //     <GridItem xs={12} sm={8} md={8}>
    //       <h3 className={classes.title}>
    //         Woiro: la primera plataforma de orientación vocacional on-line donde
    //         lo importante sos vos y no los algoritmos.
    //       </h3>

    //       <GridContainer style={{ paddingTop: 30, paddingBottom: 40 }}>
    //         <GridItem md={6} sm={6}>
    //           <InfoArea
    //             style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 20 }}
    //             title="Información"
    //             description={
    //               <span>
    //                 <p>
    //                   Toda la información de carreras y facultades para decidir
    //                   tu futuro
    //                 </p>
    //               </span>
    //             }
    //             icon={Info}
    //             iconColor="rose"
    //           />
    //         </GridItem>
    //         <GridItem md={6} sm={6}>
    //           <InfoArea
    //             style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 20 }}
    //             title="Comunidad"
    //             description={
    //               <span>
    //                 <p>Testimonios de jóvenes profesionales</p>
    //               </span>
    //             }
    //             icon={ChatBubbleOutline}
    //             iconColor="rose"
    //           />
    //         </GridItem>
    //         <GridItem md={6} sm={6}>
    //           <InfoArea
    //             style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 20 }}
    //             title="Herramientas"
    //             description={
    //               <span>
    //                 <p>Conocemos las cosas que aceleran tu auto-conocimiento</p>
    //               </span>
    //             }
    //             icon={Build}
    //             iconColor="rose"
    //           />
    //         </GridItem>
    //         <GridItem md={6} sm={6}>
    //           <InfoArea
    //             style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 20 }}
    //             title="Nuestros profesionales"
    //             description={
    //               <span>
    //                 <p>Acompañamiento psicológico</p>
    //               </span>
    //             }
    //             icon={Mood}
    //             iconColor="rose"
    //           />
    //         </GridItem>
    //       </GridContainer>
    //       <br />

    //     </GridItem>
    //   </GridContainer>
    // </div>
  );
}

export default withStyles(productStyle)(SectionText);
