import {
  SET_UNIVERSITY,
  FETCH_UNIVERSITIES,
  FETCH_FAVORITE_UNIVERSITIES,
  FETCH_UNIVERSITIES_ERROR,
  ADD_UNIVERSITY_TO_FAVORITE,
  DELETE_UNIVERSITY_FROM_FAVORITE,
  FILTER_UNIVERSITIES,
  UPDATE_NEW_UNIVERSITY,
  SHOW_FILTERS,
  FORCE_SHOW_FILTERS,
  FETCH_USER_GEOLOCATION,
  FETCH_UNIVERSITIES_DISTANCES,
  FETCH_UNIVERSITIES_TRAVEL_DISTANCES,
  FILTER_UNIVERSITY_COUNTRY,
  FILTER_UNIVERSITIES_BY_NAME_AND_GROUP_CODE
} from "views/UniversitiesPage/types/universitiesTypes";
import universityReducer from "../reducers/universityReducer";
const universityServices = require("services/universityservices");
const userServices = require("services/userservices");
const geolocationServices = require("services/geolocationservices");

export const fetchUniversitiesAndDistances = () => dispatch => {
  universityServices
    .getUniversities()
    .then(snapshot => {
      var docs = [];
      snapshot.forEach(doc => {
        var newDoc = doc.data();
        docs.push(newDoc);
      });
      dispatch({
        type: FETCH_UNIVERSITIES,
        payload: {
          universities: docs,
          loadingUniversities: false
        }
      });

      geolocationServices.getUserGeolocation().then(result => {
        var sortedUniversities = geolocationServices.getUniversityDistances(
          result.coords,
          docs
        );

        sortedUniversities = sortedUniversities.sort(function(a, b) {
          return a.distance - b.distance;
        });

        dispatch({
          type: FETCH_UNIVERSITIES_DISTANCES,
          payload: {
            universities: sortedUniversities
          }
        });

        return; //ZARA MODE
        geolocationServices
          .getUniversityTravelDistances(result.coords, sortedUniversities)
          .then(result => {
            dispatch({
              type: FETCH_UNIVERSITIES_TRAVEL_DISTANCES,
              payload: {
                universities: result
              }
            });
          });
      });
    })
    .catch(err => {
      dispatch({
        type: FETCH_UNIVERSITIES_ERROR,
        payload: { error: err, loadingUniversities: false }
      });
    });
};

export const fetchFavoriteUniversities = userUid => dispatch => {
  return userServices.getSubcollection(userUid, "universities").then(data =>
    dispatch({
      type: FETCH_FAVORITE_UNIVERSITIES,
      payload: data
    })
  );
};

export const addUniversityToFavorite = (userUid, university) => dispatch => {
  return userServices.addUniversity(userUid, university);
};

export const deleteUniversityFromFavorite = (
  userUid,
  university
) => dispatch => {
  return userServices.deleteUniversity(userUid, university);
};

export const filterUniversities = (all, filters) => dispatch => {
  //console.log(all, filters);
  var filteredUniversities = all.filter(university => {
    for (var key in filters) {
      if (
        university[key] === undefined ||
        !operators[filters[key].operator](university[key], filters[key].value)
      ) {
        return false;
      }
    }
    return true;
  });

  dispatch({
    type: FILTER_UNIVERSITIES,
    payload: { filteredUniversities, filters }
  });
};

export const fetchUniversities = () => dispatch => {
  universityServices.getUniversities().then(snapshot => {
    var docs = [];
    snapshot.forEach(doc => {
      var newDoc = doc.data();
      docs.push(newDoc);
    });

    dispatch({
      type: FETCH_UNIVERSITIES,
      payload: docs
    });
  });
};

export const filterUniversitiesByNameAndGroupCode = (
  universities,
  valueToSearch
) => dispatch => {
  const regex = new RegExp(
    valueToSearch.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
    "i"
  );
  const filteredUniversities = universities.filter(university => {
    return (
      university.GroupCode.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .match(regex) ||
      university.Name.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .match(regex)
    );
  });

  dispatch({
    type: FILTER_UNIVERSITIES_BY_NAME_AND_GROUP_CODE,
    payload: filteredUniversities
  });
};

export const addUniversity = university => dispatch => {
  return universityServices.addUniversity(university);
};
export const updateUniversity = (value, field) => dispatch => {
  dispatch({
    type: UPDATE_NEW_UNIVERSITY,
    payload: {
      field,
      value
    }
  });
};

export const showFilters = value => dispatch => {
  dispatch({
    type: SHOW_FILTERS,
    payload: {
      value
    }
  });
};

export const forceShowFilters = value => dispatch => {
  dispatch({
    type: FORCE_SHOW_FILTERS,
    payload: {
      value
    }
  });
};

export const getUserGeolocation = () => dispatch => {
  return geolocationServices.getUserLocation().then(response => {
    dispatch({
      type: FETCH_USER_GEOLOCATION,
      payload: {
        response
      }
    });
  });
};

export const countryCodeChange = (all, value) => dispatch => {
  //console.log(all, filters);
  var filteredUniversities = all.filter(university => {
    let equal = false;

    university.sites.forEach(site => {
      if (
        site.geolocation !== undefined &&
        site.geolocation.countryCode !== undefined &&
        site.geolocation.countryCode == value
      ) {
        equal = true;
      }
    });

    return equal || value == "0"; //todas
  });

  //console.log(filtered);
  dispatch({
    type: FILTER_UNIVERSITY_COUNTRY,
    payload: { filteredUniversities, value }
  });
};

export const setUniversity = value => dispatch => {
  dispatch({
    type: SET_UNIVERSITY,
    payload: {
      value
    }
  });
};

export const operators = {
  "+": (a, b) => {
    return a + b;
  },
  "==": (a, b) => {
    return a == b;
  },
  ">": (a, b) => {
    return a > b;
  },
  ">=": (a, b) => {
    return a >= b;
  },
  "<": (a, b) => {
    return a < b;
  },
  "<=": (a, b) => {
    return a <= b;
  },
  "!=": (a, b) => {
    return a != b;
  },
  in: (a, b) => {
    return b.indexOf(a) != -1;
  },
  inStr: (a, b) => {
    if (b.lenght == 0 || (b.length == 1 && b[0] == "")) return true;

    let result = false;

    b.forEach(str => {
      if (
        a
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .indexOf(
            str
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
          ) != -1
      ) {
        result = true;
      }
    });

    return result;
  }, //si es vacio entonces es un string valido
  notin: (a, b) => {
    return b.indexOf(a) == -1;
  }
};
