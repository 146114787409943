import React from "react";
import ProgressStepPageContainer from '../ProgressStepPage/ProgressStepPageContainer';
import withStyles from "@material-ui/core/styles/withStyles";
import headersStyle from "assets/jss/material-ui-styles/views/sectionsSections/headersStyle.jsx";
import { addStepToProgress } from '../../redux/progress/actions';
import { connect } from "react-redux";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";

class ProgressStepShareContainer extends ProgressStepPageContainer {
  constructor(props) {
    super(props);
  }

  getDefaultState() {
    return {
      disableButton: false
    };
  }

  getButtons() {
    return [this.getButton("Ir a Mi Camino", false, () => this.props.history.push("home"))];
  }

  getChildren(classes) {
    return <GridContainer>
      <GridItem md={12}>
        <Button
          color="facebook"
          href=""
          round
          onClick={e => {
            window.open("https://www.facebook.com/woiroedu");
          }}
        >
          <i
            className={`fab fa-facebook ${
              classes.marginRight
              }`}
          />
                            Facebook
                          </Button>
        <Button
          color="instagram"
          href="#"
          round
          onClick={e => {
            window.open(
              "https://www.instagram.com/somoswoiro/"
            );
          }}
        >
          <i
            className={`fab fa-instagram ${
              classes.marginRight
              }`}
          />
                            Instagram
                          </Button>
        <Button
          color="twitter"
          href="#"
          round
          onClick={e => {
            window.open("https://twitter.com/somoswoiro");
          }}
        >
          <i
            className={`fab fa-twitter ${
              classes.marginRight
              }`}
          />
                            Twitter
                          </Button>
      </GridItem>
    </GridContainer>;
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  progress: state.progress
});

const mapDispatchToProps = {
  addStepToProgress
}

export default withStyles(headersStyle)(connect(mapStateToProps, mapDispatchToProps)(ProgressStepShareContainer));