const basicsStyle = {
  customRadioButtonSmall: {
    width: "32px !important", height: "32px !important", minWidth: "32px !important", lineHeight: "16px",
  },
  customRadioButtonPositive: {
    backgroundColor: "transparent !important", border: "4px solid #cd6d5e !important"
  },
  customRadioButtonPositiveChecked: {
    backgroundColor: "#cd6d5e !important", border: "4px solid #cd6d5e !important"
  },
  customRadioButton: {
    marginRight: "5px",
    backgroundImage: "none",
    boxSizing: "border-box",
    cursor: "pointer",
    //display: "block",
    float: "none",
    fontSize: "14px",
    fontWeight: "600",
    height: "40px",
    letterSpacing: "0.7px",
    lineHeight: "20px",
    marginBottom: "0px",
    marginLeft: "0px",
    maxWidth: "100%",
    outlineStyle: "none",
    outlineWidth: "0px",
    paddingBottom: "0px",
    paddingLeft: "0px",
    paddingRight: "5px",
    paddingTop: "0px",
    position: "relative",
    textAlign: "center",
    textSizeAdjust: "100%",
    textTransform: "uppercase",
    transitionDelay: "0s",
    transitionDuration: "0s",
    transitionProperty: "none",
    userSelect: "none",
    verticalAlign: "middle",
    whiteSpace: "nowrap",
    width: "40px",
  },
  chequedCustomRadioButton: {
    marginRight: "5px",
    backgroundImage: "none",
    boxSizing: "border-box",
    color: "secondary",
    cursor: "pointer",
    //display: "block",
    float: "none",
    fontSize: "14px",
    fontWeight: "600",
    height: "40px",
    letterSpacing: "0.7px",
    lineHeight: "20px",
    marginBottom: "0px",
    marginLeft: "0px",
    maxWidth: "100%",
    outlineStyle: "none",
    outlineWidth: "0px",
    paddingBottom: "0px",
    paddingLeft: "0px",
    paddingRight: "5px",
    paddingTop: "0px",
    position: "relative",
    textAlign: "center",
    textSizeAdjust: "100%",
    textTransform: "uppercase",
    transitionDelay: "0s",
    transitionDuration: "0s",
    transitionProperty: "none",
    userSelect: "none",
    verticalAlign: "middle",
    whiteSpace: "nowrap",
    width: "40px",
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'white !important'
  },
};

export default basicsStyle;
