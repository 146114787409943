import {
    section,
    container,
    cardTitle,
    title,
    coloredShadow,
    mlAuto
} from "assets/jss/material-ui-styles.jsx";

import tooltipsStyle from "assets/jss/material-ui-styles/tooltipsStyle.jsx";

const styles = {
    title,
    cardTitle,
    container,
    ...tooltipsStyle,
    section: {
        ...section,
        textAlign: "center",
        padding: "70px 0px"
    },
    coloredShadow,
    cardDescription: {
        color: "3e3d3d",
        textAlign: "center"
    },
    mlAuto,
    priceContainer: {
        display: "inline-flex"
    },
    price: {
        fontSize: "18px",
        color: "#9a9a9a"
    },
    priceOld: {
        fontSize: "16px",
        textDecoration: "line-through"
    },
    priceNew: {
        color: "#f44336"
    },
    stats: {
        color: "3e3d3d"
    },
    textCenter: {
        textAlign: "center"
    }
};

export default styles;
