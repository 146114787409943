import React from "react";
import {
  ADD_CAREER_TO_FAVORITE,
  DELETE_CAREER_FROM_FAVORITE,
  FETCH_FAVORITE_CAREERS,
  FETCH_FAVORITE_CAREERS_START
} from "./types";
import { ADD_NOTIFICATION } from "../notifications/types";
import {
  addFavoriteCareerToStep,
  deleteFavoriteCareerFromStep,
  getFavoriteCareersFromLastStep,
  getFavoriteCareersFromStep
} from "../../services/favoriteCareersServices";
import uuid from "uuid";
import { FIRST_STEP_TEN_CAREERS } from "../../services/favoriteCareersServices";

export const addFavoriteCareer = (
  userUID,
  step,
  career,
  selectedCount,
  requiredCount
) => dispatch => {
  return addFavoriteCareerToStep(userUID, step, career)
    .then(() => {
      dispatch({
        type: ADD_CAREER_TO_FAVORITE,
        payload: career
      });
      const uid = uuid.v4();

      dispatch({
        type: ADD_NOTIFICATION,
        payload: {
          message:
            selectedCount && requiredCount - selectedCount >= 0 ? ( //si se informo el count y si le faltan carreras por elegir
              <div>
                <b>{career.Name}</b>{" "}
                {" agregada a tus Favoritas. Te faltan elegir: "}
                <b>{(requiredCount - selectedCount).toString()} </b>
              </div>
            ) : (
              <div>
                <b>{career.Name}</b> {" agregada a tus Favoritas."}
              </div>
            ),
          uid,
          timeout: 2500
        }
      });
    })
    .catch(e => {
      const uid = uuid.v4();
      dispatch({
        type: ADD_NOTIFICATION,
        payload: {
          message: `No se pudo agregar la carrera ${career.Name} a la lista de favoritas`,
          uid,
          timeout: 2500
        }
      });
    });
};

export const deleteFavoriteCareer = (userUID, step, career) => dispatch => {
  return deleteFavoriteCareerFromStep(userUID, step, career)
    .then(() => {
      dispatch({ type: DELETE_CAREER_FROM_FAVORITE, payload: career });
      const uid = uuid.v4();
      dispatch({
        type: ADD_NOTIFICATION,
        payload: {
          message: (
            <div>
              <b>{career.Name}</b> {" fue eliminada"}
            </div>
          ),
          uid,
          timeout: 2500
        }
      });
    })
    .catch(e => {
      const uid = uuid.v4();
      dispatch({
        type: ADD_NOTIFICATION,
        payload: {
          message: `No se pudo eleminar la carrera ${career.Name} de la lista de favoritas`,
          uid,
          timeout: 2500
        }
      });
    });
};

export const fetchFavoriteCareersFromStep = (userUID, step) => dispatch => {
  dispatch({ type: FETCH_FAVORITE_CAREERS_START });
  return getFavoriteCareersFromStep(userUID, step)
    .then(r =>
      dispatch({
        type: FETCH_FAVORITE_CAREERS,
        payload: {
          favoriteCareers: r.careers,
          lastStep: r.step
        }
      })
    )
    .catch(e => {
      const uid = uuid.v4();
      dispatch({
        type: ADD_NOTIFICATION,
        payload: {
          message: `No pudimos cargar tus carreras favoritas`,
          uid,
          timeout: 2500
        }
      });
    });
};
