const eventsHelper = require("helpers/eventsHelper");

export const handleGeneralError = (auth, error, history) => {
  eventsHelper.registerEvent(auth, "E", error, {
    message: error.message,
    code: error.code
  });

  window.lastError = error;
  history.push("/error");
};
