import React, { Component } from "react";
import { Router, Route, Switch } from "react-router";
import { Redirect } from "react-router-i18n";
import indexRoutes from "routes/index.jsx";
import requiereAuth from "./components/Auth/RequireAuth";
import { Provider } from "react-redux";
import IpuLoading from "./components/Loader/IpuLoading";
import { connect } from "react-redux";
import { fetchProgress } from "./redux/progress/actions";
import { fetchCountry } from "./redux/country/actions";
import { fetchUser } from "./actions/authActions";

class App extends Component {
  componentDidMount() {
    this.props.fetchUser();
    this.props.fetchCountry();
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth != prevProps.auth) {
      this.props.fetchProgress(this.props.auth.uid, this.props.auth.plan);
    }
  }

  render() {
    return (
      <Provider store={this.props.store}>
        {(this.props.auth &&
          this.props.country.isReady &&
          this.props.progress.isReady && (
            <Router history={this.props.history}>
              <Switch>
                <Route
                  exact
                  path="/ar"
                  render={() => (window.location = `https://hola.woiro.com/ar`)}
                />
                <Route
                  exact
                  path="/cl"
                  render={() => (window.location = `https://hola.woiro.com/cl`)}
                />
                {indexRoutes.map((prop, key) => {
                  var component = prop.requireAuth
                    ? requiereAuth(prop.component)
                    : prop.component;
                  return (
                    <Route
                      exact
                      path={prop.path}
                      key={key}
                      component={component}
                    />
                  );
                })}
                <Redirect
                  from="*"
                  to={`/${this.props.country.code || "ar"}/`}
                />
              </Switch>
            </Router>
          )) || <IpuLoading />}
      </Provider>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  progress: state.progress,
  country: state.country,
});

const mapDispatchToProps = {
  fetchUser,
  fetchProgress,
  fetchCountry,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
