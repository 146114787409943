import {
  ADD_CAREER_TO_FAVORITE,
  ADD_CAREER_TO_FAVORITE_ERROR,
  DELETE_CAREER_FROM_FAVORITE,
  DELETE_CAREER_FROM_FAVORITE_ERROR,
  FETCH_FAVORITE_CAREERS_START,
  FETCH_FAVORITE_CAREERS,
  FETCH_FAVORITE_CAREERS_ERROR
} from "./types";

import { FIRST_STEP_TEN_CAREERS } from "../../services/favoriteCareersServices";

const initialState = {
  list: [],
  errors: [],
  lastStep: FIRST_STEP_TEN_CAREERS,
  isLoadingFavoriteCareers: false,
  count: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_CAREER_TO_FAVORITE:
      return {
        ...state,
        list: [...state.list, action.payload],
        count: state.count + 1
      };
    case ADD_CAREER_TO_FAVORITE_ERROR:
      return { ...state, errors: [...state.errors, action.payload] };
    case DELETE_CAREER_FROM_FAVORITE:
      return {
        ...state,
        list: state.list.filter(c => c.Id != action.payload.Id),
        count: state.count - 1
      };
    case DELETE_CAREER_FROM_FAVORITE_ERROR:
      return { ...state, errors: [...state.errors, action.payload] };
    case FETCH_FAVORITE_CAREERS_START:
      return {
        ...state,
        isLoadingFavoriteCareers: true
      };
    case FETCH_FAVORITE_CAREERS:
      return {
        ...state,
        list: action.payload.favoriteCareers,
        count: action.payload.favoriteCareers.length,
        lastStep: action.payload.lastStep,
        isLoadingFavoriteCareers: false
      };
    case FETCH_FAVORITE_CAREERS_ERROR:
      return { ...state, errors: [...state.errors, action.payload] };
    default:
      return state;
  }
};
