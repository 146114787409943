/* eslint-disable */
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import Button from "components/CustomButtons/Button.jsx";

import profilePageStyle from "assets/jss/material-ui-styles/views/profilePageStyle.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";

import { connect } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import DoneAll from "@material-ui/icons/DoneAll";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import Table from "components/Table/Table.jsx";
import Loading from "components/Loader/Loading.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import Email from "@material-ui/icons/Email";
import Edit from "@material-ui/icons/Edit";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {
  getFavoriteCareersFromStep,
  SECOND_STEP_FIVE_CAREERS,
  FIRST_STEP_TEN_CAREERS,
  THIRD_STEP_THREE_CAREERS,
  FINAL_STEP_SINGLE_CAREER,
} from "services/favoriteCareersServices";
import NavPills from "components/NavPills/NavPills.jsx";
import Palette from "@material-ui/icons/Palette";
import People from "@material-ui/icons/People";
const notificationServices = require("services/notificationservices");

const userServices = require("services/userservices");
const homeServices = require("services/homeservices");

let background = "/img/texture-blue.jpg";

import groupBy from "lodash/groupBy";

function getStepComponent({ steps, userData, isAdmin }) {
  if (!userData || !steps) return;

  return (
    <GridContainer>
      <GridItem>
        <p>Pais: {userData.countryCode}</p>
        <p>Nombre: {userData.displayName}</p>
        <p>Email: {userData.email}</p>
        <p>Teléfono: {userData.telephone}</p>
      </GridItem>
      {stepsData(
        steps.filter((step) => step.isCompleted),
        userData,
        isAdmin
      )}
    </GridContainer>
  );
}

function stepsData(steps, userData, isAdmin) {
  return steps
    .filter((step) => step.isCompleted)
    .map((step) => {
      return (
        <GridItem>
          <h4>
            <b>
              {step.Name} |{" "}
              {step.state &&
                step.state.timestamp &&
                step.state.timestamp.toDate().toLocaleString("es-AR")}
            </b>{" "}
            <a
              href="javascript:void(0)"
              onClick={
                isAdmin ? () => deleteStep(userData.uid, step.Code) : undefined
              }
            >
              Eliminar paso
            </a>
          </h4>
          {step.state.infoToDisplay &&
            Object.keys(step.state.infoToDisplay).map((key) => {
              return <p>{JSON.stringify(step.state.infoToDisplay[key])}</p>;
            })}
          {step.state &&
            Object.keys(step.state).length > 1 &&
            !step.state.infoToDisplay &&
            getStepData(step)}
        </GridItem>
      );
    });
}

function deleteStep(uid, stepCode) {
  homeServices.deleteStep(uid, stepCode);
}

function getStepData(step) {
  let data;
  if (step.Code == "STEP_CATEGORIES") {
    data = step.state.categories
      .filter((category) =>
        step.state.categoriesSelected.includes(category.uid)
      )
      .map((category) => category.Description);
  }

  if (step.Code == "STEP_FEELING") {
    data = step.state.careers.map((career) => career.Name);
  }

  if (step.Code == "STEP_TEST") {
    data = step.state.events.map((event) => `${event.year}: ${event.text}`);
  }

  if (step.Code == "STEP_EXPERIENCE") {
    data = [step.state.selectedExperience.Name, step.state.input];
  }

  if (step.Code == "STEP_PROFILE") {
    data = {
      Nombre: step.state.name,
      Email: step.state.email,
      Teléfono: step.state.telephone,
      Edad: step.state.age,
      Localidad: step.state.city,
      Colegio: step.state.school,
      Why: step.state.why,
      Hobbies: step.state.hobbies,
    };
  }

  if (step.Code == "STEP_INCOMPLETE_PRHASES") {
    (step.state.questions || []).forEach((question, index) => {
      question.Text = step.state.responses[index];

      if (index == 42) {
        question.Text = step.state.text;
      }
    });

    const groupedQuestions = groupBy(step.state.questions || [], "GroupCode");
    data = Object.keys(groupedQuestions).map((key) => {
      return {
        [key]: groupedQuestions[key].reduce((previous, actual) => {
          return [...previous, actual.Text];
        }, []),
      };
    });
  }

  if (step.Code == "STEP_IAMI") {
    data = (step.state.results || []).reduce((previous, result) => {
      return [...previous, `${[result.Code]}: ${result.Value}`];
    }, []);
  }

  if (step.Code == "STEP_CIP") {
    data = (step.state.results || []).reduce((previous, result) => {
      return [...previous, `${[result.Code]}: ${result.Value}`];
    }, []);
  }

  return (
    data &&
    Object.keys(data).map((key) => {
      return <p>{JSON.stringify(data[key])}</p>;
    })
  );
}

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class AdminProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logs: [],
      user: null,
      userData: null,
      loading: true,
      showLoading: false,
      openSuccessModal: false,
      currentUserData: null,
      button_link: "https://site.woiro.com/",
      steps: [],
    };

    let rnd = Math.floor(Math.random() * 3);

    if (rnd == 0) background = "/img/texture-blue.jpg";
    if (rnd == 1) background = "/img/texture-red.jpg";
    if (rnd == 2) background = "/img/texture-yellow.jpg";
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    userServices
      .getUser(this.props.user.uid)
      .then((userData) => {
        this.setState({
          currentUserData: userData,
        });
      })
      .catch((e) => {
        handleGeneralError(this.props.user, e, this.props.history);
      });
  }

  handleEmailChange = (event) => {
    this.setState({ userEmail: event.target.value });
  };

  deleteProfile = () => {
    if (
      !this.state.currentUserData.rols ||
      !this.state.currentUserData.rols.find((r) => {
        return r == "admin";
      })
    )
      throw { message: "error", code: "err-rol" };
    userServices.deleteProgress(this.state.userData.uid);
    userServices.upsertUser({ uid: this.state.userData.uid, plan: "initial" });
  };

  createPayment = () => {
    if (
      !this.state.userData.uid ||
      !this.state.currentUserData.rols ||
      !this.state.currentUserData.rols.find((r) => {
        return r == "admin";
      })
    )
      throw { message: "error", code: "err-rol" };
    userServices.createPayment(
      this.state.userData.uid,
      this.state.paymentPlanCode
    );
    userServices.upsertUser({
      uid: this.state.userData.uid,
      plan: this.state.paymentPlanCode,
    });
  };

  filter() {
    debugger;
    userServices
      .getUserByEmail(this.state.currentUserData, this.state.filterEmail)
      .then((userData) => {
        debugger;
        this.getSteps(userData.uid);
        // this.getFavoriteCareersByStep(userData.uid);

        this.setState({
          loading: false,
          userData: userData,
        });
      })
      .catch((e) => {
        debugger;
        this.setState({
          loading: false,
        });
        console.log(e);
      });
  }

  getFavoriteCareersByStep(userUid) {
    getFavoriteCareersFromStep(userUid, FIRST_STEP_TEN_CAREERS)
      .then((r) => {
        this.setState({ favoriteCareersStepOne: r.careers });
      })
      .catch((e) => {
        console.log(e);
      });
    getFavoriteCareersFromStep(userUid, SECOND_STEP_FIVE_CAREERS)
      .then((r) => {
        this.setState({
          favoriteCareersStepTwo: (r.careers || []).filter(
            (c) => c.Discard === false
          ),
        });
      })
      .catch((e) => console.log(e));
    getFavoriteCareersFromStep(userUid, THIRD_STEP_THREE_CAREERS)
      .then((r) => {
        this.setState({
          favoriteCareersStepThree: (r.careers || []).filter(
            (c) => c.Discard === false
          ),
        });
      })
      .catch((e) => console.log(e));
    getFavoriteCareersFromStep(userUid, FINAL_STEP_SINGLE_CAREER)
      .then((r) => {
        this.setState({
          favoriteCareersStepFour: (r.careers || []).filter(
            (c) => c.Discard === false
          ),
        });
      })
      .catch((e) => console.log(e));
  }

  getSteps(userUid) {
    homeServices
      .getSteps(userUid)
      .then((data) => {
        if (!data) data = {};
        this.setState({
          steps: data,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  sendEmail(e) {
    let msg = {
      subject: this.state.emailSubject,
      to: this.state.filterEmail,
      button_link: this.state.button_link,
      button_text: this.state.button_text,
      body: this.state.emailBody,
      title: this.state.emailTitle,
    };

    console.log(msg);
    notificationServices
      .sendPushEmail(msg)
      .then((res) => {})
      .finally(() => {})
      .catch((err) => console.log(err));
  }

  isAdmin(currentUserData) {
    return currentUserData.rols && currentUserData.rols.includes("admin");
  }

  render() {
    if (!this.state.currentUserData) return <div>loading...</div>;
    const { classes, ...rest } = this.props;
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRoundedCircle,
      classes.imgFluid
    );

    let userDisplayName = "";

    if (this.state.userData && this.state.userData.displayName)
      userDisplayName = this.state.userData.displayName;
    else if (this.state.userData && this.state.userData.name)
      userDisplayName = this.state.userData.name;

    var pageContent = (
      <div>
        <Header
          authenticatedUser={this.props.user}
          links={
            <HeaderLinks
              dropdownHoverColor="info"
              authenticatedUser={this.props.user}
            />
          }
          fixed
          color="transparent"
          forceTransparent={true}
          changeColorOnScroll={{
            height: 50,
            color: "white",
          }}
          {...rest}
        />
        <Parallax
          image={background}
          filter="dark"
          className={classes.parallax}
        />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <Dialog
              classes={{
                root: classes.modalRoot,
                paper: classes.modal,
              }}
              open={this.state.showLoading}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => {}}
              aria-labelledby="notice-modal-slide-title"
              aria-describedby="notice-modal-slide-description"
            >
              <DialogContent
                id="notice-modal-slide-description"
                className={classes.modalBody}
              >
                <CircularProgress className={classes.progress} />
              </DialogContent>
            </Dialog>

            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img
                      src={
                        this.state.userData &&
                        this.state.userData.photoURL != null
                          ? this.state.userData.photoURL + "?type=large"
                          : require("assets/img/unknown_avatar.png")
                      }
                      alt="avatar"
                      className={imageClasses}
                    />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>
                      {this.state.userData && this.state.userData.displayName}
                    </h3>
                  </div>
                </div>
              </GridItem>
            </GridContainer>

            <GridContainer justify="center">
              <GridItem
                xs={12}
                sm={12}
                md={12}
                className={classNames(classes.mlAuto, classes.mrAuto)}
                style={{ textAlign: "center" }}
              >
                <CustomInput
                  formControlProps={{
                    fullWidth: false,
                    className: classes.customFormControlClasses,
                  }}
                  inputProps={{
                    value: this.state.filterEmail,
                    onChange: (e) => {
                      this.setState({
                        filterEmail: e.target.value,
                      });
                    },
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className={classes.inputAdornment}
                      >
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    placeholder: "Email",
                  }}
                />

                <Button
                  color="info"
                  size="sm"
                  onClick={() => {
                    this.filter();
                  }}
                >
                  Filtrar
                </Button>

                {this.isAdmin(this.state.currentUserData) &&
                this.state.userData &&
                this.state.userData.uid ? (
                  <Button
                    color="info"
                    size="sm"
                    onClick={() => {
                      this.deleteProfile();
                    }}
                  >
                    Borrar progreso
                  </Button>
                ) : null}

                {this.state.userData && this.state.userData.uid ? (
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    className={classNames(classes.mlAuto, classes.mrAuto)}
                    style={{ textAlign: "center" }}
                  >
                    {this.state.userData.uid}
                  </GridItem>
                ) : null}
              </GridItem>
            </GridContainer>

            <div className={classes.profileTabs}>
              <NavPills
                alignCenter
                color="primary"
                tabs={[
                  {
                    tabButton: "Carreras",
                    tabIcon: Palette,
                    tabContent: getStepComponent({
                      steps: this.state.steps,
                      userData: this.state.userData,
                      isAdmin: this.isAdmin(this.state.currentUserData),
                    }),
                  },
                  {
                    tabButton: "Email",
                    tabIcon: People,
                    tabContent: (
                      <div>
                        <GridContainer>
                          <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                            className={classNames(
                              classes.mlAuto,
                              classes.mrAuto
                            )}
                          >
                            <form>
                              <CustomInput
                                labelText="Subject"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  onChange: (e) => {
                                    this.setState({
                                      emailSubject: e.target.value,
                                    });
                                  },

                                  value: this.state.emailSubject
                                    ? this.state.emailSubject
                                    : "",
                                }}
                              />
                              <CustomInput
                                labelText="Title"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  onChange: (e) => {
                                    this.setState({
                                      emailTitle: e.target.value,
                                    });
                                  },

                                  value: this.state.emailTitle
                                    ? this.state.emailTitle
                                    : "",
                                }}
                              />
                              <CustomInput
                                labelText="Body"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  onChange: (e) => {
                                    this.setState({
                                      emailBody: e.target.value,
                                    });
                                  },

                                  value: this.state.emailBody
                                    ? this.state.emailBody
                                    : "",
                                }}
                              />

                              <CustomInput
                                labelText="Button text"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  onChange: (e) => {
                                    this.setState({
                                      button_text: e.target.value,
                                    });
                                  },

                                  value: this.state.button_text
                                    ? this.state.button_text
                                    : "",
                                }}
                              />

                              <CustomInput
                                labelText="Button url"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  onChange: (e) => {
                                    this.setState({
                                      button_link: e.target.value,
                                    });
                                  },

                                  value: this.state.button_link
                                    ? this.state.button_link
                                    : "",
                                }}
                              />
                            </form>
                          </GridItem>

                          <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                            className={classNames(
                              classes.mlAuto,
                              classes.mrAuto
                            )}
                          >
                            <div className={classes.textCenter}>
                              <Button
                                color="primary"
                                round
                                onClick={(e) => {
                                  this.sendEmail(e);
                                }}
                              >
                                Envair mail
                              </Button>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </div>
                    ),
                  },
                  {
                    tabButton: "Pago",
                    tabIcon: Edit,
                    tabContent: (
                      <div>
                        <GridContainer>
                          <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                            className={classNames(
                              classes.mlAuto,
                              classes.mrAuto
                            )}
                          >
                            <form>
                              <CustomInput
                                labelText="Title"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  placeholder: "free | starter | basic | full",
                                  onChange: (e) => {
                                    this.setState({
                                      paymentPlanCode: e.target.value,
                                    });
                                  },

                                  value: this.state.paymentPlanCode
                                    ? this.state.paymentPlanCode
                                    : "",
                                }}
                              />
                            </form>
                          </GridItem>

                          <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                            className={classNames(
                              classes.mlAuto,
                              classes.mrAuto
                            )}
                          >
                            <div className={classes.textCenter}>
                              <Button
                                color="primary"
                                round
                                onClick={(e) => {
                                  this.createPayment(e);
                                }}
                              >
                                Grabar pago
                              </Button>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );

    return pageContent;
  }
}

const maptStateToProps = (state) => ({
  user: state.auth,
});

export default connect(maptStateToProps)(
  withStyles(profilePageStyle)(AdminProfilePage)
);
