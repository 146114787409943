import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import HeaderLanding from "components/Header/HeaderLanding.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// import Button from "components/CustomButtons/Button.jsx";
// sections for this page
import SectionText from "./Sections/SectionText.jsx";
import SectionText2 from "./Sections/SectionText2.jsx";
// import SectionComponents from "./Sections/SectionComponents.jsx";
import SectionCards from "./Sections/SectionCards.jsx";

import { connect } from "react-redux";
import { Redirect } from "react-router-i18n";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import landingPageStyle from "assets/jss/material-ui-styles/views/landingPageStyle.jsx";

class TYCPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      loading: true
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    const { classes } = this.props;
    if (this.props.auth && this.props.auth.uid) return <Redirect to="/home" />;

    return (
      <div>
        <HeaderLanding
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 300,
            color: "white"
          }}
        />
        <div className={classes.main}>
          <div className={classes.container} style={{ marginTop: 150 }}>
            <GridContainer>
              <GridItem md={12} sm={12}>
                <div
                  onClick={() => {
                    this.goBack();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <ChevronLeft style={{ float: "left" }} />
                  Volver
                </div>
              </GridItem>
            </GridContainer>
            <b>Términos y Condiciones Generales de Uso.</b>
            <br />
            Fecha de entrada en vigencia: 01 de julio de 2019.
            <br />
            <br />
            1. SOBRE LOS TÉRMINOS Y CONDICIONES GENERALES DE USO.
            <br />
            Las cláusulas descriptas a continuación representan los términos y
            condiciones generales de uso (en adelante, los “Términos y
            Condiciones”) del sitio web www.woiro.com, incluyendo cualquiera de
            sus subdominios, aplicaciones y cada una de las herramientas
            disponibles actualmente o en el futuro (en adelante, el "Sitio") que
            es operado por Woiro S.A.S., una Sociedad por Acciones Simplificada,
            constituida en conformidad con las normas vigentes en la República
            Argentina, e inscripta en la Inspección General de Justicia el día
            02 de Julio de 2019, bajo el número IF-2019-58988813-APN-DA#IGJ.
            WOIRO tiene su sede social en la calle Pres. Tte. Gral. Juan Domingo
            Perón 315, piso 5° “21”, Ciudad Autónoma de Buenos Aires. Al acceder
            o utilizar el Sitio, usted (en adelante, el "Usuario"), manifiesta
            que ha leído, entendido y acepta los presentes Términos y
            Condiciones, bajo su única y exclusiva responsabilidad, en la
            versión publicada por WOIRO en el momento mismo en que acceda al
            Sitio. En consecuencia, el Usuario debe leer atentamente los
            Términos y Condiciones en cada una de las ocasiones en que se
            proponga utilizar el Sitio y en caso de no estar de acuerdo con los
            mismos, deberá abstenerse de usarlo. El Sitio consiste de una
            plataforma virtual que tiene por finalidad brindar información a
            potenciales alumnos universitarios sobre las diferentes opciones de
            carreras universitarias que se ofrecen en determinadas instituciones
            educativas (en adelante, los “Contenidos”), orientandolos en su
            elección vocacional a través de diferentes exámenes de
            autoconocimiento y asesoramiento profesional a prestarse en modo
            presencial o virtual, dependiendo de los servicios a los que el
            Usuario voluntariamente accediera a través de dicha plataforma (en
            adelante, los “Servicios”). En algunos supuestos, la utilización de
            los Servicios ofrecidos al Usuario a través del Sitio podrá estar
            sometida a condiciones particulares propias que, según el caso,
            podrán sustituir, complementar y/o modificar los presentes Términos
            y Condiciones (en adelante, las "Condiciones Especiales"). Por lo
            tanto, el Usuario también deberá leer atentamente las
            correspondientes Condiciones Especiales con anterioridad a la
            utilización de dichos Servicios, y en caso de no estar de acuerdo
            con las mismas, deberá abstenerse de utilizarlos.
            <br />
            <br />
            2. REGISTRO DEL USUARIO.
            <br />
            El Usuario podrá acceder a determinados Contenidos del Sitio, sin
            tener que registrarse previamente para ello. Sin embargo, en caso
            que el Usuario quiera a acceder a Contenidos o Servicios que
            requieran de su previo registro, deberá ingresar sus datos por
            primera vez mediante el inicio de sesión a través de su correo
            electrónico, creando un nombre de usuario y contraseña a tales
            efectos, o autorizando al Sitio a acceder por medio de su usuario de
            otras redes sociales (Facebook, Google, etc.). El registro será
            necesario en todos los casos en que el Usuario quiera acceder a
            contenido o servicios que dependan del pago de un precio,
            aplicándose las Condiciones Especiales que en su caso los regulen.
            La dirección de correo electrónico proporcionada no deberá contener
            términos ofensivos, discriminatorios, ni términos relacionados o
            similares o confundibles con WOIRO o que atenten contra la moral y
            las buenas costumbres. En cualquiera de los supuestos recién
            mencionados, WOIRO podrá a su sola discreción solicitar al Usuario
            la modificación del correo electrónico, bajo apercibimiento de
            cancelar su registración como tal. Toda actividad realizada en el
            Sitio con el correo electrónico registrado se considerará legítima y
            realizada por el Usuario. El Usuario es plenamente responsable por
            la veracidad de los datos que ingresa para el proceso de su
            registración como Usuario. El Usuario reconoce que el correo
            electrónico es un elemento fundamental en la registración al Sitio y
            para la comunicación con WOIRO. El Usuario es responsable de
            proporcionar una dirección de correo electrónico válida y de uso y
            control frecuente y acepta que WOIRO envíe allí las comunicaciones
            habituales.
            <br />
            <br />
            3. USO DEL SITIO. OBLIGACIONES DEL USUARIO.
            <br />
            El Usuario se compromete a utilizar el Sitio y los Servicios de
            conformidad con la ley, los presentes Términos y Condiciones, las
            Condiciones Especiales aplicables, así como con la moral y buenas
            costumbres generalmente aceptadas y el orden público. El Usuario se
            obliga a abstenerse de utilizar el Sitio y los Servicios con fines o
            efectos ilícitos, contrarios a lo establecido en estos Términos y
            Condiciones, lesivos de los derechos e intereses de terceros, o que
            de cualquier forma puedan dañar, inutilizar o deteriorar el Sitio y
            los Servicios o impedir la normal utilización o disfrute del Sitio y
            de los Servicios por parte de los Usuarios. El Usuario se compromete
            a abstenerse en todo momento de interferir o intentar interferir en
            el normal desarrollo de la operación y funcionamiento del Sitio, por
            cualquier medio que sea, incluyendo, sin que implique limitación
            alguna, el envío de virus informáticos en cualquier formato, el
            desarrollo de actividades de spamming, hacking o cracking, o
            cualquier otro medio expresamente prohibido por cualquier previsión
            de los Términos y Condiciones o cuyo efecto sea interferir, intentar
            interferir, dañar o afectar el Sitio o a terceros que lo utilicen o
            accedan al mismo. Si el Usuario violare cualquiera de estos Términos
            y Condiciones, WOIRO podrá borrar su perfil y cualquier contenido o
            información que haya publicado en el Sitio y/o prohibirle su uso (o
            cualquier parte, aspecto o característica del Sitio), en cualquier
            momento y a sola discreción de la WOIRO, sin necesidad de previo
            aviso. Las violaciones de la seguridad del sistema pueden resultar
            en acciones civiles y/o penales. WOIRO autoriza el acceso a su
            plataforma según se desprende de estos Términos y Condiciones, pero
            no tiene obligación alguna de proveer ningún equipamiento ni
            software alguno para la navegación por el Sitio y la utilización de
            los Servicios o visualización de los Contenidos, debiendo cada
            Usuario proveerse a sí mismo el equipamiento necesario para su
            utilización.
            <br />
            <br />
            4. PROPIEDAD INTELECTUAL E INDUSTRIAL.
            <br />
            Los logotipos, denominaciones, diseños, cabeceras, íconos, código
            del sitio (HTML, CSS, Java scripts entre otros), ilustraciones,
            presentaciones y nombres de servicios son marcas registradas y/o
            propiedad intelectual y/o industrial de WOIRO, y se encuentran
            protegidos por las leyes de derecho de autor y de propiedad
            industrial tanto nacionales como internacionales, y no pueden ser
            utilizados ni se pueden copiar, imitar o utilizar, en todo o en
            parte, sin la previa autorización por escrito de WOIRO, al igual que
            todo el Contenido del Sitio y los procesos de WOIRO para la
            operación y la prestación de sus servicios. El Usuario únicamente
            podrá visualizar y/o descargar una copia de los Contenidos del Sitio
            para su exclusivo uso personal, quedando terminantemente prohibido
            cualquier tipo de comercialización de los mismos. WOIRO podrá
            suspender o dar de baja del sistema a cualquier Usuario que hubiera
            incumpliere con lo dispuesto en el presente punto, sin derecho a
            reclamo alguno.
            <br />
            <br />
            5. TRATAMIENTO DE DATOS DEL USUARIO.
            <br />
            En los casos en que sea necesario el registro del Usuario, o cuando
            éste utilice servicios que requieran la provisión de datos
            personales, se le pedirá que aporte a WOIRO cierta información que
            incluirá, entre otras, su nombre y apellido completos, y una
            dirección válida de correo electrónico (de aquí en adelante su
            "Información"). A tales efectos, el Usuario acepta la Políticas de
            Privacidad que lucen al final de los presentes Términos y
            Condiciones. Los datos personales que el Usuario aporte
            voluntariamente a WOIRO para acceder a los Servicios no revistirán
            en ningún caso el carácter de dato sensible, y, de corresponder,
            formarán parte de una base de datos debidamente registrada en
            cumplimiento al procedimiento dispuesto por Ley 25.326 de Protección
            de Datos Personales. WOIRO no compartirá con terceras partes la
            Información del Usuario sin su consentimiento previo, excepto en la
            medida en que sea necesario para el cumplimiento de las leyes o
            procedimientos legales vigentes, o de los servicios a los que se
            dedica WOIRO, donde tal información sea relevante, conforme a lo
            dispuesto en las Políticas de Privacidad mencionadas. WOIRO se
            reserva el derecho de ofrecerle al Usuario, por medios propios o a
            través de terceros, servicios y productos brindados por terceros
            basados en las preferencias que el Usuario indicó o demostró
            mediante el uso del Sitio al momento de registrarse o en cualquier
            momento posterior. El Usuario es el único responsable de mantener la
            confidencialidad de su Información, en especial de su nombre de
            Usuario y de su contraseña, debiendo informar inmediatamente a WOIRO
            de cualquier uso no autorizado de su cuenta.
            <br />
            <br />
            6. SERVICIOS CON COSTO. CONVERSACIONES DE ORIENTACIÓN VOCACIONAL.
            <br /> En determinados supuestos establecidos en el Sitio, los
            Servicios a brindar por parte de WOIRO contarán con un precio
            publicado, y su acceso dependerá del pago de dicho precio a través
            de los medios ofrecidos a tal efecto a través del Sitio. Dentro de
            los Servicios que WOIRO brinda a través del Sitio se prevé la
            posibilidad que el Usuario pueda mantener una o más conversaciones
            presenciales o virtuales (video-conferencia) (en adelante, las
            “Conversaciones”) con uno o más profesionales de psicología o
            psicopedagogía (en adelante, los “Profesionales”), a fin de obtener
            una orientación respecto a los exámenes de personalidad y las
            opciones de carreras que el Usuario haya preseleccionado. En todos
            los casos, dichas Conversaciones se limitarán estrictamente a la
            orientación vocacional del Usuario teniendo en cuenta las
            preferencias demostradas a través del uso del Sitio y los exámenes
            de personalidad voluntariamente completados por el mismo. El Usuario
            reconoce y acepta que WOIRO no asegura la satisfacción del Usuario
            en el resultado de las Conversaciones, limitándose a facilitar el
            contacto entre el Usuario y los Profesionales para el desarrollo de
            las mismas. Asimismo, el Usuario reconoce y acepta que WOIRO no
            interviene en la relación entre los Profesionales y el Usuario,, no
            siendo responsable por su accionar, incluyendo -pero no limitado a-
            cualquier tipo de acto ilegal, delitos, fraudes y/o a la mala
            práctica profesional de los mismos.
            <br />
            <br />
            7. INDEMNIDADES
            <br />
            El Usuario acepta indemnizar y mantener a WOIRO, sus
            administradores, accionistas y empleados, de y contra cualquier
            pérdida, responsabilidad, reclamo, demanda, daños, costos y gastos,
            incluyendo honorarios razonables de abogados, que surjan de o en
            conexión con cualquier uso del Servicio o el Sitio por parte del
            Usuario, o en relación a otros usuarios de la plataforma, o el uso
            de contenido de terceros que el Usuario publicara o compartiera a
            través del Sitio, o cualquier violación de estos Términos y
            Condiciones o de cualquier ley o derechos de terceros. El Sitio, los
            Contenidos y los Servicios son ofrecidos tal y como existen
            actualmente, o según sean éstos ofrecidos a partir de modificaciones
            futuras, y tal como se describen en los Términos y Condiciones y, de
            corresponder, en las Condiciones Especiales. El Usuario reconoce y
            acepta que la utilización del Sitio corre por su propia cuenta y
            riesgo, no cabiendo ninguna responsabilidad a WOIRO por tal motivo,
            incluyendo, sin límite, daños, pérdidas o gastos directos,
            indirectos, que surjan en relación con el Sitio o su uso o
            imposibilidad de uso por el Usuario, o en relación con cualquier
            falla en el rendimiento, error, omisión, interrupción, defecto,
            demora en la operación o transmisión, virus informáticos o falla de
            sistema o línea.
            <br />
            <br />
            8. FALTA DE RESPONSABILIDAD
            <br /> WOIRO no garantiza la veracidad de cualquier información
            brindada por el Usuario, no siendo responsable de ningún contenido
            ofensivo, inapropiado, obsceno, ilegal o cuestionable que hubiera
            sido aportado al Sitio por el Usuario. WOIRO no se hace responsable
            en ningún caso por la conducta de cualquier Usuario del Sitio o del
            Servicio. Asimismo, WOIRO no puede garantizar y no promete ningún
            resultado específico del uso del Sitio o del Servicio ofrecido,
            limitándose el Contenido ofrecido a simples recomendaciones puestas
            a disposición del Usuario para una mejor toma de decisión personal y
            voluntaria en relación a carreras y/o instituciones educativas. En
            tal sentido, WOIRO se limita a brindar información a fin de que el
            Usuario, bajo su exclusiva cuenta y total responsabilidad pueda
            tomar una mejor decisión de qué carrera estudiar, y/o en qué
            institución educativa, bajo su propio criterio. El Usuario reconoce
            y acepta que WOIRO no asegura la satisfacción en la eventual
            elección de la carrera y/o institución educativa que escoja el
            Usuario bajo su exclusivo criterio y voluntad, siendo una elección
            personal de cada Usuario, no contando con acción alguna frente a
            WOIRO para reclamar por concepto alguno derivado de la elección del
            Usuario en tal sentido. El Usuario reconoce que el Sitio y el
            Servicio pueden estar indisponibles temporalmente de vez en cuando
            por mantenimiento u otras razones. WOIRO no asume ninguna
            responsabilidad por cualquier error, omisión, interrupción,
            supresión, defecto, demora en la operación o transmisión, falla en
            la línea de comunicaciones, robo o destrucción o acceso no
            autorizado, o alteración de las comunicaciones del Usuario. WOIRO no
            es responsable de cualquier problema técnico o mal funcionamiento de
            cualquier red o líneas telefónicas, sistemas informáticos en línea,
            servidores o proveedores, equipos informáticos, software, fallos del
            correo electrónico o reproductores ocasionados por problemas
            técnicos o congestión de tráfico en Internet o en el Sitio o
            combinación de ellas, incluyendo lesiones o daños al Usuario o a la
            computadora de cualquier otra persona relacionada con o derivada de
            la participación o descarga de materiales relacionados con el Sitio
            <br />
            <br />
            9. POLÍTICA DE COOKIES
            <br /> El término "cookie" refiere a la información que se almacena
            en el navegador del Usuario cuando visita el Sitio. Las cookies
            ayudan a mejorar la experiencia de navegación, recordar las
            preferencias y adaptar las funcionalidades más relevantes para el
            Usuario. El Usuario pueden no aceptar el uso de cookies mediante la
            selección de la configuración a tal efecto en su navegador, pero, en
            tal caso, algunas funciones del Sitio pueden no estar disponibles en
            toda su plenitud.
            <br />
            <br />
            10. ENLACES A OTROS SITIOS
            <br />
            El Sitio puede contener enlaces a otros sitios en los cuales WOIRO
            no ejerce ningún tipo de control. Es responsabilidad del Usuario las
            consecuencias derivadas de su acceso, reconociendo que cualquier
            reclamo relativo a un inconveniente generado con otro sitio, portal,
            plataforma o página en Internet al que se accedió por vía de una
            conexión desde el Sitio, deberá ser dirigido directamente al
            administrador de dicho sitio, portal, plataforma o página en
            Internet. <br />
            <br />
            11. COMUNICACIÓN ENTRE WOIRO Y EL USUARIO.
            <br />
            La Empresa cursará las notificaciones o comunicaciones al Usuario a
            la dirección de correo electrónico denunciada en su proceso de
            registración mientras que las notificaciones o comunicaciones que
            los Usuarios deseen hacer llegar a WOIRO deberán ser enviadas a la
            siguiente dirección de correo electrónico: info@woiro.com. Al
            brindar su número de teléfono celular, el Usuario acepta que WOIRO
            pueda enviarle comunicaciones a través de la aplicación de Whatsapp,
            o aquella que en un futuro la reemplazare. En caso de que los
            Usuarios no deseen recibir comunicaciones comerciales, pueden
            solicitar la baja de la suscripción ingresando al link que se
            encuentra en la parte inferior de los correos electrónicos.
            <br />
            <br />
            12. ACEPTACIÓN
            <br />
            El Usuario declara haber leído y comprendido la totalidad de los
            Términos y Condiciones al marcar el cuadro con el siguiente texto:
            "Acepto los términos y condiciones", y al presionar la tecla
            "REGISTRARME", aceptará encontrarse plenamente regido por los mismos
            sin salvedad u oposición alguna.
            <br />
            <br />
            13. MODIFICACIÓN A LOS TÉRMINOS Y CONDICIONES
            <br />
            WOIRO se reserva el derecho de realizar modificaciones a los
            Términos y Condiciones a fin de adaptarlos a nuevas normas y
            reglamentaciones que oportunamente se dicten, a cambios en la
            legislación o jurisprudencia sin limitación alguna, por una
            obligación legal o contractual que así lo determine, sin perjuicio
            de también poder modificarlos al exclusivo criterio de WOIRO para
            adaptarse a prácticas de la industria o conveniencias operativas. El
            Usuario entiende y acepta que su uso continuado del Sitio después de
            haber realizado cambios constituye su aceptación de tales
            modificaciones. En caso de que dichas modificaciones no sean
            aceptadas por el Usuario, el mismo deberá comunicarlo a WOIRO, lo
            cual implicará que el Usuario sea inmediatamente dado de baja del
            Sitio, o podrá voluntariamente darse de baja sin efectuar
            comunicación alguna, momento en el que los Términos y Condiciones
            dejarán de regir para con él. <br />
            <br />
            14. DURACIÓN Y TERMINACIÓN <br />
            Sin perjuicio de que, en principio, está previsto que el Sitio tenga
            un plazo de duración indefinido, WOIRO se reserva el derecho a dar
            por terminada o suspender la prestación del servicio del Sitio y/o
            de cualquiera de los Servicios en cualquier momento, siendo de
            aplicación, de corresponder, lo que se hubiere dispuesto al respecto
            en las Condiciones Especiales. <br />
            <br />
            15. LEY APLICABLE Y JURISDICCIÓN <br />
            Los presentes Términos y Condiciones se rigen por las leyes de la
            República Argentina. WOIRO y el Usuario, sea residente nacional o
            extranjero, se someten, con renuncia expresa a cualquier otro fuero,
            a los juzgados y tribunales ordinarios de la Capital Federal de la
            República Argentina.
            <br />
            <br />
            <b>Política de Privacidad y Protección de Datos.</b>
            <br />
            <br />
            En esta sección explicamos al Usuario cómo recabamos, utilizamos y
            protegemos su información personal.
            <br />
            <br />
            1. DISPOSICIONES GENERALES
            <br /> La presente Política de Privacidad y Protección de Datos se
            aplica a toda información personal del Usuario obtenida como
            consecuencia de la utilización del Sitio.
            <br />
            <br />
            2. REGISTRO DE BASE DE DATOS <br />
            WOIRO cuenta con una base de datos electrónica y ha sido inscripta
            ante el Registro Nacional de Bases de Datos de la Dirección Nacional
            de Protección de Datos Personales, en cumplimiento de lo exigido por
            el artículo 21 de la Ley 25.326. WOIRO se dedica principalmente a:
            a) Proporcionar al Usuario que se encuentre en un proceso de
            selección de una carrera terciaria y/o universitaria, información y
            herramientas a los fines de que le sirvan para acompañarlo en ese
            proceso de selección y éste pueda definir principalmente qué
            estudiar y dónde hacerlo; y b) servir de nexo entre las
            instituciones educativas y el Usuario a fin de que éstas puedan
            utilizar la información recolectada y mejorar la oferta de sus
            servicios, y a su vez, que el Usuario cuente con más información y
            pueda conocer con detalle las ofertas educativas provistas. <br />
            <br />
            3. FORMAS DE OBTENCIÓN DE DATOS <br />
            WOIRO recogerá datos personales del Usuario a través de su
            participación en el Sitio, teniendo en cuenta datos aportados,
            elecciones, preferencias, cuestionarios completados, exámenes de
            autoconocimiento y resultados de orientación vocacional. Para los
            fines previstos, WOIRO recaba información del Usuario a través de
            una serie de pasos, ejercicios, cuestionarios y actividades que
            permiten evaluar su perfil en base a intereses, objetivos y
            características personales del Usuario para guiarlo en el proceso de
            búsqueda de una carrera y/o institución educativa.
            <br />
            <br />
            4. DATOS RECOLECTADOS
            <br /> WOIRO recolectará datos personales del Usuario tales como,
            nombre, domicilio, teléfono, e-mail, antecedentes e intereses
            académicos que permitan realizar una evaluación del perfil del
            Usuario e identificar las carreras y/o instituciones académicas que
            mejor se adapten a su perfil. WOIRO requiere que los datos
            solicitados sean verdaderos y exactos, caso contrario, el Usuario
            será exclusivo responsable por cualquier daño o perjuicio que
            pudiera ocasionar a WOIRO y/o a terceros por la falsedad de la
            información aportada. WOIRO no solicitará información que sea
            incompatible con la finalidad del Sitio o de sus Servicios, ni que
            directa o indirectamente revele datos sensibles del Usuario, como
            ser datos que revelan origen racial y étnico, opiniones políticas,
            convicciones religiosas, filosóficas o morales, afiliación sindical,
            información referente a la salud o a la vida sexual. De la misma
            manera, WOIRO sugiere al Usuario no incluir esta información en el
            Sitio. <br />
            <br />
            5. FINALIDAD DE LOS DATOS RECOLECTADOS <br />
            Los datos proporcionados por el Usuario serán utilizados únicamente
            por WOIRO para: a) Analizar el perfil del Usuario en base a su
            personalidad, habilidades e intereses y acompañarlo en el proceso de
            búsqueda de una carrera y/o institución educativa que mejor se
            adapte al Usuario en base a los datos por él proporcionados. b)
            Enviar la información recolectada relativa al perfil del Usuario a
            aquellas instituciones educativas que se adecúen a su perfil y/o
            preferencias, o en relación a las cuales el Usuario haya demostrado
            interés o solicitado más información, con la finalidad de agilizar
            el contacto del Usuario con la institución educativa. WOIRO
            únicamente cederá o transmitirá la información recolectada con
            aquellas instituciones educativas que previamente hayan aceptado las
            presentes políticas de privacidad y se obliguen a cumplir con la
            normativa vigente relativa a la protección de datos personales. c)
            Para enviarle al Usuario información, reportes y notificaciones
            relativas a las instituciones educativas que ofrecen servicios
            afines a sus intereses. Asimismo, WOIRO podrá utilizar la
            información para los fines de identificación y autenticación,
            administración y gestión comercial, mejora del servicio, fines
            estadísticos, envío de notificaciones, promociones o publicidad y
            con cualquier otra actividad compatible con la finalidad expuesta en
            el presente punto. <br />
            <br />
            6. SEGURIDAD DE LOS DATOS RECOLECTADOS <br />
            WOIRO utiliza tecnología que garantiza los estándares de seguridad y
            confidencialidad de los datos aportados por el Usuario en
            conformidad con las normas vigentes, a fin de evitar su
            adulteración, pérdida o tratamiento no autorizado de los datos.
            WOIRO utiliza en su Sitio cookies con fines analíticos, comerciales
            y sociales. <br />
            <br />
            7. RESPONSABILIDAD POR LOS DATOS PROVISTOS
            <br /> Los datos proporcionados por el Usuario son de su exclusiva
            responsabilidad, no pudiendo reclamar a WOIRO en ninguno de los
            casos por la confección de un perfil académico que no concuerde con
            el perfil del Usuario. Aun en los casos en que los datos
            proporcionados fueran correctos, WOIRO no se responsabiliza por la
            satisfacción final del Usuario con respecto al perfil académico
            revelado. <br />
            <br />
            8. DERECHOS DEL USUARIO <br />
            El Usuario o titular de los datos personales proporcionados gozará
            siempre del derecho a acceder a los mismos en cualquier momento y
            solicitar su rectificación o supresión en forma gratuita. En caso de
            ser solicitado, WOIRO rectificará o eliminará automáticamente de sus
            registros los datos proporcionados. La solicitud de acceso,
            rectificación o supresión deberá efectuarse vía mail dirigido a:
            info@woiro.com. WOIRO eliminará de sus registros aquellos datos que
            ya no fueran necesarios para los fines en que fueron recolectados.
            Frente a cualquier incumplimiento, la Dirección Nacional de
            Protección de Datos Personales, Órgano de Control de la Ley N°
            25.326, tiene la atribución de atender denuncias y reclamos que se
            interpongan sobre dicha norma. <br />
            <br />
            9. ACEPTACIÓN DE LAS POLÍTICAS DE PRIVACIDAD
            <br /> El Usuario acepta y autoriza expresamente la utilización de
            los datos personales proporcionados a WOIRO, bajo los términos y
            condiciones establecidos precedentemente, manifestando que los ha
            prestado bajo su propia voluntad y que presta el debido
            consentimiento para que sean utilizados a la finalidad prevista. El
            consentimiento aquí prestado podrá ser libremente revocado por el
            Usuario en cualquier momento, mediante indicación expresa y por
            escrito dirigida a WOIRO. En ningún caso, la revocación tendrá
            efectos retroactivos. El Usuario manifiesta que los datos fueron
            provistos voluntariamente y, son ciertos y exactos. Asimismo, se
            compromete a modificar y/o actualizar inmediatamente los datos
            provistos en caso de que sean inexactos o incompletos, o que hayan
            dejado de ser ciertos. La presente Política de Privacidad y
            Protección de Datos podrá ser modificada por WOIRO en cualquier
            momento. En caso de hacerse efectiva, la modificación será
            notificada al Usuario por correo electrónico, solicitando prestar
            consentimiento. El Usuario autoriza a WOIRO para que pueda
            contactarlo en cualquier momento a través de cualquiera de los
            medios de contacto por él proporcionados al momento de registrarse
            en la plataforma a efectos de consultarlo por cuestiones relativas
            al funcionamiento, calidad y/o servicio proporcionado por WOIRO.-
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(withStyles(landingPageStyle)(TYCPage));
