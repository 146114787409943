import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import * as UserService from "../../services/userservices";
import MuiPhoneNumber from "material-ui-phone-number";
import { isValidPhoneNumber } from 'react-phone-number-input'

export default function FormDialog({ user }) {
  const [open, setOpen] = React.useState(false);
  const [userData, setUserData] = React.useState(user);
  const [errors, setErrors] = React.useState({});

  const handleClose = () => {
    setOpen(!open);
  };

  // TODO: move this to a helper
  const isValidEmail = (email) => {
    const r = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return r.test(String(email).toLowerCase()) && email.trim() != "";
  };

  const isValidTelephone = (telephone) => {
    return !telephone || isValidPhoneNumber(telephone)
  }

  const handleChange = ({ target: { value, name } }) => {
    setUserData({ ...userData, [name]: value });
  };

  const handleChangePhone = (value) => {
    setUserData({ ...userData, telephone: value });
  }

  const handleConfirm = () => {
    let handleConfirmErrors = {}

    if (!isValidEmail(userData.email)) {
      handleConfirmErrors.email = `Agregar un email valido ${userData.email}`;
    }

    if (!isValidTelephone(userData.telephone)) {
      handleConfirmErrors.telephone = "Por favor ingresa un número valido";
    }

    if (handleConfirmErrors.email || handleConfirmErrors.telephone) {
      setErrors({
        email: handleConfirmErrors.email,
        telephone: handleConfirmErrors.telephone
      })
      return;
    }

    UserService.upsertUser({...userData, telephoneAlreadyAsked: true})
      .then(() => handleClose())
      .catch((e) => console.log(e))
    UserService.updateFirebaseEmail(userData.email)
  };

  React.useEffect(() => {
    UserService.getUser(user.uid)
      .then((user) => setUserData(user))
      .catch((e) => console.dir(e));
    setOpen(!isValidEmail(user.email) || !user.telephoneAlreadyAsked);
  }, [user]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">¡Ya casi estamos!</DialogTitle>
      <DialogContent>
        <DialogContentText>¿Nos compartis tus datos de contacto?</DialogContentText>
        <TextField
          autoFocus
          error={errors.email ? true : false}
          helperText={
            errors.email
              ? `Ingresa un email valido. Ejemplo: example@email.com`
              : false
          }
          margin="dense"
          id="email"
          name="email"
          label="Email"
          type="email"
          fullWidth
          onChange={(e) => handleChange(e)}
          value={userData.email}
        />
        <MuiPhoneNumber
          id="telephone"
          name="telephone"
          label="Celular"
          fullWidth
          autoFormat={false}
          defaultCountry={userData.countryCode || "ar"}
          onlyCountries={['ar', 'cl', 'es']}
          value={userData.telephone}
          onChange={handleChangePhone}
          error={errors.telephone ? true : false}
          helperText={
            errors.telephone
              ? `Ingresa un número de celular valido`
              : false
          }
        />
      </DialogContent>
      <DialogActions>
        <Button type="submit" onClick={handleConfirm} color="primary">
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
