import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Badge from "components/Badge/Badge.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";

import profileImage from "assets/img/faces/card-profile1-square.jpg";

import sectionBlogInfoStyle from "../jss/sections/sectionBlogInfoStyle.jsx";

function SectionBlogInfo({ ...props }) {
  const { classes } = props;
  let topic = props.topic;

  let avatar_1;

  if (topic) {
    let rex = /<img[^>]+src="([^">]+)/g;
    avatar_1 = rex.exec(topic.author_avatar.replace(new RegExp("'", 'g'), "\""))[1].replace("#038;", "");
  }

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={10} md={8}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <div className={classes.blogTags}>
                {
                  topic.tags.map((tag, i) => {
                    return <Badge color="primary" key={i}>{tag}</Badge>
                  })
                }
              </div>
            </GridItem>

          </GridContainer>
          <hr />
          <Card plain profile className={classes.card}>
            <GridContainer>
              <GridItem xs={12} sm={2} md={2}>
                <CardAvatar plain profile>
                  <img src={avatar_1} alt="..." />
                </CardAvatar>
              </GridItem>
              <GridItem xs={12} sm={8} md={8}>
                <h4 className={classes.cardTitle}>{topic.author_name}</h4>
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withStyles(sectionBlogInfoStyle)(SectionBlogInfo);
