import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Header from "components/Header/Header.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Footer from "components/Footer/Footer.jsx";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import SectionProductsCards from "./Sections/SectionProductsCards.jsx";
import SectionUniversityCards from "./Sections/SectionUniversityCards.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Search from "@material-ui/icons/Search";
import Button from "components/CustomButtons/Button.jsx";
import SectionSearch from "./Sections/SectionSearch";
import SectionComments from "components/Topics/SectionComments.jsx";
import styles from "./jss/style.jsx";
import Loading from "components/Loader/Loading.jsx";

import { CLOSE_TO_YOU_LIST_NAME } from "./types/universitiesTypes";

class UniversityPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoading: false
    };
  }

  showLoading() {
    this.setState({ showLoading: true });
  }

  hideLoading() {
    this.setState({ showLoading: false });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  render() {
    console.log(this.props);
    const { classes, ...rest } = this.props;
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRoundedCircle,
      classes.imgFluid
    );
    return (
      <div>
        <Header
          authenticatedUser={this.props.auth}
          links={<HeaderLinks authenticatedUser={this.props.auth} />}
          fixed
          color="transparent"
          forceTransparent={true}
          changeColorOnScroll={{
            height: 250,
            color: "white"
          }}
          {...rest}
        />
        <Parallax
          image={require("assets/img/backgrounds/background-universities.png")}
          filter="dark"
          className={classes.parallax}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem
                md={8}
                sm={8}
                className={classNames(
                  classes.mlAuto,
                  classes.mrAuto,
                  classes.textCenter
                )}
              >
                <h2 className={classes.mainTitle}>UNIVERSIDADES</h2>
                <h4 className={classes.subtitle}>
                  Encontr&aacute; la universidad que busc&aacute;s
                </h4>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem
                xs={12}
                sm={6}
                md={6}
                className={classNames(classes.mlAuto, classes.mrAuto)}
              >
                <div className={classes.textCenter}>
                  {/* <h3 className={classes.title}>Buscador</h3> */}
                  {/* <p className={classes.description}>
                          Ingres&aacute; el nombre, una ubicaci&oacute;n
                          &oacute; un &aacute;rea de inter&eacute;s
                        </p> */}
                </div>
                <Card raised className={classes.cardSearch}>
                  <CardBody className={classes.cardBodySearch}>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={6} lg={8}>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            className: classes.cardFormSearch
                          }}
                          inputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Search />
                              </InputAdornment>
                            ),
                            placeholder: "¿Qué universidad querés ver?",
                            onChange: e => this.props.searchInputOnChange(e)
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6} lg={4}>
                        <Button
                          color="primary"
                          block
                          className={classes.subscribeButton}
                          style={{ marginTop: 10 }}
                        >
                          buscar{" "}
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>

              {this.props.isLoadingUniversities && (
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  className={classNames(classes.mlAuto, classes.mrAuto)}
                >
                  <Loading />
                </GridItem>
              )}
              {!this.props.isLoadingUniversities && (
                <GridItem>
                  <SectionUniversityCards
                    favoriteUniversities={this.props.favoriteUniversities}
                    universities={this.props.universities}
                    fetchDetail={this.props.fetchDetail}
                    addFavoriteUniversity={this.props.addFavoriteUniversity}
                    deleteFavoriteUniversity={
                      this.props.deleteFavoriteUniversity
                    }
                  />
                </GridItem>
              )}
            </GridContainer>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(styles)(UniversityPage);
