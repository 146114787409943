import {
  FETCH_PROGRESS_START,
  FETCH_PROGRESS_END,
  FETCH_PROGRESS_EMPTY,
  FETCH_PROGRESS_ERROR,
  ADD_STEP_TO_PROGRESS_END
} from "./types";

import { FETCH_PLAN } from "../../types/authTypes";

import { groupSteps } from "services/homeservices";

const initialState = {
  plan: "initial",
  steps: [],
  groupedSteps: [],
  currentStep: null,
  error: {},
  isLoading: false,
  isReady: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROGRESS_START:
      return { ...state, isLoading: true };
    case FETCH_PROGRESS_END:
      return { ...state, steps: action.payload.steps, currentStep: action.payload.currentStep, groupedSteps: action.payload.groupedSteps, isLoading: false, isReady: true };
    case FETCH_PROGRESS_ERROR:
      return { ...state, error: action.payload, isLoading: false };
    case ADD_STEP_TO_PROGRESS_END:
      let [currentStep] = state.steps.filter(step => step.Code == action.payload.code);
      currentStep = { ...currentStep, state: action.payload.state };
      currentStep.isCompleted = true;
      currentStep.isCurrent = false;

      let [nextStep] = state.steps.filter(step => step.Order >= currentStep.Order + 1 && !step.isCompleted);
      nextStep.isCurrent = true;

      let steps = state.steps.filter(step => step.Code != currentStep.Code && step.Code != nextStep.Code);
      let orederedSteps = [...steps, currentStep, nextStep].sort((a, b) => { return a.Order - b.Order; });

      return {
        ...state,
        currentStep: nextStep,
        steps: orederedSteps,
        groupedSteps: groupSteps(orederedSteps)
      };
    case FETCH_PLAN:
      let filteredStepsByPlan = state.steps.filter(step => step.Plans.includes(action.payload));
      return { ...state, plan: action.payload, steps: filteredStepsByPlan }
    case FETCH_PROGRESS_EMPTY:
      return initialState;
    default:
      return state;
  }
};
