import React, { Component } from "react";
import ProgressStepPage from './ProgressStepPage';
import Button from "components/CustomButtons/Button.jsx";
import I18n, { getCurrentLocation } from '../../i18n/index';
import { ThreeSixtyOutlined } from "@material-ui/icons";
const bg12 = "/img/texture-blue.jpg";

class ProgressStepPageContainer extends Component {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
  }

  handleStepDone(event, callback) {
    if (this.props.progress && this.props.progress.steps) {
      let step = this.getStep();
      if (step) {
        let nextStep = this.getNextStep(step.Order);
        let nextStepUrl = nextStep ? nextStep.URL : "/home";
        if (!step.isCompleted) {

          let stateToSave = {}
          if (step.Plans.includes(this.props.auth.plan)) {
            stateToSave = this.getStateToSave ? this.getStateToSave() : { ...this.state };
          } else {
            stateToSave = {
              skipped: true
            }
          }

          if (callback) {
            this.props.addStepToProgress(this.props.auth.uid, step.Code, stateToSave, callback);
          } else {
            this.props.addStepToProgress(this.props.auth.uid, step.Code, stateToSave, () => this.pushToUrl(nextStepUrl));
          }
        } else {
          if (callback) { callback(); }
          else {
            this.pushToUrl(nextStepUrl)
          }
        }
      }
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    if (this.props.progress && this.props.progress.steps && this.props.progress.steps.length > 0) {
      let step = this.getStep();
      if (step && !step.isCurrent && process.env.REACT_APP_ENV === "production") {
        let currentStep = this.getCurrentStep();
        if (step.Order > currentStep.Order) {
          let url = "home";
          if (currentStep) url = currentStep.URL;

          this.pushToUrl(url);
        }
      }

      if (step && step.isCompleted) {
        if (step.state && Object.keys(step.state).length > 0) this.setState(step.state);
      } else {
        if (this.executeDefaultBehavior) this.executeDefaultBehavior();
      }
    }
    if (this.executeCustomComponentDidMount) this.executeCustomComponentDidMount();
  }

  pushToUrl(url) {
    this.props.history.push(`/${getCurrentLocation(this.props.location)}/${url}`)
  }

  getDefaultButton() {
    return <Button
      disabled={this.state.disableButton}
      color="primary"
      size="lg"
      style={{ width: "100%" }}
      onClick={this.handleStepDone.bind(this)}
    >
      Siguiente Paso
      </Button>;
  }

  getButtons() {
    return [this.getDefaultButton()];
  }

  getDefaultButtons() {
    if (this.stepIncludedInUserPlan()) {
      return this.getButtons();
    } else {
      let nextStepButton = this.getButton("Siguiente Paso", false, this.handleStepDone.bind(this), "secondary")
      let unlockButton = this.getButton("Desbloquear", false, () => this.pushToUrl("step-plans"), "primary")
      return [nextStepButton, unlockButton];
    }
  }

  stepIncludedInUserPlan() {
    let step = this.getStep();
    let userPlan = this.getUserPlan();
    return step && step.Plans.includes(userPlan);
  }

  getButton(text, disabled, fn, color) {
    return <Button
      color={color || "primary"}
      size="lg"
      style={{ width: "100%" }}
      onClick={fn}
      disabled={disabled}
    >{text}</Button>
  }

  getProgressStep() {
    return null;
  }

  getText() {
    let step = this.getStep();
    if (step) return <h4>{<I18n t={step.Text} /> || step.Text} </h4>;
    return "";
  }

  getTitle() {
    let step = this.getStep();
    if (step) return <I18n t={step.Title} />;
    return "";
  }

  getChildren(classes) {
  }

  getStep() {
    if (this.props.progress && this.props.progress.steps && this.props.progress.steps.length > 0) {
      let matchUrl = this.props.location.pathname.replace(`/${getCurrentLocation(this.props.location)}/`, "");
      let step = this.props.progress.steps.filter(step => step.URL == matchUrl)[0];
      if (step) return step;
    }
    return null;
  }

  getCurrentStep() {
    if (this.props.progress && this.props.progress.steps && this.props.progress.steps.length > 0) {
      let [step] = this.props.progress.steps.filter(step => step.isCurrent);
      if (step) return step;
    }
    return null;
  }

  getNextStep(currentStepOrder) {
    let [nextStep] = this.props.progress.steps.filter(element => element.Order >= currentStepOrder + 1);
    return nextStep;
  }

  getUserPlan() {
    return this.props.auth.plan;
  }

  getPayText() {
      let step = this.getStep();
      if (step) return <h4>{<I18n t={step.PayText} /> || step.PayText} </h4>;
      return "";
  }

  getRealText() {
    if (this.stepIncludedInUserPlan()) {
      return this.getText()
    }

    return this.getPayText();
  }

  render() {
    return <div>
      <ProgressStepPage title={this.getTitle()}
        text={this.getRealText()}
        history={this.props.history}
        classes={this.props.classes}
        background={bg12}
        auth={this.props.auth}
        buttons={this.getDefaultButtons()}
        progressStep={this.getProgressStep()}
      >
        {this.stepIncludedInUserPlan() && this.getChildren(this.props.classes)}
      </ProgressStepPage>
    </div>;
  }
}

export default ProgressStepPageContainer;
