import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { MenuItem } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
});

class SectionSearchElement extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { classes } = this.props;
        return (
            <Paper className={classes.root} elevation={1} square onClick={this.props.onClick}>
                <Typography variant="title" gutterBottom>
                    {this.props.element.title}
                </Typography>
                <Typography variant="subheading" gutterBottom>
                    {this.props.element.subtitle}
                </Typography>
            </Paper>
        );
    }
}

SectionSearchElement.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SectionSearchElement);