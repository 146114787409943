var firebase = require("../config/firebase");

const firestore = firebase.db;
const colRef = firestore.collection("careers");
const careers_pending_approval_colRef = firestore.collection(
  "careers_pending_approval"
);

export const NEW_CAREER_IDENTIFIER = "template";

export function getID() {
  return colRef.doc().id;
}

export function isAdmin(auth) {
  return (
    auth &&
    (auth.uid == "ssjIrte5P4T1wPrh5HepLhE80ks2" ||
      auth.uid == "xjTR9WiojnQRfwgkGtnPjUDjb263")
  );
}

export function isNewCareer(career) {
  return career.uid == NEW_CAREER_IDENTIFIER;
}

export function upsertCareer(career, auth) {
  if (isNewCareer(career)) {
    let uid = null;

    if (isAdmin(auth)) uid = colRef.doc().id;
    else uid = careers_pending_approval_colRef.doc().id;

    career.uid = uid;
    career.Id = uid;
    career.creator = auth.uid;
  }

  if (isAdmin(auth)) {
    return colRef.doc(career.uid).set(career);
  } else {
    return careers_pending_approval_colRef.doc(career.uid).set(career);
  }
}

export function getCareers() {
  return colRef.get();
}

export function getCareersPendingApproval() {
  return careers_pending_approval_colRef.get();
}

export function getCareer(careerUid) {
  return colRef
    .doc(careerUid)
    .get()
    .then(doc => {
      if (doc && doc.exists) return doc.data();
    })
    .catch(e => console.log(e));
}

export function getCareerByFilter(filter) {
  console.log(filter);
  return colRef
    .where(...filter)
    .get()
    .then(querySnapshot => {
      return querySnapshot.docs.map(doc => {
        return doc.data();
      });
    })
    .catch(e => console.log(e));
}

export function addCareer(career) {
  let id = colRef.doc().id;
  career.uid = id;
  return colRef.doc(id).set(career);
}