import React, { Component } from "react";
import { connect } from "react-redux";
import PostComponent from "./PostPage";

class PostContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <PostComponent {...this.props} {...this.state} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  detail: state.detail
});

export default connect(
  mapStateToProps,
  {}
)(PostContainer);
