import { SET_TOPIC } from "../types/communityTypes";

export const setTopic = value => dispatch => {
  dispatch({
    type: SET_TOPIC,
    payload: {
      value
    }
  });
};
