import {
  mlAuto,
  grayColor,
  cardTitle
} from "assets/jss/material-ui-styles.jsx";

import tooltipsStyle from "assets/jss/material-ui-styles/tooltipsStyle.jsx";

import imageStyles from "assets/jss/material-ui-styles/imagesStyles.jsx";

import rotatingCards from "assets/jss/material-ui-styles/rotatingCards.jsx";

const sectionOwnDiscussionsStyle = {
  mlAuto,
  ...imageStyles,
  ...rotatingCards,
  ...tooltipsStyle,
  section: {
    backgroundPosition: "50%",
    backgroundSize: "cover",
    padding: "70px 0"
  },
  textCenter: {
    textAlign: "center"
  },
  category: {
    color: "rgba(255, 255, 255, 0.7) !important",
    marginTop: "10px"
  },
  avatar: {
    width: "30px",
    height: "30px",
    overflow: "hidden",
    borderRadius: "50%",
    marginRight: "5px"
  },
  statsWhite: {
    color: "rgba(255, 255, 255, 0.8)",
    display: "inline-flex",
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "3px",
      marginRight: "3px",
      marginLeft: "3px",
      fontSize: "18px",
      lineHeight: "18px"
    },
    "& svg": {
      position: "relative",
      top: "3px",
      marginRight: "3px",
      marginLeft: "3px",
      width: "18px",
      height: "18px"
    }
  },

  authorWhite: {
    display: "inline-flex",
    "& a": {
      color: "rgba(255, 255, 255, 0.8)"
    }
  },

  cardTitle: {
    ...cardTitle,
    color: "#FFFFFF !important"
  },
  icons: {
    width: "1.1rem",
    height: "1.1rem",
    position: "relative",
    display: "inline-block",
    top: "0",
    marginTop: "-1em",
    marginBottom: "-1em",
    marginRight: "4px",
    verticalAlign: "middle"
  },
  tabSpace: {
    padding: "20px 0 50px"
  },

  postCardTitle: { ...cardTitle },
  postDescription: { fontSize: "1rem", color: grayColor[0] }
};

export default sectionOwnDiscussionsStyle;
