import {
  FETCH_CAREERS_START,
  FETCH_CAREERS,
  FILTER_CAREERS
} from "../types/careerTypes";
const careerServices = require("services/careerservices");
const userServices = require("services/userservices");

export const fetchCareers = () => dispatch => {
  dispatch({ type: FETCH_CAREERS_START });
  careerServices
    .getCareers()
    .then(snapshot => {
      var docs = [];
      snapshot.forEach(doc => {
        var newDoc = doc.data();
        docs.push(newDoc);
      });

      dispatch({
        type: FETCH_CAREERS,
        payload: docs
      });
    })
    .catch(err =>
      // dispatch({
      //     type: FETCH_CAREERS_ERROR,
      //     payload: { error: err, loadingCareers: false }
      // })
      console.log(err)
    );
};

export const filterCareersByNameAndArea = (
  careers,
  valueToSearch
) => dispatch => {
  const regex = new RegExp(
    valueToSearch.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
    "i"
  );
  const filteredCareers = careers.filter(career => {
    return (
      career.Area.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .match(regex) ||
      career.Name.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .match(regex) ||
      career.SearchTags.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .match(regex)
    );
  });
  dispatch({
    type: FILTER_CAREERS,
    payload: filteredCareers
  });
};

export const filterCareers = (allCareers, filters) => dispatch => {
  var filteredCareers = allCareers.filter(career => {
    for (var key in filters) {
      if (
        career[key] === undefined ||
        !operators[filters[key].operator](career[key], filters[key].value)
      ) {
        return false;
      }
    }
    return true;
  });
  return filteredCareers;
};

// export const addCareer = (career) => dispatch => {
//     return careerServices.addCareer(career);
// }
// export const updateCareer = (value, field) => dispatch => {
//     dispatch({
//         type: UPDATE_NEW_CAREER,
//         payload: {
//             field,
//             value
//         }
//     });
// };

// export const showFilters = (value) => dispatch => {
//     dispatch({
//         type: SHOW_FILTERS,
//         payload: {
//             value
//         }
//     });
// };

// export const forceShowFilters = (value) => dispatch => {
//     dispatch({
//         type: FORCE_SHOW_FILTERS,
//         payload: {
//             value
//         }
//     });
// };

// export const setCareer = (value) => dispatch => {
//     dispatch({
//         type: SET_CAREER,
//         payload: {
//             value
//         }
//     });
// };

export const operators = {
  "+": (a, b) => {
    return a + b;
  },
  "==": (a, b) => {
    return a == b;
  },
  ">": (a, b) => {
    return a > b;
  },
  ">=": (a, b) => {
    return a >= b;
  },
  "<": (a, b) => {
    return a < b;
  },
  "<=": (a, b) => {
    return a <= b;
  },
  "!=": (a, b) => {
    return a != b;
  },
  in: (a, b) => {
    return b.indexOf(a) != -1;
  },
  inStr: (a, b) => {
    if (b.lenght == 0 || (b.length == 1 && b[0] == "")) return true;

    let result = false;

    b.forEach(str => {
      if (
        a
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .indexOf(
            str
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
          ) != -1
      ) {
        result = true;
      }
    });

    return result;
  }, //si es vacio entonces es un string valido
  notin: (a, b) => {
    return b.indexOf(a) == -1;
  }
};
