import React from "react";
import Hidden from "@material-ui/core/Hidden";
import Footer from "components/Footer/Footer.jsx";
import Header from "components/Header/HeaderSteps.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

class ProgressStepPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <Header
          authenticatedUser={this.props.auth}
          links={<HeaderLinks authenticatedUser={this.props.auth} />}
          fixed
          color="transparent"
          forceTransparent={true}
          changeColorOnScroll={{
            height: 50,
            color: "white"
          }}
          progressStep={this.props.progressStep}
        />
        <div
          className={classes.pageHeader}
          style={{ paddingTop: 40, backgroundImage: `url("${this.props.background}")` }}
        >
          <div className={classes.container}>
            <Hidden mdUp>
              {this.props.progressStep}
            </Hidden>
            <GridContainer
              justify="center"
              alignItems="center"
              direction="column"
              style={{ color: "white", textAlign: "center" }}
            >
              <GridItem
                xs={12}
                sm={12}
                md={12}
                className={`${classes.mlAuto} ${classes.mrAuto} ${
                  classes.textCenter
                  }`}
              >
                <h1 className={classes.title}>{this.props.title}</h1>
                  {this.props.text}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                {this.props.children}
              </GridItem>
              <GridItem style={{ marginTop: "10px", marginBottom: "10px" }}>
                <GridContainer justify="center">
                  {this.props.buttons.map((button, index) => {
                    return <GridItem
                      key={index}
                      xs={6}
                      sm={6}
                      md={3}
                      className={(classes.mlAuto, classes.mrAuto, classes.textCenter)}
                    >
                      {button}
                    </GridItem>
                  })}
                </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default ProgressStepPage;
