const axios = require("axios");

const appConfig = require("config/appConfig");

const eventsHelper = require("helpers/eventsHelper");

function _promotionalCode(auth, token, promotionalCode) {
  let purchaseRequestData = {
    code: promotionalCode,
    firebaseUserId: auth.uid
  };

  let config = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: `application/vnd.iman.v1+json, application/json, text/plain, */*`,
      "App-Version": 1
    }
  };

  return axios
    .post(
      `${appConfig.gcfUrl}/be_premium/promotional-code`,
      purchaseRequestData,
      config
    )
    .catch(e => {
      eventsHelper.registerEvent(auth, "E", e, {
        promotionalCode: promotionalCode,
        message: e.message,
        code: e.code
      });
      throw e;
    });
}

function _purchase(auth, token, paymentData) {
  return new Promise(function (resolve, reject) {
    const mercadopago = window.Mercadopago;

    mercadopago.setPublishableKey(appConfig.mercadoPagoApiKey);

    // limpiar sesion, luego de "payment" previo
    mercadopago.clearSession();

    mercadopago.createToken(
      {
        cardNumber: paymentData.card.cardNumber,
        securityCode: paymentData.card.cvv,
        cardExpirationMonth: paymentData.card.expiryMonth,
        cardExpirationYear: paymentData.card.expiryYear,
        cardholderName: paymentData.card.cardholderName,
        installments: paymentData.card.installments,
        docType: paymentData.card.docType,
        docNumber: paymentData.card.cardHolderIdentificationNumber
      },
      function (status, response) {
        createTokenCallback(
          status,
          response,
          auth,
          token,
          paymentData,
          resolve,
          reject
        );
      }
    );
  });
}

function createTokenCallback(
  status,
  responseToken,
  auth,
  token,
  paymentData,
  resolve,
  reject
) {
  if (status != 200) {
    let erroData = {};
    if (responseToken.cause && responseToken.cause.length != 0)
      erroData = {
        code: responseToken.cause[0].code,
        description: responseToken.cause[0].description
      };
    else
      erroData = {
        code: responseToken.error,
        description: responseToken.message
      };

    console.log("Error al realizar el pago: ", status, responseToken);

    eventsHelper.registerEvent(
      auth,
      "E",
      {
        message: "Pago con error",
        status: status,
        responseToken: responseToken,
        response: erroData
      },
      null
    );

    //let errorData = {code:  };
    reject(erroData);
  } else {
    //none
    let creditCardType = "0";

    if (/^4[0-9]{6,}$/.test(paymentData.card.cardNumber.split(" ").join("")))
      creditCardType = 1;
    //visa
    else if (
      /^5[1-5][0-9]{5,}|222[1-9][0-9]{3,}|22[3-9][0-9]{4,}|2[3-6][0-9]{5,}|27[01][0-9]{4,}|2720[0-9]{3,}$/.test(
        paymentData.card.cardNumber.split(" ").join("")
      )
    )
      creditCardType = 2;
    //mastercard
    else if (
      /^3[47][0-9]{5,}$/.test(paymentData.card.cardNumber.split(" ").join(""))
    )
      creditCardType = 3; //amex

    let purchaseRequestData = {
      mpTokenId: responseToken.id,
      purchaseOption: paymentData.purchaseOption,
      firebaseUserId: auth.uid,
      creditCardType: creditCardType,
      installments: paymentData.card.installments,
      environment: process.env.NODE_ENV //todo - no debería existir acá por cuestiones de seguridad, ahora por comodidad queda...
    };

    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: `application/vnd.iman.v1+json, application/json, text/plain, */*`,
        "App-Version": 1
        // "Access-Control-Allow-Origin": "*"
      }
    };

    axios
      .post(appConfig.gcfUrl + "/be_premium/full", purchaseRequestData, config)
      .then(paymentResult => {
        var data = null;

        if (paymentResult && paymentResult.data) {
          eventsHelper.registerEvent(
            auth,
            "I",
            {
              message: "Pago aprobado",
              request: purchaseRequestData,
              response: paymentResult
            },
            null
          );
          resolve(data);
        } else {
          eventsHelper.registerEvent(
            auth,
            "E",
            {
              message: "Pago no aprobado (purchase)",
              request: purchaseRequestData,
              response: paymentResult
            },
            null
          );

          reject(paymentResult);
        }
      })
      .catch(e => {
        eventsHelper.registerEvent(auth, "E", e, {
          message: "Error al realizar el pago (purchase)",
          request: purchaseRequestData,
          message: e.message,
          code: e.code
        });
        reject(e);
      });
  }
}

export const purchase = _purchase;
export const promotionalCode = _promotionalCode;
