import { createI18n } from "react-router-i18n";

const translations = require('./translations.json');

const LOCALES = ["ar", "cl", "es"];

const I18n = createI18n(LOCALES, translations);

export default I18n;

export const getCountryWithLocation = (countryCode = "ar") =>
  ({
    ar: "Argentina",
    cl: "Chile",
    es: "España",
  }[countryCode] || "Argentina");

export const getLocales = () => LOCALES;

export const getCurrentLocation = (location = {}) =>
  location.pathname.match("/(.*?)/")
    ? LOCALES.includes(location.pathname.match("/(.*?)/")[1])
      ? location.pathname.match("/(.*?)/")[1]
      : null
    : null;


