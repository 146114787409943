import React from "react";
import ProgressStepPageContainer from '../ProgressStepPage/ProgressStepPageContainer';
import withStyles from "@material-ui/core/styles/withStyles";
import headersStyle from "assets/jss/material-ui-styles/views/sectionsSections/headersStyle.jsx";
import { addStepToProgress } from '../../redux/progress/actions';
import { connect } from "react-redux";
import Button from "components/CustomButtons/Button.jsx";
import TestDots from "../../components/Tests/TestDots";
import { getTest } from "../../services/testsservices";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.jsx";
import groupBy from "lodash/groupBy"
import orderBy from "lodash/orderBy"
import sumBy from "lodash/sumBy"
import I18n from "../../i18n";

const groupedQuestionsQuantity = 5;

class ProgressCIPContainer extends ProgressStepPageContainer {
    constructor(props) {
        super(props);
    }

    getStateToSave() {
        let infoToDisplay = this.state.results.reduce((previous, result) => {
            return [
                ...previous,
                `${[result.Code]}: ${result.Value}`
            ];
        }, []);

        return { ...this.state, infoToDisplay }
    }

    calculateResults() {
        const questionsAndRate = this.state.questions.map(question => {
            const [{ Value }] = question.Rates.filter(rate => rate.selected)
            return {
                GroupCode: question.GroupCode,
                Value: parseInt(Value)
            }
        });
        const groupedQuestions = groupBy(questionsAndRate, "GroupCode");
        let groupedQuestionsWithAvg = [];
        Object.keys(groupedQuestions).forEach(key => {
            groupedQuestionsWithAvg = [...groupedQuestionsWithAvg, {
                Code: key,
                Value: sumBy(groupedQuestions[key], "Value")
            }];
        })
        const results = orderBy(groupedQuestionsWithAvg, ["Value"], ["desc"]);

        this.setState({ results, disableButton: false });
    }

    executeCustomComponentDidMount() {
        getTest("CIP").then(test => {
            const { Questions, Results } = test;
            this.setState({ questions: Questions, interpretations: Results })
        })
    }

    getDefaultState() {
        return {
            questions: [],
            disableButton: true,
            questionsGroup: 0,
            showFirstDescription: true,
            results: [],
            count: 0
        };
    }

    getText() {
        if (this.state.results.length > 0) {
            return <I18n t="STEP_TEST_CIP_FOOTER3"/>
        } else if (this.state.showFirstDescription) {
            return super.getText();
        }
        return <I18n t="STEP_TEST_CIP_FOOTER2"/>
    }

    getTitle() {
        return this.state.showFirstDescription ? super.getTitle() : "CIP";
    }

    getChildren(classes) {
        if (this.state.results.length > 0) {
            const elementsToShow = this.state.results.slice(0, 4);

            return <div>
                {
                    elementsToShow.map((element, index) => {
                        return <div>
                            <h4><b>{index + 1}. {element.Code}</b></h4>
                            <h4>{this.getInterpretationText(element.Code)}</h4>
                        </div>
                    })
                }
            </div>
        }
        return this.state.showFirstDescription ? null : <GridContainer>
            <GridItem>
                <TestDots questions={this.getQuestions()} onClick={this.handleClick.bind(this)} loadTestData={this.loadTestData.bind(this)} />
            </GridItem>
        </GridContainer>
    }

    getProgressStep() {
        return this.state.showFirstDescription ? null : <CustomLinearProgress
            variant="determinate"
            value={((this.state.questionsGroup + 1) * groupedQuestionsQuantity * (100 / this.state.questions.length))}
            style={{
                listStyle: "none",
                position: "relative",
                marginTop: "20px",
            }}
            color="primary"
        />
    }

    getButtons() {
        if (this.state.showFirstDescription) {
            return [
                <Button
                    onClick={() => this.setState({ showFirstDescription: false, disableButton: true })}
                    color="primary"
                    size="lg"
                    style={{ width: "100%" }}
                >
                    Comenzar
                </Button>
            ]
        }

        //Proximo paso
        if (this.state.questionsGroup == (this.state.questions.length / groupedQuestionsQuantity) - 1 && this.state.results.length > 0) {
            return [this.getDefaultButton()]
        }

        //Ultima pregunta - Ver resultados
        if (this.state.questionsGroup == (this.state.questions.length / groupedQuestionsQuantity) - 1) {
            return [
                <Button
                    onClick={() => this.calculateResults()}
                    disabled={this.state.count < groupedQuestionsQuantity}
                    color="primary"
                    size="lg"
                    style={{ width: "100%" }}
                >
                    Ver resultados
                </Button>
            ]
        }

        return [
            <Button
                onClick={() => this.setState({ questionsGroup: this.state.questionsGroup + 1, disableButton: true, count: 0 }, () => window.scrollTo(0, 0))}
                disabled={this.state.count < groupedQuestionsQuantity}
                color="primary"
                size="lg"
                style={{ width: "100%" }}
            >
                Siguiente Pregunta
            </Button>
        ];
    }

    handleClick(order, value) {
        let questions = [...this.state.questions];
        const questionIndex = questions.findIndex(question => question.Order == order);
        if (questionIndex >= 0) {
            questions[questionIndex].Rates.forEach(rate => {
                rate.selected = rate.Value == value;
            });

            this.setState({
                questions,
                count: this.state.count + 1
            });
        }
    }

    getQuestions() {
        let questions = [...this.state.questions];
        const inicio = this.state.questionsGroup * groupedQuestionsQuantity;
        const final = this.state.questionsGroup * groupedQuestionsQuantity + groupedQuestionsQuantity;

        return questions.slice(inicio, final);
    }

    getInterpretationText(code) {
        const [selectedInterpretation] = this.state.interpretations.filter(result => result.Code == code);

        return selectedInterpretation.Text;
    }

    loadTestData() {
        if (this.props.auth.rols && this.props.auth.rols.includes("admin")) {
            const newQuestions = this.state.questions.map(question => {
                const index = Math.floor(Math.random() * 2) + 1;
                question.Rates[index].selected = true;
                return question;
            });
            this.setState({ questions: newQuestions, showFirstDescription: false, questionsGroup: (this.state.questions.length / groupedQuestionsQuantity) - 1, disableButton: false, count: 5 })
        }
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    progress: state.progress
});

const mapDispatchToProps = {
    addStepToProgress
}

export default withStyles(headersStyle)(connect(mapStateToProps, mapDispatchToProps)(ProgressCIPContainer));