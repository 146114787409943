import { LOGIN_SET_STATE, LOGIN_SET_VALUE } from '../types/loginTypes';

var initialState = {
    signupModal: false,
    noticeModal: false,
    errorCode: '',
    functionalError: false,
    errorTitle: '',
    errorMessage: '',
    termsChecked: false,
    isSigninIn: false,
    registrationUsername: '',
    registrationPassword: '',
    registrationEmail: '',
    email: '',
    password: ''
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_SET_STATE:
            return { ...state, ...action.payload };
        case LOGIN_SET_VALUE:
            var obj = {};
            obj[action.payload.field] = action.payload.value;
            return { ...state, ...obj };
        default:
            return state;
    }
};
