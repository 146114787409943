import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Check from "@material-ui/icons/Check";

// core components
import cardStyle from "assets/jss/material-ui-styles/components/cardStyle.jsx";

import CardHeader from "components/Card/CardHeader.jsx";
import Checkbox from "@material-ui/core/Checkbox";
import Hidden from "@material-ui/core/Hidden";

function CardSearchResult({ ...props }) {
  const {
    classes,
    className,
    children,
    plain,
    profile,
    blog,
    raised,
    background,
    pricing,
    color,
    product,
    testimonial,
    ...rest
  } = props;

  return (
    // <Card plain className={classes.card3}>
    <GridContainer
      xs={12}
      sm={12}
      md={12}
      className={classNames(
        classes.mlAuto,
        classes.mrAuto,
        classes.cardSearchResult
      )}
      style={{
        background: "#FFF",
        borderRadius: 6,
        paddingTop: 10,
        paddingBottom: 10,
        marginLeft: "auto",
        marginRight: "auto",
        cursor: "pointer"
      }}
      onClick={props.onClick}
    >
      <GridItem
        xs={4}
        sm={4}
        md={4}
        style={{ marginTop: 10, marginBottom: 10 }}
      >
        <CardHeader image plain>
          <a href="javascript:void(0)" onClick={e => e.preventDefault()}>
            <img src={props.image} alt="..." style={{ maxWidth: 150 }} />
          </a>
          <div
            className={classes.coloredShadow}
            style={{
              backgroundImage: `url(${props.image})`,
              opacity: "1"
            }}
          />
        </CardHeader>
      </GridItem>
      <GridItem xs={6} sm={6} md={6} className={classes.gridItemContainer}>
        <CardBody plain style={{ padding: 0 }}>
          <h4
            className={classes.cardTitleFontResize}
            style={{
              marginTop: 0,
              color: "black"
              // maxWidth: 250,
              // minWidth: 250,
              // display: "inline"
            }}
          >
            {props.title}
          </h4>
          <Hidden xsDown implementation="css" className={classes.hidden}>
            <p
              className={classes.description}
              style={{ maxWidth: 250, minWidth: 250, margin: "auto" }}
            >
              {props.description}
            </p>
          </Hidden>
        </CardBody>
      </GridItem>
    </GridContainer>
    // </Card>
  );
}
export default withStyles(cardStyle)(CardSearchResult);
