import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components

import HeaderBack from "components/Header/HeaderBack.jsx";

import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import headersStyle from "assets/jss/material-ui-styles/views/sectionsSections/headersStyle.jsx";

import classNames from "classnames";
// @material-ui/icons
import Edit from "@material-ui/icons/Edit";

import Table from "components/Table/Table.jsx";
import Loading from "components/Loader/Loading.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Email from "@material-ui/icons/Email";
import InputAdornment from "@material-ui/core/InputAdornment";
const eventservices = require("services/eventservices");

class LogsAdminPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      logs: [],
      isLoading: true
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    this.filterEvents();
  }
  filterEvents() {
    let logs = [];
    eventservices.getEventsByEmail(this.state.filterEmail).then(snapshot => {
      snapshot = snapshot.sort((a, b) => {
        return b.created.toDate() - a.created.toDate();
      });
      snapshot.forEach(log => {
        logs.push([
          log.created.toDate().toLocaleDateString(),
          log.eventType,
          log.userEmail,
          log.userUid,
          log.info,
          log.data,
          log.location,

          <div>
            <Button
              color="info"
              size="sm"
              onClick={() => {
                // this.goToCareerEditionPage(career);
              }}
            >
              <Edit />
            </Button>
          </div>
        ]);

        this.setState({ logs: logs, isLoading: false });
      });
    });
  }

  render() {
    const { classes, ...rest } = this.props;

    return (
      <div>
        <HeaderBack
          authenticatedUser={this.props.auth}
          fixed
          color="transparent"
          forceTransparent={true}
          {...rest}
        />
        <div className={classes.pageHeader} style={{ height: 150 }} />

        <div>
          <GridContainer justify="center">
            <GridItem
              xs={12}
              sm={12}
              md={12}
              className={classNames(classes.mlAuto, classes.mrAuto)}
              style={{ textAlign: "center" }}
            >
              <CustomInput
                formControlProps={{
                  fullWidth: false,
                  className: classes.customFormControlClasses
                }}
                inputProps={{
                  value: this.state.filterEmail,
                  onChange: e => {
                    this.setState({
                      filterEmail: e.target.value
                    });
                  },
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.inputAdornment}
                    >
                      <Email className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  ),
                  placeholder: "Email"
                }}
              />

              <Button
                color="info"
                size="sm"
                onClick={() => {
                  this.filterEvents();
                }}
              >
                Filtrar
              </Button>
            </GridItem>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              className={classNames(classes.mlAuto, classes.mrAuto)}
            >
              {this.state.isLoading ? (
                <Loading />
              ) : (
                <Table
                  tableHead={[
                    "created",
                    "eventType",
                    "userEmail",
                    "userId",
                    "info",
                    "data",
                    "location",
                    ""
                  ]}
                  tableData={this.state.logs}
                  customCellClasses={[
                    classes.textCenter,
                    classes.textRight,
                    classes.textRight
                  ]}
                  customClassesForCells={[0, 4, 5]}
                  customHeadCellClasses={[
                    classes.textCenter,
                    classes.textRight,
                    classes.textRight
                  ]}
                  customHeadClassesForCells={[0, 4, 5]}
                />
              )}
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(headersStyle)(LogsAdminPage);
