import {
  CAREERSPAGE_FETCH_CAREERS_START,
  CAREERSPAGE_FETCH_CAREERS,
  CAREERSPAGE_FETCH_CAREERS_ERROR
} from "../types/careerPageTypes";
const careerPageServices = require("services/careerspageservices");

export const fetchCareers = () => dispatch => {
  dispatch({ type: CAREERSPAGE_FETCH_CAREERS_START });
  careerPageServices.getCareersPageLists().then(data => {
    dispatch({
      type: CAREERSPAGE_FETCH_CAREERS,
      payload: data
    });
  })
  .catch((e) => {
    dispatch({
      type: CAREERSPAGE_FETCH_CAREERS_ERROR,
      error: e
    });

  })
  ;
};
