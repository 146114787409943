import { LOGIN_SET_STATE, LOGIN_SET_VALUE } from '../types/loginTypes';

export const setState = (state) => dispatch => {
    dispatch({
        type: LOGIN_SET_STATE,
        payload: state
    });
};

export const resetUserDataState = () => dispatch => {
    dispatch({
        type: LOGIN_SET_STATE,
        payload: {
            registrationUsername: '',
            registrationPassword: '',
            registrationEmail: '',
            email: '',
            password: ''
            //termsChecked: false //no lo reseteo intencionalmente porque esto lo estoy manejando tipo toogle
        }
    });
}

export const updateValue = (value, field) => dispatch => {
    dispatch({
        type: LOGIN_SET_VALUE,
        payload: {
            field,
            value
        }
    });
};

