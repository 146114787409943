var firebase = require("config/firebase");
const uuidv4 = require("uuid/v4");

const firestore = firebase.db;
const colRef = firestore.collection("universities");

function _getID() {
  return colRef.doc().id;
}

function _upsertUniversity(university) {
  colRef
    .doc(university.uid)
    .set(university)
    .catch(err => console.log(err));
}

function _getUniversities() {
  return colRef.get().then(snapshot => {
    var docs = [];
    snapshot.forEach(doc => {
      var newDoc = doc.data();
      docs.push(newDoc);
    });
    return docs;
  });
}

function _getUniversitiesByCountry(country) {
  console.log(country);
  return colRef.where("Geo.Country", "==", country).get().then(snapshot => {
    return snapshot.docs.map(doc => doc.data())
  });
}

function _getUniversity(uid) {
  return colRef
    .doc(uid)
    .get()
    .then(doc => {
      if (doc && doc.exists) return doc.data();
    })
    .catch(e => console.log(e));
}

function _addUniversity(university) {
  let id = colRef.doc().id;
  university.uid = id;
  return colRef.doc(id).set(university);
}

export function filterUniversitiesByNameAndGroupCode(universities, valueToSearch) {
  const regex = new RegExp(
    valueToSearch.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
    "i"
  );
  const filteredUniversities = universities.filter(university => {
    return (
      university.GroupCode.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .match(regex) ||
      university.Name.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .match(regex)
    );
  });

  return filteredUniversities;
}

export const upsertUniversity = _upsertUniversity;
export const getUniversity = _getUniversity;
export const getUniversities = _getUniversities;
export const getUniversitiesByCountry = _getUniversitiesByCountry;
export const getID = _getID;
export const addUniversity = _addUniversity;
