import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Media from "components/Media/Media.jsx";
import Button from "components/CustomButtons/Button.jsx";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import sectionChatStyle from "./sectionChatStyle.jsx";
import { connect } from "react-redux";

import CustomInput from "components/CustomInput/CustomInput.jsx";

import CircularProgress from "@material-ui/core/CircularProgress";
import DoneAll from "@material-ui/icons/DoneAll";

const userMessageservices = require("services/userMessageservices");
const mentorServices = require("services/mentorservices");

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class SectionChat extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openErrorModal: false,
      openSuccessModal: false,
      loading: false
    };
  }

  componentDidMount() {
    this.setState({
      loading: true
    });

    this.loadMessages().then(() => {
      this.setState({
        loading: false
      });
    });
  }

  loadMessages() {
    return userMessageservices
      .getConversation(this.props.auth.uid, this.props.receiverUid)
      .then(conversationWithMessages => {
        if (conversationWithMessages)
          this.setState({
            conversationUid: conversationWithMessages.conversation.uid,
            messages: conversationWithMessages.messages
          });
      });
  }

  postNewComment(event) {
    this.setState({
      loading: true
    });
    mentorServices
      .sendMessage(
        this.props.auth,
        this.props.auth.email,
        this.props.receiverUid,
        this.props.receiverEmail,
        this.state.postReplyContent,
        this.state.conversationUid
      )
      .then(addedMessage => {
        // this.setState({ conversationUid: addedMessage.conversationUid });

        this.loadMessages().then(() => {
          this.setState({
            loading: false,
            postReplyContent: ""
            //openSuccessModal: true,
          });
        });
      })
      .catch(e => {
        console.log(e);
        this.setState({ showLoading: false });
      });
  }

  render() {
    let props = this.props;
    const { classes } = props;

    if (this.state.loading)
      return (
        <div>
          <GridContainer justify="center">
            <GridItem xs={1} sm={1} md={1}>
              <CircularProgress className={classes.progress} />
            </GridItem>
          </GridContainer>
        </div>
      );

    return (
      // <div className={classes.section}>
      <div>
        {/* NOTICE MODAL START */}

        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: classes.modal
          }}
          open={this.state.openErrorModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => {
            this.setState({ openErrorModal: false });
          }}
          aria-labelledby="notice-modal-slide-title"
          aria-describedby="notice-modal-slide-description"
        >
          <DialogTitle
            id="notice-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h4 className={classes.modalTitle}>Hubo un error</h4>
          </DialogTitle>
          <DialogContent
            id="notice-modal-slide-description"
            className={classes.modalBody}
          >
            Por favor reintentá
          </DialogContent>
        </Dialog>

        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: classes.modal
          }}
          open={this.state.openSuccessModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => {
            this.setState({ openSuccessModal: false });
          }}
          aria-labelledby="notice-modal-slide-title"
          aria-describedby="notice-modal-slide-description"
        >
          <DialogTitle
            id="notice-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h4 className={classes.modalTitle}>
              Recibimos tu mensaje !, en poco tiempo recibirás una respuesta
            </h4>
          </DialogTitle>
          <DialogContent
            id="notice-modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer justify="center">
              <GridItem xs={1} sm={1} md={1}>
                <DoneAll />
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>

        {/* NOTICE MODAL END */}

        <GridContainer justify="center" id="topic-comments">
          <GridItem xs={12} sm={12} md={12}>
            {
              <div>
                {/* <h3 className={classes.title}>{topic.total_replies + " Comentarios"}</h3> */}

                {this.state.messages &&
                  this.state.messages.map((reply, i) => {
                    let avatar_1 = "/img/unknown_avatar.png";

                    if (reply.author_uid == this.props.auth.uid)
                      avatar_1 = this.props.auth.photoURL + "?type=large";
                    else if (this.props.receiverAvatar)
                      avatar_1 = this.props.receiverAvatar;

                    return (
                      <Media
                        key={i}
                        avatar={avatar_1}
                        title={
                          <span>
                            {reply.author_name}{" "}
                            <small>
                              · {reply.post_date.toDate().toLocaleDateString()}
                            </small>
                          </span>
                        }
                        body={
                          <p
                            className={classes.color555}
                            dangerouslySetInnerHTML={{ __html: reply.content }}
                          />
                        }
                        footer={
                          <div>
                            {/* <Tooltip
                          id="tooltip-tina"
                          title="Responder"
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <Button
                            color="primary"
                            simple
                            className={classes.footerButtons}
                          >
                            <Reply className={classes.footerIcons} /> Responder
                          </Button>
                        </Tooltip>
                        */}
                            <br />
                          </div>
                        }
                      />
                    );
                  })}
              </div>
            }

            {this.props.title ? (
              this.props.title
            ) : (
              <h3 className={classes.title}>Chateá</h3>
            )}
            <Media
              avatar={
                this.props.auth && this.props.auth.photoURL
                  ? this.props.auth.photoURL
                  : "/img/unknown_avatar.png"
              }
              body={
                <CustomInput
                  labelText="Tu mensaje"
                  id="postReplyContent"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.postReplyContent,
                    onChange: e =>
                      this.setState({ postReplyContent: e.target.value }),
                    multiline: true,
                    rows: 5
                  }}
                />
              }
              footer={
                <Button
                  color="primary"
                  round
                  className={classes.footerButtons}
                  onClick={event => this.postNewComment()}
                >
                  Enviar
                </Button>
              }
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const maptStateToProps = state => ({
  user: state.auth
});

export default connect(maptStateToProps)(
  withStyles(sectionChatStyle)(SectionChat)
);
